import { object, string, boolean, number, StringSchema, NumberSchema, array, Schema, mixed, } from "yup";

import { FormikContextType } from 'formik';

export const REQUIRED_MSG = "Required field: please enter a value.";
export const CHECK_BOX_MSG = "Required field: Please check the box.";
export const OPTION_MSG = "Required field: please choose an option.";

// Utility function for `oneOf` validation
 const oneOfValidation = (message = CHECK_BOX_MSG) => ({
  oneOf: {
    values: ["Y"],
    message,
  },
});

export const RequiredField = (
  name: string,
  message: string = REQUIRED_MSG,
  required: boolean = true,
  type: "string" | "number" = "string",
  oneOf: boolean = false
): FieldConfig => ({
  name,
  type,
  required,
  message,
  ...(oneOf && oneOfValidation(message)),
});


// Utility function for conditional fields
export const ConditionalField = (
  name: string,
  key: string,
  value: string = "Y",
  required: boolean = true,
  message: string = REQUIRED_MSG,
  type: "string" | "number" = "string",
  oneOf: boolean = false
): FieldConfig => ({
  name,
  type,
  nullable: true,
  when: { key, value, required, message },
  ...(oneOf && oneOfValidation(message)),
});

// Define the field configuration type
export interface WhenConfig {
  key: string;
  value: any;
  required?: boolean;
  message?: string;
  max?: number;
  maxMessage?: string;
  moreThan?: number;
  moreThanMessage?: string;
  lessThan?: number;
  lessThanMessage?: string;
};

export interface FieldConfig {
  name: string;
  type: "string" | "number" | "boolean" | "array";
  required?: boolean;
  message?: string;
  matches?: RegExp;
  matchesMessage?: string;
  existNotBeBlank?: boolean;
  max?: number;
  maxMessage?: string;
  min?: number;
  minMessage?: string;
  positive?: boolean;
  integer?: boolean;
  nullable?: boolean;
  moreThan?: number;
  lessThan?: number;
  lessThanMessage?: string;
  moreThanMessage?: string;
  when?: WhenConfig;
  schema?: FieldConfig[];
  oneOf?: {
    values?: string[],
    message?: string
  }
}

// Regular expressions for validation
export const AlphanumericRegex = /^[0-9a-zA-Z]+$/;
export const NotAllowPipeRegex = /^[^|]*$/;

export const CreateDynamicSchema = (config: any) => {
  const schemaFields: any = {};
  config.forEach((field: any) => {
    let schemaField: any;
    switch (field.type) {
      case "string":
        schemaField = string().trim();
        if (field.required) {
          schemaField = schemaField.required(field.message);
        }
        if (field.matches) {
          schemaField = schemaField.matches(field.matches, { message: field.matchesMessage });
        }
        if (field.oneOf) {  // Check if 'oneOf' condition is present
          schemaField = schemaField.oneOf(field.oneOf.values, field.oneOf.message || "Invalid value");
        }
        if (field.max) {
          schemaField = schemaField.max(field.max, field.maxMessage);
        }
        if (field.min) {
          schemaField = schemaField.min(field.min, field.minMessage);
        }
        break;
      case "boolean":
        schemaField = boolean();
        if (field.required) {
          schemaField = schemaField.required(field.message);
        }
        break;
      case "number":
        schemaField = number();
        if (field.required) {
          schemaField = schemaField.required(field.message);
        }
        if (field.positive) {
          schemaField = schemaField.positive();
        }
        if (field.integer) {
          schemaField = schemaField.integer();
        }
        if (field.max) {
          schemaField = schemaField.test("len", field?.maxMessage!, (val: string | number) => val === undefined || val.toString().length <= field?.max!);
        }
        if (field.moreThan) {
          schemaField = schemaField.moreThan(field.moreThan, field.moreThanMessage);
        }
        if (field.lessThan) {
          schemaField = schemaField.lessThan(field.lessThan, field.lessThanMessage);
        }
        break;
      case "array":
        let innerSchemaFields: { [key: string]: Schema } = {};
        field.schema?.forEach((innerField: FieldConfig) => {
          let innerSchemaField: any;

          switch (innerField.type) {
            case "string":
              innerSchemaField = string().trim();
              if (innerField.required) {
                innerSchemaField = innerSchemaField.required(innerField.message || "Required");
              }
              if (innerField.min) {
                innerSchemaField = innerSchemaField.min(innerField.min, innerField.minMessage);
              }
              if (innerField.existNotBeBlank) {
                innerSchemaField = innerSchemaField.test(
                  'is-not-blank',
                  'Field cannot be blank if it already exists, you can only modify it',
                  function (this: { options: { context: FormikContextType<any> } }, value: any) {
                    const { initialValues } = this.options.context;
                    if (initialValues[innerField.name] && !value) {
                      return false;
                    }
                    return true;
                  }
                );
              }

              break;
            case "number":
              innerSchemaField = number();
              if (innerField.required) {
                innerSchemaField = innerSchemaField.required(innerField.message || "Required");
              }
              if (innerField.max) {
                innerSchemaField = innerSchemaField.max(innerField.max, innerField.maxMessage);
              }
              if (innerField.moreThan) {
                innerSchemaField = innerSchemaField.moreThan(innerField.moreThan, innerField.moreThanMessage);
              }
              if (innerField.lessThan) {
                innerSchemaField = innerSchemaField.lessThan(innerField.lessThan, innerField.lessThanMessage);
              }
              break;
            case "boolean":
              innerSchemaField = boolean();
              if (innerField.required) {
                innerSchemaField = innerSchemaField.required(innerField.message || "Required");
              }
              break;
            // Add cases for other types as needed

            default:
              innerSchemaField = string();
              break;
          }

          if (innerField.when) {
            innerSchemaField = innerSchemaField.when(innerField.when.key, {
              is: innerField.when.value,
              then: (schema: any) => {
                if (innerField.when?.required) {
                  schema = schema.required(innerField.when.message || "Required");
                }
                // Add other conditions as needed

                return schema;
              },
              otherwise: mixed().notRequired(),
            });
          }

          innerSchemaFields[innerField.name] = innerSchemaField;
        });

        schemaField = array().of(object().shape(innerSchemaFields));

        if (field.required) {
          schemaField = schemaField.required(field.message || "Required");
        }
        if (field.min) {
          schemaField = schemaField.min(field.min, field.minMessage);
        }
        break;
      default:
        schemaField = string();
        break;
    }

    if (field.when) {
      schemaField = schemaField?.when(field.when.key, {
        is: field.when.value,
        then: (schema: any) => {
          if (field?.when?.required) {
            schema = schema.required(field?.when?.message!);
          }
          if (field?.when?.max) {

            schema = schema.test("len", field?.when?.maxMessage!, (val: string | number) => val === undefined || val.toString().length <= field?.when?.max!);
          }

          if (field?.when?.moreThan) {
            schema = schema.moreThan(field?.when?.moreThan, field?.when?.moreThanMessage);
          }
          return schema;
        },
        otherwise: (schema: StringSchema | NumberSchema) => schema.notRequired(),
      });
    }
    schemaFields[field.name] = schemaField;
  });

  return object().shape(schemaFields);

};