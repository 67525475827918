import { IDSInvestigation } from "../models/dsException";
import {
  CHECK_BOX_MSG,
  ConditionalField,
  CreateDynamicSchema,
  FieldConfig,
  OPTION_MSG,
  REQUIRED_MSG,
  RequiredField,
} from "./formik";

//  AS Investigation form Start
const dsDay1Fields: FieldConfig[] = [
  // Day 1 Section (6 keys)
  RequiredField("statusCorrect", CHECK_BOX_MSG, true, "string", true),
  ConditionalField("showAtDestTerm", "statusCorrect", "Y", false),
  RequiredField("deliveryCode", CHECK_BOX_MSG, true, "string", true),
  ConditionalField("deliveryCodeResponse", "deliveryCode", "Y", false),
  RequiredField("epImaging", OPTION_MSG),
  RequiredField("epImagingResponse"),
  RequiredField("day1F18Note", "", false),
];

const dsDay2Fields: FieldConfig[] = [
  RequiredField("noMovement", CHECK_BOX_MSG, true, "string", true),
  ConditionalField("dateLastMovement", "noMovement"),
  ConditionalField("noMovementSteps", "noMovement", "Y", false),
  RequiredField("suffixBill", CHECK_BOX_MSG, true, "string", true),
  ConditionalField("suffixBillSteps", "suffixBill"),
  RequiredField("spotTrailer", OPTION_MSG),
  ConditionalField("freightLeft", "spotTrailer"),
  RequiredField("day2F18Note", "", false),
];

// Day 3 Section (32 keys)
const dsDay3Fields: FieldConfig[] = [
  RequiredField("bolCheck", CHECK_BOX_MSG, true, "string", true),
  ConditionalField("bolSteps", "bolCheck"),
  ConditionalField("bolDiscrepancies", "bolCheck"),
  RequiredField("drCheck", OPTION_MSG),
  ConditionalField(
    "confirm",
    "drCheck",
    "Y",
    true,
    CHECK_BOX_MSG,
    "string",
    true
  ),
  ConditionalField("confirmSteps", "confirm"),
  RequiredField("pumCheck", CHECK_BOX_MSG, true, "string", true),
  ConditionalField(
    "pumManifest",
    "pumCheck",
    "Y",
    true,
    REQUIRED_MSG,
    "number"
  ),
  ConditionalField("pumDiscrepancy", "pumCheck"),
  RequiredField("shipperLoad", OPTION_MSG),
  ConditionalField("shipperDiscrepancy", "shipperLoad"),
  RequiredField("lineHaulManifest", CHECK_BOX_MSG, true, "string", true),
  ConditionalField("numbersReview", "lineHaulManifest"),
  RequiredField("interviewWorkers", CHECK_BOX_MSG, true, "string", true),
  ConditionalField("workerSteps", "interviewWorkers"),
  RequiredField("consigneeShort", OPTION_MSG),
  ConditionalField("itemNumbers", "consigneeShort"),
  ConditionalField("orderReceived", "consigneeShort"),
  ConditionalField("freightLabeled", "consigneeShort"),
  RequiredField("consigneePhone"),
  RequiredField("consigneeName"),
  RequiredField("shipperPhone"),
  RequiredField("shipperName"),
  RequiredField("shippedDetails"),
  RequiredField("shortShipped"),
  RequiredField("productValue"),
  RequiredField("multiShipments"),
  RequiredField("callDriver"),
  RequiredField("adeDelivery"),
  RequiredField("freightNose"),
  RequiredField("outOfOrdinary"),
  RequiredField("day3F18Note", "", false),
];

// Day 4 Section (3 keys)
const dsDay4Fields: FieldConfig[] = [
  RequiredField("check7818", CHECK_BOX_MSG, true, "string", true),
  ConditionalField("details7818", "check7818"),
  RequiredField("cameraFindings"),
  RequiredField("day4F18Note", "", false),
];
// Day 5 Section (21 keys)
const dsDay5Fields: FieldConfig[] = [
  RequiredField("callSameDays", CHECK_BOX_MSG, true, "string", true),

  {
    name: "callSameDaysDataset",
    type: "array",
    when: {
      key: "callSameDays",
      value: "Y",
      required: true,
      message: REQUIRED_MSG,
    },
    schema: [RequiredField("deliveryInfo"), RequiredField("sameDayPro")],
  },
  RequiredField("contactTerminals", CHECK_BOX_MSG, true, "string", true),
  {
    name: "freightTerminalLeadInquiries",
    type: "array",
    when: {
      key: "contactTerminals",
      value: "Y",
      required: true,
      message: REQUIRED_MSG,
    },
    schema: [
      RequiredField("terminal"),
      RequiredField("talkedTo"),
      RequiredField("updates"),
    ],
  },
  RequiredField("day5F18Note", "", false),
];
const dsDay10Fields: FieldConfig[] = [
  RequiredField("followUp", CHECK_BOX_MSG, true, "string", true),
  ConditionalField("followUpDetails", "followUp"),
  RequiredField("day10F18Note", "", false),
];

const dsDay15Fields: FieldConfig[] = [
  RequiredField("check7818_2", CHECK_BOX_MSG, true, "string", true),
  ConditionalField("details7818_2", "check7818_2"),
  RequiredField("highValue", OPTION_MSG),
  RequiredField("day15F18Note", "", false),
];

const dsDay20Fields: FieldConfig[] = [
  RequiredField("finalReCheck", CHECK_BOX_MSG, true, "string", true),
  RequiredField("detailsOfSearch", "", false),
  RequiredField("day20F18Note", "", false),
];

// Exporting the dynamically created schema
// export const DSInvestigationSchema = CreateDynamicSchema(dsInvestigationFields);

export const DSInvestigationSchema = [
  CreateDynamicSchema(dsDay1Fields),
  CreateDynamicSchema(dsDay2Fields),
  CreateDynamicSchema(dsDay3Fields),
  CreateDynamicSchema(dsDay4Fields),
  CreateDynamicSchema(dsDay5Fields),
  CreateDynamicSchema(dsDay10Fields),
  CreateDynamicSchema(dsDay15Fields),
  CreateDynamicSchema(dsDay20Fields),
];

export const DSInvestigationInitValue: IDSInvestigation = {
  // Day 1 Section (6 keys)
  statusCorrect: undefined,
  statusResponse: undefined,
  deliveryCode: undefined,
  deliveryCodeResponse: undefined,
  epImaging: undefined,
  epImagingResponse: undefined,

  // Day 2 Section (7 keys)
  noMovement: undefined,
  dateLastMovement: undefined,
  noMovementSteps: undefined,
  suffixBill: undefined,
  suffixBillSteps: undefined,
  spotTrailer: undefined,
  freightLeft: undefined,

  // Day 3 Section (32 keys)
  bolCheck: undefined,
  bolSteps: undefined,
  bolDiscrepancies: undefined,
  drCheck: undefined,
  confirm: undefined,
  confirmSteps: undefined,
  pumCheck: undefined,
  pumManifest: undefined,
  pumDiscrepancy: undefined,
  shipperLoad: undefined,
  shipperDiscrepancy: undefined,
  lineHaulManifest: undefined,
  numbersReview: undefined,
  interviewWorkers: undefined,
  workerSteps: undefined,
  consigneePhone: undefined,
  consigneeName: undefined,
  consigneeShort: undefined,
  itemNumbers: undefined,
  orderReceived: undefined,
  freightLabeled: undefined,
  shipperPhone: undefined,
  shipperName: undefined,
  shippedDetails: undefined,
  shortShipped: undefined,
  productValue: undefined,
  multiShipments: undefined,
  callDriver: undefined,
  adeDelivery: undefined,
  freightNose: undefined,
  outOfOrdinary: undefined,

  // Day 4 Section (3 keys)
  check7818: undefined,
  details7818: undefined,
  cameraFindings: undefined,

  // Day 5 Section (21 keys)
  callSameDays: undefined,
  callSameDaysDataset: [
    {
      sameDayPro: undefined,
      deliveryInfo: undefined,
    },
  ],
  contactTerminals: undefined,
  freightTerminalLeadInquiries: [
    {
      terminal: undefined,
      talkedTo: undefined,
      updates: undefined,
    },
  ],

  // Day 10 Section (2 keys)
  followUp: undefined,
  followUpDetails: undefined,

  // Day 15 Section (3 keys)
  check7818_2: undefined,
  details7818_2: undefined,
  highValue: undefined,

  // Day 20 Section (2 keys)
  finalReCheck: undefined,
  detailsOfSearch: undefined,

  day1F18Note: undefined,
  day2F18Note: undefined,
  day3F18Note: undefined,
  day4F18Note: undefined,
  day5F18Note: undefined,
  day10F18Note: undefined,
  day15F18Note: undefined,
  day20F18Note: undefined,
};
// AS Investigation form End
