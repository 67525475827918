import _ from "lodash";
import React, { useMemo } from "react";
import {
  IDSInvestigation,
  ICallSameDaysDataset,
} from "../models/dsException";
import { ASInvestigationWizardStep } from "../constants/shortage";

interface ASDay4TemplateTemplateProps {
  investigationValues?: IDSInvestigation;
  osdNumber: number;
  currentDay?: string;
  callSameDaysChunk?: ICallSameDaysDataset[];
  pageIndex?: number;
}

const ASDay4Template: React.FC<ASDay4TemplateTemplateProps> = ({
  investigationValues,
  osdNumber,
  currentDay,
  callSameDaysChunk,
  pageIndex = 0,
}: ASDay4TemplateTemplateProps): JSX.Element => {
  const values = useMemo(() => investigationValues, [investigationValues]);
  return (
    <>
      {values && (
        <>
          {currentDay === ASInvestigationWizardStep[3] && (
            <div id="as-in-day4">
              <div style={{ width: "100%", fontFamily: "calibri" }}>
                <div
                  style={{
                    width: "100vw",
                    background: "#f5f7fb",
                    padding: "10px 10px 15px",
                    lineHeight: "40px",
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginBottom: "15px",
                    textTransform: "uppercase",
                  }}
                >
                  All Shortage Investigation Day 4{" "}
                  <span
                    style={{
                      background: "#f5f5f5",
                      border: "1px solid #d4d4d4",
                      padding: "5px 15px",
                      borderRadius: "20px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    #{osdNumber}
                  </span>
                </div>
              </div>

              {typeof callSameDaysChunk !== "undefined" && (
                <div
                  style={{
                    width: "calc(100vw - 40px)",
                    margin: "0 20px 20px",
                    display: "inline-block",
                  }}
                >
                  <div style={{ margin: "0 0 10px" }}>Call same day deliveries and pickups</div>

                  <div>
                    <div
                      style={{
                        width: "15vw",
                        display: "inline-block",
                        verticalAlign: "top",
                      }}
                    >
                      {values.callSameDays === "Y" ? "Yes" : "No"}
                    </div>
                    {values.callSameDays === "Y" && (
                      <>
                        {(callSameDaysChunk || []).map(
                          (_c: ICallSameDaysDataset, index: number) => (
                            <React.Fragment key={index + ""}>
                              <div style={{ marginBottom: "10px" }}>
                                <h3 className="header-text mb-0">
                                  <strong>
                                    {" "}
                                    Pro and Delivery Info:{" "}
                                    {index + pageIndex + 1}{" "}
                                  </strong>
                                </h3>
                              </div>
                              <div style={{ marginBottom: "10px" }}>
                                Pro Number:{" "}
                              </div>
                              <div
                                style={{
                                  width: "75%",
                                  margin: "0 0 0 0",
                                  display: "inline-block",
                                  background: "#f5f7fb",
                                  padding: "10px",
                                  border: "1px solid #b5b6b9",
                                  wordWrap: "break-word",
                                }}
                              >
                                {_.get(
                                  values,
                                  `callSameDaysDataset[${index + pageIndex}].sameDayPro`
                                )}
                              </div>

                              <div style={{ marginBottom: "10px" }}>
                                Delivery Info?:{" "}
                              </div>
                              <div
                                style={{
                                  width: "75%",
                                  margin: "0 0 0 0",
                                  display: "inline-block",
                                  background: "#f5f7fb",
                                  padding: "10px",
                                  border: "1px solid #b5b6b9",
                                  wordWrap: "break-word",
                                }}
                              >
                                {_.get(
                                  values,
                                  `callSameDaysDataset[${index + pageIndex}].deliveryInfo`
                                )}
                              </div>
                            </React.Fragment>
                          )
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ASDay4Template;
