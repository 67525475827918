import Button from "@mui/material/Button";
import "./exceptionDetails.css";
import React, { useEffect, useState } from "react";
import DynamicGrid from "../../components/dynamicGrid/DynamicGrid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { APP, AppURLs, EOsdType, ShortageOsdType } from "../../constants/app";
import { Link, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { IProImage } from "../../models/master";
import { useDispatch, useSelector } from "react-redux";
import { getProImages } from "../../API/getProImages";
import DialogImage from "../../components/popupImage/popupImage";
import { PopoutNotification } from "../../AppState/popoutNotification";
import NOTIFICATION_TYPE from "../../AppState/NotificationType";
import { GridColDef } from "@mui/x-data-grid";
import Loader from "../../components/loader/loader";
import { DateHelper } from "../../helpers/date.helper";
import AppState from "../../AppState";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { exceptionDetails } from "../../API/exceptionDetails";
import { IOsdDetails } from "../../models/osd";

function ExceptionDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tabPosition: number = useSelector(
    (state: AppState) => state.tabPosition
  );
  const urls: string [] = [AppURLs.myAssignments, AppURLs.overages, AppURLs.shortages];
  
  const columns: GridColDef[] = APP.overage.overageDetailsColumns;
  const shipperDetails: GridColDef[] = APP.overage.overagesShipperDetails;
  const consigneeDetails: GridColDef[] = APP.overage.overageConsigneeDetails;
  const [overage, setOverage] = useState<IOsdDetails>({});
  const [proImages, setProImages] = useState<IProImage[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [pageLoading, setPageLoading] = useState<boolean>(false);

  const param = useParams();
  const isBnf = overage?.osdType?.toUpperCase().trim() === EOsdType.bnf;
  // Send APB mail
  const sendAPBMail = () => {
   navigate(AppURLs.sendAPB.replace(":osdNumber", param.id!));
  };

  const columnVisibilityModel = React.useMemo(() => {
    if (overage?.osdType?.trim()?.toUpperCase() !== EOsdType.nb) {
      return {
        proNumber: true,
      };
    }
    return {
      proNumber: false,
    };
  }, [overage?.osdType]);

  // Get Exception Details by exception number 
  const getExceptionDetails = () => {
    setPageLoading(true);
    exceptionDetails(param.id)
      .then(
        (data) => {
          setPageLoading(false);

          if (ShortageOsdType.includes(data.osdType || "")) {
            Object.assign(data, {
              hazmat: "",
              od400ProNumber: undefined,
              od400Completed: "",
              od400Manifest: "", 
              od400WhereFound: "",
              od400Trailer: "",
              od400Bay: "",
              swOk: "",
              blNumber: "",
              poNumber: "",
              snNumber: "",
            });
          }

          (['manifest', 'od400Manifest'] as Array<keyof IOsdDetails>).forEach(key => {
            // eslint-disable-next-line 
            if (data[key] == 0) {
              data[key] = undefined;
            }
          });
          
          // merge address1 and address2 to address for Shipper and Consign
          if (_.get(data, "osdNumber", "") !== "") {
            data[
              "shipperAddress"
            ] = `${data?.shipperAddress1} ${data?.shipperAddress2}`;
            data[
              "consigneeAddress"
            ] = `${data?.consigneeAddress1} ${data?.consigneeAddress2}`;
            setOverage(data);
          }

        },
        (err) => {
          setPageLoading(false);
          console.log(err);
        }
      )
      .catch((error) => {
        setPageLoading(false);
        console.log(error);
      });
  };

  const getAS400Images = () => {
    // check pro is valid or not
   const osdNumber = overage.osdNumber;
    if (!osdNumber) {
      dispatch(
        PopoutNotification(
          NOTIFICATION_TYPE.WARNING,
          "Selected OSD number is not valid",
          Date.now().toString()
        )
      );
    } else {
    setPageLoading(true);
    const param = {
      osdNumber
      // documentFilter: APP.overage.imageType,
    };
    // Get Pro Images from API
    getProImages(param)
      .then((response) => {
        const sortImages = _.get(response, 'data', []).sort((a: IProImage, b:IProImage) => a.imageFilename!.localeCompare(b.imageFilename!));
        setProImages(sortImages);
        if (response.data.length > 0) {
          setIsOpen(!isOpen);
          setPageLoading(false);
        }else {
          setPageLoading(false);
          dispatch(
            PopoutNotification(
              NOTIFICATION_TYPE.INFO,
              "No image available",
              Date.now().toString()
            )
          );
        }
      })
      .catch((error) => {
        setPageLoading(false);
        dispatch(
          PopoutNotification(
            NOTIFICATION_TYPE.ERROR,
            typeof error === "string" ? error : "Image API error",
            Date.now().toString()
          )
        );
      });
    }
  };

  useEffect(() => {
    getExceptionDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param.id]);

  return (
    <div className="bg-layout">
      {/* header */}
      <div className="header-section">
        <div className="back-section">
          <div className="back">
            <Link to={urls[tabPosition - 1] || urls[1]}>
              <ArrowLeftIcon />
            </Link>
          </div>
          <div className="page-hader-content">
            <div className="page-header">OSD Description</div>
            <div className="page-subtitle">
              Last update:{" "}
              {overage?.lastUpdatedDate && overage?.lastUpdatedTime ? (
                (() => {
                  const lastUpdatedDate =
                    overage?.lastInvUpdatedDate &&
                    overage?.lastInvUpdatedDate > overage?.lastUpdatedDate
                      ? overage?.lastInvUpdatedDate
                      : overage?.lastUpdatedDate;

                  const lastUpdatedTime =
                    overage?.lastInvUpdatedDate &&
                    overage?.lastInvUpdatedTime &&
                    (overage?.lastInvUpdatedDate > overage?.lastUpdatedDate ||
                      (overage?.lastInvUpdatedDate === overage?.lastUpdatedDate &&
                        overage?.lastInvUpdatedTime > overage?.lastUpdatedTime))
                      ? overage?.lastInvUpdatedTime
                      : overage?.lastUpdatedTime;

                  return DateHelper.convertDateTimeFromDB(
                    DateHelper.convertFromAS400Date(JSON.stringify(lastUpdatedDate)),
                    DateHelper.convertFromAS400Time(JSON.stringify(lastUpdatedTime))
                  );
                })()
              ) : (
                "NA"
              )}
            </div>
          </div>
        </div>
        <div className="edit-section">
          <Button
            variant="contained"
            onClick={getAS400Images}
            className={`entry ${isBnf ? 'mr-20' : ''}`}
          >
            View Image AS400
          </Button>
          {!isBnf &&
          <Button
            variant="contained"
            onClick={() => sendAPBMail()}
            className="assign"
          >
            Send APB
          </Button>
          }
        </div>
      </div>

      {/* body */}
      <div className="content-area osd-description">
        <DynamicGrid
          id={"osdNumber"}
          checkboxSelection={false}
          rows={overage?.osdNumber ? [overage] : []}
          columns={columns}
          hideFooter={true}
          disableColumnMenu={true}
          columnVisibilityModel={columnVisibilityModel}
        ></DynamicGrid>

        <Accordion className="table-accordion" defaultExpanded>
          <AccordionSummary
            className="des-header"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Grid container>
              <Grid item md={6} xs={6}>
                <Typography className="accordion-header">
                  Shipper Details
                </Typography>
              </Grid>
              <Grid item md={6} xs={6}>
                <Typography className="accordion-header consignee-border">
                  Consignee Details
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className="details-accordion">
            <Grid container>
              <Grid item md={6} xs={6} className="border-right">
                <DynamicGrid
                  id={"shipperName"}
                  checkboxSelection={false}
                  rows={overage?.osdNumber ? [overage] : []}
                  columns={shipperDetails}
                  hideFooter={true}
                  disableColumnMenu={true}
                ></DynamicGrid>
              </Grid>
              <Grid item md={6} xs={6}>
                <DynamicGrid
                  id={"consigneeName"}
                  checkboxSelection={false}
                  rows={overage?.osdNumber ? [overage] : []}
                  columns={consigneeDetails}
                  hideFooter={true}
                  disableColumnMenu={true}
                ></DynamicGrid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion className="table-accordion" defaultExpanded>
          <AccordionSummary
            className="des-header"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Grid item md={6} xs={6}>
              <Typography className="accordion-header">More Details</Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className="more-details">
            <Grid container spacing={2}>
              <Grid item lg={3} md={3} sm={6} className="background-box pb-0">
                <div className="grey-box">
                  <div className="content-box">
                    <div className="label">OD400 Completed</div>
                    <div className="value">{overage?.["od400Completed"]} </div>
                  </div>
                  <div className="content-box">
                    <div className="label">OD400 PRO #</div>
                    <div className="value">{overage?.["od400ProNumber"]}</div>
                  </div>
                  <div className="content-box">
                    <div className="label">OD400 Manifest</div>
                    <div className="value">{overage?.["od400Manifest"]}</div>
                  </div>
                  <div className="content-box">
                    <div className="label">OD400 Found</div>
                    <div className="value">
                      {overage?.["od400WhereFound"]}
                    </div>
                  </div>
                  <div className="content-box">
                    <div className="label">OD400 Trailer</div>
                    <div className="value">{overage?.["od400Trailer"]}</div>
                  </div>
                </div>
              </Grid>

              <Grid item lg={3} md={3} sm={6} className="background-box pb-0">
                <div className="grey-box">
                  <div className="content-box">
                    <div className="label">OD400 Bay</div>
                    <div className="value">{overage?.["od400Bay"]}</div>
                  </div>
                  <div className="content-box">
                    <div className="label">BL #</div>
                    <div className="value">{overage?.["blNumber"]}</div>
                  </div>
                  <div className="content-box">
                    <div className="label">PO #</div>
                    <div className="value">{overage?.["poNumber"]}</div>
                  </div>
                  <div className="content-box">
                    <div className="label">SN #</div>
                    <div className="value">{overage?.["snNumber"]}</div>
                  </div>
                  <div className="content-box">
                    <div className="label">Commodity</div>
                    <div
                      className="value text-overflow"
                      title={overage?.["commodity"]}
                    >
                      {overage?.["commodity"]}
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid item lg={3} md={3} sm={6} className="background-box pb-0">
                <div className="grey-box">
                  <div className="content-box">
                    <div className="label">Package</div>
                    <div className="value">{overage?.["package"]}</div>
                  </div>
                  <div className="content-box">
                    <div className="label">Sum of Pieces</div>
                    <div className="value">{overage?.["pieces"]}</div>
                  </div>
                  <div className="content-box">
                    <div className="label">Sum of Weight</div>
                    <div className="value">{overage?.["weight"]}</div>
                  </div>
                  <div className="content-box">
                    <div className="label">Sum of # Pallets</div>
                    <div className="value">{overage?.["numberOfPallets"]}</div>
                  </div>
                  <div className="content-box">
                    <div className="label">Icm</div>
                    <div
                      className="value text-overflow"
                      title={overage?.["icm"]}
                    >
                      {overage?.["icm"]}
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid item lg={3} md={3} sm={6} className="background-box pb-0">
                <div className="grey-box">
                  <div className="content-box">
                    <div className="label">SW ok</div>
                    <div className="value">{overage?.["swOk"]}</div>
                  </div>
                  <div className="content-box">
                    <div className="label">Manufacturer/Brand</div>
                    <div
                      className="value text-overflow"
                      title={overage?.["manufacturerBrand"]}
                    >
                      {overage?.["manufacturerBrand"]}
                    </div>
                  </div>
                  <div className="content-box">
                    <div className="label">Value Range</div>
                    <div className="value">{overage?.["valueRange"]}</div>
                  </div>
                  <div className="content-box">
                    <div className="label">Sum of Value &#62; $1k</div>
                    <div className="value">{overage?.["value1k"]}</div>
                  </div>
                  <div className="content-box">
                    <div className="label">H/U</div>
                    <div className="value">{overage?.["handlingUnits"]}</div>
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item lg={9} md={9} xs={9} className="background-box">
                <div className="grey-box">
                  <div className="content-box">
                    <div className="label">Descriptions</div>
                  </div>
                  <div className="content-box">
                    <div className="value">{overage?.description}</div>
                  </div>
                </div>
              </Grid>

              <Grid item lg={3} md={3} xs={3} className="background-box">
                <div className="grey-box remarks">
                  <div className="content-box">
                    <div className="label">Remarks</div>
                  </div>
                  <div className="content-box">
                    <div className="value">{overage?.remarks}</div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
      <div>
        <DialogImage
          isDialogOpened={isOpen}
          handleCloseDialog={() => setIsOpen(false)}
          key={isOpen.toString()}
          imageData={proImages}
          excNumber={overage?.osdNumber ? overage?.osdNumber.toString() : ""}
        />
      </div>
      <div>
        <Loader pageLoader={pageLoading}></Loader>
      </div>
    </div>
  );
}

export default ExceptionDetails;
