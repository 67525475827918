
import { AlphanumericRegex, CreateDynamicSchema, FieldConfig, NotAllowPipeRegex } from "./formik";

// Configuration array defining validation rules
const config: FieldConfig[] = [
  {
    name: "type",
    type: "string",
    required: true,
    message: "Exception type is required"
  },

  {
    name: "reporting",
    type: "string",
    required: true,
    message: "Reporting terminal is required"
  },

  {
    name: "proNumber",
    type: "number",
    nullable: true,
    when: {
      key: "type",
      value: "AS",
      required: true,
      message: "Pro number is required",
      max: 11,
      maxMessage: "Pro number must be less than or equal to 11 digits"
    }
  },

  {
    name: "origin",
    type: "string"
  },

  {
    name: "destination",
    type: "string"
  },

  {
    name: "shipperAccount",
    type: "string"
  },

  {
    name: "shipper",
    type: "string"
  },

  {
    name: "shipperAddress",
    type: "string"
  },

  {
    name: "shipperCity",
    type: "string"
  },

  {
    name: "shipperState",
    type: "string"
  },

  {
    name: "shipperZip",
    type: "string"
  },

  {
    name: "consigneeAccount",
    type: "string"
  },

  {
    name: "consignee",
    type: "string"
  },

  {
    name: "consigneeCity",
    type: "string"
  },

  {
    name: "consigneeState",
    type: "string"
  },

  {
    name: "consigneeZip",
    type: "string"
  },

  {
    name: "consigneeAddress",
    type: "string"
  },

  {
    name: "lastManifest",
    type: "number",
    required: true,
    message: "Last manifest number# is required",
    positive: true,
    max: 9,
    maxMessage: "Last manifest number# must be less than or equal to 9 digits"
  },
  {
    name: "trailerOrBay",
    type: "string",
    required: true,
    message: "Please select trailer or bay/dock"
  },

  {
    name: "trailer",
    type: "string",
    when: {
      key: "trailerOrBay",
      value: "T",
      required: true,
      message: "Trailer number# is required"
    },
    matches: AlphanumericRegex,
    matchesMessage: "Invalid trailer number, only alphanumerical characters allowed"
  },

  {
    name: "manifest",
    type: "number",
    when: {
      key: "trailerOrBay",
      value: "T",
      required: true,
      message: "Manifest number# is required"
    },
    positive: true,
    max: 9,
    maxMessage: "Manifest number# must be less than or equal to 9 digits"
  },

  {
    name: "bay",
    type: "string",
    when: {
      key: "trailerOrBay",
      value: "B",
      required: true,
      message: "Bay/Dock number is required"
    },
    matches: AlphanumericRegex,
    matchesMessage: "Invalid bay number, only alphanumerical characters allowed"
  },
  {
    name: "commodity",
    type: "string",
    required: true,
    message: "Commodity is required"
  },
  {
    name: "package",
    type: "string",
    required: true,
    message: "Package type is required"
  },
  {
    name: "pieces",
    type: "number",
    required: true,
    positive: true,
    message: "Total pieces are required"
  },
  {
    name: "weight",
    type: "number",
    required: true,
    message: "Weight is required", 
    positive: true
  },
  {
    name: "itemCartonModelSerialNumber",
    type: "string",
    required: true,
    message: "Does the shipment have (Item#, Carton#, Model#, Serial#) is required",
    max: 56,
    maxMessage: "Item#, carton#, model#, serial# must be less than or equal to 56 characters"
  },

  {
    name: "valueRange", type: "string", required: true, message: "Value range is required"
  },
  {
    name: "value1k",
    type: "number",
    nullable: true,
    when: {
      key: "valueRange",
      value: "3", required: true,
      message: "Amount is required",
      moreThan: 1000,
      moreThanMessage: "Amount must be greater than 1000",
      lessThan: 9999999999,
      lessThanMessage: "Amount must be greater than 1000"
    }
  },
  {
    name: "dimension",
    type: "string",
    required: true,
    message: "Dimension is required",
    matches: NotAllowPipeRegex,
    matchesMessage: "Special character | is not allowed"
  },
  {
    name: "packagingDescription",
    type: "string",
    required: true,
    message: "Packaging description is required",
    matches: NotAllowPipeRegex,
    matchesMessage: "Special character | is not allowed"
  },
  {
    name: "productDescription",
    type: "string",
    required: true,
    message: "Product description is required",
    matches: NotAllowPipeRegex,
    matchesMessage: "Special character | is not allowed"
  },
  {
    name: "agentInterline",
    type: "string",
    matches: NotAllowPipeRegex,
    matchesMessage: "Special character | is not allowed"
  },
  {
    name: "additionalDescription",
    type: "string",
    required: true,
    message: "Description is required",
    matches: NotAllowPipeRegex,
    matchesMessage: "Special character | is not allowed",
    max: 450,
    maxMessage: "Additional description must be less than or equal to 450 characters"
  },


];
// Function to create a Yup validation schema dynamically from the config array

// Exporting the dynamically created schema
export const ShortageEntryNBSchema = CreateDynamicSchema(config);

export const InitAllShortageFormValue = {
  type: "AS",
  reporting: "",
  proNumber: "",
  origin: "",
  destination: "",
  shipperAccount: "",
  consigneeAccount: "",
  shipper: "",
  shipperAddress: "",
  shipperCity: "",
  shipperState: "",
  shipperZip: "",
  consigneeCity: "",
  consigneeState: "",
  consigneeZip: "",
  consignee: "",
  consigneeAddress: "",
  itemCartonModelSerialNumber: "",
  weight: "",
  lastManifest: "",
  manifest: "",
  trailer: "",
  bay: "",
  commodity: "",
  package: "",
  pieces: "",
  valueRange: "",
  dimension: "",
  packagingDescription: "",
  additionalDescription: "",
  productDescription: "",
  agentInterline: "",
  trailerOrBay: "",
  value1k: null,
};
