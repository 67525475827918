import React, { useState, useCallback, useEffect } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BNFFollowupAPI } from "../../../API/bnfFollowUp";
import Loader from "../../../components/loader/loader";
import { PopoutNotification } from "../../../AppState/popoutNotification";
import NOTIFICATION_TYPE from "../../../AppState/NotificationType";
import { EBNFStatus } from "../../../constants/shortage";
import { AppURLs } from "../../../constants/app";
import { ConstantMessage } from "../../../constants/constantsMsg";

type Props = {
  osdNumber?: number;
  hasMovementSinceBNFValue?: string;
  investigationF18Note?: string
  investigationStatusNumber?: number;
  setBNFStatus: (status: number) => void;
};

const selectStyle = {
  fontSize: "13px",
};

const BNFInvestigation: React.FC<Props> = ({
  osdNumber = 0,
  hasMovementSinceBNFValue,
  investigationF18Note,
  investigationStatusNumber,
  setBNFStatus,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [hasMovementSinceBNF, setHasMovementSinceBNF] = useState("");
  const [f18Note, setF18Note] = useState("");
  const [formFieldsDisabled, setFormFieldsDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(0);

  const movementMessage =
    hasMovementSinceBNF === "Y"
      ? "Clear the BNF exception in the 78-6 and ensure freight goes out to delivery ASAP."
      : "Clear the BNF exception in the 78-6 and enter an AS exception.";

  // Update form fields state
  const enableEditing = useCallback(() => setFormFieldsDisabled(false), []);
  const resetForm = useCallback(() => {
    setHasMovementSinceBNF("");
    setF18Note("");
    setFormFieldsDisabled(true);
  }, []);

  // Handle radio and text field changes
  const handleRadioChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setHasMovementSinceBNF(event.target.value);
    },
    []
  );

  const handleF18NoteChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setF18Note(event.target?.value);
    },
    []
  );

  // Follow-up action
  const followup = useCallback(async () => {
    if (!hasMovementSinceBNF || !f18Note) return;

    setLoading(true);
    try {
      const { statusNumber } = await BNFFollowupAPI(
        { hasMovementSinceBNF, f18Note: f18Note.trim() },
        osdNumber
      );
      setF18Note(f18Note.trim());
      setStatus(statusNumber);
      setBNFStatus(statusNumber);
      setFormFieldsDisabled(true);

      dispatch(
        PopoutNotification(
          NOTIFICATION_TYPE.SUCCESS,
          ConstantMessage.bnfFollowUpSuccess,
          Date.now().toString()
        )
      );

      if (hasMovementSinceBNF === "N") {
        navigate(AppURLs.shortageEntry, { state: { osdNumber } });
      }
    } catch (err) {
      const errorMessage =
        err instanceof Error
          ? err.message
          : "BNF follow-up failed. Please try again.";
      dispatch(
        PopoutNotification(
          NOTIFICATION_TYPE.ERROR,
          errorMessage,
          Date.now().toString()
        )
      );
      console.error("BNF follow-up error:", err);
    } finally {
      setLoading(false);
    }
  }, [
    dispatch,
    hasMovementSinceBNF,
    f18Note,
    osdNumber,
    navigate,
    setBNFStatus,
  ]);

  // Sync props with state
  useEffect(() => {
    if (hasMovementSinceBNFValue)
      setHasMovementSinceBNF(hasMovementSinceBNFValue);
  }, [hasMovementSinceBNFValue]);

  useEffect(() => {
    if (investigationStatusNumber) setStatus(investigationStatusNumber);
  }, [investigationStatusNumber]);

  useEffect(() => {
    if (investigationF18Note) setF18Note(investigationF18Note);
  }, [investigationF18Note]);

  return (
    <>
      <Grid container spacing={2} className="filter-margin">
        {/* Movement Radio Group */}
        <Grid item md={6} xs={12}>
          <FormControl className="radio-group question-group">
            <FormLabel>Has this shown any movement since BNF?</FormLabel>
            <RadioGroup
              row
              name="movement"
              value={hasMovementSinceBNF}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="Y"
                control={<Radio disabled={formFieldsDisabled} />}
                label="Yes"
              />
              <FormControlLabel
                value="N"
                control={<Radio disabled={formFieldsDisabled} />}
                label="No"
              />
            </RadioGroup>
            {hasMovementSinceBNF && (
              <p className="mb-0 fs">{movementMessage}</p>
            )}
          </FormControl>
        </Grid>

        {/* F18 Notes */}
        <Grid item md={12} xs={12}>
        <FormControl  fullWidth size="small"> 
          <InputLabel style={selectStyle} shrink={true} >
            F18 Notes <sup>*</sup>
          </InputLabel>
          <TextField  autoComplete="off"
            multiline
            rows={2}
            name="f18Note"
            value={f18Note}
            onChange={handleF18NoteChange}
            placeholder="Enter F18 Notes"
            fullWidth
            inputProps={{ maxLength: 300 }}
            disabled={formFieldsDisabled}
            variant="outlined"
          />
          </FormControl>
        </Grid>

        {/* Action Buttons */}
        {status !== EBNFStatus.needsCleared && (
          <Grid item md={12} xs={12} className="btn-group-submit">
            {formFieldsDisabled ? (
              <Button
                variant="contained"
                onClick={enableEditing}
                className="assign ml mr-0"
              >
                Edit
              </Button>
            ) : (
              <>
                <Button
                  variant="contained"
                  onClick={resetForm}
                  className="clear-btn mr-0 ml"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={hasMovementSinceBNF && f18Note?.trim() ? followup : void 0}
                  className={
                    hasMovementSinceBNF && f18Note?.trim()
                      ? "entry ml"
                      : "disable-btn disable entry ml"
                  }
                  // disabled={!hasMovementSinceBNF || !f18Note}
                >
                  {hasMovementSinceBNF === "N" ? "Save & Enter AS" : "Save"}
                </Button>
              </>
            )}
          </Grid>
        )}
      </Grid>
 
      <Loader pageLoader={loading} />
    </>
  );
};

export default React.memo(BNFInvestigation);
