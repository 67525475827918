import FormControl from '@mui/material/FormControl/FormControl';
import FormGroup from '@mui/material/FormGroup';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';

const SearchWithBarcode: React.FC = () => {
    return (
        <FormGroup className="flex-update">
            <FormControl className="search-input">
                <TextField  autoComplete="off"

                    placeholder="Search Manifest/Exception #"
                    id="outlined-start-adornment"
                    variant="outlined"
                    size="medium"
                    fullWidth
                    InputProps={{
                        inputProps: {
                            maxLength: 11
                        },
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </FormControl>
            <FormGroup>
                <Button variant="contained" className="search-btn search-effect" disableElevation>Search</Button>
            </FormGroup>
        </FormGroup>
    );
};

export default SearchWithBarcode;