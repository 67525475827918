import React from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

interface TooltipWithClickAwayProps {
  tooltipText: string;
  tooltipKey: string;
  openTooltips: Record<string, boolean>;
  handleTooltipOpen: (key: string) => void;
  handleTooltipClose: (key: string) => void;
}

const TooltipWithClickAway: React.FC<TooltipWithClickAwayProps> = ({
  tooltipText,
  tooltipKey,
  openTooltips,
  handleTooltipOpen,
  handleTooltipClose,
}) => {
  return (
    <ClickAwayListener onClickAway={() => handleTooltipClose(tooltipKey)}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={() => handleTooltipClose(tooltipKey)}
        open={openTooltips[tooltipKey] || false}
        disableFocusListener
        disableTouchListener
        title={tooltipText}
      >
        <InfoIcon
          className="info-icon"
          onMouseEnter={() => handleTooltipOpen(tooltipKey)}
          onMouseLeave={() => handleTooltipClose(tooltipKey)}
        />
      </Tooltip>
    </ClickAwayListener>
  );
};

export default TooltipWithClickAway;
