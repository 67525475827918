import _ from "lodash";
import React, { useMemo } from "react";
import {
  IDSInvestigation,
  IDSFreightTerminalLeadInquiries,
  ICallSameDaysDataset,
} from "../models/dsException";
import { ASInvestigationWizardStep } from "../constants/shortage";

interface DSDay5TemplateTemplateProps {
  investigationValues?: IDSInvestigation;
  osdNumber: number;
  currentDay?: string;
  callSameDaysChunk?: ICallSameDaysDataset[];
  freightTerminalChunk?: IDSFreightTerminalLeadInquiries[];
  pageIndex?: number;
}

const DSDay5Template: React.FC<DSDay5TemplateTemplateProps> = ({
  investigationValues,
  osdNumber,
  currentDay,
  callSameDaysChunk,
  freightTerminalChunk,
  pageIndex = 0,
}: DSDay5TemplateTemplateProps): JSX.Element => {
  const values = useMemo(() => investigationValues, [investigationValues]);
  return (
    <>
      {values && (
        <>
          {currentDay === ASInvestigationWizardStep[4] && (
            <div id="ds-in-day5">
              <div style={{ width: "100%", fontFamily: "calibri" }}>
                <div
                  style={{
                    width: "100vw",
                    background: "#f5f7fb",
                    padding: "10px 10px 15px",
                    lineHeight: "40px",
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginBottom: "15px",
                    textTransform: "uppercase",
                  }}
                >
                  Delivered Short Investigation Day 5{" "}
                  <span
                    style={{
                      background: "#f5f5f5",
                      border: "1px solid #d4d4d4",
                      padding: "5px 15px",
                      borderRadius: "20px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    #{osdNumber}
                  </span>
                </div>
              </div>

              {typeof callSameDaysChunk !== "undefined" && (
                <div
                  style={{
                    width: "calc(100vw - 40px)",
                    margin: "0 20px 20px",
                    display: "inline-block",
                  }}
                >
                  <div style={{ margin: "0 0 10px" }}>Call same day deliveries and pickups</div>

                  <div>
                    <div
                      style={{
                        width: "15vw",
                        display: "inline-block",
                        verticalAlign: "top",
                      }}
                    >
                      {values.callSameDays === "Y" ? "Yes" : "No"}
                    </div>
                    {values.callSameDays === "Y" && (
                      <>
                        {(callSameDaysChunk || []).map(
                          (_c: ICallSameDaysDataset, index: number) => (
                            <React.Fragment key={index + ""}>
                              <div style={{ marginBottom: "10px" }}>
                                <h3 className="header-text mb-0">
                                  <strong>
                                    {" "}
                                    Pro and Delivery Info:{" "}
                                    {index + pageIndex + 1}{" "}
                                  </strong>
                                </h3>
                              </div>
                              <div style={{ marginBottom: "10px" }}>
                                Pro Number:{" "}
                              </div>
                              <div
                                style={{
                                  width: "75%",
                                  margin: "0 0 0 0",
                                  display: "inline-block",
                                  background: "#f5f7fb",
                                  padding: "10px",
                                  border: "1px solid #b5b6b9",
                                  wordWrap: "break-word",
                                }}
                              >
                                {_.get(
                                  values,
                                  `callSameDaysDataset[${index + pageIndex}].sameDayPro`
                                )}
                              </div>

                              <div style={{ marginBottom: "10px" }}>
                                Delivery Info?:{" "}
                              </div>
                              <div
                                style={{
                                  width: "75%",
                                  margin: "0 0 0 0",
                                  display: "inline-block",
                                  background: "#f5f7fb",
                                  padding: "10px",
                                  border: "1px solid #b5b6b9",
                                  wordWrap: "break-word",
                                }}
                              >
                                {_.get(
                                  values,
                                  `callSameDaysDataset[${index + pageIndex}].deliveryInfo`
                                )}
                              </div>
                            </React.Fragment>
                          )
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
              {typeof freightTerminalChunk !== "undefined" && (
                <>
                  <div
                    style={{
                      width: "calc(100vw - 40px)",
                      margin: "0 20px 20px",
                      display: "inline-block",
                    }}
                  >
                    <div style={{ margin: "0 0 10px" }}>
                      Contact the terminals that handled the freight to find
                      leads and check cameras if applicable
                    </div>

                    <div>
                      <div
                        style={{
                          width: "15vw",
                          display: "inline-block",
                          verticalAlign: "top",
                        }}
                      >
                        {values.contactTerminals === "Y" ? "Yes" : "No"}
                      </div>

                      {values.contactTerminals === "Y" && (
                        <>
                          {(freightTerminalChunk || []).map(
                            (
                              _f: IDSFreightTerminalLeadInquiries,
                              index: number
                            ) => (
                              <React.Fragment key={index + ""}>
                                <div style={{ marginBottom: "10px" }}>
                                  <h3 className="header-text mb-0">
                                    <strong>
                                      {" "}
                                      Investigation details: {index + 1}{" "}
                                    </strong>
                                  </h3>
                                </div>
                                <div style={{ marginBottom: "10px" }}>
                                  TID:{" "}
                                </div>
                                <div
                                  style={{
                                    width: "75%",
                                    margin: "0 0 0 0",
                                    display: "inline-block",
                                    background: "#f5f7fb",
                                    padding: "10px",
                                    border: "1px solid #b5b6b9",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {_.get(
                                    values,
                                    `freightTerminalLeadInquiries[${index}].terminal`
                                  )}
                                </div>

                                <div style={{ marginBottom: "10px" }}>
                                  Who did you talk to?:{" "}
                                </div>
                                <div
                                  style={{
                                    width: "75%",
                                    margin: "0 0 0 0",
                                    display: "inline-block",
                                    background: "#f5f7fb",
                                    padding: "10px",
                                    border: "1px solid #b5b6b9",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {_.get(
                                    values,
                                    `freightTerminalLeadInquiries[${index}].talkedTo`
                                  )}
                                </div>

                                <div style={{ marginBottom: "10px" }}>
                                  Provide updates and/or information from the
                                  terminal:{" "}
                                </div>
                                <div
                                  style={{
                                    width: "75%",
                                    margin: "0 0 0 0",
                                    display: "inline-block",
                                    background: "#f5f7fb",
                                    padding: "10px",
                                    border: "1px solid #b5b6b9",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {_.get(
                                    values,
                                    `freightTerminalLeadInquiries[${index}].updates`
                                  )}
                                </div>
                              </React.Fragment>
                            )
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  {/* F18 Notes */}
                  <div
                    style={{
                      width: "calc(100vw - 40px)",
                      margin: "0 20px 20px",
                      display: "inline-block",
                    }}
                  >
                    <div style={{ margin: "0 0 10px" }}>F18 Notes</div>

                    <div>
                      <div
                        style={{
                          width: "75%",
                          margin: "0 0 0 0",
                          display: "inline-block",
                          background: "#f5f7fb",
                          padding: "10px",
                          border: "1px solid #b5b6b9",
                          wordWrap: "break-word",
                        }}
                      >
                        {values.day5F18Note}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default DSDay5Template;
