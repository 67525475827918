export const DSDay1Fields = [
  "statusCorrect",
  "statusResponse",
  "deliveryCode",
  "deliveryCodeResponse",
  "epImaging",
  "epImagingResponse",
  "day1F18Note",
];

export const DSDay2Fields = [
  "noMovement",
  "dateLastMovement",
  "noMovementSteps",
  "suffixBill",
  "suffixBillSteps",
  "spotTrailer",
  "freightLeft",
  "day2F18Note",
];
export const DSDay3Fields = [
  "bolCheck",
  "bolSteps",
  "bolDiscrepancies",
  "drCheck",
  "confirm",
  "confirmSteps",
  "pumCheck",
  "pumManifest",
  "pumDiscrepancy",
  "shipperLoad",
  "shipperDiscrepancy",
  "lineHaulManifest",
  "numbersReview",
  "interviewWorkers",
  "workerSteps",
  "consigneePhone",
  "consigneeName",
  "consigneeShort",
  "itemNumbers",
  "orderReceived",
  "freightLabeled",
  "shipperPhone",
  "shipperName",
  "shippedDetails",
  "shortShipped",
  "productValue",
  "multiShipments",
  "callDriver",
  "adeDelivery",
  "freightNose",
  "outOfOrdinary",
  "day3F18Note",
];
export const DSDay4Fields = [
  "check7818",
  "details7818",
  "cameraFindings",
  "day4F18Note",
];
export const DSDay5Fields = [
  "callSameDays",
  "callSameDaysDataset",
  "freightTerminalLeadInquiries",
  "contactTerminals",
  "day5F18Note",
];
export const DSDay10Fields = ["followUp", "followUpDetails", "day10F18Note"];
export const DSDay15Fields = [
  "check7818_2",
  "details7818_2",
  "highValue",
  "day15F18Note",
];
export const DSDay20Fields = [
  "finalReCheck",
  "detailsOfSearch",
  "day20F18Note",
];

export const DSYesNoFields = [
  "statusCorrect",
  "deliveryCode",
  "epImaging",
  "noMovement",
  "suffixBill",
  "spotTrailer",
  "bolCheck",
  "drCheck",
  "confirm",
  "pumCheck",
  "shipperLoad",
  "lineHaulManifest",
  "interviewWorkers",
  "consigneeShort",
  "check7818",
  "callSameDays",
  "contactTerminals",
  "followUp",
  "check7818_2",
  "highValue",
  "finalReCheck",
];
