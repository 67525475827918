import { createTheme, ThemeProvider } from "@mui/material/styles";
import AccordionDetails from "@mui/material/AccordionDetails";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Stepper,
  Step,
  StepButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Grid,
  FormControl,
  TextField,
  Checkbox,
  Snackbar,
  Button,
  InputLabel,
} from "@mui/material";
import "./shortageInvestigation.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { State } from "../../../components/shared/Notification";
import {
  ASInvestigationInitValue,
  ASInvestigationSchema,
} from "../../../schema/asInvestigation";
import { FieldArray, FormikErrors, FormikProvider, useFormik } from "formik";
import dayjs from "dayjs";
import {
  ASInvestigationWizardStep,
  Day1Fields,
  Day10Fields,
  Day15Fields,
  Day20Fields,
  Day2Fields,
  Day3Fields,
  Day4Fields,
  Day5Fields,
  EShortageInvestigationStatus,
  StepStatusMap,
  EASInvestigationFieldsErrorMessage,
  StapDayMapping,
  InvestigationDoneTillTheDayStepMap,
  StepsRequired,
  FrightLocatedStepMap,
} from "../../../constants/shortage";
import { ShortageInvestigationCreateUpdateAPI } from "../../../API/shortageInvestigationCreateUpdate";
import { useDispatch, useSelector } from "react-redux";
import { PopoutNotification } from "../../../AppState/popoutNotification";
import NOTIFICATION_TYPE from "../../../AppState/NotificationType";
import { isFormValueChanged } from "../../../AppState/isFormValueChanged";
import Loader from "../../../components/loader/loader";
import {
  ChunkArray,
  CombineTowJsonObject,
  CompareObjects,
  HandleKeyDown,
  HandleNumberMaxLength,
  HandleTextFieldKeyDown,
  HandlePaste,
  HtmlToImage,
  TrimStrings,
} from "../../../helpers/utils.helper";
import {
  EASInvestigationFields,
  IAllShortageInvestigation,
  IDay5Investigation,
} from "../../../models/shortage";
import _, { debounce } from "lodash";
import ASInvestigationTemplate from "../../../templates/asInvestigationTemplate";
import { FileUploadAPI } from "../../../API/fileUpload";
import { IUploadFile } from "../../../models/master";
import {
  EFormAction,
  EUploadKey,
  MAX_LENGTH_SUMMARY_TEXT,
} from "../../../constants/app";
import AppState from "./../../../AppState";
import SendAPBImageList from "../../../components/sendAPBImageList/sendAPBImageList";
import TooltipWithClickAway from "../../../components/terminalPicker/TooltipWithClickAway";
import CustomDialog from "../../../components/confirmationPopup/confirmationPopup";
import { ICallSameDaysDataset } from "../../../models/dsException";
import ASDay4Template from "../../../templates/asDay4Template";
import { ConstantMessage } from "../../../constants/constantsMsg";

type Props = {
  isFormEditMode?: boolean;
  setInvestigationStatus: (status: number) => void;
  osdDetails?: any;
};
interface TooltipState {
  [key: string]: boolean;
}

const ShortageInvestigation: React.FC<Props> = ({
  osdDetails,
  isFormEditMode,
  setInvestigationStatus,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const investigationDetails = useMemo(() => osdDetails, [osdDetails]);
  const selectStyle = {
    fontSize: "13px",
  };

  const [activeStep, setActiveStep] = useState<number>(
    StepStatusMap[EShortageInvestigationStatus.exceptionEntry]
  );

  const [pageLoading, setPageLoading] = React.useState<boolean>(false);
  const [formFieldsDisabled, setFormFieldsDisabled] = React.useState<boolean>(
    isFormEditMode || false
  );
  const [resetTimestamps, setResetTimestamps] = useState<string>(
    Date.now().toString()
  );
  const [open, setOpen] = useState<boolean>(false);
  const [isCompleting, setIsCompleting] = useState<boolean>(false);
  const [displayCompleteBtn, setDisplayCompleteBtn] = useState<boolean>(true);
  const [formKey, setFormKey] = useState<string>(Date.now().toString());
  const [isFreightLocated, setIsFreightLocated] = useState<boolean>(false);
  const [status, setStatus] = React.useState<number>(1);
  const [lastStatusNumber, setLastStatusNumber] = React.useState<number>(1);
  const [invalidFields, setInvalidFields] = React.useState<string[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false); // State to manage dialog open/close
  const [addButtonDisabled, setAddButtonDisabled] = useState(false);
  const [isEditMode, setIsEditMode] = React.useState<boolean>(
    isFormEditMode || false
  );
  const [proInfoAddButtonDisabled, setProInfoAddButtonDisabled] =
    useState(false);
  const [dateKeys, setDateKeys] = React.useState({
    dateToDestTerm: Date.now().toString(),
    dateLastMovement: Date.now().toString(),
  });
  const isPrivilegedUser: boolean = useSelector(
    (state: AppState) => state.isPrivilegedUser
  );
  const [selectedImages, setSelectedImages] = useState<string[]>([]);

  const [initValues, setInitValues] = React.useState<IAllShortageInvestigation>(
    _.mergeWith(
      {},
      ASInvestigationInitValue,
      investigationDetails,
      CombineTowJsonObject
    )
  );

  const activeDay = StapDayMapping[activeStep as keyof typeof StapDayMapping];

  const adjustedStatus = isFreightLocated
    ? FrightLocatedStepMap[
        lastStatusNumber as keyof typeof FrightLocatedStepMap
      ]
    : StepStatusMap[status as keyof typeof StepStatusMap];

  const shouldShowCompleteButton =
    formFieldsDisabled &&
    isPrivilegedUser &&
    status === EShortageInvestigationStatus.pendingSrApproval &&
    adjustedStatus === activeStep;
  // Handle image selection
  const handleImageSelect = (imageUrl: string) => {
    setSelectedImages((prevSelectedImages) =>
      prevSelectedImages.includes(imageUrl)
        ? prevSelectedImages.filter((url) => url !== imageUrl)
        : [...prevSelectedImages, imageUrl]
    );
  };

  const showSendAPB = () => {
    const { osdNumber } = investigationDetails;
    const { highValue, details7818_2, check7818_2 } = values;
    const {
      highValue: initialHighValue,
      details7818_2: initialDetails7818_2,
      check7818_2: initialCheck7818_2,
    } = initValues;

    const isStepActive = activeStep === StepStatusMap[8];
    const isFormEnabled = !formFieldsDisabled;
    const hasChangedValue =
      !initialDetails7818_2?.trim() ||
      initialCheck7818_2 !== "Y" ||
      initialHighValue !== "Y";

    return (
      osdNumber &&
      isStepActive &&
      isFormEnabled &&
      highValue === "Y" &&
      details7818_2?.trim() &&
      check7818_2 === "Y" &&
      hasChangedValue
    );
  };

  const uploadInvestigation = async () => {
    setPageLoading(true);
    let ids = ASInvestigationWizardStep;
    if (isFreightLocated) {
      ids = ids.slice(
        0,
        InvestigationDoneTillTheDayStepMap[
          lastStatusNumber as keyof typeof InvestigationDoneTillTheDayStepMap
        ]
      );
    }
    const imagePromises: Promise<File>[] = ids.flatMap(
      (day: string, index: number) => {
        const promises: Promise<File>[] = [];

        if (day === "Day 4") {
          const defaultId = `investigation-${day}`;
          promises.push(HtmlToImage(defaultId, `${index + 1}.jpeg`, 0.1));
          // Process "callSameDaysChunks" asynchronously and add promises to the array
          promises.push(
            ...callSameDaysChunks.map((_c, chunkIndex) => {
              const chunkId = `investigation-${day}-callSameDays-${chunkIndex}`;
              return HtmlToImage(
                chunkId,
                `${day}-page-${chunkIndex + 1}.jpeg`,
                0.2
              );
            })
          );
        } else {
          // Default handling for other days
          const defaultId = `investigation-${day}`;
          promises.push(
            HtmlToImage(
              defaultId,
              `${index + 1}.jpeg`,
              day === "Day 3" ? 0.2 : 0.1
            )
          );
        }

        return promises;
      }
    );
    const images = await Promise.all(imagePromises);
    const reqInput: IUploadFile = {
      osdNumber: investigationDetails?.osdNumber,
      docType: "FX",
      uploadKey: EUploadKey.pro,
    };
    FileUploadAPI(images, reqInput)
      .then((res: any) => {
        setPageLoading(false);
        const errors = _.get(res, "errors", []);
        const notificationType =
          errors.length > 0
            ? NOTIFICATION_TYPE.ERROR
            : NOTIFICATION_TYPE.SUCCESS;
        const message =
          errors.length > 0
            ? errors.join(", ")
            : res.result || "OS&D investigation image uploaded successfully";

        dispatch(
          PopoutNotification(notificationType, message, Date.now().toString())
        );
      })
      .catch((error: any) => {
        setPageLoading(false);
        dispatch(
          PopoutNotification(
            NOTIFICATION_TYPE.ERROR,
            typeof error === "string"
              ? error
              : `OS&D investigation image uploading failed`,
            Date.now().toString()
          )
        );
      });
  };

  // If active day investigation is completed the checkboxes is disabled of day
  const isControlDisabled = (fieldName: keyof IAllShortageInvestigation) => {
    const value = "Y";
    const isChecked =
      typeof initValues[fieldName] === "string" &&
      initValues[fieldName] === value;
    if (
      (status > EShortageInvestigationStatus.day20InProgress ||
        isFreightLocated) &&
      isChecked
    ) {
      return true;
    }
    if (StepStatusMap[status as keyof typeof StepStatusMap] <= activeStep)
      return;
    if (isChecked) {
      return true;
    }
  };

  /**  
    Switch the investigation day step
    Allows the user to navigate to a previous day's investigation from the current day in progress
    However, the user cannot proceed to the next day until the current day's investigation is completed
  */

  const handleStep = (step: number) => () => {
    const statusStep = isFreightLocated
      ? FrightLocatedStepMap[
          lastStatusNumber as keyof typeof FrightLocatedStepMap
        ]
      : StepStatusMap[status as keyof typeof StepStatusMap];
    if (statusStep < step) return;
    setIsCompleting(false);
    setFormFieldsDisabled(true);
    setIsEditMode(true);
    reset();
    setActiveStep(step);
  };

  const theme = createTheme({
    components: {
      MuiStepLabel: {
        styleOverrides: {
          iconContainer: {
            display: "none", // This will hide the icon container
          },
        },
      },
      MuiStepConnector: {
        styleOverrides: {
          line: {
            display: "none", // This will hide the connector line
          },
        },
      },
      MuiStep: {
        styleOverrides: {
          horizontal: {
            width: "12.5%", // This will set the width of each step to 12.5%
            padding: "0", // This will set the padding to 0
          },
        },
      },
      MuiStepButton: {
        styleOverrides: {
          root: {
            margin: "initial", // This will set the margin to its initial value
            padding: "0", // This will set the padding to its initial value
            zIndex: "1", // This will set the z-index to 1
            height: "50px",
            fontWeight: "bold",
            fontStyle: "italic",
            letterSpacing: "1px",
            textTransform: "uppercase",
          },
        },
      },
    },
  });

  // Declare a new state variable for tooltips with a default empty object
  const [openTooltips, setOpenTooltips] = useState<TooltipState>({});

  // Function to handle tooltip close
  const handleTooltipClose = (id: string) => {
    setOpenTooltips((prev) => ({ ...prev, [id]: false }));
  };

  // Function to handle tooltip open
  const handleTooltipOpen = (id: string) => {
    setOpenTooltips((prev) => ({ ...prev, [id]: true }));
  };

  const [state, setState] = React.useState<State>({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  // Enable fields in edit mode upon clicking the edit button
  const enableEditing = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault(); // Prevents the default form submission behavior
    setFormFieldsDisabled(false); // Enables the form fields for editing
  };

  // Initialize form handling using Formik
  // This setup uses Formik to manage the form state, validation, and submission process.
  const form = useFormik({
    validationSchema: ASInvestigationSchema[activeStep], // Validation schema for the form fields
    validateOnChange: false, // Disable validation on field change
    initialValues: initValues, // Initial values for the form fields
    initialTouched: {
      showAtDestTerm: true,
      noMovement: true,
      pumCheck: true,
      check7818: true,
      contactTerminals: true,
      followUp: true,
      check7818_2: true,
      finalReCheck: true,
    },
    onSubmit: () => {
      saveOrSubmit(EFormAction.submit); // Function to handle form submission
    },
  });

  // Destructure various handlers and values provided by Formik for easy use within the component
  const {
    values, // Current values of the form fields
    handleChange, // Handler for changing form field values
    handleBlur, // Handler for managing field blur events
    validateField, // Function to validate a specific field
    setFieldValue, // Function to set the value of a specific field
    validateForm, // Function to validate the entire form
    resetForm,
    errors, // Function to reset the form to its initial values
  } = form;

  const callSameDaysChunks = ChunkArray(values?.callSameDaysDataset || [], 8);

  const getPageIndex = (index: number, callSameDaysChunks: any[]) => {
    if (index === 1) {
      return callSameDaysChunks[0].length;
    }
    if (index === 2) {
      return [...callSameDaysChunks[0], ...callSameDaysChunks[1]].length;
    }
    return 0;
  };
  const isValidDependedField = (
    fieldName: keyof IAllShortageInvestigation,
    dependedField: keyof IAllShortageInvestigation,
    activeStep: number,
    val: string = "Y"
  ) => {
    if (StepStatusMap[status as keyof typeof StepStatusMap] <= activeStep)
      return;
    if (
      values[dependedField] === val &&
      values[fieldName] &&
      values[fieldName]?.toString() === "" &&
      invalidFields.indexOf(fieldName) === -1
    ) {
      setInvalidFields([...invalidFields, fieldName]);
    } else {
      setInvalidFields([
        ...invalidFields.filter((field) => field !== fieldName),
      ]);
    }
  };

  const addRemove = (type: string = "add") => {
    if (StepStatusMap[status as keyof typeof StepStatusMap] <= activeStep)
      return;
    const l = values?.freightTerminalLeadInquiries?.length!;

    if (type === "add") {
      setInvalidFields([
        ...invalidFields,
        `manifest${l}`,
        `terminal${l}`,
        `discrepancies${l}`,
      ]);
    } else {
      setInvalidFields([
        ...invalidFields.filter(
          (field) =>
            ![
              `manifest${l - 1}`,
              `terminal${l - 1}`,
              `discrepancies${l - 1}`,
            ].includes(field)
        ),
      ]);
    }
  };

  // check text field box value exist and on blank show error for completed investigation day
  const isValueExist = (
    fieldName: string,
    index?: number,
    fieldArrayName: string = "freightTerminalLeadInquiries"
  ) => {
    // Exit if the current step is not active
    if (StepStatusMap[status as keyof typeof StepStatusMap] <= activeStep)
      return;

    const fieldKey = index !== undefined ? `${fieldName}${index}` : fieldName;
    const value =
      index !== undefined
        ? _.get(values, `${fieldArrayName}[${index}][${fieldName}]`, "")
        : values[fieldName as keyof IAllShortageInvestigation] ?? "";

    const initValue =
      index !== undefined
        ? _.get(initValues, `${fieldArrayName}[${index}][${fieldName}]`, "")
        : initValues[fieldName as keyof IAllShortageInvestigation];
    const isInvalid =
      (value == null || value === "") && // Covers null, undefined, and empty string
      ((index === undefined && initValue !== "" && initValue != null) ||
        index !== undefined) && // Checks if initValue is neither an empty string, null, nor undefined
      !invalidFields.includes(fieldKey);

    // Update invalid fields
    setInvalidFields((prevInvalidFields) =>
      isInvalid
        ? [...prevInvalidFields, fieldKey]
        : prevInvalidFields.filter((field) => field !== fieldKey)
    );
  };

  const proInfoAddRemove = (type: string = "add") => {
    // Exit if the current step is not active
    if (StepStatusMap[status as keyof typeof StepStatusMap] <= activeStep)
      return;
    // Determine the length based on the type
    const length =
      (values?.callSameDaysDataset?.length ?? 0) - (type === "add" ? 0 : 1);

    const fields = [`sameDayPro${length}`, `deliveryInfo${length}`];

    setInvalidFields((prevFields) =>
      type === "add"
        ? [...prevFields, ...fields]
        : prevFields.filter((field) => !fields.includes(field))
    );
  };

  const reset = () => {
    resetForm();
    setIsCompleting(false);
    setResetTimestamps(Date.now().toString());
    setInvalidFields([]);
    const source: IAllShortageInvestigation = JSON.parse(
      JSON.stringify(initValues)
    );
    const fields: string[] = [
      ...Day1Fields,
      ...Day2Fields,
      ...Day3Fields,
      ...Day4Fields,
      ...Day10Fields,
      ...Day15Fields,
      ...Day20Fields,
      "contactTerminals",
      "pickupManifest",
      "day5F18Note",
    ];
    fields.forEach((f: string) => {
      const fieldValue = source[f as keyof IAllShortageInvestigation];
      setFieldValue(f, fieldValue);
    });
    setFieldValue(`pickupManifest`, _.get(source, `pickupManifest`, undefined));

    _.get(source, "freightTerminalLeadInquiries", []).forEach(
      (obj: IDay5Investigation, index: number) => {
        setFieldValue(
          `freightTerminalLeadInquiries[${index}].terminal`,
          _.get(obj, `terminal`, undefined)
        );
        setFieldValue(
          `freightTerminalLeadInquiries[${index}].manifest`,
          _.get(obj, `manifest`, undefined)
        );
        setFieldValue(
          `freightTerminalLeadInquiries[${index}].discrepancies`,
          _.get(obj, `discrepancies`, undefined)
        );
      }
    );

    _.get(source, "callSameDaysDataset", []).forEach(
        (obj: ICallSameDaysDataset, index: number) => {
          setFieldValue(
            `callSameDaysDataset[${index}].sameDayPro`,
            _.get(obj, `sameDayPro`, undefined)
          );
          setFieldValue(
            `callSameDaysDataset[${index}].deliveryInfo`,
            _.get(obj, `deliveryInfo`, undefined)
          );
        }
      );
  };

  // validate day 5 investigation Details for add and remove buttons
  const validateDay5Fields = () => {
    values.freightTerminalLeadInquiries &&
      values.freightTerminalLeadInquiries.forEach((_: any, index: number) => {
        validateField(`freightTerminalLeadInquiries[${index}].terminal`);
        validateField(`freightTerminalLeadInquiries[${index}].manifest`);
        validateField(`freightTerminalLeadInquiries[${index}].discrepancies`);
      });
  };

  // Updated Initial values on page load and save, submit, and complete action
  const updateInitValue = (
    key: string,
    value: string | number | null | undefined
  ) => {
    setInitValues((prevState) => {
      // Update the top-level property
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  // handle confirmation pop while user want back according button
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  // Close the confirmation modal pop and navigate user to back All Shortage listing page if user confirmed
  const handleDialogClose = (flag?: string) => {
    setDialogOpen(false);
    if (flag === "yes") {
      handleFreightAndCompletion(EFormAction.freightLocated);
    }
  };

  // Investigation complete and upload images
  const handleFreightAndCompletion = async (action: EFormAction) => {
    try {
      setPageLoading(true);
      const submitResponse = await ShortageInvestigationCreateUpdateAPI(
        {},
        investigationDetails?.osdNumber?.toString(),
        action,
        action === EFormAction.freightLocated ? activeDay.toString() : undefined
      );
      const {
        status: statusNumber,
        investigationDoneTillTheDay: lastStatusNumber,
        freightLocated,
      } = submitResponse;
      setStatus(statusNumber);
      setLastStatusNumber(lastStatusNumber);
      setIsFreightLocated(freightLocated);
      setInvestigationStatus(statusNumber);
      setPageLoading(false);
      setFormKey(Date.now().toString());
      let message;
      if (action === EFormAction.complete) {
        message = ConstantMessage.investigationNoteCompleted;
        uploadInvestigation();
      } else {
        setActiveStep(
          FrightLocatedStepMap[
            lastStatusNumber as keyof typeof FrightLocatedStepMap
          ]
        );
        message = ConstantMessage.freightLocatedSuccess;
      }
      dispatch(
        PopoutNotification(
          NOTIFICATION_TYPE.SUCCESS,
          message,
          Date.now().toString()
        )
      );
    } catch (error) {
      setPageLoading(false);
      dispatch(
        PopoutNotification(
          NOTIFICATION_TYPE.ERROR,
          typeof error === "string"
            ? error
            : `OS&D investigation notes submit failed`,
          Date.now().toString()
        )
      );
    }
  };
  const handleDateChange =
    (fieldName: string) => (value: dayjs.Dayjs | null) => {
      if (value?.format("MM/DD/YY") !== "Invalid Date") {
        setFieldValue(fieldName, value?.format("MM/DD/YY"), true);
        setOpen(false);
      } else {
        setDateKeys((prevKeys) => ({
          ...prevKeys,
          [fieldName]: Date.now().toString(),
        }));
      }
    };

  const saveOrSubmit = async (action: EFormAction) => {
    setIsCompleting(false);

    if (await shouldAbortDueToErrors(action)) return;
    if (shouldAbortDueToInvalidFields()) return;
    if (focusOnRequiredField()) return;

    let reqParams = createRequestParams();
    reqParams = _.omitBy(reqParams, _.isUndefined);

    if (_.isEmpty(reqParams)) return;

    // Trim all of string variables
    reqParams = TrimStrings(reqParams);
    setPageLoading(true);

    submitRequest(reqParams, action);
  };

  const shouldAbortDueToErrors = async (action: EFormAction) => {
    // Ensure the form is fully validated before proceeding
    const errorList = await form.validateForm();
    const requiredSteps =
      StepsRequired[lastStatusNumber as keyof typeof StepsRequired] || [];

    // Check if the action is either 'submit' or 'save' (validate the save form if the Investigation Day is already completed) with valid conditions

    const isValidAction =
      action === EFormAction.submit ||
      (action === EFormAction.save && requiredSteps.includes(activeStep));

    // If the action is valid and there are errors, abort submission
    if (isValidAction && Object.keys(errorList).length > 0) {
      setIsCompleting(true);
      // Focus on the first error field if there are validation errors
      const firstErrorField = Object.keys(errors)[0];
      document.getElementsByName(firstErrorField)[0]?.focus();
      return true; // Abort due to validation errors
    }

    // If there are no errors, proceed with submission
    return false;
  };

  const shouldAbortDueToInvalidFields = () => {
    return invalidFields?.length > 0;
  };

  const focusOnRequiredField = () => {
     if (activeStep === StepStatusMap[6]) {
      const isAllSameDayProValid = values?.callSameDaysDataset?.every(
        (item, index) => {
          if (isNaN(+(item?.sameDayPro ?? ""))) {
            // Focus on the invalid field
            const field = document.getElementsByName(
              `callSameDaysDataset[${index}].sameDayPro`
            )[0];
            field?.focus();
            return false;
          }
          return true;
        }
      );

      if (!isAllSameDayProValid) {
        return true; // Prevent save
      }
    }
    if (activeStep === StepStatusMap[4] && values.pickupManifest === 0) {
      document.getElementsByName("pickupManifest")[0].focus();
      return true;
    }

    if (activeStep === StepStatusMap[6]) {
      return focusOnZeroManifestField();
    }

    return false;
  };

  const focusOnZeroManifestField = () => {
    let isManifestZero = false;
    (values.freightTerminalLeadInquiries || []).forEach(
      (obj: IDay5Investigation, index: number) => {
        if (obj.manifest === 0) {
          document
            .getElementsByName(
              `freightTerminalLeadInquiries[${index}].manifest`
            )[0]
            .focus();
          isManifestZero = true;
        }
      }
    );
    return isManifestZero;
  };

  const createRequestParams = () => {
    let reqParams = JSON.parse(JSON.stringify(values));
    const deleteFields: string[] = [];

    // Filter based on active step
    const dayFields = [
      Day1Fields,
      Day2Fields,
      Day3Fields,
      Day4Fields,
      Day5Fields,
      Day10Fields,
      Day15Fields,
      Day20Fields,
    ];
    if (activeStep >= 0 && activeStep < dayFields.length) {
      reqParams = Object.fromEntries(
        Object.entries(reqParams).filter(([key]) =>
          dayFields[activeStep].includes(key)
        )
      );
    }

    handleConditionalFields(reqParams, deleteFields);
    deleteFields.forEach((field) => delete reqParams[field]);

    formatRequestParams(reqParams);

    const { freightTerminalLeadInquiries, callSameDaysDataset, ...data } = reqParams;

    reqParams = {
      data,
      ...(freightTerminalLeadInquiries ? { freightTerminalLeadInquiries } : {}),
      ...(callSameDaysDataset ? { callSameDaysDataset } : {}),
      ...(showSendAPB() ? { attachmentImageUrls: selectedImages } : {}),
    };

    return reqParams;
  };

  const handleConditionalFields = (reqParams: any, deleteFields: string[]) => {
    if (!reqParams["contactTerminals"]) {
      deleteFields.push("contactTerminals", "freightTerminalLeadInquiries");
    } else if (reqParams["contactTerminals"] === "N") {
      deleteFields.push("freightTerminalLeadInquiries");
    }

    if (!reqParams["callSameDays"]) {
      deleteFields.push("callSameDays", "callSameDaysDataset");
    } else if (reqParams["callSameDays"] === "N") {
      deleteFields.push("callSameDaysDataset");
    }
  };

  const formatRequestParams = (reqParams: any) => {
    // Convert specific fields to the correct types

    if (!reqParams.callSameDaysDataset) {
      delete reqParams.callSameDaysDataset;
    } else {
      reqParams.callSameDaysDataset = (reqParams.callSameDaysDataset || []).map(
        (obj: ICallSameDaysDataset) => ({
          sameDayPro: obj.sameDayPro === 0 ? "" : obj.sameDayPro?.toString(),
          deliveryInfo: obj.deliveryInfo === null ? "" : obj.deliveryInfo,
        })
      );
    }
    if (reqParams.consigneePhone) {
      reqParams.consigneePhone = reqParams.consigneePhone.toString();
    }

    if (reqParams.shipperPhone) {
      reqParams.shipperPhone = reqParams.shipperPhone.toString();
    }

    // Convert `pickupManifest` to an integer if it exists and is a valid number
    if (reqParams.pickupManifest && !isNaN(reqParams.pickupManifest)) {
      reqParams.pickupManifest = parseInt(reqParams.pickupManifest);
    } else {
      delete reqParams.pickupManifest;
    }

    // Process `freightTerminalLeadInquiries` if present
    if (Array.isArray(reqParams.freightTerminalLeadInquiries)) {
      reqParams.freightTerminalLeadInquiries =
        reqParams.freightTerminalLeadInquiries.map(
          (item: IDay5Investigation) => ({
            ...item,
            manifest: item.manifest ? +item.manifest : undefined,
          })
        );
    }
  };

  const submitRequest = (reqParams: any, action: EFormAction) => {
    ShortageInvestigationCreateUpdateAPI(
      reqParams,
      investigationDetails?.osdNumber?.toString(),
      action,
      activeDay.toString()
    )
      .then((res) => handleSuccessfulSubmission(res, action))
      .catch((error) => handleSubmissionError(error, action));
  };

  const handleSuccessfulSubmission = (res: any, action: EFormAction) => {
    if (action === EFormAction.save) setFormFieldsDisabled(true);
    setIsCompleting(false);

    processRequestFields();
    displaySuccessMessage(action);

    handleStatusAndUIUpdates(res);

    setPageLoading(false);
    validateForm(values);
    dispatch(isFormValueChanged(false));
  };

  const handleSubmissionError = (err: any, action: EFormAction) => {
    setPageLoading(false);
    dispatch(
      PopoutNotification(
        NOTIFICATION_TYPE.ERROR,
        typeof err === "string"
          ? err
          : `OS&D investigation notes ${action} failed`,
        Date.now().toString()
      )
    );
  };

  const processRequestFields = () => {
    const reqParams = TrimStrings(values);

    if (!reqParams.freightTerminalLeadInquiries) {
      delete reqParams.freightTerminalLeadInquiries;
    } else {
      reqParams.freightTerminalLeadInquiries = (
        reqParams.freightTerminalLeadInquiries || []
      ).map((obj: IDay5Investigation) => ({
        manifest: obj.manifest === 0 ? undefined : obj.manifest,
        discrepancies: obj.discrepancies === null ? "" : obj.discrepancies,
        terminal: obj.terminal === null ? "" : obj.terminal,
      }));
    }

    reqParams.callSameDaysDataset = (reqParams.callSameDaysDataset || []).map(
      (obj: ICallSameDaysDataset) => ({
        sameDayPro:
          obj.sameDayPro === null || obj.sameDayPro === "0"
            ? ""
            : obj.sameDayPro,
        deliveryInfo: obj.deliveryInfo === null ? "" : obj.deliveryInfo,
      })
    );

    Object.keys(reqParams).forEach((field: string) => {
      if (
        [
          ...Day1Fields,
          ...Day2Fields,
          ...Day3Fields,
          ...Day4Fields,
          ...Day5Fields,
          ...Day10Fields,
          ...Day15Fields,
          ...Day20Fields,
        ].includes(field)
      ) {
        const value =
          field === "pickupManifest" && reqParams[field] === 0
            ? undefined
            : reqParams[field];
        setFieldValue(field, value);
        updateInitValue(field, value);
      }
    });
  };

  const displaySuccessMessage = (action: EFormAction) => {
    const message =
      action === EFormAction.save
        ? ConstantMessage.investigationNoteSaved
        : `OS&D investigation day ${activeDay} notes have been completed successfully`;

    dispatch(
      PopoutNotification(
        NOTIFICATION_TYPE.SUCCESS,
        message,
        Date.now().toString()
      )
    );
  };

  const validateCallSameDaysDataset = useCallback(() => {
    if (!values.callSameDaysDataset?.length) return;
    for (let index = 0; index < values.callSameDaysDataset.length; index++) {
      ["sameDayPro", "deliveryInfo"].forEach((field) =>
        validateField(`callSameDaysDataset[${index}].${field}`)
      );
    }
  }, [values.callSameDaysDataset, validateField]);

  const handleStatusAndUIUpdates = (res: any) => {
    const statusNumber = res.status;
    if (
      status === statusNumber ||
      statusNumber > EShortageInvestigationStatus.day20InProgress ||
      (status === EShortageInvestigationStatus.exceptionEntry &&
        statusNumber === EShortageInvestigationStatus.day1InProgress)
    ) {
      setFormFieldsDisabled(true);
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
    setStatus(statusNumber);
    setIsFreightLocated(res.freightLocated);
    setInvestigationStatus(statusNumber);
    setLastStatusNumber(res.investigationDoneTillTheDay);

    if (!res?.freightLocated) {
      setActiveStep(StepStatusMap[statusNumber as keyof typeof StepStatusMap]);
    } else if (res.freightLocated) {
      setLastStatusNumber(res.investigationDoneTillTheDay);
      setActiveStep(
        FrightLocatedStepMap[
          res.investigationDoneTillTheDay as keyof typeof FrightLocatedStepMap
        ]
      );
    }
  };


  const getCallSameDaysDataset = (checked: boolean) => {
    if (checked) {
      return _.isArray(values.callSameDaysDataset) &&
        values.callSameDaysDataset.length > 0
        ? values.callSameDaysDataset
        : ASInvestigationInitValue.callSameDaysDataset;
    }
    return [];
  };

  const getFreightTerminalLeadInquiries = (checked: boolean) => {
    if (checked) {
      return _.isArray(values.freightTerminalLeadInquiries) &&
        values.freightTerminalLeadInquiries.length > 0
        ? values.freightTerminalLeadInquiries
        : ASInvestigationInitValue.freightTerminalLeadInquiries;
    }
    return [];
  };

  // Returns a color class where completeStep indicates green for completed investigation days,
  // activeStep indicates yellow for in-progress investigation days,
  // and an empty class indicates gray for investigations that have not started yet.
  // return call depend on investigation status
  const stepClass = useCallback(
    (index: number) => {
      const stapStatus = isFreightLocated
        ? FrightLocatedStepMap[
            lastStatusNumber as keyof typeof FrightLocatedStepMap
          ]
        : StepStatusMap[status as keyof typeof StepStatusMap];
      if (isFreightLocated && stapStatus >= index) {
        return "completeStep";
      }
      if (
        (index === activeStep && stapStatus > activeStep) ||
        stapStatus > index ||
        (!isFreightLocated &&
          (status === EShortageInvestigationStatus.pendingSrApproval ||
            status === EShortageInvestigationStatus.complete))
      ) {
        return "completeStep";
      } else if (index === activeStep || stapStatus === index) {
        return "activeStep";
      }
      return "";
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFreightLocated, lastStatusNumber, status, activeStep, StepStatusMap]
  );
  // Assuming `displayCompleteBtn`, `isFreightLocated`, `lastStatusNumber`, `status`, `activeStep`, and `StepStatusMap` are all defined

  // Use `useEffect` to set `displayCompleteBtn` when dependencies change
  useEffect(() => {
    const adjustedStatus = isFreightLocated
      ? FrightLocatedStepMap[
          lastStatusNumber as keyof typeof FrightLocatedStepMap
        ]
      : StepStatusMap[status as keyof typeof StepStatusMap];
    // Determine the condition to display the complete button
    const shouldDisplayCompleteBtn = !(
      isFreightLocated ||
      adjustedStatus > activeStep ||
      status === EShortageInvestigationStatus.pendingSrApproval ||
      (!isFreightLocated && status === EShortageInvestigationStatus.complete)
    );

    setDisplayCompleteBtn(shouldDisplayCompleteBtn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFreightLocated, lastStatusNumber, status, activeStep, StepStatusMap]);

  useEffect(() => {
    validateForm().then((formErrors: FormikErrors<any>) => {
      const freightTerminalLeadInquiriesErrors =
        formErrors.freightTerminalLeadInquiries;
      // Check if any manifest in the freightTerminalLeadInquiries array is zero
      const hasZeroManifest = values.freightTerminalLeadInquiries?.some(
        (detail) => detail.manifest === 0
      );
      if (!freightTerminalLeadInquiriesErrors?.length) {
        if (hasZeroManifest) {
          setAddButtonDisabled(true);
        } else {
          setAddButtonDisabled(false);
        }
      } else {
        validateDay5Fields();
        setAddButtonDisabled(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (!investigationDetails) return;

    const { investigationFreightLocated, investigationDoneTillTheDay } =
      investigationDetails;
    setIsFreightLocated(investigationFreightLocated);
    setLastStatusNumber(investigationDoneTillTheDay ?? 1);

    if (investigationDetails.investigationStatus) {
      const stepMap =
        isFreightLocated || investigationDetails?.investigationFreightLocated
          ? FrightLocatedStepMap
          : StepStatusMap;

      const stepKey =
        isFreightLocated || investigationDetails?.investigationFreightLocated
          ? investigationDetails?.investigationDoneTillTheDay
          : investigationDetails.investigationStatus;

      setActiveStep(stepMap[stepKey as keyof typeof stepMap]);
      setStatus(investigationDetails.investigationStatus);
    }

    // Normalize investigation details, setting manifest to undefined if it's 0
    const normalizedDetails =
      investigationDetails?.freightTerminalLeadInquiries?.map(
        ({
          terminal,
          manifest,
          discrepancies,
        }: {
          terminal: string;
          manifest: number | undefined;
          discrepancies: string;
        }) => ({
          terminal,
          manifest: manifest === 0 ? undefined : manifest,
          discrepancies,
        })
      ) || [];

    // Update investigation details
    investigationDetails.freightTerminalLeadInquiries = normalizedDetails;

    // Prepare the list of all fields to iterate
    if (
      investigationDetails.contactTerminals === "Y" &&
      normalizedDetails.length === 0
    ) {
      investigationDetails.freightTerminalLeadInquiries = [
        { terminal: undefined, manifest: undefined, discrepancies: undefined },
      ];
    }

    if (
      (!investigationDetails?.callSameDaysDataset ||
        investigationDetails?.callSameDaysDataset?.length < 1) &&
      investigationDetails?.callSameDays === "Y"
    ) {
      investigationDetails.callSameDaysDataset = [
        { sameDayPro: undefined, deliveryInfo: undefined },
      ];
    }
    const allFields = [
      ...Day1Fields,
      ...Day2Fields,
      ...Day3Fields,
      ...Day4Fields,
      ...Day10Fields,
      ...Day15Fields,
      ...Day20Fields,
      ...Day5Fields,
    ];

    allFields.forEach((key) => {
      const value =
        key === "pickupManifest" && investigationDetails[key] === 0
          ? undefined
          : investigationDetails[key];

      setFieldValue(key, value);
      updateInitValue(key, value);
    });

    normalizedDetails.forEach((obj: IDay5Investigation, index: number) => {
      setFieldValue(
        `freightTerminalLeadInquiries[${index}].terminal`,
        obj.terminal
      );
      setFieldValue(
        `freightTerminalLeadInquiries[${index}].manifest`,
        obj.manifest
      );
      setFieldValue(
        `freightTerminalLeadInquiries[${index}].discrepancies`,
        obj.discrepancies
      );
    });

    investigationDetails?.callSameDaysDataset?.forEach(
      (obj: ICallSameDaysDataset, index: number) => {
        setFieldValue(
          `callSameDaysDataset[${index}].sameDayPro`,
          obj.sameDayPro
        );
        setFieldValue(
          `callSameDaysDataset[${index}].deliveryInfo`,
          obj.deliveryInfo
        );
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investigationDetails]);

  useEffect(() => {
    if (!values) return; // Skip validation if no values

    const debouncedValidation = debounce(async () => {
      try {
        const formErrors = await validateForm();
        const proDeliveryInfoErrors = formErrors.callSameDaysDataset;

        if (!proDeliveryInfoErrors?.length) {
          setProInfoAddButtonDisabled(false);
        } else {
          validateCallSameDaysDataset();
          setProInfoAddButtonDisabled(true);
        }
      } catch (error) {
        setProInfoAddButtonDisabled(true);
      }
    }, 300);

    debouncedValidation();

    return () => debouncedValidation.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, validateForm, validateCallSameDaysDataset]);

  useEffect(() => {
    dispatch(isFormValueChanged(!CompareObjects(initValues, values)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, initValues]);
  return (
    <div>
      <AccordionDetails className="accordion-container">
        <Grid item md={12} xs={12}>
          <FormControl className="custom-group">
            <FormLabel>
              Investigation Day&nbsp;
              {activeDay}
              <sup>*</sup>{" "}
            </FormLabel>
          </FormControl>
        </Grid>
        <ThemeProvider theme={theme}>
          <Stepper nonLinear activeStep={activeStep}>
            {ASInvestigationWizardStep.map((label, index) => (
              <Step key={label} className={stepClass(index)}>
                <StepButton
                  onClick={index === activeStep ? void 0 : handleStep(index)}
                  disableRipple
                >
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </ThemeProvider>
        <FormikProvider key={resetTimestamps} value={form}>
          <>
            {activeStep === StepStatusMap[1] && (
              <div>
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id="destinationTerminal">
                        Does the freight show at the destination terminal?{" "}
                        <sup>*</sup> &nbsp;
                        <TooltipWithClickAway
                          tooltipText="Freight must be forced to the DT if not already showing there"
                          tooltipKey="tooltip1"
                          openTooltips={openTooltips}
                          handleTooltipOpen={handleTooltipOpen}
                          handleTooltipClose={handleTooltipClose}
                        />
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          <RadioGroup
                            row
                            aria-labelledby="destinationTerminal"
                            name="destinationTerminal"
                            className="textarea-radio-group"
                          >
                            <FormControlLabel
                              value="Y"
                              control={<Radio />}
                              label="Yes"
                              name="showAtDestTerm"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue("dateToDestTerm", "", true);
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.showAtDestTerm === "Y"}
                            />
                            <FormControlLabel
                              value="N"
                              control={<Radio />}
                              label="No"
                              name="showAtDestTerm"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue(
                                  "dateToDestTerm",
                                  initValues["dateToDestTerm"],
                                  true
                                );
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.showAtDestTerm === "N"}
                            />
                          </RadioGroup>

                          {values.showAtDestTerm === "N" && (
                            <FormLabel id="dateToDestTerm">
                              Date forced to destination terminal <sup>*</sup>
                            </FormLabel>
                          )}
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            {values.showAtDestTerm === "N" && (
                              <DatePicker
                                disableFuture
                                open={open}
                                key={dateKeys.dateToDestTerm}
                                onOpen={() => setOpen(true)}
                                onClose={() => setOpen(false)}
                                value={dayjs(values.dateToDestTerm ?? null)}
                                name="dateToDestTerm"
                                onChange={handleDateChange("dateToDestTerm")}
                                format="MM/DD/YYYY"
                                slotProps={{
                                  textField: {
                                    error:
                                      !!errors.dateToDestTerm && isCompleting,
                                    onBlur: (e) => {
                                      handleBlur(e);
                                      isValidDependedField(
                                        "dateToDestTerm",
                                        "showAtDestTerm",
                                        StepStatusMap[2],
                                        "N"
                                      );
                                    },
                                    onClick: () => setOpen(true),
                                    inputProps: {
                                      readOnly: true,
                                    },
                                  },
                                }}
                                disabled={formFieldsDisabled}
                                className="col-md-4 mt-1"
                              />
                            )}
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                      {invalidFields.includes(
                        EASInvestigationFields.DateToDestTerm
                      ) && (
                        <span className="form-error">
                          {EASInvestigationFieldsErrorMessage.day1}
                        </span>
                      )}
                      {errors.showAtDestTerm && isCompleting ? (
                        <span className="form-error">
                          {errors.showAtDestTerm}
                        </span>
                      ) : null}
                      {errors.dateToDestTerm && isCompleting ? (
                        <span className="form-error">
                          {errors.dateToDestTerm}
                        </span>
                      ) : null}
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.notInDestTerm === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "notInDestTerm",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "notInDestTermSteps",
                                  e.target.checked
                                    ? values.notInDestTermSteps
                                    : "",
                                  true
                                );
                              }}
                              disabled={
                                formFieldsDisabled ||
                                isControlDisabled("notInDestTerm")
                              }
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />{" "}
                        Confirm freight is not in the destination terminals OS&D
                        bay or on their Overage report <sup>*</sup>
                      </FormLabel>
                      {errors.notInDestTerm && isCompleting ? (
                        <span className="form-error">
                          {errors.notInDestTerm}
                        </span>
                      ) : null}
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.notInDestTerm === "Y" && (
                            <TextField
                              autoComplete="off"
                              name="notInDestTermSteps"
                              value={values.notInDestTermSteps}
                              fullWidth
                              placeholder="Describe Steps Taken"
                              inputProps={{
                                maxLength: MAX_LENGTH_SUMMARY_TEXT,
                              }}
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                              }}
                              disabled={formFieldsDisabled}
                              variant="outlined"
                            />
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>
                  {/* F18 Notes */}
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel style={selectStyle} shrink={true}>
                        F18 Notes <sup></sup>
                      </InputLabel>
                      <TextField
                        autoComplete="off"
                        multiline
                        rows={2}
                        name="day1F18Note"
                        value={values.day1F18Note}
                        onChange={handleChange}
                        placeholder="Enter F18 Notes"
                        fullWidth
                        inputProps={{ maxLength: 300 }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            )}

            {activeStep === StepStatusMap[3] && (
              <div>
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.noMovement === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "noMovement",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "dateLastMovement",
                                  e.target.checked
                                    ? values.dateLastMovement
                                    : "",
                                  true
                                );
                                setFieldValue(
                                  "noMovementSteps",
                                  e.target.checked
                                    ? values.noMovementSteps
                                    : "",
                                  true
                                );
                              }}
                              disabled={
                                formFieldsDisabled ||
                                isControlDisabled("noMovement")
                              }
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />
                        Confirm the master shows no movement ? <sup>*</sup>
                      </FormLabel>
                      {errors.noMovement && isCompleting ? (
                        <span className="form-error">
                          {errors.noMovement}
                        </span>
                      ) : null}
                      <Grid container spacing={2}>
                        <Grid item md={4} xs={12} className="all-short-entry">
                          {values.noMovement === "Y" && (
                            <FormLabel
                              id="dateLastMovement"
                              className="date-label-margin mt-0"
                            >
                              Select date of last movement <sup>*</sup>
                            </FormLabel>
                          )}
                          {values.noMovement === "Y" && (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                disableFuture
                                value={dayjs(values.dateLastMovement ?? null)}
                                name="dateLastMovement"
                                open={open}
                                key={dateKeys.dateLastMovement}
                                onOpen={() => setOpen(true)}
                                onClose={() => setOpen(false)}
                                onChange={handleDateChange("dateLastMovement")}
                                format="MM/DD/YYYY"
                                slotProps={{
                                  textField: {
                                    error:
                                      !!errors.dateLastMovement && isCompleting,
                                    onBlur: handleBlur,
                                    onClick: () => {
                                      setOpen(true);
                                    },
                                    inputProps: {
                                      readOnly: true,
                                    },
                                  },
                                }}
                                disabled={formFieldsDisabled}
                                className="col-md-12"
                              />
                            </LocalizationProvider>
                          )}
                          {errors.dateLastMovement && isCompleting ? (
                            <span className="form-error">
                              {errors.dateLastMovement}
                            </span>
                          ) : null}
                        </Grid>
                        <Grid item md={8} xs={12} className="all-short-entry">
                          {values.noMovement === "Y" && (
                            <FormLabel
                              id="noMovementSteps"
                              className="date-label-margin mt-2"
                            >
                              &nbsp;
                            </FormLabel>
                          )}
                          {values.noMovement === "Y" && (
                            <TextField
                              autoComplete="off"
                              name="noMovementSteps"
                              value={values.noMovementSteps}
                              fullWidth
                              placeholder="Describe Steps Taken"
                              inputProps={{
                                maxLength: MAX_LENGTH_SUMMARY_TEXT,
                              }}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              variant="outlined"
                            />
                          )}
                          {invalidFields.includes(
                            EASInvestigationFields.NoMovementSteps
                          ) && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day2}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.suffixBill === "Y"}
                              disabled={
                                formFieldsDisabled ||
                                isControlDisabled("suffixBill")
                              }
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "suffixBill",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "suffixBillSteps",
                                  e.target.checked
                                    ? values.suffixBillSteps
                                    : "",
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />{" "}
                        Check if a suffix bill or OD400 was cut to move some or
                        all of the freight <sup>*</sup>
                        <TooltipWithClickAway
                          tooltipText="If yes, view the AS exception & confirm it was cleared properly. If no, move to next step."
                          tooltipKey="tooltip2"
                          openTooltips={openTooltips}
                          handleTooltipOpen={handleTooltipOpen}
                          handleTooltipClose={handleTooltipClose}
                        />
                      </FormLabel>
                      {errors.suffixBill && isCompleting ? (
                        <span className="form-error">
                          {errors.suffixBill}
                        </span>
                      ) : null}
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.suffixBill === "Y" && (
                            <TextField
                              autoComplete="off"
                              name="suffixBillSteps"
                              value={values.suffixBillSteps}
                              fullWidth
                              placeholder="Describe Steps Taken"
                              inputProps={{
                                maxLength: MAX_LENGTH_SUMMARY_TEXT,
                              }}
                              error={!!errors.suffixBillSteps && isCompleting}
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                isValueExist(
                                  EASInvestigationFields.SuffixBillSteps
                                );
                              }}
                              disabled={formFieldsDisabled}
                              variant="outlined"
                            />
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                    {errors.suffixBillSteps && isCompleting ? (
                      <span className="form-error">
                        {errors.suffixBillSteps}
                      </span>
                    ) : null}

                    {invalidFields.includes(
                      EASInvestigationFields.SuffixBillSteps
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day2}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled || isControlDisabled("bolCheck")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.bolCheck === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "bolCheck",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "bolCheckSteps",
                                  e.target.checked ? values.bolCheckSteps : "",
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />
                        Check the BOL to confirm completion and no
                        discrepancies? <sup>*</sup>
                        <TooltipWithClickAway
                          tooltipText="Use info on BOL in option 5 to look for duplicate Pros/billing errors. Be sure to check for how the BOL was signed."
                          tooltipKey="tooltip3"
                          openTooltips={openTooltips}
                          handleTooltipOpen={handleTooltipOpen}
                          handleTooltipClose={handleTooltipClose}
                        />
                      </FormLabel>
                      {errors.bolCheck && isCompleting ? (
                        <span className="form-error">
                          {errors.bolCheck}
                        </span>
                      ) : null}
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.bolCheck === "Y" && (
                            <>
                              <TextField
                                autoComplete="off"
                                name="bolCheckSteps"
                                value={values.bolCheckSteps}
                                fullWidth
                                placeholder="Describe Steps Taken"
                                inputProps={{
                                  maxLength: MAX_LENGTH_SUMMARY_TEXT,
                                }}
                                onChange={handleChange}
                                error={!!errors.bolCheckSteps && isCompleting}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValueExist(
                                    EASInvestigationFields.BolCheckSteps
                                  );
                                }}
                                disabled={formFieldsDisabled}
                                variant="outlined"
                              />
                              {invalidFields.includes(
                                EASInvestigationFields.BolCheckSteps
                              ) && (
                                <span className="form-error">
                                  {EASInvestigationFieldsErrorMessage.day2}
                                </span>
                              )}

                              {errors.bolCheckSteps && isCompleting ? (
                                <span className="form-error">
                                  {errors.bolCheckSteps}
                                </span>
                              ) : null}
                              <br />
                              <br />
                              <Snackbar
                                className="snackbar-error flex-start"
                                anchorOrigin={{ vertical, horizontal }}
                                open={true}
                                onClose={handleClose}
                                message="Note: Search for PO #, BOL#, Shipper #, other Pro #’s, Driver signature & date, and any notation of shipper load & count."
                                key={vertical + horizontal}
                              />
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id="drCheck">
                        Is there a DR? <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          <RadioGroup
                            row
                            aria-labelledby="drCheck"
                            name="drCheck"
                            className="textarea-radio-group"
                          >
                            <FormControlLabel
                              value="Y"
                              control={<Radio />}
                              label="Yes"
                              name="drCheck"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue(
                                  "drCheckSteps",
                                  initValues["drCheckSteps"],
                                  true
                                );
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.drCheck === "Y"}
                            />
                            <FormControlLabel
                              value="N"
                              control={<Radio />}
                              label="No"
                              name="drCheck"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue("drCheckSteps", "", true);
                                setInvalidFields([
                                  ...invalidFields.filter(
                                    (field) => field !== "drCheckSteps"
                                  ),
                                ]);
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.drCheck === "N"}
                            />
                          </RadioGroup>
                          {errors.drCheck && isCompleting ? (
                            <span className="form-error">
                              {errors.drCheck}
                            </span>
                          ) : null}
                          {values.drCheck === "Y" && (
                            <>
                              <FormLabel id="drCheckStepsLabel">
                                Did you confirm completion and no discrepancies?{" "}
                                <sup>*</sup>
                              </FormLabel>
                              <TextField
                                autoComplete="off"
                                id="drCheckSteps"
                                name="drCheckSteps"
                                fullWidth
                                value={values.drCheckSteps}
                                error={!!errors.drCheckSteps && isCompleting}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValidDependedField(
                                    "drCheckSteps",
                                    "drCheck",
                                    StepStatusMap[3]
                                  );
                                  isValueExist(
                                    EASInvestigationFields.DrCheckSteps
                                  );
                                }}
                                disabled={formFieldsDisabled}
                                className="mt-1"
                                placeholder="Describe Steps Taken"
                                inputProps={{
                                  maxLength: MAX_LENGTH_SUMMARY_TEXT,
                                }}
                                variant="outlined"
                              />
                            </>
                          )}
                          {errors.drCheckSteps && isCompleting ? (
                            <span className="form-error">
                              {errors.drCheckSteps}
                            </span>
                          ) : null}
                          {invalidFields.includes(
                            EASInvestigationFields.DrCheckSteps
                          ) && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day2}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>
                  {/* F18 Notes */}
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel style={selectStyle} shrink={true}>
                        F18 Notes <sup></sup>
                      </InputLabel>
                      <TextField
                        autoComplete="off"
                        multiline
                        rows={2}
                        name="day2F18Note"
                        value={values.day2F18Note}
                        onChange={handleChange}
                        placeholder="Enter F18 Notes"
                        fullWidth
                        inputProps={{ maxLength: 300 }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            )}

            {activeStep === StepStatusMap[4] && (
              <div>
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled || isControlDisabled("pumCheck")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.pumCheck === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "pumCheck",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "pickupManifest",
                                  e.target.checked ? values.pickupManifest : "",
                                  true
                                );
                                // setFieldValue("pumSteps", e.target.checked ? values.pumSteps :"", true)
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />
                        Check the PUM (Pickup manifest) for discrepancies,
                        shipper load and count information, and proper reporting
                        process <sup>*</sup>
                      </FormLabel>
                      {errors.pumCheck && isCompleting ? (
                        <span className="form-error">
                          {errors.pumCheck}
                        </span>
                      ) : null}
                      <Grid container spacing={2}>
                        <Grid item md={4} xs={12} className="all-short-entry">
                          {values.pumCheck === "Y" && (
                            <>
                              <FormLabel
                                id="pickupManifestLabel"
                                className="mt-0 mb-1"
                              >
                                Pickup Manifest # <sup>*</sup>
                              </FormLabel>
                              <TextField
                                autoComplete="off"
                                name="pickupManifest"
                                value={values.pickupManifest}
                                fullWidth
                                placeholder="Describe Steps Taken"
                                type="number"
                                onPaste={HandlePaste}
                                onKeyDown={HandleKeyDown}
                                onInput={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  HandleNumberMaxLength(e, 9);
                                }}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValueExist(
                                    EASInvestigationFields.PickupManifest
                                  );
                                }}
                                error={
                                  values.pickupManifest === 0 ||
                                  (!!errors.pickupManifest && isCompleting)
                                }
                                disabled={formFieldsDisabled}
                                variant="outlined"
                              />
                            </>
                          )}

                          {errors.pickupManifest && isCompleting ? (
                            <span className="form-error">
                              {errors.pickupManifest}
                            </span>
                          ) : null}
                          {invalidFields.includes(
                            EASInvestigationFields.PickupManifest
                          ) &&
                            values.pickupManifest !== 0 && (
                              <span className="form-error">
                                {EASInvestigationFieldsErrorMessage.day3}
                              </span>
                            )}
                          {values.pickupManifest === 0 &&
                            values.pumCheck === "Y" && (
                              <span className="form-error">
                                Invalid pickup manifest# number
                              </span>
                            )}
                        </Grid>
                        <Grid item md={8} xs={12} className="all-short-entry">
                          {values.pumCheck === "Y" && (
                            <>
                              <FormLabel id="pumStepsL" className="mt-0 mb-1">
                                List any and all discrepancies <sup>*</sup>
                              </FormLabel>
                              <TextField
                                autoComplete="off"
                                name="pumSteps"
                                value={values.pumSteps}
                                error={!!errors.pumSteps && isCompleting}
                                fullWidth
                                placeholder="Describe Steps Taken"
                                inputProps={{
                                  maxLength: MAX_LENGTH_SUMMARY_TEXT,
                                }}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValueExist(EASInvestigationFields.PumSteps);
                                }}
                                disabled={formFieldsDisabled}
                                variant="outlined"
                              />
                            </>
                          )}
                          {errors.pumSteps && isCompleting ? (
                            <span className="form-error">
                              {errors.pumSteps}
                            </span>
                          ) : null}

                          {invalidFields.includes(
                            EASInvestigationFields.PumSteps
                          ) && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day3}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id="shipperLoad">
                        Was this a shipper load and count? <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          <RadioGroup
                            row
                            aria-labelledby="shipperLoad"
                            name="shipperLoad"
                            className="textarea-radio-group"
                          >
                            <FormControlLabel
                              value="Y"
                              control={<Radio />}
                              label="Yes"
                              name="shipperLoad"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue(
                                  "discrepancies",
                                  initValues["discrepancies"],
                                  true
                                );
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.shipperLoad === "Y"}
                            />
                            <FormControlLabel
                              value="N"
                              control={<Radio />}
                              label="No"
                              name="shipperLoad"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue("discrepancies", "", true);
                                setInvalidFields([
                                  ...invalidFields.filter(
                                    (field) => field !== "discrepancies"
                                  ),
                                ]);
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.shipperLoad === "N"}
                            />
                          </RadioGroup>
                          {errors.shipperLoad && isCompleting ? (
                            <span className="form-error">
                              {errors.shipperLoad}
                            </span>
                          ) : null}
                          {values.shipperLoad === "Y" && (
                            <FormLabel
                              id="discrepanciesLabel"
                              className="mb-10"
                            >
                              Were there any discrepancies? If so, were they
                              properly reported to the shipper? <sup>*</sup>
                            </FormLabel>
                          )}
                          {values.shipperLoad === "Y" && (
                            <TextField
                              autoComplete="off"
                              name="discrepancies"
                              value={values.discrepancies}
                              fullWidth
                              placeholder="Describe Steps Taken"
                              inputProps={{
                                maxLength: MAX_LENGTH_SUMMARY_TEXT,
                              }}
                              onChange={handleChange}
                              error={!!errors.discrepancies && isCompleting}
                              onBlur={(e) => {
                                handleBlur(e);
                                isValidDependedField(
                                  EASInvestigationFields.Discrepancies,
                                  EASInvestigationFields.ShipperLoad,
                                  StepStatusMap[4]
                                );
                                isValueExist(
                                  EASInvestigationFields.Discrepancies
                                );
                              }}
                              disabled={formFieldsDisabled}
                              variant="outlined"
                            />
                          )}
                          {errors.discrepancies && isCompleting ? (
                            <span className="form-error">
                              {errors.discrepancies}
                            </span>
                          ) : null}
                          {invalidFields.includes(
                            EASInvestigationFields.Discrepancies
                          ) && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day3}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled ||
                            isControlDisabled("checkLinehaulManifests")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.checkLinehaulManifests === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "checkLinehaulManifests",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "linehaulManifests",
                                  e.target.checked
                                    ? values.linehaulManifests
                                    : "",
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />
                        Check all linehaul manifests – movement and
                        abnormalities. <sup>*</sup> &nbsp;
                        <TooltipWithClickAway
                          tooltipText="Get manifest numbers from the movement screen (F10)"
                          tooltipKey="tooltip4"
                          openTooltips={openTooltips}
                          handleTooltipOpen={handleTooltipOpen}
                          handleTooltipClose={handleTooltipClose}
                        />
                      </FormLabel>
                      {errors.checkLinehaulManifests && isCompleting ? (
                        <span className="form-error">
                          {errors.checkLinehaulManifests}
                        </span>
                      ) : null}
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.checkLinehaulManifests === "Y" && (
                            <TextField
                              autoComplete="off"
                              name="linehaulManifests"
                              value={values.linehaulManifests}
                              error={!!errors.linehaulManifests && isCompleting}
                              fullWidth
                              placeholder="Describe Steps Taken"
                              inputProps={{
                                maxLength: MAX_LENGTH_SUMMARY_TEXT,
                              }}
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                isValueExist(
                                  EASInvestigationFields.LinehaulManifests
                                );
                              }}
                              disabled={formFieldsDisabled}
                              variant="outlined"
                            />
                          )}
                          {errors.linehaulManifests && isCompleting ? (
                            <span className="form-error">
                              {errors.linehaulManifests}
                            </span>
                          ) : null}
                          {invalidFields.includes(
                            EASInvestigationFields.LinehaulManifests
                          ) && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day3}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled ||
                            isControlDisabled("interviewWorkers")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.interviewWorkers === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "interviewWorkers",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "workerSteps",
                                  e.target.checked ? values.workerSteps : "",
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />
                        Interview the dock worker(s) about movement
                        abnormalities. <sup>*</sup>
                      </FormLabel>
                      {errors.interviewWorkers && isCompleting ? (
                        <span className="form-error">
                          {errors.interviewWorkers}
                        </span>
                      ) : null}
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.interviewWorkers === "Y" && (
                            <TextField
                              autoComplete="off"
                              name="workerSteps"
                              value={values.workerSteps}
                              error={!!errors.workerSteps && isCompleting}
                              fullWidth
                              placeholder="Describe Steps Taken"
                              inputProps={{
                                maxLength: MAX_LENGTH_SUMMARY_TEXT,
                              }}
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                isValueExist(
                                  EASInvestigationFields.WorkerSteps
                                );
                              }}
                              disabled={formFieldsDisabled}
                              variant="outlined"
                            />
                          )}
                          {errors.workerSteps && isCompleting ? (
                            <span className="form-error">
                              {errors.workerSteps}
                            </span>
                          ) : null}
                          {invalidFields.includes(
                            EASInvestigationFields.WorkerSteps
                          ) && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day3}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <h3 className="header-text mb-0">
                      Call consignee to confirm what was ordered and received
                      (if anything)
                    </h3>
                  </Grid>

                  <Grid item md={3} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        Phone Number <sup>*</sup>
                      </FormLabel>
                      <TextField
                        autoComplete="off"
                        name="consigneePhone"
                        value={values.consigneePhone}
                        error={!!errors.consigneePhone && isCompleting}
                        fullWidth
                        inputProps={{ maxLength: 12 }}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist(EASInvestigationFields.ConsigneePhone);
                        }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                        placeholder="Enter Phone Number"
                      />
                    </FormControl>
                    {errors.consigneePhone && isCompleting ? (
                      <span className="form-error">
                        {errors.consigneePhone}
                      </span>
                    ) : null}
                    {invalidFields.includes(
                      EASInvestigationFields.ConsigneePhone
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={3} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        Consignee Name <sup>*</sup>
                      </FormLabel>
                      <TextField
                        autoComplete="off"
                        name="consigneeName"
                        value={values.consigneeName}
                        error={!!errors.consigneeName && isCompleting}
                        fullWidth
                        inputProps={{ maxLength: 30 }}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist(EASInvestigationFields.ConsigneeName);
                        }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                        placeholder="Enter Consignee Name"
                      />
                    </FormControl>
                    {errors.consigneeName && isCompleting ? (
                      <span className="form-error">
                        {errors.consigneeName}
                      </span>
                    ) : null}
                    {invalidFields.includes(
                      EASInvestigationFields.ConsigneeName
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id="destinationTerminal">
                        Is the consignee still short? <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          <RadioGroup
                            row
                            aria-labelledby="destinationTerminal"
                            name="destinationTerminal"
                            className="textarea-radio-group"
                          >
                            <FormControlLabel
                              value="Y"
                              control={<Radio />}
                              label="Yes"
                              name="consigneeShort"
                              // onChange={handleChange}
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue(
                                  "itemNumbers",
                                  initValues["itemNumbers"],
                                  true
                                );
                                setFieldValue(
                                  "orderReceived",
                                  initValues["orderReceived"],
                                  true
                                );
                                setFieldValue(
                                  "freightLabeled",
                                  initValues["freightLabeled"],
                                  true
                                );
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.consigneeShort === "Y"}
                            />
                            <FormControlLabel
                              value="N"
                              control={<Radio />}
                              label="No"
                              name="consigneeShort"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue("itemNumbers", "", true);
                                setFieldValue("orderReceived", "", true);
                                setFieldValue("freightLabeled", "", true);
                                setInvalidFields([
                                  ...invalidFields.filter(
                                    (field) =>
                                      ![
                                        "itemNumbers",
                                        "orderReceived",
                                        "freightLabeled",
                                      ].includes(field)
                                  ),
                                ]);
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.consigneeShort === "N"}
                            />
                          </RadioGroup>
                          {errors.consigneeShort && isCompleting ? (
                            <span className="form-error">
                              {errors.consigneeShort}
                            </span>
                          ) : null}
                          {values.consigneeShort === "N" && (
                            <Snackbar
                              className="snackbar-error flex-start"
                              anchorOrigin={{ vertical, horizontal }}
                              open={true}
                              onClose={handleClose}
                              message="Note: If the consignee is no longer short, the investigation is considered complete once we obtain written confirmation and/or a signed DR."
                              key={vertical + horizontal}
                            />
                          )}

                          {values.consigneeShort === "Y" && (
                            <>
                              <FormLabel className="mb-10">
                                What are the item numbers received and still
                                missing? <sup>*</sup>
                              </FormLabel>
                              <TextField
                                autoComplete="off"
                                name="itemNumbers"
                                value={values.itemNumbers}
                                error={!!errors.itemNumbers && isCompleting}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValidDependedField(
                                    EASInvestigationFields.ItemNumbers,
                                    EASInvestigationFields.ConsigneeShort,
                                    StepStatusMap[4]
                                  );
                                  isValueExist(
                                    EASInvestigationFields.ItemNumbers
                                  );
                                }}
                                disabled={formFieldsDisabled}
                                fullWidth
                                placeholder="Describe Steps Taken"
                                inputProps={{
                                  maxLength: MAX_LENGTH_SUMMARY_TEXT,
                                }}
                                variant="outlined"
                              />
                              {errors.itemNumbers && isCompleting ? (
                                <span className="form-error">
                                  {errors.itemNumbers}
                                </span>
                              ) : null}
                              {invalidFields.includes(
                                EASInvestigationFields.ItemNumbers
                              ) && (
                                <span className="form-error">
                                  {EASInvestigationFieldsErrorMessage.day3}
                                </span>
                              )}
                              <FormLabel className="mb-10">
                                What was ordered and received for each PO?{" "}
                                <sup>*</sup>
                              </FormLabel>
                              <TextField
                                autoComplete="off"
                                name="orderReceived"
                                value={values.orderReceived}
                                error={!!errors.orderReceived && isCompleting}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValidDependedField(
                                    EASInvestigationFields.OrderReceived,
                                    EASInvestigationFields.ConsigneeShort,
                                    StepStatusMap[4]
                                  );
                                  isValueExist(
                                    EASInvestigationFields.OrderReceived
                                  );
                                }}
                                disabled={formFieldsDisabled}
                                fullWidth
                                placeholder="Describe Steps Taken"
                                inputProps={{
                                  maxLength: MAX_LENGTH_SUMMARY_TEXT,
                                }}
                                variant="outlined"
                              />
                              {errors.orderReceived && isCompleting ? (
                                <span className="form-error">
                                  {errors.orderReceived}
                                </span>
                              ) : null}
                              {invalidFields.includes(
                                EASInvestigationFields.OrderReceived
                              ) && (
                                <span className="form-error">
                                  {EASInvestigationFieldsErrorMessage.day3}
                                </span>
                              )}
                              <FormLabel className="mb-10">
                                Was the freight received labeled for them?{" "}
                                <sup>*</sup>
                              </FormLabel>
                              <TextField
                                autoComplete="off"
                                name="freightLabeled"
                                value={values.freightLabeled}
                                error={!!errors.freightLabeled && isCompleting}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValidDependedField(
                                    EASInvestigationFields.FreightLabeled,
                                    EASInvestigationFields.ConsigneeShort,
                                    StepStatusMap[4]
                                  );
                                  isValueExist(
                                    EASInvestigationFields.FreightLabeled
                                  );
                                }}
                                disabled={formFieldsDisabled}
                                fullWidth
                                placeholder="Describe Steps Taken"
                                inputProps={{
                                  maxLength: MAX_LENGTH_SUMMARY_TEXT,
                                }}
                                variant="outlined"
                              />
                              {errors.freightLabeled && isCompleting ? (
                                <span className="form-error">
                                  {errors.freightLabeled}
                                </span>
                              ) : null}
                              {invalidFields.includes(
                                EASInvestigationFields.FreightLabeled
                              ) && (
                                <span className="form-error">
                                  {EASInvestigationFieldsErrorMessage.day3}
                                </span>
                              )}
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <h3 className="header-text mb-0">
                      Call Shipper to confirm what was shipped, how it was
                      packaged and the value?
                    </h3>
                  </Grid>

                  <Grid item md={3} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        Phone Number <sup>*</sup>
                      </FormLabel>
                      <TextField
                        autoComplete="off"
                        name="shipperPhone"
                        value={values.shipperPhone}
                        error={!!errors.shipperPhone && isCompleting}
                        fullWidth
                        inputProps={{ maxLength: 12 }}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist(EASInvestigationFields.ShipperPhone);
                        }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                        placeholder="Enter Phone Number"
                      />
                    </FormControl>
                    {errors.shipperPhone && isCompleting ? (
                      <span className="form-error">
                        {errors.shipperPhone}
                      </span>
                    ) : null}
                    {invalidFields.includes(
                      EASInvestigationFields.ShipperPhone
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={3} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        Shipper Name <sup>*</sup>
                      </FormLabel>
                      <TextField
                        autoComplete="off"
                        name="shipperName"
                        value={values.shipperName}
                        error={!!errors.shipperName && isCompleting}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist(EASInvestigationFields.ShipperName);
                        }}
                        disabled={formFieldsDisabled}
                        placeholder="Enter Shipper Name"
                        inputProps={{ maxLength: 30 }}
                        variant="outlined"
                      />
                    </FormControl>
                    {errors.shipperName && isCompleting ? (
                      <span className="form-error">
                        {errors.shipperName}
                      </span>
                    ) : null}
                    {invalidFields.includes(
                      EASInvestigationFields.ShipperName
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        What are the details of what shipped? <sup>*</sup>
                      </FormLabel>
                      <TextField
                        autoComplete="off"
                        name="shippedDetails"
                        value={values.shippedDetails}
                        error={!!errors.shippedDetails && isCompleting}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist(EASInvestigationFields.ShippedDetails);
                        }}
                        disabled={formFieldsDisabled}
                        fullWidth
                        placeholder="Describe Steps Taken"
                        inputProps={{ maxLength: MAX_LENGTH_SUMMARY_TEXT }}
                        variant="outlined"
                      />
                    </FormControl>
                    {errors.shippedDetails && isCompleting ? (
                      <span className="form-error">
                        {errors.shippedDetails}
                      </span>
                    ) : null}
                    {invalidFields.includes(
                      EASInvestigationFields.ShippedDetails
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        Was this short shipped? <sup>*</sup>
                      </FormLabel>
                      <TextField
                        autoComplete="off"
                        name="shortShipped"
                        value={values.shortShipped}
                        error={!!errors.shortShipped && isCompleting}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist(EASInvestigationFields.ShortShipped);
                        }}
                        disabled={formFieldsDisabled}
                        fullWidth
                        placeholder="Describe Steps Taken"
                        inputProps={{ maxLength: MAX_LENGTH_SUMMARY_TEXT }}
                        variant="outlined"
                      />
                    </FormControl>
                    {errors.shortShipped && isCompleting ? (
                      <span className="form-error">
                        {errors.shortShipped}
                      </span>
                    ) : null}
                    {invalidFields.includes(
                      EASInvestigationFields.ShortShipped
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        What is the value of the product? <sup>*</sup>
                      </FormLabel>
                      <TextField
                        autoComplete="off"
                        name="productValue"
                        value={values.productValue}
                        error={!!errors.productValue && isCompleting}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist(EASInvestigationFields.ProductValue);
                        }}
                        disabled={formFieldsDisabled}
                        fullWidth
                        placeholder="Describe Steps Taken"
                        inputProps={{ maxLength: MAX_LENGTH_SUMMARY_TEXT }}
                        variant="outlined"
                      />
                    </FormControl>
                    {errors.productValue && isCompleting ? (
                      <span className="form-error">
                        {errors.productValue}
                      </span>
                    ) : null}
                    {invalidFields.includes(
                      EASInvestigationFields.ProductValue
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        Were there multiple shipments for the same consignee
                        that day? <sup>*</sup>
                      </FormLabel>
                      <TextField
                        autoComplete="off"
                        name="multShipments"
                        value={values.multShipments}
                        error={!!errors.multShipments && isCompleting}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist(EASInvestigationFields.MultShipments);
                        }}
                        disabled={formFieldsDisabled}
                        fullWidth
                        placeholder="Describe Steps Taken"
                        inputProps={{ maxLength: MAX_LENGTH_SUMMARY_TEXT }}
                        variant="outlined"
                      />
                    </FormControl>
                    {errors.multShipments && isCompleting ? (
                      <span className="form-error">
                        {errors.multShipments}
                      </span>
                    ) : null}
                    {invalidFields.includes(
                      EASInvestigationFields.MultShipments
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  {/* F18 Notes */}
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel style={selectStyle} shrink={true}>
                        F18 Notes <sup></sup>
                      </InputLabel>
                      <TextField
                        autoComplete="off"
                        multiline
                        rows={2}
                        name="day3F18Note"
                        value={values.day3F18Note}
                        onChange={handleChange}
                        placeholder="Enter F18 Notes"
                        fullWidth
                        inputProps={{ maxLength: 300 }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            )}

            {activeStep === StepStatusMap[5] && (
              <div>
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled || isControlDisabled("check7818")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.check7818 === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "check7818",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "details7818",
                                  e.target.checked ? values.details7818 : "",
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />
                        Check 78/18 and/or Power BI for any overages (OV, NB,
                        DO) to apply <sup>*</sup>
                      </FormLabel>
                      {errors.check7818 && isCompleting ? (
                        <span className="form-error">
                          {errors.check7818}
                        </span>
                      ) : null}
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.check7818 === "Y" && (
                            <TextField
                              autoComplete="off"
                              id={`details7818`}
                              name="details7818"
                              value={values.details7818}
                              error={!!errors.details7818 && isCompleting}
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                isValueExist(
                                  EASInvestigationFields.Details7818
                                );
                              }}
                              disabled={formFieldsDisabled}
                              fullWidth
                              className=""
                              placeholder="Provide details of your search"
                              inputProps={{
                                maxLength: MAX_LENGTH_SUMMARY_TEXT,
                              }}
                              variant="outlined"
                            />
                          )}
                          {errors.details7818 && isCompleting ? (
                            <span className="form-error">
                              {errors.details7818}
                            </span>
                          ) : null}
                          {invalidFields.includes(
                            EASInvestigationFields.Details7818
                          ) && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day4}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id="manifestedLable">
                        Did this show manifested to the destination terminal
                        (even if NF)? <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          <RadioGroup
                            row
                            aria-labelledby="destinationTerminal"
                            name="manifested"
                            className="textarea-radio-group"
                          >
                            <FormControlLabel
                              value="Y"
                              control={<Radio />}
                              label="Yes"
                              name="manifested"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue(
                                  "cameraFindings",
                                  initValues["cameraFindings"],
                                  true
                                );
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.manifested === "Y"}
                            />
                            <FormControlLabel
                              value="N"
                              control={<Radio />}
                              label="No"
                              name="manifested"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue("cameraFindings", "", true);
                                setInvalidFields([
                                  ...invalidFields.filter(
                                    (field) => field !== "cameraFindings"
                                  ),
                                ]);
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.manifested === "N"}
                            />
                          </RadioGroup>
                          {errors.manifested && isCompleting ? (
                            <span className="form-error">
                              {errors.manifested}
                            </span>
                          ) : null}
                          {values.manifested === "Y" && (
                            <>
                              <FormLabel id="cameraFindingsLabel">
                                Check cameras at DT and report findings{" "}
                                <sup>*</sup> <sup>&nbsp;</sup>
                              </FormLabel>
                              <TextField
                                autoComplete="off"
                                name="cameraFindings"
                                value={values.cameraFindings}
                                error={!!errors.cameraFindings && isCompleting}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValidDependedField(
                                    EASInvestigationFields.CameraFindings,
                                    EASInvestigationFields.Manifested,
                                    StepStatusMap[5]
                                  );
                                  isValueExist(
                                    EASInvestigationFields.CameraFindings
                                  );
                                }}
                                disabled={formFieldsDisabled}
                                fullWidth
                                className="mt-1"
                                placeholder="Describe Steps Taken"
                                inputProps={{
                                  maxLength: MAX_LENGTH_SUMMARY_TEXT,
                                }}
                                variant="outlined"
                              />
                            </>
                          )}
                          {errors.cameraFindings && isCompleting ? (
                            <span className="form-error">
                              {errors.cameraFindings}
                            </span>
                          ) : null}
                          {invalidFields.includes(
                            EASInvestigationFields.CameraFindings
                          ) && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day4}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                    <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled ||
                            isControlDisabled("callSameDays")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.callSameDays === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const checked = e.target.checked;
                                setFieldValue( "callSameDays", checked ? "Y" : "N");
                                setFieldValue(
                                  "callSameDaysDataset",
                                  getCallSameDaysDataset(checked),
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />
                        Call same day deliveries and pickups <sup>*</sup>
                        <TooltipWithClickAway
                          tooltipText="Use F17 from FB inquiry screen for pickup info. To look up delivery manifest use Option 16 from the Operations menu"
                          tooltipKey="tooltip5"
                          openTooltips={openTooltips}
                          handleTooltipOpen={handleTooltipOpen}
                          handleTooltipClose={handleTooltipClose}
                        />
                      </FormLabel>
                      {isCompleting && (
                        <span className="form-error">
                          {errors["callSameDays"] as string}
                        </span>
                      )}

                      {values.callSameDays === "Y" && (
                        <Grid
                          key={formKey}
                          container
                          spacing={2}
                          className="filter-margin"
                        >
                          <FieldArray name="callSameDaysDataset">
                            {({ push, remove }) => (
                              <>
                                {_.get(values, "callSameDaysDataset", []).map(
                                  (
                                    _d: ICallSameDaysDataset,
                                    index: number
                                  ) => (
                                    <React.Fragment key={index + ""}>
                                      <Grid
                                        item
                                        md={12}
                                        xs={12}
                                        className="pt-0"
                                      >
                                        <h3 className="header-text mb-0">
                                          PRO and Delivery Info: {index + 1}
                                        </h3>
                                      </Grid>

                                      <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        className="pt-0"
                                      >
                                        <FormControl className="radio-group question-group ">
                                          <FormLabel>
                                            All same Day Pro's <sup>*</sup>
                                          </FormLabel>
                                          <TextField
                                            autoComplete="off"
                                            id={`tid-${index}`}
                                            name={`callSameDaysDataset[${index}].sameDayPro`}
                                            value={_.get(
                                              values,
                                              `callSameDaysDataset[${index}].sameDayPro`,
                                              ""
                                            )}
                                            onChange={handleChange}
                                            onBlur={(e) => {
                                              handleBlur(e);
                                              isValueExist(
                                                "sameDayPro",
                                                index,
                                                "callSameDaysDataset"
                                              );
                                            }}
                                            disabled={formFieldsDisabled}
                                            fullWidth
                                            className="mt-1"
                                            placeholder="PRO Number"
                                            variant="outlined"
                                            type="text"
                                            onKeyDown={HandleTextFieldKeyDown}
                                            inputProps={{ maxLength: 32 }}
                                          />
                                        </FormControl>
                                        {!invalidFields.includes(
                                          "sameDayPro" + index
                                        ) &&
                                        errors.callSameDaysDataset &&
                                        errors.callSameDaysDataset.length > 0 &&
                                        _.get(
                                          errors,
                                          `callSameDaysDataset[${index}].sameDayPro`
                                        ) &&
                                        isCompleting ? (
                                          <span className="form-error">
                                            {_.get(
                                              errors,
                                              `callSameDaysDataset[${index}].sameDayPro`,
                                              ""
                                            )}
                                          </span>
                                        ) : (
                                          ""
                                        )}

                                        {isNaN(
                                          +(
                                            values?.callSameDaysDataset?.[index]
                                              ?.sameDayPro ?? ""
                                          )
                                        ) &&
                                          values.callSameDays === "Y" && (
                                            <span className="form-error">
                                              Invalid PRO# number
                                            </span>
                                          )}
                                        {invalidFields.includes(
                                          "sameDayPro" + index
                                        ) &&
                                          !values?.callSameDaysDataset?.[index]
                                            ?.sameDayPro && (
                                            <span className="form-error">
                                              {
                                                EASInvestigationFieldsErrorMessage.day5
                                              }
                                            </span>
                                          )}
                                      </Grid>

                                      <Grid
                                        item
                                        md={9}
                                        xs={12}
                                        className="pt-0"
                                      >
                                        <FormControl className="radio-group question-group ">
                                          <FormLabel>
                                            Delivery Info?<sup>*</sup>
                                          </FormLabel>
                                          <TextField
                                            autoComplete="off"
                                            id={`deliveryInfo-${index}`}
                                            name={`callSameDaysDataset[${index}].deliveryInfo`}
                                            value={_.get(
                                              values,
                                              `callSameDaysDataset[${index}].deliveryInfo`,
                                              ""
                                            )}
                                            onChange={handleChange}
                                            onBlur={(e) => {
                                              handleBlur(e);
                                              isValueExist(
                                                "deliveryInfo",
                                                index,
                                                "callSameDaysDataset"
                                              );
                                            }}
                                            disabled={formFieldsDisabled}
                                            fullWidth
                                            className="mt-1"
                                            placeholder="Delivery Info"
                                            inputProps={{
                                              maxLength:
                                                MAX_LENGTH_SUMMARY_TEXT,
                                            }}
                                            variant="outlined"
                                          />
                                        </FormControl>
                                        {!invalidFields.includes(
                                          "deliveryInfo" + index
                                        ) &&
                                        errors.callSameDaysDataset &&
                                        errors.callSameDaysDataset.length > 0 &&
                                        _.get(
                                          errors,
                                          `callSameDaysDataset[${index}].deliveryInfo`
                                        ) &&
                                        isCompleting ? (
                                          <span className="form-error">
                                            {_.get(
                                              errors,
                                              `callSameDaysDataset[${index}].deliveryInfo`,
                                              ""
                                            )}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                        {invalidFields.includes(
                                          "deliveryInfo" + index
                                        ) &&
                                          !values?.callSameDaysDataset?.[
                                            index
                                          ]?.deliveryInfo?.trim() && (
                                            <span className="form-error">
                                              {
                                                EASInvestigationFieldsErrorMessage.day5
                                              }
                                            </span>
                                          )}
                                      </Grid>
                                    </React.Fragment>
                                  )
                                )}
                                <Grid
                                  item
                                  md={12}
                                  xs={12}
                                  className="btn-group-submit"
                                >
                                  {!(
                                    formFieldsDisabled ||
                                    status ===
                                      EShortageInvestigationStatus.complete
                                  ) && (
                                    <>
                                      {values.callSameDaysDataset?.length! <
                                        24 && (
                                        <Button
                                          onClick={
                                            proInfoAddButtonDisabled
                                              ? void 0
                                              : () => {
                                                  push({
                                                    sameDayPro: undefined,
                                                    deliveryInfo: undefined,
                                                  });
                                                  proInfoAddRemove();
                                                }
                                          }
                                          variant="contained"
                                          className={`entry ml ${
                                            proInfoAddButtonDisabled
                                              ? "disable-btn disable"
                                              : ""
                                          }`}
                                        >
                                          Add
                                        </Button>
                                      )}
                                      { (values.callSameDaysDataset?.length ?? 0) >
                                        1 && (
                                        <Button
                                          onClick={() => {
                                            remove(
                                              values.callSameDaysDataset
                                                ?.length! - 1
                                            );
                                            proInfoAddRemove("remove");
                                          }}
                                          variant="contained"
                                          className="clear-btn mr-0 ml"
                                        >
                                          Remove
                                        </Button>
                                      )}
                                    </>
                                  )}
                                </Grid>
                              </>
                            )}
                          </FieldArray>
                        </Grid>
                      )}
                    </FormControl>
                  </Grid>
                  {/* F18 Notes */}
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel style={selectStyle} shrink={true}>
                        F18 Notes <sup></sup>
                      </InputLabel>
                      <TextField
                        autoComplete="off"
                        multiline
                        rows={2}
                        name="day4F18Note"
                        value={values.day4F18Note}
                        onChange={handleChange}
                        placeholder="Enter F18 Notes"
                        fullWidth
                        inputProps={{ maxLength: 300 }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            )}

            {activeStep === StepStatusMap[6] && (
              <div>
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled ||
                            isControlDisabled("contactTerminals")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.contactTerminals === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const checked = e.target.checked
                                setFieldValue( "contactTerminals", checked ? "Y" : "N");
                                setFieldValue(
                                  "freightTerminalLeadInquiries",
                                  getFreightTerminalLeadInquiries(checked),
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />
                        Contact the terminals that handled the freight to find
                        leads and check cameras if applicable * <sup>*</sup>
                        <TooltipWithClickAway
                          tooltipText="Use movement screen (F10) to locate terminals."
                          tooltipKey="tooltip6"
                          openTooltips={openTooltips}
                          handleTooltipOpen={handleTooltipOpen}
                          handleTooltipClose={handleTooltipClose}
                        />
                      </FormLabel>
                      {errors.contactTerminals && isCompleting ? (
                        <span className="form-error">
                          {errors.contactTerminals}
                        </span>
                      ) : null}
                    </FormControl>
                  </Grid>

                  {values.contactTerminals === "Y" && (
                    <Grid
                      key={formKey}
                      container
                      spacing={2}
                      className="filter-margin custom-container-pos"
                    >
                      <FieldArray name="freightTerminalLeadInquiries">
                        {({ push, remove }) => (
                          <>
                            {_.get(
                              values,
                              "freightTerminalLeadInquiries",
                              []
                            ).map(
                              (
                                _i: IDay5Investigation,
                                index: number
                              ) => (
                                <React.Fragment key={index + ""}>
                                  <Grid item md={12} xs={12} className="pt-0">
                                    <h3 className="header-text mb-0">
                                      Investigation details: {index + 1}
                                    </h3>
                                  </Grid>

                                <Grid item md={3} xs={12} className="pt-0">
                                  <FormControl className="radio-group question-group ">
                                    <FormLabel>
                                      TID <sup>*</sup>
                                    </FormLabel>
                                    <TextField
                                      autoComplete="off"
                                      id={`tid-${index}`}
                                      name={`freightTerminalLeadInquiries[${index}].terminal`}
                                      value={_.get(
                                        values,
                                        `freightTerminalLeadInquiries[${index}].terminal`
                                      )}
                                      onChange={(e) => {
                                        handleChange(e);
                                        isValueExist(
                                          EASInvestigationFields.Terminal,
                                          index
                                        );
                                      }}
                                      onBlur={(e) => {
                                        handleBlur(e);
                                        isValueExist(
                                          EASInvestigationFields.Terminal,
                                          index
                                        );
                                      }}
                                      disabled={formFieldsDisabled}
                                      fullWidth
                                      className="mt-1"
                                      placeholder="Enter TID"
                                      inputProps={{ maxLength: 5 }}
                                      variant="outlined"
                                      error={
                                        !!_.get(
                                          errors,
                                          `freightTerminalLeadInquiries[${index}].terminal`
                                        ) && isCompleting
                                      }
                                    />
                                  </FormControl>
                                  {!invalidFields.includes(
                                    EASInvestigationFields.Terminal + index
                                  ) &&
                                  errors.freightTerminalLeadInquiries &&
                                  errors.freightTerminalLeadInquiries.length >
                                    0 &&
                                  _.get(
                                    errors,
                                    `freightTerminalLeadInquiries[${index}].terminal`
                                  ) &&
                                  isCompleting ? (
                                    <span className="form-error">
                                      {_.get(
                                        errors,
                                        `freightTerminalLeadInquiries[${index}].terminal`,
                                        ""
                                      )}
                                    </span>
                                  ) : null}
                                  {invalidFields.includes(
                                    EASInvestigationFields.Terminal + index
                                  ) &&
                                    !values?.freightTerminalLeadInquiries?.[
                                      index
                                    ]?.terminal?.trim() && (
                                      <span className="form-error">
                                        {
                                          EASInvestigationFieldsErrorMessage.day5
                                        }
                                      </span>
                                    )}
                                </Grid>

                                  <Grid item md={3} xs={12} className="pt-0">
                                    <FormControl className="radio-group question-group ">
                                      <FormLabel>
                                        Manifest #<sup>*</sup>
                                      </FormLabel>
                                      <TextField  autoComplete="off"
                                        id={`manifest-${index}`}
                                        type="number"
                                        onPaste={HandlePaste}
                                        onKeyDown={HandleKeyDown}
                                        onInput={(
                                          e: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                          HandleNumberMaxLength(e, 9);
                                          isValueExist(
                                            EASInvestigationFields.Manifest,
                                            index
                                          );
                                        }}
                                        name={`freightTerminalLeadInquiries[${index}].manifest`}
                                        value={_.get(
                                          values,
                                          `freightTerminalLeadInquiries[${index}].manifest`
                                        )}
                                        onChange={(e) => {
                                          handleChange(e);
                                          isValueExist(
                                            EASInvestigationFields.Manifest,
                                            index
                                          );
                                        }}
                                        onBlur={(e) => {
                                          handleBlur(e);
                                          isValueExist(
                                            EASInvestigationFields.Manifest,
                                            index
                                          );
                                        }}
                                        error={  
                                        isCompleting &&
                                        (
                                          invalidFields.includes(EASInvestigationFields.Manifest + index) || // Invalid field check
                                          !values?.freightTerminalLeadInquiries?.[index]?.manifest ||        // Manifest value is missing
                                          values?.freightTerminalLeadInquiries?.[index]?.manifest === 0 ||   // Manifest value is 0
                                          _.get(errors, `freightTerminalLeadInquiries[${index}].manifest`)
                                        )
                                      }
                                      disabled={formFieldsDisabled}
                                      fullWidth
                                      className="mt-1"
                                      placeholder="Enter Manifest #"
                                      inputProps={{ maxLength: 9 }}
                                      variant="outlined"
                                    />
                                  </FormControl>
                                  {values?.freightTerminalLeadInquiries![index]
                                    ?.manifest !== 0 &&
                                  !invalidFields.includes(
                                    EASInvestigationFields.Manifest + index
                                  ) &&
                                  errors.freightTerminalLeadInquiries &&
                                  errors.freightTerminalLeadInquiries.length >
                                    0 &&
                                  _.get(
                                    errors,
                                    `freightTerminalLeadInquiries[${index}].manifest`
                                  ) &&
                                  isCompleting ? (
                                    <span className="form-error">
                                      {_.get(
                                        errors,
                                        `freightTerminalLeadInquiries[${index}].manifest`,
                                        ""
                                      )}
                                    </span>
                                  ) : null}
                                  {values?.freightTerminalLeadInquiries![index]
                                    ?.manifest !== 0 &&
                                    invalidFields.includes(
                                      EASInvestigationFields.Manifest + index
                                    ) &&
                                    !values?.freightTerminalLeadInquiries?.[
                                      index
                                    ]?.manifest && (
                                      <span className="form-error">
                                        {
                                          EASInvestigationFieldsErrorMessage.day5
                                        }
                                      </span>
                                    )}
                                  {values?.freightTerminalLeadInquiries![index]
                                    ?.manifest === 0 && (
                                    <span className="form-error">
                                      Invalid Manifest# Number
                                    </span>
                                  )}
                                </Grid>

                                <Grid item md={6} xs={12} className="pt-0">
                                  <FormControl className="radio-group question-group ">
                                    <FormLabel>
                                      List any discrepancies or abnormalities{" "}
                                      <sup>*</sup>
                                    </FormLabel>
                                    <TextField
                                      autoComplete="off"
                                      id={`pumSteps-${index}`}
                                      name={`freightTerminalLeadInquiries[${index}].discrepancies`}
                                      value={_.get(
                                        values,
                                        `freightTerminalLeadInquiries[${index}].discrepancies`
                                      )}
                                      onChange={(e) => {
                                        handleChange(e);
                                        isValueExist(
                                          EASInvestigationFields.Discrepancies,
                                          index
                                        );
                                      }}
                                      error={
                                        !!_.get(
                                          errors,
                                          `freightTerminalLeadInquiries[${index}].discrepancies`
                                        ) && isCompleting
                                      }
                                      onBlur={(e) => {
                                        handleBlur(e);
                                        isValueExist(
                                          EASInvestigationFields.Discrepancies,
                                          index
                                        );
                                      }}
                                      disabled={formFieldsDisabled}
                                      fullWidth
                                      className="mt-1"
                                      placeholder="List all terminals contacted and your findings"
                                      inputProps={{
                                        maxLength: MAX_LENGTH_SUMMARY_TEXT,
                                      }}
                                      variant="outlined"
                                    />
                                  </FormControl>
                                  {!invalidFields.includes(
                                    EASInvestigationFields.Discrepancies + index
                                  ) &&
                                  errors.freightTerminalLeadInquiries &&
                                  errors.freightTerminalLeadInquiries.length >
                                    0 &&
                                  _.get(
                                    errors,
                                    `freightTerminalLeadInquiries[${index}].discrepancies`
                                  ) &&
                                  isCompleting ? (
                                    <span className="form-error">
                                      {_.get(
                                        errors,
                                        `freightTerminalLeadInquiries[${index}].discrepancies`,
                                        ""
                                      )}
                                    </span>
                                  ) : null}
                                  {invalidFields.includes(
                                    EASInvestigationFields.Discrepancies + index
                                  ) &&
                                    !values?.freightTerminalLeadInquiries?.[
                                      index
                                    ]?.discrepancies?.trim() && (
                                      <span className="form-error">
                                        {
                                          EASInvestigationFieldsErrorMessage.day5
                                        }
                                      </span>
                                    )}
                                </Grid>
                              </React.Fragment>
                            ))}
                            <Grid
                              item
                              md={12}
                              xs={12}
                              className="btn-group-submit"
                            >
                              {!(
                                formFieldsDisabled ||
                                status === EShortageInvestigationStatus.complete
                              ) && (
                                <>
                                  {values.freightTerminalLeadInquiries
                                    ?.length! < 6 && (
                                    <Button
                                      onClick={
                                        addButtonDisabled
                                          ? void 0
                                          : () => {
                                              push({
                                                terminal: undefined,
                                                manifest: undefined,
                                                discrepancies: undefined,
                                              });
                                              addRemove();
                                            }
                                      }
                                      variant="contained"
                                      className={`entry ml ${
                                        addButtonDisabled
                                          ? "disable-btn disable"
                                          : ""
                                      }`}
                                    >
                                      Add
                                    </Button>
                                  )}
                                  {(values.freightTerminalLeadInquiries
                                    ?.length ?? 0) > 1 && (
                                    <Button
                                      onClick={() => {
                                        remove(
                                          values.freightTerminalLeadInquiries
                                            ?.length! - 1
                                        );
                                        addRemove("remove");
                                      }}
                                      variant="contained"
                                      className="clear-btn mr-0 ml"
                                    >
                                      Remove
                                    </Button>
                                  )}
                                </>
                              )}
                            </Grid>
                          </>
                        )}
                      </FieldArray>
                    </Grid>
                  )}
                  {/* F18 Notes */}
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel style={selectStyle} shrink={true}>
                        F18 Notes <sup></sup>
                      </InputLabel>
                      <TextField
                        autoComplete="off"
                        multiline
                        rows={2}
                        name="day5F18Note"
                        value={values.day5F18Note}
                        onChange={handleChange}
                        placeholder="Enter F18 Notes"
                        fullWidth
                        inputProps={{ maxLength: 300 }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            )}

            {activeStep === StepStatusMap[7] && (
              <div>
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled || isControlDisabled("followUp")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.followUp === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "followUp",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "followUpDetails",
                                  e.target.checked
                                    ? values.followUpDetails
                                    : "",
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />{" "}
                        Follow up on all leads/request from previous days{" "}
                        <sup>*</sup>
                      </FormLabel>
                      {errors.followUp && isCompleting ? (
                        <span className="form-error">
                          {errors.followUp}
                        </span>
                      ) : null}
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.followUp === "Y" && (
                            <TextField
                              autoComplete="off"
                              name="followUpDetails"
                              value={values.followUpDetails}
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                              }}
                              disabled={formFieldsDisabled}
                              fullWidth
                              placeholder="Provide details of your search"
                              inputProps={{
                                maxLength: MAX_LENGTH_SUMMARY_TEXT,
                              }}
                              variant="outlined"
                            />
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>
                  {/* F18 Notes */}
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel style={selectStyle} shrink={true}>
                        F18 Notes <sup></sup>
                      </InputLabel>
                      <TextField
                        autoComplete="off"
                        multiline
                        rows={2}
                        name="day10F18Note"
                        value={values.day10F18Note}
                        onChange={handleChange}
                        placeholder="Enter F18 Notes"
                        fullWidth
                        inputProps={{ maxLength: 300 }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            )}

            {activeStep === StepStatusMap[8] && (
              <div>
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled ||
                            isControlDisabled("check7818_2")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.check7818_2 === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "check7818_2",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "details7818_2",
                                  e.target.checked ? values.details7818_2 : "",
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />{" "}
                        Check 78/18 and/or Power BI for any overages (OV, NB,
                        DO) to apply <sup>*</sup>
                      </FormLabel>
                      {errors.check7818_2 && isCompleting ? (
                        <span className="form-error">
                          {errors.check7818_2}
                        </span>
                      ) : null}
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.check7818_2 === "Y" && (
                            <TextField
                              autoComplete="off"
                              name="details7818_2"
                              value={values.details7818_2}
                              error={!!errors.details7818_2 && isCompleting}
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                isValueExist(
                                  EASInvestigationFields.Details7818_2
                                );
                              }}
                              disabled={formFieldsDisabled}
                              fullWidth
                              placeholder="Provide details of your search"
                              inputProps={{
                                maxLength: MAX_LENGTH_SUMMARY_TEXT,
                              }}
                              variant="outlined"
                            />
                          )}
                          {errors.details7818_2 && isCompleting ? (
                            <span className="form-error">
                              {errors.details7818_2}
                            </span>
                          ) : null}
                          {invalidFields.includes(
                            EASInvestigationFields.Details7818_2
                          ) && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day15}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id="highValueLabel">
                        Is this high value or high quantity? <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          <RadioGroup
                            row
                            aria-labelledby="destinationTerminal"
                            name="highValue"
                            className="textarea-radio-group"
                          >
                            <FormControlLabel
                              value="Y"
                              control={<Radio />}
                              label="Yes"
                              name="highValue"
                              checked={values.highValue === "Y"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                            />
                            <FormControlLabel
                              value="N"
                              control={<Radio />}
                              label="No"
                              name="highValue"
                              checked={values.highValue === "N"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    </FormControl>
                    {errors.highValue && isCompleting ? (
                      <span className="form-error">
                        {errors.highValue}
                      </span>
                    ) : null}
                  </Grid>
                </Grid>
                {/* {showSendAPB() && ( */}
                <div
                  className={`${
                    showSendAPB() ? "" : "hide-me"
                  } another-class-name`}
                >
                  <div className="MuiTypography-root header-notice justify-content">
                    Since you've chosen the "high value/quantity" option, an APB
                    will be issued. Please remove any photos you'd prefer not to
                    include in the APB email.
                  </div>
                  <SendAPBImageList
                    osdNumber={investigationDetails?.osdNumber}
                    setSelectedImages={setSelectedImages}
                    selectedImages={selectedImages}
                    handleImageSelect={handleImageSelect}
                    isSendAPBScreen={false}
                  />
                  <br />
                </div>
                {/* )} */}
                {/* F18 Notes */}
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel style={selectStyle} shrink={true}>
                        F18 Notes <sup></sup>
                      </InputLabel>
                      <TextField
                        autoComplete="off"
                        multiline
                        rows={2}
                        name="day15F18Note"
                        value={values.day15F18Note}
                        onChange={handleChange}
                        placeholder="Enter F18 Notes"
                        fullWidth
                        inputProps={{ maxLength: 300 }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            )}

            {activeStep === StepStatusMap[9] && (
              <div>
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled ||
                            isControlDisabled("finalReCheck")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.finalReCheck === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "finalReCheck",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "detailsOfSearch",
                                  e.target.checked
                                    ? initValues.detailsOfSearch
                                    : "",
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />
                        Final re-check of all leads/requests/overages to confirm
                        the freight cannot be located at this time <sup>*</sup>
                      </FormLabel>
                      {errors.finalReCheck && isCompleting ? (
                        <span className="form-error">
                          {errors.finalReCheck}
                        </span>
                      ) : null}
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.finalReCheck === "Y" && (
                            <TextField
                              autoComplete="off"
                              id={`detailsOfSearch`}
                              name="detailsOfSearch"
                              value={values.detailsOfSearch ?? ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              fullWidth
                              className=""
                              placeholder="Provide details of your search"
                              inputProps={{
                                maxLength: MAX_LENGTH_SUMMARY_TEXT,
                              }}
                              variant="outlined"
                            />
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>
                  {/* F18 Notes */}
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel style={selectStyle} shrink={true}>
                        F18 Notes <sup></sup>
                      </InputLabel>
                      <TextField
                        autoComplete="off"
                        multiline
                        rows={2}
                        name="day20F18Note"
                        value={values.day20F18Note}
                        onChange={handleChange}
                        placeholder="Enter F18 Notes"
                        fullWidth
                        inputProps={{ maxLength: 300 }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            )}
          </>
        </FormikProvider>
        <Grid item md={12} xs={12} className="btn-group-submit">
          {formFieldsDisabled &&
            status !== EShortageInvestigationStatus.complete && (
              <Button
                variant="contained"
                onClick={enableEditing}
                className="assign ml mr-0"
              >
                Edit
              </Button>
            )}
          {!formFieldsDisabled && (
            <>
              <Button
                onClick={() => reset()}
                variant="contained"
                className="clear-btn mr-0 ml"
                type="button"
              >
                {isEditMode ? "Cancel" : "Clear"}
              </Button>
              <Button
                variant="contained"
                onClick={
                  invalidFields?.length > 0
                    ? void 0
                    : () => {
                        saveOrSubmit(EFormAction.save);
                      }
                }
                className={
                  invalidFields?.length > 0
                    ? "disable-btn disable entry ml"
                    : "entry ml"
                }
              >
                Save
              </Button>
              {displayCompleteBtn && (
                <Button
                  variant="contained"
                  onClick={
                    invalidFields?.length > 0
                      ? void 0
                      : () => {
                          saveOrSubmit(EFormAction.submit);
                        }
                  }
                  className={
                    invalidFields?.length > 0
                      ? "disable-btn disable entry ml"
                      : "entry ml"
                  }
                >
                  Complete Day {activeDay}
                </Button>
              )}
            </>
          )}
          {formFieldsDisabled &&
            !isFreightLocated &&
            StepStatusMap[status as keyof typeof StepStatusMap] ===
              activeStep &&
            [
              EShortageInvestigationStatus.complete,
              EShortageInvestigationStatus.exceptionEntry,
              EShortageInvestigationStatus.pendingSrApproval,
            ].indexOf(status) === -1 && (
              <Button
                onClick={() => handleDialogOpen()}
                variant="contained"
                className="entry ml"
              >
                Freight Located
              </Button>
            )}

          {shouldShowCompleteButton && (
            <Button
              onClick={() => handleFreightAndCompletion(EFormAction.complete)}
              variant="contained"
              className="entry ml"
            >
              Complete & Image
            </Button>
          )}
        </Grid>
      </AccordionDetails>
      <Loader pageLoader={pageLoading}></Loader>

      {ASInvestigationWizardStep.map((day: string, index: number) => (
        <>
          {day !== "Day 4" ? (
            <div key={day} className="hide-me" id={`investigation-${day}`}>
              <ASInvestigationTemplate
                currentDay={day}
                values={values}
                osdNumber={investigationDetails?.osdNumber}
              />
            </div>
          ) : (
            <>
              <div key={day} className="hide-me" id={`investigation-${day}`}>
                <ASInvestigationTemplate
                  currentDay={day}
                  values={values}
                  osdNumber={investigationDetails?.osdNumber}
                />
              </div>
              {(callSameDaysChunks || [[]]).map(
                (chunk: ICallSameDaysDataset[], index: number) => {
                  const chunkId = `investigation-${day}-callSameDays-${index}`;
                  return (
                    <div key={chunkId} className="hide-me" id={chunkId}>
                      <ASDay4Template
                        currentDay={day}
                        osdNumber={investigationDetails?.osdNumber}
                        investigationValues={values}
                        callSameDaysChunk={chunk}
                        pageIndex={getPageIndex(index, callSameDaysChunks)}
                      />
                    </div>
                  );
                }
              )}
            </>
          )}
        </>
      ))}
      <CustomDialog
        message={"Are you sure freight is located?"}
        open={dialogOpen}
        onClose={handleDialogClose}
      />
    </div>
  );
};

export default ShortageInvestigation;
