import * as R from "ramda";
import { Action } from "redux";
import { AppState } from "./appState";
import ACTION_TYPE from "./ActionTypes";
import { IMaterUser } from "../models/user";


export interface IFilterForm {
  search: string;
  date: string | null;
  toDate?: string | null;
  fromDate?: string | null;
  origin: string[] | null;
  destination: string[] | null;
  reporting: string[] | null;
  osdType: string[] | null;
  commodityType: string[] | null;
  description: string | null;
  shipperName: string | null;
  consigneeName:string | null;
  proNumber: string | null;
  od400ProNumber: string | null;
  osdNumber: string | null;
  values: string[] | null;
  status: string[] | null;
  entryUser: IMaterUser | null;
  assignedTo?: IMaterUser | null;
  assignedBy?: IMaterUser | null;
}

export type typeOveragesFilterForm = {
  type: ACTION_TYPE.OVERAGE_LIST_FILTER_FORM_STATE;
  overagesFilterForm: IFilterForm;
};

/**
 * Redux action to update DispatchForm in Redux State
 * @param manifestReviewForm - the params we use for the dispatch api put request Obtained from DispatchForm component.
 */

export const saveOveragesFilterForm = (
    overagesFilterForm: IFilterForm
): typeOveragesFilterForm => ({
  type: ACTION_TYPE.OVERAGE_LIST_FILTER_FORM_STATE,
  overagesFilterForm: overagesFilterForm,
});

export const saveOveragesFilterFormReducer = (
  state: AppState,
  action: Action
): AppState => {
  if (action.type !== ACTION_TYPE.OVERAGE_LIST_FILTER_FORM_STATE) {
    return state;
  }
  return R.mergeRight(state, {
    overagesFilterForm: (action as typeOveragesFilterForm)
      .overagesFilterForm,
  });
};


export interface IFilterDropdown {
  actualValue: string;
  displayValue: string;
  serialNumber?: number;
  combinedSerialNumber?: number;
  code?: string;
};

export type TTerminal= {
  value: string;
  region: string | null;
}

export type actionFilterDate = {
  type: ACTION_TYPE.OVERAGE_LIST_FILTER_DATE_STATE;
  filterDate: IFilterDropdown[];
};

export const saveFilterDateData = (filterDate: IFilterDropdown[]): actionFilterDate => ({
  type: ACTION_TYPE.OVERAGE_LIST_FILTER_DATE_STATE,
  filterDate: filterDate,
});

export const saveFilterDateDataReducer = (
  state: AppState,
  action: Action,
): AppState => {
  if (action.type !== ACTION_TYPE.OVERAGE_LIST_FILTER_DATE_STATE) {
    return state;
  }
  return R.mergeRight(state, {
    overagesFilterDateData: (action as actionFilterDate).filterDate,
  });
};

export type actionFilterExceptionCode = {
  type: ACTION_TYPE.OVERAGE_LIST_FILTER_EXCEPTION_CODE;
  filterExceptionCode: string[];
};

export const saveFilterExceptionCodeData = (filterExceptionCode: string[]): actionFilterExceptionCode => ({
  type: ACTION_TYPE.OVERAGE_LIST_FILTER_EXCEPTION_CODE,
  filterExceptionCode: filterExceptionCode,
});

export const saveFilterExceptionCodeReducer = (
  state: AppState,
  action: Action,
): AppState => {
  if (action.type !== ACTION_TYPE.OVERAGE_LIST_FILTER_EXCEPTION_CODE) {
    return state;
  }
  return R.mergeRight(state, {
    filterExceptionCodeData: (action as actionFilterExceptionCode).filterExceptionCode,
  });
};

export type actionShortageExceptionCode = {
  type: ACTION_TYPE.SHORTAGES_LIST_FILTER_EXCEPTION_CODE;
  shortageExceptionCode: string[];
};

export const saveShortageExceptionCodeData = (shortageExceptionCode: string[]): actionShortageExceptionCode => ({
  type: ACTION_TYPE.SHORTAGES_LIST_FILTER_EXCEPTION_CODE,
  shortageExceptionCode: shortageExceptionCode,
});

export const saveShortageExceptionCodeReducer = (
  state: AppState,
  action: Action,
): AppState => {
  if (action.type !== ACTION_TYPE.SHORTAGES_LIST_FILTER_EXCEPTION_CODE) {
    return state;
  }
  return R.mergeRight(state, {
    shortageExceptionCodeData: (action as actionShortageExceptionCode).shortageExceptionCode,
  });
};

export type actionAllExceptionCode = {
  type: ACTION_TYPE.OVERAGE_LIST_ALL_EXCEPTION_CODE;
  allExceptionCode: string[];
};

export const saveAllExceptionCodeData = (allExceptionCode: string[]): actionAllExceptionCode => ({
  type: ACTION_TYPE.OVERAGE_LIST_ALL_EXCEPTION_CODE,
  allExceptionCode: allExceptionCode,
});

export const saveAllExceptionCodeReducer = (
  state: AppState,
  action: Action,
): AppState => {
  if (action.type !== ACTION_TYPE.OVERAGE_LIST_ALL_EXCEPTION_CODE) {
    return state;
  }
  return R.mergeRight(state, {
    allExceptionCodeData: (action as actionAllExceptionCode).allExceptionCode,
  });
};

export type actionFilterCommodityType = {
  type: ACTION_TYPE.OVERAGE_LIST_FILTER_COMMODITY_TYPE;
  filterCommodityType: IFilterDropdown[];
};

export const saveFilterCommodityTypeData = (filterCommodityType: IFilterDropdown[]): actionFilterCommodityType => ({
  type: ACTION_TYPE.OVERAGE_LIST_FILTER_COMMODITY_TYPE,
  filterCommodityType: filterCommodityType,
});

export const saveFilterCommodityTypeReducer = (
  state: AppState,
  action: Action,
): AppState => {
  if (action.type !== ACTION_TYPE.OVERAGE_LIST_FILTER_COMMODITY_TYPE) {
    return state;
  }
  return R.mergeRight(state, {
    filterCommodityTypeData: (action as actionFilterCommodityType).filterCommodityType,
  });
};



export type actionPackageOptionMaster = {
  type: ACTION_TYPE.PACKAGE_OPTION_MASTER;
  packageOptionMaster: IFilterDropdown[];
};

export const savePackageOptionMaster = (packageOptionMaster: IFilterDropdown[]): actionPackageOptionMaster => ({
  type: ACTION_TYPE.PACKAGE_OPTION_MASTER,
  packageOptionMaster: packageOptionMaster,
});

export const savePackageOptionMasterReducer = (
  state: AppState,
  action: Action,
): AppState => {
  if (action.type !== ACTION_TYPE.PACKAGE_OPTION_MASTER) {
    return state;
  }
  return R.mergeRight(state, {
    packageOptionMasterData: (action as actionPackageOptionMaster).packageOptionMaster,
  });
};



export type actionFilterValue = {
  type: ACTION_TYPE.OVERAGE_LIST_FILTER_VALUE;
  filterValue: IFilterDropdown[];
};

export const saveFilterValueData = (filterValue: IFilterDropdown[]): actionFilterValue => ({
  type: ACTION_TYPE.OVERAGE_LIST_FILTER_VALUE,
  filterValue: filterValue,
});

export const saveFilterValueReducer = (
  state: AppState,
  action: Action,
): AppState => {
  if (action.type !== ACTION_TYPE.OVERAGE_LIST_FILTER_VALUE) {
    return state;
  }
  return R.mergeRight(state, {
    filterValueData: (action as actionFilterValue).filterValue,
  });
};

export type actionFilterStatus = {
  type: ACTION_TYPE.OVERAGE_LIST_FILTER_STATUS;
  filterStatus: IFilterDropdown[];
};

export const saveFilterStatusData = (filterStatus: IFilterDropdown[]): actionFilterStatus => ({
  type: ACTION_TYPE.OVERAGE_LIST_FILTER_STATUS,
  filterStatus: filterStatus,
});

export const saveFilterStatusReducer = (
  state: AppState,
  action: Action,
): AppState => {
  if (action.type !== ACTION_TYPE.OVERAGE_LIST_FILTER_STATUS) {
    return state;
  }
  return R.mergeRight(state, {
    filterStatusData: (action as actionFilterStatus).filterStatus,
  });
};


export type actionShortageStatusMaster = {
  type: ACTION_TYPE.SHORTAGES_STATE_MASTER;
  shortageStatusMaster: IFilterDropdown[];
};

export const saveShortageStatusMaster = (shortageStatusMaster: IFilterDropdown[]): actionShortageStatusMaster => ({
  type: ACTION_TYPE.SHORTAGES_STATE_MASTER,
  shortageStatusMaster: shortageStatusMaster,
});

export const saveShortageStatusReducer = (
  state: AppState,
  action: Action,
): AppState => {
  if (action.type !== ACTION_TYPE.SHORTAGES_STATE_MASTER) {
    return state;
  }
  return R.mergeRight(state, {
    shortageStatusMasterData: (action as actionShortageStatusMaster).shortageStatusMaster,
  });
};


export type actionMyAssignmentStatusMaster = {
  type: ACTION_TYPE.MY_ASSIGNMENT_STATUS_MASTER;
  myAssignmentStatusMaster: IFilterDropdown[];
};

export const saveMyAssignmentStatusMaster = (myAssignmentStatusMaster: IFilterDropdown[]): actionMyAssignmentStatusMaster => ({
  type: ACTION_TYPE.MY_ASSIGNMENT_STATUS_MASTER,
  myAssignmentStatusMaster: myAssignmentStatusMaster,
});

export const saveMyAssignmentStatusReducer = (
  state: AppState,
  action: Action,
): AppState => {
  if (action.type !== ACTION_TYPE.MY_ASSIGNMENT_STATUS_MASTER) {
    return state;
  }
  return R.mergeRight(state, {
    myAssignmentStatusMasterData: (action as actionMyAssignmentStatusMaster).myAssignmentStatusMaster,
  });
};
