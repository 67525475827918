import React, { useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete, { AutocompleteChangeReason } from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { getFilterMasterUser } from "../../API/getFilterMasterUser";
import { APP, EAutoSearchFieldName, TMasterSearchParameter } from "../../constants/app";
import { exceptionAssign } from "../../API/exceptionAssign";
import { useDispatch } from "react-redux";
import { PopoutNotification } from "../../AppState/popoutNotification";
import NOTIFICATION_TYPE from "../../AppState/NotificationType";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { debounce } from "lodash";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import { NoOptionsText } from "../../helpers/utils.helper";
import { IMaterUser } from "../../models/user";

type Props = {
  selectedOSDIds?: GridRowSelectionModel | number[];
  isOpen?: boolean;
  onClose?:  () => void;
  onSuccessFullyAssigned?: () => void;
};

const AssignTo: React.FC<Props> = (props: Props): JSX.Element => {
  const [assignUserList, setAssignUserList] = React.useState<IMaterUser[]>([]);
  const [assignUserObject, setAssignUserObject] = React.useState<IMaterUser | null>(null);
  const [assignUser, setAssignUser] = React.useState<string>("");
  const [pageLoading, setPageLoading] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);;
 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce(
      (values) =>
      getFiltersAssignUserDataAPI(values),
      1000
    ),[]
  );

  const onInputChange = (event:  React.SyntheticEvent, values: string) => {
    if (values === "undefined") return;
    setAssignUser(values);
    setLoading(true);
    setAssignUserList([]);
    debouncedSave(values);
  };

  const onAssignUserChanged = (event: React.SyntheticEvent, value: any,  reason?: AutocompleteChangeReason) => {
    if (reason === "clear") {
      setAssignUserList([]);
      setAssignUserObject(null);
    }
    if (!value || value === "undefined") return;
    setAssignUserObject(value);
  };

  const dispatch = useDispatch();
  const assignToUser = () => {
    setPageLoading(true);
    exceptionAssign({
      osdIds: props.selectedOSDIds,
      username: assignUserObject?.as400Id,
    })
      .then((responseData: {status: string}) => {
        setPageLoading(false);
        if(props.onSuccessFullyAssigned) props.onSuccessFullyAssigned();
        dispatch(
          PopoutNotification(
            NOTIFICATION_TYPE.SUCCESS,
            "OSD records assigned successfully.",
            Date.now().toString()
          )
        );
      })
      .catch((err) => {
        setPageLoading(false);
        if (props.onSuccessFullyAssigned) {
          props.onSuccessFullyAssigned();
        }
        dispatch(
          PopoutNotification(
            typeof err === 'string' && err.toLocaleLowerCase().includes('missing email address') ? NOTIFICATION_TYPE.WARNING : NOTIFICATION_TYPE.ERROR,
            typeof err === 'string' ? err : JSON.stringify(err).substring(0,100),
            Date.now().toString()
          )
        );
      });
  };

  const getFiltersAssignUserDataAPI = (values: string) => {
    if (values && values.length > 1) {
      let params: TMasterSearchParameter = {
        search: values.toString(),
        page: APP.overage.userAPIpageLimit,
        limit: APP.overage.userAPILimit,
      };
      try {
        getFilterMasterUser(params).then((users: IMaterUser[]) => {
          setLoading(false);
          setAssignUserList(users);
        });
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };
  return (
    <div>
      <Dialog
        open={props.isOpen!}
        aria-labelledby="customized-dialog-title"
        keepMounted
        maxWidth="sm"
        fullWidth={true}
        className="assignment"
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
          className="modal-header"
        >
          Assign Exception
        </DialogTitle>
        <IconButton
          aria-label="close"
          className={pageLoading ? "disable-btn" : ""}
          onClick={pageLoading ? void 0 : props.onClose}
          sx={{
            position: "absolute",
            right: 15,
            top: 15,
            color: (theme: any) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers className="assignment-area">
          <Grid item md={3} xs={9}>
            <FormControl fullWidth size="small">
              <InputLabel
                style={{ fontSize: "13px" }}
                shrink={true}
                id="assignedTo"
              >
                Select User <sup>*</sup>
              </InputLabel>
              <Autocomplete
                disablePortal
                size="small"
                id="assignedTo"
                disabled={pageLoading}
                value={assignUserObject}
                onInputChange={onInputChange}
                onChange={onAssignUserChanged}
                inputValue={assignUser}
                noOptionsText={
                  NoOptionsText(assignUser, loading, EAutoSearchFieldName.name)
                }
                forcePopupIcon={false}
                className="autocomplete"
                options={assignUserList}
                getOptionLabel={(option) => (typeof option === "string" ? option : option?.nameFullLc || "")}
                isOptionEqualToValue={(option, value) => option?.nameFullLc === value?.nameFullLc}
                filterOptions={(options, { inputValue }) =>
                  options.filter(option =>
                    option.nameFullLc.concat(" ", option.as400Id).toLowerCase().includes(inputValue.toLowerCase())
                  )
                }
                renderInput={(params) => <TextField  autoComplete="off" {...params} />}
              />
            </FormControl>
            {pageLoading ? (
              <LoadingButton
                loading
                loadingPosition="start"
                startIcon={<SaveIcon />}
                className="assign loading"
              >
                Assign
              </LoadingButton>
            ) : (
              <Button
                onClick={!assignUserObject ? void 0 : assignToUser}
                variant="contained"
                className={
                  !assignUserObject
                    ? " disable disable-btn assign"
                    : "assign"
                }
              >
                Assign
              </Button>
            )}

            <Button
              onClick={pageLoading ? void 0 : props.onClose}
              variant="contained"
              className={pageLoading ? "disable-btn disable" : "disable"}
            >
              Cancel
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AssignTo;
