import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControl,
  InputLabel,
  TextField,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowLeftIcon from "@mui/icons-material/ArrowBack";
import BNFInvestigation from "./bnfInvestigation";
import UploadDocument from "../../../components/uploadDocument/uploadDocument";
import { AppURLs, EUploadKey } from "../../../constants/app";
import { getBillNoFreightEditViewAPI } from "../../../API/getBillNoFreightEditViewAPI";
import { IBillNoFreight } from "../../../models/bnfException";
import { DateHelper } from "../../../helpers/date.helper";
import { EBNFStatus } from "../../../constants/shortage";
import { commodityTypeMasterApi } from "../../../API/getCommodityTypeMaster";
import { IFilterDropdown, saveFilterCommodityTypeData } from "../../../AppState/overagesFilterFormActions";
import AppState from "../../../AppState";
import { useDispatch, useSelector } from "react-redux";

const CustomTextField = ({
  id,
  label,
  md,
  value,
}: {
  id: string; // Change to string
  label: string;
  md: number;
  value: string | number | undefined; // Keep as is
}) => (
  <Grid item md={md} xs={12}>
    {id && (
      <FormControl fullWidth size="small">
        <InputLabel style={{ fontSize: "13px" }} shrink={true} id={id}>
          {label} <sup>&nbsp;</sup>
        </InputLabel>
        <TextField  autoComplete="off" value={value || ""} id={id} variant="outlined" disabled />
      </FormControl>
    )}
  </Grid>
);

const fields: { key: keyof IBillNoFreight | ""; label: string; md: number }[] =
  [
    { key: "type", label: "Type", md: 3 },
    { key: "reporting", label: "Reported Terminal", md: 3 },
    { key: "proNumber", label: "PRO#", md: 3 },
    { key: "", label: "", md: 3 },
    { key: "origin", label: "Origin Terminal", md: 3 },
    { key: "destination", label: "Destination Terminal", md: 3 },
    { key: "trailer", label: "Last Trailer", md: 3 },
    { key: "lastManifest", label: "Last Manifest#", md: 3 },
    { key: "shipperAccount", label: "Shipper Account #", md: 3 },
    { key: "shipper", label: "Shipper Name", md: 3 },
    { key: "shipperAddress", label: "Shipper Address", md: 6 },
    { key: "shipperZip", label: "Shipper Zip", md: 3 },
    { key: "shipperCity", label: "Shipper City", md: 3 },
    { key: "shipperState", label: "Shipper State", md: 3 },
    { key: "", label: "", md: 3 },
    { key: "consigneeAccount", label: "Consignee Account #", md: 3 },
    { key: "consignee", label: "Consignee Name", md: 3 },
    { key: "consigneeAddress", label: "Consignee Address", md: 6 },
    { key: "consigneeZip", label: "Consignee Zip", md: 3 },
    { key: "consigneeCity", label: "Consignee City", md: 3 },
    { key: "consigneeState", label: "Consignee State", md: 3 },
    { key: "", label: "", md: 3 },
    { key: "commodity", label: "Commodity", md: 3 },
    { key: "package", label: "Package Type", md: 3 },
    { key: "pieces", label: "Total Pieces", md: 3 },
    { key: "", label: "", md: 3 },
    { key: "description", label: "Additional Details 1 ", md: 12 },
    { key: "supplementalDescription", label: "Additional Details 2", md: 12 },
  ];

const BnfEntry = () => {
  const { osdNumber } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const urls: string[] = [AppURLs.myAssignments, AppURLs.myAssignments, AppURLs.shortages];
  const tabPosition: number = useSelector(
    (state: AppState) => state.tabPosition
  );

  const [bnfDetails, setBNFDetails] = React.useState<IBillNoFreight>();
  const [expanded, setExpanded] = React.useState<string[]>(["panel1"]);
  const [updatedDateTime, setUpdatedDateTime] = React.useState<string>("");
  const [bnfStatus, setBNFStatus] = React.useState<number>(EBNFStatus.exceptionEntry);

    // handle confirmation pop while user want back according button
    const handleDialogOpen = () => {
      navigate(urls[tabPosition - 1] || urls[1])
    };

  const handleChange =
    (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(prevExpanded => {
        if (isExpanded) {
          // Add the panel to the array if it doesn't already exist
          return prevExpanded.includes(panel) ? prevExpanded : [...prevExpanded, panel];
        } else {
          // Remove the panel from the array
          return prevExpanded.filter(item => item !== panel);
        }
      });
    };
    const commodityCodeOptions = useSelector(
      (state: AppState) => state.filterCommodityTypeData
    );
    
  const panels = [
    {
      id: "panel1",
      title: "Bill No Freight Exception",
      content: (
        <Grid container spacing={2} className="filter-margin">
          {fields.map(({ key, label, md }) => (
            <CustomTextField
              key={key}
              id={key?.toString() || ""} // Ensure key is a string
              value={key && bnfDetails ? bnfDetails[key] : undefined} // This can be string | undefined
              label={label}
              md={md}
            />
          ))}
        </Grid>
      ),
    },
    {
      id: "panel2",
      title: "Bill No Freight Follow Up",
      content: <BNFInvestigation  
      key={`${expanded} bnf-Investigation`}
        osdNumber={parseInt(osdNumber!)} 
        hasMovementSinceBNFValue={bnfDetails?.hasMovementSinceBNF} 
        investigationStatusNumber={bnfDetails?.investigationStatusNumber} 
        investigationF18Note={bnfDetails?.f18Note} 
        setBNFStatus={setBNFStatus}
      />,
    },
    {
      id: "panel3",
      title: "Upload Documents",
      content: (
        <UploadDocument
          key={expanded.toString() || "upload-document"}
          osdNumber={parseInt(osdNumber!)}
          uploadKey={EUploadKey.pro}
        />
      ),
    },
  ];

  useEffect(() => {
    if (osdNumber) {
      const viewBNFDetails = async () => {
        const bnfResponse = await getBillNoFreightEditViewAPI(osdNumber);
        const lastUpdatedDate = bnfResponse?.lastUpdatedDate;
        const lastUpdatedTime = bnfResponse?.lastUpdatedTime;
        setBNFStatus(bnfResponse?.investigationStatusNumber || EBNFStatus.exceptionEntry);
        if (lastUpdatedDate && lastUpdatedTime) {
          setUpdatedDateTime(
            DateHelper.mergerAS400DateTime(lastUpdatedDate, lastUpdatedTime)
          );
        }
        if (commodityCodeOptions === null || commodityCodeOptions.length === 0) {
          getMasters();
        }
        if (bnfResponse) setBNFDetails(bnfResponse);
      };
      viewBNFDetails();
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [osdNumber]);

  const getMasters = async () => {
    try {
      const results = await commodityTypeMasterApi();
      const sortedData = results.data
        .map((item: IFilterDropdown) => ({
          ...item,
          code: item.code?.trim(),
        }))
        .sort((a: IFilterDropdown, b: IFilterDropdown) => parseInt(a.code!) - parseInt(b.code!));
  
      dispatch(saveFilterCommodityTypeData(sortedData));
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    if (commodityCodeOptions?.length && bnfDetails?.commodity) {
      const commodity = commodityCodeOptions.find(
        (e: IFilterDropdown) => e.code === bnfDetails.commodity
      );
  
      if (commodity) {
        setBNFDetails(prevState => ({
          ...prevState,
          commodity: `${commodity.code} - ${commodity.displayValue}`,
        }));
      }
    }
  }, [commodityCodeOptions, bnfDetails?.commodity]);

  return (
    <div className="bg-layout overage-entry">
      {/* Header */}
      <div className="header-section">
        <div className="back-section">
          <div className="back" onClick={handleDialogOpen} role="button" tabIndex={0}>
            <ArrowLeftIcon />
          </div>
          <div className="page-hader-content">
            <div className="page-header">Bill No Freight</div>
            <div className="page-subtitle">
              Last update: &nbsp;
              {updatedDateTime ? updatedDateTime : "NA"}
            </div>
          </div>
          <span className="badge">{bnfDetails?.osdNumber}</span>
        </div>
      </div>

      {/* Body */}
      <div className="content-area">
        {panels.map(({ id, title, content }) => (
          <Accordion
            className="accordion"
            expanded={expanded.includes(id)}
            onChange={handleChange(id)}
            key={id}
          >
            <AccordionSummary
              className="header"
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${id}-content`}
              id={`${id}-header`}
              key={`${id}-header`}
            >
              <Typography
                className={
                  id === "panel1" || (id === "panel2" && bnfStatus === EBNFStatus.needsCleared) ? "queue-check queue-check-gc" : "queue-check"
                }
              >
                {id.slice(-1)}
              </Typography>
              <Typography className="accordion-header">{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>{content}</AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default BnfEntry;

