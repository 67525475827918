import { createTheme, ThemeProvider } from "@mui/material/styles";
import AccordionDetails from "@mui/material/AccordionDetails";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Stepper,
  Step,
  StepButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Grid,
  FormControl,
  TextField,
  Checkbox,
  Snackbar,
  Button,
  InputLabel,
} from "@mui/material";
import "./dsInvestigation.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { State } from "../../../components/shared/Notification";
import {
  DSInvestigationInitValue,
  DSInvestigationSchema,
} from "../../../schema/dsInvestigation";
import { FieldArray, FormikProvider, useFormik } from "formik";
import dayjs from "dayjs";
import {
  ASInvestigationWizardStep,
  EShortageInvestigationStatus,
  StepStatusMap,
  EASInvestigationFieldsErrorMessage,
  StapDayMapping,
  StepsRequired,
  FrightLocatedStepMap,
  InvestigationDoneTillTheDayStepMap,
} from "../../../constants/shortage";

import {
  DSDay1Fields,
  DSDay10Fields,
  DSDay15Fields,
  DSDay20Fields,
  DSDay2Fields,
  DSDay3Fields,
  DSDay4Fields,
  DSDay5Fields,
} from "../../../constants/dsException";
import { useDispatch, useSelector } from "react-redux";
import { PopoutNotification } from "../../../AppState/popoutNotification";
import NOTIFICATION_TYPE from "../../../AppState/NotificationType";
import { isFormValueChanged } from "../../../AppState/isFormValueChanged";
import Loader from "../../../components/loader/loader";
import {
  ChunkArray,
  CombineTowJsonObject,
  CompareObjects,
  HandleKeyDown,
  HandleNumberMaxLength,
  HandlePaste,
  HandleTextFieldKeyDown,
  HtmlToImage,
  TrimStrings,
} from "../../../helpers/utils.helper";
import {
  EDSInvestigationFields,
  IDSInvestigation,
  IDSFreightTerminalLeadInquiries,
  ICallSameDaysDataset,
} from "../../../models/dsException";
import _, { debounce } from "lodash";
import DSInvestigationTemplate from "../../../templates/dsInvestigationTemplate";
import { FileUploadAPI } from "../../../API/fileUpload";
import { IUploadFile } from "../../../models/master";
import {
  EFormAction,
  EUploadKey,
  MAX_LENGTH_MANIFEST_NUMBER,
  MAX_LENGTH_MEDIUM_ENTRY,
  MAX_LENGTH_PHONE_NUMBER,
  MAX_LENGTH_SUMMARY_TEXT,
} from "../../../constants/app";
import AppState from "../../../AppState";
import SendAPBImageList from "../../../components/sendAPBImageList/sendAPBImageList";
import { ShortageInvestigationCreateUpdateAPI } from "../../../API/shortageInvestigationCreateUpdate";
import DSDay5Template from "../../../templates/dsDay5Template";
import CustomDialog from "../../../components/confirmationPopup/confirmationPopup";
import TooltipWithClickAway from "../../../components/terminalPicker/TooltipWithClickAway";
import { ConstantMessage } from "../../../constants/constantsMsg";

type Props = {
  isFormEditMode?: boolean;
  setInvestigationStatus: (status: number) => void;
  osdDetails?: any;
};

interface TooltipState {
  [key: string]: boolean;
}

const DsInvestigation: React.FC<Props> = ({
  osdDetails,
  isFormEditMode,
  setInvestigationStatus,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const investigationDetails = useMemo(() => osdDetails, [osdDetails]);
  const [activeStep, setActiveStep] = useState<number>(
    StepStatusMap[EShortageInvestigationStatus.exceptionEntry]
  );
  const [pageLoading, setPageLoading] = React.useState<boolean>(false);
  const [formFieldsDisabled, setFormFieldsDisabled] = React.useState<boolean>(
    isFormEditMode || false
  );
  const [resetTimestamps, setResetTimestamps] = useState<string>(
    Date.now().toString()
  );
  const [formKey, setFormKey] = useState<string>(Date.now().toString());
  const [open, setOpen] = useState<boolean>(false);
  const [isFreightLocated, setIsFreightLocated] = useState<boolean>(false);
  const [status, setStatus] = React.useState<number>(1);
  const [lastStatusNumber, setLastStatusNumber] = React.useState<number>(1);
  const [invalidFields, setInvalidFields] = React.useState<string[]>([]);
  const [addButtonDisabled, setAddButtonDisabled] = useState(false);
  const [proInfoAddButtonDisabled, setProInfoAddButtonDisabled] =
    useState(false);
  const [isCompleting, setIsCompleting] = useState<boolean>(false);
  const [shouldShowDayCompleteButton, setShouldShowDayCompleteButton] =
    useState<boolean>(true);
  const activeDay = StapDayMapping[activeStep as keyof typeof StapDayMapping];
  const [isEditMode, setIsEditMode] = React.useState<boolean>(
    isFormEditMode || false
  );
  const [dateKey, setDateKey] = React.useState<string>(Date.now().toString());
  const isPrivilegedUser: boolean = useSelector(
    (state: AppState) => state.isPrivilegedUser
  );
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false); // State to manage dialog open/close

  const adjustedStatus = isFreightLocated
  ? FrightLocatedStepMap[
      lastStatusNumber as keyof typeof FrightLocatedStepMap
    ]
  : StepStatusMap[status as keyof typeof StepStatusMap];
  const selectStyle = {
    fontSize: "13px",
  };
  const shouldShowCompleteButton =
    formFieldsDisabled &&
    isPrivilegedUser &&
    status === EShortageInvestigationStatus.pendingSrApproval &&
    adjustedStatus === activeStep;

  const shouldShowFreightButton =
    formFieldsDisabled &&
    !isFreightLocated &&
    StepStatusMap[status as keyof typeof StepStatusMap] === activeStep &&
    ![
      EShortageInvestigationStatus.complete,
      EShortageInvestigationStatus.exceptionEntry,
      EShortageInvestigationStatus.pendingSrApproval,
    ].includes(status);

  const [initValues, setInitValues] = React.useState<IDSInvestigation>(
    _.mergeWith(
      {},
      DSInvestigationInitValue,
      investigationDetails,
      CombineTowJsonObject
    )
  );

  // Handle image selection
  const handleImageSelect = (imageUrl: string) => {
    setSelectedImages((prevSelectedImages) =>
      prevSelectedImages.includes(imageUrl)
        ? prevSelectedImages.filter((url) => url !== imageUrl)
        : [...prevSelectedImages, imageUrl]
    );
  };

  /**
   * Determines whether the "Send APB" option should be displayed based on the current state and form values.
   * .
   */
  const showSendAPB = () => {
    const { osdNumber } = investigationDetails;
    const { highValue, details7818_2, check7818_2 } = values;
    const {
      highValue: initialHighValue,
      details7818_2: initialDetails7818_2,
      check7818_2: initialCheck7818_2,
    } = initValues;

    const isStepActive =
      activeStep ===
      StepStatusMap[EShortageInvestigationStatus.day15InProgress];
    const isFormEnabled = !formFieldsDisabled;
    const hasChangedValue =
      !initialDetails7818_2?.trim() ||
      initialCheck7818_2 !== "Y" ||
      initialHighValue !== "Y";

    return (
      osdNumber &&
      isStepActive &&
      isFormEnabled &&
      highValue === "Y" &&
      details7818_2?.trim() &&
      check7818_2 === "Y" &&
      hasChangedValue
    );
  };

  /**
   * Handles the process of uploading investigation images and updating the UI based on the result.
   *  1. **Determine IDs to Process**: Creates a list of IDs to process based on the `ASInvestigationWizardStep`. If `isFreightLocated` is `true`, slices the IDs array to include only the relevant IDs up to `lastStatusNumber - 1`.
   *
   * 2. **Generate Images**: Maps over the IDs to generate image promises using the `HtmlToImage` function. Each promise captures the image of the element with an ID formatted as `investigation-${day}` and names the image file sequentially.
   *
   * 2. **Upload Images**: Waits for all image promises to resolve. Constructs a request input object for file upload with properties such as `osdNumber`, `docType`, and `uploadKey`.
   *
   * 4. **Send Upload Request**: Calls the `FileUploadAPI` function to upload the images along with the request input. Handles the response:
   *    - **Success**: If the upload is successful,
   *    - **Failure**: If the upload fails, dispatches a notification with an error message.
   *
   */
  const uploadInvestigation = async () => {
    setPageLoading(true);
    let ids = ASInvestigationWizardStep;
    if (isFreightLocated) {
      ids = ids.slice(
        0,
        InvestigationDoneTillTheDayStepMap[
          lastStatusNumber as keyof typeof InvestigationDoneTillTheDayStepMap
        ]
      );
    }
    const imagePromises: Promise<File>[] = ids.flatMap(
      (day: string, index: number) => {
        const promises: Promise<File>[] = [];

        if (day === "Day 5") {
          // Process "callSameDaysChunks" asynchronously and add promises to the array
          promises.push(
            ...callSameDaysChunks.map((_c, chunkIndex) => {
              const chunkId = `investigation-${day}-callSameDays-${chunkIndex}`;
              return HtmlToImage(
                chunkId,
                `${day}-page-${chunkIndex + 1}.jpeg`,
                0.2
              );
            })
          );

          // Add the freightTerminal chunk promise
          const freightTerminalChunkId = `investigation-${day}-freightTerminal-${index}`;
          promises.push(
            HtmlToImage(
              freightTerminalChunkId,
              `${day}-freightTerminal-page-${index + 1}.jpeg`,
              0.2
            )
          );
        } else {
          // Default handling for other days
          const defaultId = `investigation-${day}`;
          promises.push(
            HtmlToImage(
              defaultId,
              `${index + 1}.jpeg`,
              day === "Day 3" ? 0.2 : 0.1
            )
          );
        }

        return promises;
      }
    );
    const images = await Promise.all(imagePromises);
    const reqInput: IUploadFile = {
      osdNumber: investigationDetails?.osdNumber,
      docType: "FX",
      uploadKey: EUploadKey.pro,
    };
    FileUploadAPI(images, reqInput)
      .then((res: any) => {
        setPageLoading(false);
        const errors = _.get(res, "errors", []);
        const notificationType =
          errors.length > 0
            ? NOTIFICATION_TYPE.ERROR
            : NOTIFICATION_TYPE.SUCCESS;
        const message =
          errors.length > 0
            ? errors.join(", ")
            : res.result || "OS&D investigation image uploaded successfully";

        dispatch(
          PopoutNotification(notificationType, message, Date.now().toString())
        );
      })
      .catch((error: any) => {
        setPageLoading(false);
        dispatch(
          PopoutNotification(
            NOTIFICATION_TYPE.ERROR,
            typeof error === "string"
              ? error
              : `OS&D investigation image uploading failed`,
            Date.now().toString()
          )
        );
      });
  };

  // If active day investigation is completed the checkboxes is disabled of day
  const isControlDisabled = (fieldName: keyof IDSInvestigation) => {
    const value = "Y";
    const isChecked =
      typeof initValues[fieldName] === "string" &&
      initValues[fieldName] === value;
    if (
      (status > EShortageInvestigationStatus.day20InProgress ||
        isFreightLocated) &&
      isChecked
    ) {
      return true;
    }
    if (StepStatusMap[status as keyof typeof StepStatusMap] <= activeStep)
      return;
    if (isChecked) {
      return true;
    }
  };

  /**  
    Switch the investigation day step
    Allows the user to navigate to a previous day's investigation from the current day in progress
    However, the user cannot proceed to the next day until the current day's investigation is completed
  */

  const handleStep = (step: number) => () => {
    setIsCompleting(false);
    const statusStep = isFreightLocated
      ?  FrightLocatedStepMap[lastStatusNumber as keyof typeof FrightLocatedStepMap]
      : StepStatusMap[status as keyof typeof StepStatusMap]
    if (statusStep < step) return;
    setFormFieldsDisabled(true);
    setIsEditMode(true);
    reset();
    setActiveStep(step);
  };

  const theme = createTheme({
    components: {
      MuiStepLabel: {
        styleOverrides: {
          iconContainer: {
            display: "none", // This will hide the icon container
          },
        },
      },
      MuiStepConnector: {
        styleOverrides: {
          line: {
            display: "none", // This will hide the connector line
          },
        },
      },
      MuiStep: {
        styleOverrides: {
          horizontal: {
            width: "12.5%", // This will set the width of each step to 12.5%
            padding: "0", // This will set the padding to 0
          },
        },
      },
      MuiStepButton: {
        styleOverrides: {
          root: {
            margin: "initial", // This will set the margin to its initial value
            padding: "0", // This will set the padding to its initial value
            zIndex: "1", // This will set the z-index to 1
            height: "50px",
            fontWeight: "bold",
            fontStyle: "italic",
            letterSpacing: "1px",
            textTransform: "uppercase",
          },
        },
      },
    },
  });

  // Declare a new state variable for tooltips with a default empty object
  const [openTooltips, setOpenTooltips] = useState<TooltipState>({});

  // Function to handle tooltip close
  const handleTooltipClose = (id: string) => {
    setOpenTooltips((prev) => ({ ...prev, [id]: false }));
  };

  // Function to handle tooltip open
  const handleTooltipOpen = (id: string) => {
    setOpenTooltips((prev) => ({ ...prev, [id]: true }));
  };

  const [state, setState] = React.useState<State>({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  // Enable fields in edit mode upon clicking the edit button
  const enableEditing = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault(); // Prevents the default form submission behavior
    setFormFieldsDisabled(false); // Enables the form fields for editing
  };

  // Initialize form handling using Formik
  // This setup uses Formik to manage the form state, validation, and submission process.
  const form = useFormik({
    validationSchema: DSInvestigationSchema[activeStep], // Validation schema for the form fields
    validateOnChange: false, // Disable validation on field change
    initialValues: initValues, // Initial values for the form fields
    onSubmit: () => {},
  });

  // Destructure various handlers and values provided by Formik for easy use within the component
  const {
    values, // Current values of the form fields
    handleChange, // Handler for changing form field values
    handleBlur, // Handler for managing field blur events
    validateField, // Function to validate a specific field
    setFieldValue, // Function to set the value of a specific field
    validateForm, // Function to validate the entire form
    resetForm, // Function to reset the form to its initial values
    errors,
  } = form;

  const callSameDaysChunks = ChunkArray(values?.callSameDaysDataset || [], 8);

  const getPageIndex = (index: number, callSameDaysChunks: any[]) => {
    if (index === 1) {
      return callSameDaysChunks[0].length;
    }
    if (index === 2) {
      return [...callSameDaysChunks[0], ...callSameDaysChunks[1]].length;
    }
    return 0;
  };
  /**
   * Validates a field based on the value of a dependent field and the current step in the process.
   *
   * - Adds the field to `invalidFields` if the dependent field matches the expected value (`val`),
   *   the field is empty, and it is not already marked as invalid.
   * - Removes the field from `invalidFields` if the conditions are not met.
   */
  const isValidDependedField = (
    fieldName: keyof IDSInvestigation,
    dependedField: keyof IDSInvestigation,
    activeStep: number,
    val: string = "Y"
  ) => {
    // Exit early if the status step is not reached
    // if (StepStatusMap[status as keyof typeof StepStatusMap] > activeStep) return;
    if (StepStatusMap[status as keyof typeof StepStatusMap] <= activeStep)
      return;

    const fieldValue = values[fieldName]?.toString();
    const dependedValue = values[dependedField];
    let otherVal = "";

    if (fieldName === "confirm") {
      otherVal = "N";
    }

    // Add field to invalidFields if conditions are met
    setInvalidFields((prevInvalidFields) => {
      // Determine if we should add or remove the fieldName
      const shouldAdd =
        dependedValue === val &&
        (!fieldValue ||
          fieldValue === "" ||
          fieldValue === undefined ||
          fieldValue === otherVal);

      if (shouldAdd) {
        // Add the fieldName to the list if it's not already present
        return prevInvalidFields.includes(fieldName)
          ? prevInvalidFields
          : [...prevInvalidFields, fieldName];
      } else {
        // Remove the fieldName from the list if it's present
        return prevInvalidFields.filter((field) => field !== fieldName);
      }
    });
    // Update state with modified invalid fields
    // setInvalidFields(Array.from(updatedInvalidFields));
  };

  /**
   * Adds or removes field names related to the investigation details from the `invalidFields` list based on the provided type.
   *
   * - **Type "add"**: Adds fields with names based on the current length of `freightTerminalLeadInquiries` to `invalidFields`.
   * - **Type "remove"**: Removes fields with names based on the current length of `freightTerminalLeadInquiries` from `invalidFields`.
   *
   */
  const addRemove = (type: string = "add") => {
    // Exit if the current step is not active
    if (StepStatusMap[status as keyof typeof StepStatusMap] <= activeStep)
      return;

    // Determine the length based on the type
    const length =
      (values?.freightTerminalLeadInquiries?.length ?? 0) -
      (type === "add" ? 0 : 1);

    const fields = [
      `terminal${length}`,
      `talkedTo${length}`,
      `updates${length}`,
    ];

    setInvalidFields((prevFields) =>
      type === "add"
        ? [...prevFields, ...fields]
        : prevFields.filter((field) => !fields.includes(field))
    );
  };

  const proInfoAddRemove = (type: string = "add") => {
    // Exit if the current step is not active
    if (StepStatusMap[status as keyof typeof StepStatusMap] <= activeStep)
      return;
    // Determine the length based on the type
    const length =
      (values?.callSameDaysDataset?.length ?? 0) - (type === "add" ? 0 : 1);

    const fields = [`sameDayPro${length}`, `deliveryInfo${length}`];

    setInvalidFields((prevFields) =>
      type === "add"
        ? [...prevFields, ...fields]
        : prevFields.filter((field) => !fields.includes(field))
    );
  };

  const getErrorMessage = (fieldName: keyof IDSInvestigation) => {
    if (
      errors[fieldName] &&
      isCompleting &&
      !invalidFields.includes(fieldName)
    ) {
      return (
        <span className="form-error">{errors[fieldName]?.toString()}</span>
      );
    }
    return null;
  };

  const isValueExist = (
    fieldName: string,
    index?: number,
    fieldArrayName: string = "freightTerminalLeadInquiries"
  ) => {
    // Exit if the current step is not active
    if (StepStatusMap[status as keyof typeof StepStatusMap] <= activeStep)
      return;

    const fieldKey = index !== undefined ? `${fieldName}${index}` : fieldName;
    const value =
      index !== undefined
        ? _.get(values, `${fieldArrayName}[${index}][${fieldName}]`, "")
        : values[fieldName as keyof IDSInvestigation] ?? "";

    const initValue =
      index !== undefined
        ? _.get(initValues, `${fieldArrayName}[${index}][${fieldName}]`, "")
        : initValues[fieldName as keyof IDSInvestigation];
    const isInvalid =
      (value == null || value === "") && // Covers null, undefined, and empty string
      (
        (index === undefined && initValue !== "" && initValue != null) || 
        index !== undefined
      ) && // Checks if initValue is neither an empty string, null, nor undefined
      !invalidFields.includes(fieldKey);

    // Update invalid fields
    setInvalidFields((prevInvalidFields) =>
      isInvalid
        ? [...prevInvalidFields, fieldKey]
        : prevInvalidFields.filter((field) => field !== fieldKey)
    );
  };

  const reset = () => {
    resetForm();
    setResetTimestamps(Date.now().toString());
    setInvalidFields([]);
    const source: IDSInvestigation = JSON.parse(JSON.stringify(initValues));
    const fields: string[] = [
      ...DSDay1Fields,
      ...DSDay2Fields,
      ...DSDay3Fields,
      ...DSDay4Fields,
      ...DSDay10Fields,
      ...DSDay15Fields,
      ...DSDay20Fields,
      ...DSDay5Fields,
    ];
    fields.forEach((f: string) => {
      const fieldValue = source[f as keyof IDSInvestigation];
      setFieldValue(f, fieldValue);
    });
    setFieldValue(`pumManifest`, _.get(source, `pumManifest`, undefined));

    _.get(source, "freightTerminalLeadInquiries", []).forEach(
      (obj: IDSFreightTerminalLeadInquiries, index: number) => {
        setFieldValue(
          `freightTerminalLeadInquiries[${index}].terminal`,
          _.get(obj, `terminal`, undefined)
        );
        setFieldValue(
          `freightTerminalLeadInquiries[${index}].talkedTo`,
          _.get(obj, `talkedTo`, undefined)
        );
        setFieldValue(
          `freightTerminalLeadInquiries[${index}].updates`,
          _.get(obj, `updates`, undefined)
        );
      }
    );

    _.get(source, "callSameDaysDataset", []).forEach(
      (obj: ICallSameDaysDataset, index: number) => {
        setFieldValue(
          `callSameDaysDataset[${index}].sameDayPro`,
          _.get(obj, `sameDayPro`, undefined)
        );
        setFieldValue(
          `callSameDaysDataset[${index}].deliveryInfo`,
          _.get(obj, `deliveryInfo`, undefined)
        );
      }
    );
  };

    const getCallSameDaysDataset = (checked: boolean) => {
      if (checked) {
        return _.isArray(values.callSameDaysDataset) &&
          values.callSameDaysDataset.length > 0
          ? values.callSameDaysDataset
          : DSInvestigationInitValue.callSameDaysDataset;
      }
      return [];
    };
  
    const getFreightTerminalLeadInquiries = (checked: boolean) => {
      if (checked) {
        return _.isArray(values.freightTerminalLeadInquiries) &&
          values.freightTerminalLeadInquiries.length > 0
          ? values.freightTerminalLeadInquiries
          : DSInvestigationInitValue.freightTerminalLeadInquiries;
      }
      return [];
    };

  const validateDSDay5Fields = useCallback(() => {
    if (!values.freightTerminalLeadInquiries?.length) return;
    for (
      let index = 0;
      index < values.freightTerminalLeadInquiries.length;
      index++
    ) {
      ["terminal", "talkedTo", "updates"].forEach((field) =>
        validateField(`freightTerminalLeadInquiries[${index}].${field}`)
      );
    }
  }, [values.freightTerminalLeadInquiries, validateField]);

  const validateCallSameDaysDataset = useCallback(() => {
    if (!values.callSameDaysDataset?.length) return;
    for (let index = 0; index < values.callSameDaysDataset.length; index++) {
      ["sameDayPro", "deliveryInfo"].forEach((field) =>
        validateField(`callSameDaysDataset[${index}].${field}`)
      );
    }
  }, [values.callSameDaysDataset, validateField]);

  const updateInitValue = (
    key: string,
    value: string | number | null | undefined
  ) => {
    setInitValues((prevState) => {
      // Update the top-level property
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  // handle confirmation pop while user want back according button
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  // Close the confirmation modal pop and navigate user to back All Shortage listing page if user confirmed
  const handleDialogClose = (flag?: string) => {
    setDialogOpen(false);
    if (flag === "yes") {
      handleFreightAndCompletion(EFormAction.freightLocated);
    }
  };

  const handleFreightAndCompletion = async (action: EFormAction) => {
    try {
      setPageLoading(true);
      const submitResponse = await ShortageInvestigationCreateUpdateAPI(
        {},
        investigationDetails?.osdNumber?.toString(),
        action,
        action === EFormAction.freightLocated ? activeDay.toString() : undefined
      );
      const {
        status: statusNumber,
        investigationDoneTillTheDay: lastStatusNumber,
        freightLocated,
      } = submitResponse;
      setStatus(statusNumber);
      setLastStatusNumber(lastStatusNumber);
      setIsFreightLocated(freightLocated);
      setInvestigationStatus(statusNumber);
      setPageLoading(false);
      setFormKey(Date.now().toString());
      let message;
      if (action === EFormAction.complete) {
        message = ConstantMessage.investigationNoteCompleted;
        uploadInvestigation();
      } else {
        setActiveStep(
          FrightLocatedStepMap[
            lastStatusNumber as keyof typeof FrightLocatedStepMap
          ]
        );
        message = ConstantMessage.freightLocatedSuccess;
      }
      dispatch(
        PopoutNotification(
          NOTIFICATION_TYPE.SUCCESS,
          message,
          Date.now().toString()
        )
      );
    } catch (error) {
      setPageLoading(false);
      dispatch(
        PopoutNotification(
          NOTIFICATION_TYPE.ERROR,
          typeof error === "string"
            ? error
            : `OS&D investigation notes submit failed`,
          Date.now().toString()
        )
      );
    }
  };

  /**
   * Handles saving or submitting the OS&D investigation notes based on the provided action.
   *
   */
  const saveOrSubmit = async (action: EFormAction) => {
    setIsCompleting(false);

    if (await shouldAbortDueToErrors(action)) return;
    if (shouldAbortDueToInvalidFields()) return;
    if (focusOnRequiredField()) return;

    let reqParams = createRequestParams();
    reqParams = _.omitBy(reqParams, _.isUndefined);

    if (_.isEmpty(reqParams)) return;

    // Trim all of string variables
    reqParams = TrimStrings(reqParams);
    setPageLoading(true);

    submitRequest(reqParams, action);
  };

  const shouldAbortDueToErrors = async (action: EFormAction) => {
    // Ensure the form is fully validated before proceeding
    const errorList = await form.validateForm();
    const requiredSteps =
      StepsRequired[lastStatusNumber as keyof typeof StepsRequired] || [];

    // Check if the action is either 'submit' or 'save' (validate the save form if the Investigation Day is already completed) with valid conditions

    const isValidAction =
      action === EFormAction.submit ||
      (action === EFormAction.save && requiredSteps.includes(activeStep));

    // If the action is valid and there are errors, abort submission
    if (isValidAction && Object.keys(errorList).length > 0) {
      setIsCompleting(true);
      // Focus on the first error field if there are validation errors
      const firstErrorField = Object.keys(errors)[0];
      document.getElementsByName(firstErrorField)[0]?.focus();
      return true; // Abort due to validation errors
    }

    // If there are no errors, proceed with submission
    return false;
  };

  const focusOnRequiredField = () => {
    if (activeStep === StepStatusMap[6]) {
      const isAllSameDayProValid = values?.callSameDaysDataset?.every(
        (item, index) => {
          if (isNaN(+(item?.sameDayPro ?? ""))) {
            // Focus on the invalid field
            const field = document.getElementsByName(
              `callSameDaysDataset[${index}].sameDayPro`
            )[0];
            field?.focus();
            return false;
          }
          return true;
        }
      );

      if (!isAllSameDayProValid) {
        return true; // Prevent save
      }
    }
    if (activeStep === StepStatusMap[4] && values.pumManifest === 0) {
      document.getElementsByName("pumManifest")[0].focus();
      return true;
    }
    return false;
  };

  const shouldAbortDueToInvalidFields = () => {
    return invalidFields?.length > 0;
  };

  const createRequestParams = () => {
    let reqParams = JSON.parse(JSON.stringify(values));
    const deleteFields: string[] = [];

    // Filter based on active step
    const dayFields = [
      DSDay1Fields,
      DSDay2Fields,
      DSDay3Fields,
      DSDay4Fields,
      DSDay5Fields,
      DSDay10Fields,
      DSDay15Fields,
      DSDay20Fields,
    ];
    if (activeStep >= 0 && activeStep < dayFields.length) {
      reqParams = Object.fromEntries(
        Object.entries(reqParams).filter(([key]) =>
          dayFields[activeStep].includes(key)
        )
      );
    }

    handleConditionalFields(reqParams, deleteFields);
    deleteFields.forEach((field) => delete reqParams[field]);

    formatRequestParams(reqParams);

    const { freightTerminalLeadInquiries, callSameDaysDataset, ...data } =
      reqParams;

    reqParams = {
      data,
      ...(callSameDaysDataset ? { callSameDaysDataset } : {}),
      ...(freightTerminalLeadInquiries ? { freightTerminalLeadInquiries } : {}),
      ...(showSendAPB() ? { attachmentImageUrls: selectedImages } : {}),
    };

    return reqParams;
  };

  const handleConditionalFields = (reqParams: any, deleteFields: string[]) => {

    if (!reqParams["contactTerminals"]) {
      deleteFields.push("contactTerminals", "freightTerminalLeadInquiries");
    } else if (reqParams["contactTerminals"] === "N") {
      deleteFields.push("freightTerminalLeadInquiries");
    }
    
    if (!reqParams["callSameDays"]) {
      deleteFields.push("callSameDays", "callSameDaysDataset");
    } else if (reqParams["callSameDays"] === "N") {
      deleteFields.push("callSameDaysDataset");
    }
  };

  const formatRequestParams = (reqParams: any) => {
    // Convert specific fields

    // Convert specific fields to the correct types

    if (!reqParams.callSameDaysDataset) {
      delete reqParams.callSameDaysDataset;
    } else {
      reqParams.callSameDaysDataset = (reqParams.callSameDaysDataset || []).map(
        (obj: ICallSameDaysDataset) => ({
          sameDayPro: obj.sameDayPro === 0 ? "" : obj.sameDayPro?.toString(),
          deliveryInfo: obj.deliveryInfo === null ? "" : obj.deliveryInfo,
        })
      );
    }
    if (reqParams.consigneePhone) {
      reqParams.consigneePhone = reqParams.consigneePhone.toString();
    }

    if (reqParams.shipperPhone) {
      reqParams.shipperPhone = reqParams.shipperPhone.toString();
    }

    // Convert `pumManifest` to an integer if it exists and is a valid number
    if (reqParams.pumManifest && !isNaN(reqParams.pumManifest)) {
      reqParams.pumManifest = parseInt(reqParams.pumManifest);
    } else {
      delete reqParams.pumManifest;
    }
  };

  const submitRequest = (reqParams: any, action: EFormAction) => {
    ShortageInvestigationCreateUpdateAPI(
      reqParams,
      investigationDetails?.osdNumber?.toString(),
      action,
      activeDay.toString()
    )
      .then((res) => handleSuccessfulSubmission(res, action))
      .catch((error) => handleSubmissionError(error, action));
  };

  const handleSuccessfulSubmission = (res: any, action: EFormAction) => {
    if (action === EFormAction.save) setFormFieldsDisabled(true);
    setIsCompleting(false);
    processRequestFields();
    displaySuccessMessage(action);

    handleStatusAndUIUpdates(res);

    setPageLoading(false);
    validateForm(values);
    dispatch(isFormValueChanged(false));
  };

  const handleSubmissionError = (err: any, action: EFormAction) => {
    setPageLoading(false);
    dispatch(
      PopoutNotification(
        NOTIFICATION_TYPE.ERROR,
        typeof err === "string"
          ? err
          : `OS&D investigation notes ${action} failed`,
        Date.now().toString()
      )
    );
  };

  const processRequestFields = () => {
    const reqParams = TrimStrings(values);
    reqParams.freightTerminalLeadInquiries = (
      reqParams.freightTerminalLeadInquiries || []
    ).map((obj: IDSFreightTerminalLeadInquiries) => ({
      terminal: obj.terminal === null ? "" : obj.terminal,
      talkedTo: obj.talkedTo === null ? "" : obj.talkedTo,
      updates: obj.updates === null ? "" : obj.updates,
    }));

    reqParams.callSameDaysDataset = (reqParams.callSameDaysDataset || []).map(
      (obj: ICallSameDaysDataset) => ({
        sameDayPro:
          obj.sameDayPro === null || obj.sameDayPro === "0"
            ? ""
            : obj.sameDayPro,
        deliveryInfo: obj.deliveryInfo === null ? "" : obj.deliveryInfo,
      })
    );

    Object.keys(reqParams).forEach((field: string) => {
      if (
        [
          ...DSDay1Fields,
          ...DSDay2Fields,
          ...DSDay3Fields,
          ...DSDay4Fields,
          ...DSDay5Fields,
          ...DSDay10Fields,
          ...DSDay15Fields,
          ...DSDay20Fields,
        ].includes(field)
      ) {
        const value =
          field === "pumManifest" && reqParams[field] === 0
            ? undefined
            : reqParams[field];
        setFieldValue(field, value);
        updateInitValue(field, value);
      }
    });
  };

  const displaySuccessMessage = (action: EFormAction) => {
    const message =
      action === EFormAction.save
        ? ConstantMessage.investigationNoteSaved
        : `OS&D investigation day ${activeDay} notes have been completed successfully`;

    dispatch(
      PopoutNotification(
        NOTIFICATION_TYPE.SUCCESS,
        message,
        Date.now().toString()
      )
    );
  };

  const handleStatusAndUIUpdates = (res: any) => {
    const statusNumber = res.status;
    if (
      status === statusNumber ||
      statusNumber > EShortageInvestigationStatus.day20InProgress ||
      (status === EShortageInvestigationStatus.exceptionEntry &&
        statusNumber === EShortageInvestigationStatus.day1InProgress)
    ) {
      setFormFieldsDisabled(true);
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
    setStatus(statusNumber);
    setIsFreightLocated(res.freightLocated);
    setInvestigationStatus(statusNumber);
    setLastStatusNumber(res.investigationDoneTillTheDay);
    if (!res?.freightLocated) {
      setActiveStep(StepStatusMap[statusNumber as keyof typeof StepStatusMap]);
    } else if (res.freightLocated) {
      setLastStatusNumber(res.investigationDoneTillTheDay);
      setActiveStep(
        FrightLocatedStepMap[
          res.investigationDoneTillTheDay as keyof typeof FrightLocatedStepMap
        ]
      );
    }
  };

  /**
   * Determines the CSS class for a step based on its index, current status,
   * and whether freight is located.
   *
   * @param index - The index of the step to determine the class for.
   * @returns - The CSS class name for the step.
   */
  const stepClass = useCallback(
    (index: number) => {
      const stapStatus = isFreightLocated
        ? FrightLocatedStepMap[
            lastStatusNumber as keyof typeof FrightLocatedStepMap
          ]
        : StepStatusMap[status as keyof typeof StepStatusMap];
      if (isFreightLocated && stapStatus >= index) {
        return "completeStep";
      }
      if (
        (index === activeStep && stapStatus > activeStep) ||
        (stapStatus > index) ||
        (!isFreightLocated &&
        (status === EShortageInvestigationStatus.pendingSrApproval ||
        status === EShortageInvestigationStatus.complete))
      ) {
        return "completeStep";
      } else if (index === activeStep || stapStatus === index) {
        return "activeStep";
      }
      return "";
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFreightLocated, lastStatusNumber, status, activeStep, StepStatusMap]
  );

  /**
   * Returns the day count based on the step index.
   */
  const dayCount = (step: number): number => {
    const val = step + 1;

    if (val >= 7) {
      return val === 7 ? 15 : 20;
    }

    return val === 6 ? 10 : val;
  };

  /**
   * Validates the form on changes to `values` and
   * updates the add button state based on validation results.
   */
  useEffect(() => {
    if (!values) return; // Skip validation if no values

    const debouncedValidation = debounce(async () => {
      try {
        const formErrors = await validateForm();
        const freightTerminalLeadInquiriesErrors =
          formErrors.freightTerminalLeadInquiries;

        if (!freightTerminalLeadInquiriesErrors?.length) {
          setAddButtonDisabled(false);
        } else {
          validateDSDay5Fields();
          setAddButtonDisabled(true);
        }
      } catch (error) {
        setAddButtonDisabled(true);
      }
    }, 300);

    debouncedValidation();

    return () => debouncedValidation.cancel();
  }, [values, validateForm, validateDSDay5Fields]);

  useEffect(() => {
    if (!values) return; // Skip validation if no values

    const debouncedValidation = debounce(async () => {
      try {
        const formErrors = await validateForm();
        const proDeliveryInfoErrors = formErrors.callSameDaysDataset;

        if (!proDeliveryInfoErrors?.length) {
          setProInfoAddButtonDisabled(false);
        } else {
          validateCallSameDaysDataset();
          setProInfoAddButtonDisabled(true);
        }
      } catch (error) {
        setProInfoAddButtonDisabled(true);
      }
    }, 300);

    debouncedValidation();

    return () => debouncedValidation.cancel();
  }, [values, validateForm, validateCallSameDaysDataset]);

  useEffect(() => {
    if (!investigationDetails) return;

    const { investigationFreightLocated, investigationDoneTillTheDay } =
      investigationDetails;
    setIsFreightLocated(investigationFreightLocated);
    setLastStatusNumber(investigationDoneTillTheDay ?? 1);

    // Prepare the list of all fields to iterate
    const allFields = [
      ...DSDay1Fields,
      ...DSDay2Fields,
      ...DSDay3Fields,
      ...DSDay4Fields,
      ...DSDay10Fields,
      ...DSDay15Fields,
      ...DSDay20Fields,
      ...DSDay5Fields,
    ];

    if (
      (!investigationDetails?.freightTerminalLeadInquiries ||
        investigationDetails?.freightTerminalLeadInquiries?.length < 1) &&
      investigationDetails?.contactTerminals === "Y"
    ) {
      investigationDetails.freightTerminalLeadInquiries = [
        { terminal: undefined, talkedTo: undefined, updates: undefined },
      ];
    }

    if (
      (!investigationDetails?.callSameDaysDataset ||
        investigationDetails?.callSameDaysDataset?.length < 1) &&
      investigationDetails?.callSameDays === "Y"
    ) {
      investigationDetails.callSameDaysDataset = [
        { sameDayPro: undefined, deliveryInfo: undefined },
      ];
    }
    allFields.forEach((key) => {
      const value =
        key === "pumManifest" && investigationDetails[key] === 0
          ? undefined
          : investigationDetails[key];

      setFieldValue(key, value);
      updateInitValue(key, value);
    });

    investigationDetails?.freightTerminalLeadInquiries?.forEach(
      (obj: IDSFreightTerminalLeadInquiries, index: number) => {
        setFieldValue(
          `freightTerminalLeadInquiries[${index}].terminal`,
          obj.terminal
        );
        setFieldValue(
          `freightTerminalLeadInquiries[${index}].talkedTo`,
          obj.talkedTo
        );
        setFieldValue(
          `freightTerminalLeadInquiries[${index}].updates`,
          obj.updates
        );
      }
    );

    investigationDetails?.callSameDaysDataset?.forEach(
      (obj: ICallSameDaysDataset, index: number) => {
        setFieldValue(
          `callSameDaysDataset[${index}].sameDayPro`,
          obj.sameDayPro
        );
        setFieldValue(
          `callSameDaysDataset[${index}].deliveryInfo`,
          obj.deliveryInfo
        );
      }
    );

    if (investigationDetails.investigationStatus) {
      const stepMap =
        isFreightLocated || investigationDetails?.investigationFreightLocated
          ? FrightLocatedStepMap
          : StepStatusMap;

      const stepKey =
        isFreightLocated || investigationDetails?.investigationFreightLocated
          ? investigationDetails?.investigationDoneTillTheDay
          : investigationDetails.investigationStatus;

      setActiveStep(stepMap[stepKey as keyof typeof stepMap]);
      setStatus(investigationDetails.investigationStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investigationDetails]);

  useEffect(() => {
    dispatch(isFormValueChanged(!CompareObjects(initValues, values)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, initValues]);

  // useEffect for epImagingResponse validation
  useEffect(() => {
    // Check if the epImaging value is 'Y' or 'N' and perform the validation
    if (values.epImaging === "Y" || values.epImaging === "N") {
      isValueExist("epImagingResponse"); // Validate epImagingResponse based on epImaging value
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.epImaging]);

  useEffect(() => {
    // Check if the drCheck value is 'Y'
    if (values.drCheck === "Y") {
      isValidDependedField("confirm", "drCheck", StepStatusMap[4]); // Validate confirm based on confirm value
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.drCheck]);

  useEffect(() => {
    // Check if the confirm value is 'Y'
    if (values.confirm === "Y") {
      isValidDependedField("confirmSteps", "confirm", StepStatusMap[4]); // Validate confirmSteps based on confirm value
    }
    isValidDependedField("confirm", "drCheck", StepStatusMap[4]); // Validate confirm based on confirm value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.confirm]);

  useEffect(() => {
    const adjustedStatus = isFreightLocated
    ? FrightLocatedStepMap[
        lastStatusNumber as keyof typeof FrightLocatedStepMap
      ]
    : StepStatusMap[status as keyof typeof StepStatusMap];
    // Determine the condition to display the complete button
    const shouldDisplayCompleteBtn = !(
      isFreightLocated ||
      adjustedStatus > activeStep ||
      status === EShortageInvestigationStatus.pendingSrApproval ||
      (!isFreightLocated && status === EShortageInvestigationStatus.complete)
    );

    setShouldShowDayCompleteButton(shouldDisplayCompleteBtn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFreightLocated, lastStatusNumber, status, activeStep, StepStatusMap]);

  return (
    <div id="main-ds-investigation">
      <AccordionDetails className="accordion-container">
        <Grid item md={12} xs={12}>
          <FormControl className="custom-group">
            <FormLabel>
              Investigation Day&nbsp;
              {dayCount(activeStep)}
              <sup>*</sup>{" "}
            </FormLabel>
          </FormControl>
        </Grid>
        <ThemeProvider theme={theme}>
          <Stepper nonLinear activeStep={activeStep}>
            {ASInvestigationWizardStep.map((label, index) => (
              <Step key={label} className={stepClass(index)}>
                <StepButton
                  onClick={index === activeStep ? void 0 : handleStep(index)}
                  disableRipple
                >
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </ThemeProvider>
        <FormikProvider key={resetTimestamps} value={form}>
          <>
            {activeStep === StepStatusMap[1] && (
              <div>
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.statusCorrect === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "statusCorrect",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "statusResponse",
                                  e.target.checked ? values.statusResponse : "",
                                  true
                                );
                              }}
                              disabled={
                                formFieldsDisabled ||
                                isControlDisabled("statusCorrect")
                              }
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />{" "}
                        Confirm the delivery status is correct <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.statusCorrect === "Y" && (
                            <TextField  autoComplete="off"
                              name="statusResponse"
                              value={values.statusResponse ?? ""}
                              fullWidth
                              placeholder="If incorrect what changes were made"
                              error={!!errors.statusResponse && isCompleting}
                              inputProps={{
                                maxLength: MAX_LENGTH_SUMMARY_TEXT,
                              }}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              variant="outlined"
                            />
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                    {getErrorMessage("statusCorrect")}
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.deliveryCode === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "deliveryCode",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "deliveryCodeResponse",
                                  e.target.checked
                                    ? initValues.deliveryCodeResponse
                                    : "",
                                  true
                                );
                              }}
                              disabled={
                                formFieldsDisabled ||
                                isControlDisabled("deliveryCode")
                              }
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />{" "}
                        Confirm the delivery code matches the ADE and populated
                        Correctly in the F7 <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.deliveryCode === "Y" && (
                            <TextField  autoComplete="off"
                              name="deliveryCodeResponse"
                              value={values.deliveryCodeResponse ?? ""}
                              fullWidth
                              placeholder="If Incorrect What Changes Were Made"
                              error={
                                !!errors.deliveryCodeResponse && isCompleting
                              }
                              inputProps={{
                                maxLength: MAX_LENGTH_SUMMARY_TEXT,
                              }}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              variant="outlined"
                            />
                          )}
                        </Grid>
                      </Grid>
                      {getErrorMessage("deliveryCode")}
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id="destinationTerminalLabel">
                        Are there EP’s in imaging? <sup>*</sup> &nbsp;
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          <RadioGroup
                            row
                            aria-labelledby="epImaging"
                            name="epImaging"
                            className="textarea-radio-group"
                          >
                            <FormControlLabel
                              value="Y"
                              control={<Radio />}
                              label="Yes"
                              name="epImaging"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                isValueExist("epImagingResponse");
                                setFieldValue(
                                  "epImagingResponse",
                                  initValues["epImaging"] === "Y"
                                    ? initValues["epImagingResponse"]
                                    : "",
                                  true
                                );
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.epImaging === "Y"}
                            />
                            <FormControlLabel
                              value="N"
                              control={<Radio />}
                              label="No"
                              name="epImaging"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                isValueExist("epImagingResponse");
                                setFieldValue(
                                  "epImagingResponse",
                                  initValues["epImaging"] === "N"
                                    ? initValues["epImagingResponse"]
                                    : "",
                                  true
                                );
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.epImaging === "N"}
                            />
                          </RadioGroup>
                          {["Y", "N"].includes(values.epImaging ?? "") && (
                            <FormControl fullWidth size="small">
                              <FormLabel
                                id={
                                  values.epImaging === "Y"
                                    ? "epValid"
                                    : "bpFreight"
                                }
                                className="mb-10"
                              >
                                {values.epImaging === "Y"
                                  ? "Confirm the EP’s are valid"
                                  : "Are there BP’s that show the freight"}
                                <sup>&nbsp;</sup>
                              </FormLabel>
                              <TextField  autoComplete="off"
                                value={values.epImagingResponse ?? ""}
                                key={values.epImaging}
                                name="epImagingResponse"
                                placeholder="Describe Steps Taken"
                                variant="outlined"
                                error={
                                  !!errors.epImagingResponse && isCompleting
                                }
                                inputProps={{
                                  maxLength: MAX_LENGTH_SUMMARY_TEXT,
                                }}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValueExist("epImagingResponse");
                                }}
                                disabled={formFieldsDisabled}
                              />
                            </FormControl>
                          )}
                          {(values.epImaging === "Y" ||
                            values.epImaging === "N") &&
                            getErrorMessage("epImagingResponse")}
                          {getErrorMessage("epImaging")}

                          {invalidFields.includes("epImagingResponse") && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day1}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>
                  {/* F18 Notes */}
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel style={selectStyle} shrink={true}>
                        F18 Notes <sup></sup>
                      </InputLabel>
                      <TextField  autoComplete="off"
                        multiline
                        rows={2}
                        name="day1F18Note"
                        value={values.day1F18Note}
                        onChange={handleChange}
                        placeholder="Enter F18 Notes"
                        fullWidth
                        inputProps={{ maxLength: 300 }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            )}

            {activeStep === StepStatusMap[3] && (
              <div>
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.noMovement === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "noMovement",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "dateLastMovement",
                                  e.target.checked
                                    ? initValues.dateLastMovement
                                    : "",
                                  true
                                );
                                setFieldValue(
                                  "noMovementSteps",
                                  e.target.checked
                                    ? initValues.noMovementSteps
                                    : "",
                                  true
                                );
                              }}
                              disabled={
                                formFieldsDisabled ||
                                isControlDisabled("noMovement")
                              }
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />
                        Confirm the master shows no movement ? <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={4} xs={12} className="all-short-entry">
                          {values.noMovement === "Y" && (
                            <FormLabel
                              id="destinationTerminal"
                              className="date-label-margin"
                            >
                              Select date of last movement <sup>*</sup>
                            </FormLabel>
                          )}
                          {values.noMovement === "Y" && (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                disableFuture
                                value={dayjs(values.dateLastMovement ?? null)}
                                name="dateLastMovement"
                                key={dateKey}
                                open={open}
                                onOpen={() => setOpen(true)}
                                onClose={() => setOpen(false)}
                                onChange={(value) => {
                                  if (
                                    value?.format("MM/DD/YY") !== "Invalid Date"
                                  ) {
                                    setFieldValue(
                                      "dateLastMovement",
                                      value?.format("MM/DD/YY"),
                                      true
                                    );
                                    setOpen(false);
                                  } else {
                                    setDateKey(Date.now().toString());
                                  }
                                }}
                                format="MM/DD/YYYY"
                                slotProps={{
                                  textField: {
                                    error: !!errors.dateLastMovement && isCompleting,
                                    onBlur: handleBlur,
                                    onClick: () => {
                                      setOpen(true);
                                    },
                                    inputProps: {
                                      readOnly: true,
                                    },
                                  },
                                }}
                                disabled={formFieldsDisabled}
                                className="col-md-12"
                              />
                            </LocalizationProvider>
                          )}
                          {getErrorMessage("noMovement")}
                          {getErrorMessage("dateLastMovement")}
                        </Grid>
                        <Grid item md={8} xs={12} className="all-short-entry">
                          {values.noMovement === "Y" && (
                            <FormLabel
                              id="noMovementSteps"
                              className="date-label-margin"
                            >
                              &nbsp;
                            </FormLabel>
                          )}
                          {values.noMovement === "Y" && (
                            <TextField  autoComplete="off"
                              name="noMovementSteps"
                              value={values.noMovementSteps ?? ""}
                              fullWidth
                              placeholder="Describe Steps Taken"
                              error={!!errors.noMovementSteps && isCompleting}
                              inputProps={{
                                maxLength: MAX_LENGTH_SUMMARY_TEXT,
                              }}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              variant="outlined"
                            />
                          )}
                          {getErrorMessage("noMovementSteps")}
                          {invalidFields.includes(
                            EDSInvestigationFields.NoMovementSteps
                          ) && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day2}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.suffixBill === "Y"}
                              disabled={
                                formFieldsDisabled ||
                                isControlDisabled("suffixBill")
                              }
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "suffixBill",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "suffixBillSteps",
                                  e.target.checked
                                    ? initValues.suffixBillSteps
                                    : "",
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />{" "}
                        Check if a suffix bill or OD400 was cut to move some or
                        all of the freight <sup>*</sup>
                        <TooltipWithClickAway
                          tooltipText="If yes, view the AS exception & confirm it was cleared properly. If no, move to next step."
                          tooltipKey="tooltip2"
                          openTooltips={openTooltips}
                          handleTooltipOpen={handleTooltipOpen}
                          handleTooltipClose={handleTooltipClose}
                        />
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.suffixBill === "Y" && (
                            <TextField  autoComplete="off"
                              name="suffixBillSteps"
                              value={values.suffixBillSteps ?? ""}
                              fullWidth
                              placeholder="Describe Steps Taken"
                              error={!!errors.suffixBillSteps && isCompleting}
                              inputProps={{
                                maxLength: MAX_LENGTH_SUMMARY_TEXT,
                              }}
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                isValueExist(
                                  EDSInvestigationFields.SuffixBillSteps
                                );
                              }}
                              disabled={formFieldsDisabled}
                              variant="outlined"
                            />
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                    {getErrorMessage("suffixBill")}
                    {getErrorMessage("suffixBillSteps")}
                    {invalidFields.includes(
                      EDSInvestigationFields.SuffixBillSteps
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day2}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id="destinationTerminal">
                        Did the driver spot the trailer somewhere? <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          <RadioGroup
                            row
                            aria-labelledby="spotTrailer"
                            name="spotTrailer"
                            className="textarea-radio-group"
                          >
                            <FormControlLabel
                              value="Y"
                              control={<Radio />}
                              label="Yes"
                              name="spotTrailer"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue(
                                  "freightLeft",
                                  initValues["freightLeft"],
                                  true
                                );
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.spotTrailer === "Y"}
                            />
                            <FormControlLabel
                              value="N"
                              control={<Radio />}
                              label="No"
                              name="spotTrailer"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue("freightLeft", "", true);
                                setInvalidFields([
                                  ...invalidFields.filter(
                                    (field) => field !== "freightLeft"
                                  ),
                                ]);
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.spotTrailer === "N"}
                            />
                          </RadioGroup>

                          {values.spotTrailer === "Y" && (
                            <>
                              <FormLabel id="destinationTerminal">
                                Was freight left on board? <sup>&nbsp;</sup>
                              </FormLabel>
                              <TextField  autoComplete="off"
                                id="freightLeft"
                                name="freightLeft"
                                fullWidth
                                aria-placeholder="Describe Steps Taken"
                                value={values.freightLeft ?? ""}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValidDependedField(
                                    "freightLeft",
                                    "spotTrailer",
                                    StepStatusMap[3]
                                  );
                                }}
                                disabled={formFieldsDisabled}
                                className="mt-1"
                                placeholder="Describe Steps Taken"
                                error={!!errors.freightLeft && isCompleting}
                                inputProps={{
                                  maxLength: MAX_LENGTH_SUMMARY_TEXT,
                                }}
                                variant="outlined"
                              />
                            </>
                          )}
                          {getErrorMessage("spotTrailer")}
                          {getErrorMessage("freightLeft")}
                          {invalidFields.includes("freightLeft") && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day2}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>
                  {/* F18 Notes */}
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel style={selectStyle} shrink={true}>
                        F18 Notes <sup></sup>
                      </InputLabel>
                      <TextField  autoComplete="off"
                        multiline
                        rows={2}
                        name="day2F18Note"
                        value={values.day2F18Note}
                        onChange={handleChange}
                        placeholder="Enter F18 Notes"
                        fullWidth
                        inputProps={{ maxLength: 300 }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            )}

            {activeStep === StepStatusMap[4] && (
              <div>
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled || isControlDisabled("bolCheck")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.bolCheck === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "bolCheck",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "bolSteps",
                                  e.target.checked ? initValues.bolSteps : "",
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />
                        Check the BOL to confirm completion and check for
                        discrepancies <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={6} xs={12} className="all-short-entry">
                          {values.bolCheck === "Y" && (
                            <>
                              <FormLabel id="bolSteps" className="mt-0 mb-1">
                                Use info on BOL to look for duplicate
                                pros/billing error <sup>*</sup> &nbsp;
                                <TooltipWithClickAway
                                  tooltipText="Option 5 is a good start to look for duplicate pros, but not the only option."
                                  tooltipKey="tooltip3"
                                  openTooltips={openTooltips}
                                  handleTooltipOpen={handleTooltipOpen}
                                  handleTooltipClose={handleTooltipClose}
                                />
                              </FormLabel>
                              <TextField  autoComplete="off"
                                name="bolSteps"
                                value={values.bolSteps ?? ""}
                                fullWidth
                                placeholder="Describe Steps taken"
                                error={!!errors.bolSteps && isCompleting}
                                inputProps={{
                                  maxLength: MAX_LENGTH_SUMMARY_TEXT,
                                }}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValueExist("bolSteps");
                                }}
                                disabled={formFieldsDisabled}
                                variant="outlined"
                              />
                            </>
                          )}
                          {getErrorMessage("bolCheck")}
                          {getErrorMessage("bolSteps")}
                          {invalidFields.includes("bolSteps") && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day3}
                            </span>
                          )}
                        </Grid>
                        <Grid item md={6} xs={12} className="all-short-entry">
                          {values.bolCheck === "Y" && (
                            <>
                              <FormLabel
                                id="bolDiscrepancies"
                                className="mt-0 mb-1"
                              >
                                List any and all discrepancies <sup>*</sup>{" "}
                                &nbsp;
                              </FormLabel>
                              <TextField  autoComplete="off"
                                name="bolDiscrepancies"
                                value={values.bolDiscrepancies ?? ""}
                                fullWidth
                                placeholder="Describe Steps taken"
                                error={
                                  !!errors.bolDiscrepancies && isCompleting
                                }
                                inputProps={{
                                  maxLength: MAX_LENGTH_SUMMARY_TEXT,
                                }}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValueExist("bolDiscrepancies");
                                }}
                                disabled={formFieldsDisabled}
                                variant="outlined"
                              />
                            </>
                          )}
                          {getErrorMessage("bolDiscrepancies")}
                          {invalidFields.includes("bolDiscrepancies") && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day3}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id="drCheck">
                        Is there is a DR? <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          <RadioGroup
                            row
                            aria-labelledby="drCheck"
                            name="drCheck"
                            className="textarea-radio-group"
                          >
                            <FormControlLabel
                              value="Y"
                              control={<Radio />}
                              label="Yes"
                              name="drCheck"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                isValidDependedField(
                                  "confirm",
                                  "drCheck",
                                  StepStatusMap[4]
                                );
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.drCheck === "Y"}
                            />
                            <FormControlLabel
                              value="N"
                              control={<Radio />}
                              label="No"
                              name="drCheck"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue("confirm", "", true);
                                setFieldValue("confirmSteps", "", true);
                                setInvalidFields([
                                  ...invalidFields.filter(
                                    (field) =>
                                      field !== "confirmSteps" &&
                                      field !== "confirm"
                                  ),
                                ]);
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.drCheck === "N"}
                            />
                          </RadioGroup>
                          {getErrorMessage("drCheck")}
                          {values.drCheck === "Y" && (
                            <FormControl className="radio-group question-group ">
                              <FormLabel>
                                <FormControlLabel
                                  className="checkbox-shoartage"
                                  control={
                                    <Checkbox
                                      disableRipple
                                      checked={values.confirm === "Y"}
                                      onBlur={() => {
                                        isValidDependedField(
                                          "confirmSteps",
                                          "confirm",
                                          StepStatusMap[4]
                                        );
                                        isValidDependedField(
                                          "confirm",
                                          "drCheck",
                                          StepStatusMap[4]
                                        );
                                      }}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        setFieldValue(
                                          "confirm",
                                          e.target.checked ? "Y" : "N"
                                        );

                                        setFieldValue(
                                          "confirmSteps",
                                          e.target.checked
                                            ? initValues.confirmSteps
                                            : "",
                                          true
                                        );
                                        isValidDependedField(
                                          "confirm",
                                          "drCheck",
                                          StepStatusMap[4]
                                        );
                                        isValidDependedField(
                                          "confirmSteps",
                                          "confirm",
                                          StepStatusMap[4]
                                        );
                                      }}
                                      disabled={
                                        formFieldsDisabled ||
                                        isControlDisabled("confirm")
                                      }
                                      sx={{
                                        "& .MuiSvgIcon-root": { fontSize: 20 },
                                      }}
                                    />
                                  }
                                  label=""
                                />
                                Confirm the master shows no movement ?{" "}
                                <sup>*</sup>
                                {invalidFields.includes("confirm") && (
                                  <>
                                    <br />
                                    <span className="form-error">
                                      {EASInvestigationFieldsErrorMessage.day3}
                                    </span>
                                  </>
                                )}
                              </FormLabel>
                              <Grid container spacing={2}>
                                <Grid
                                  item
                                  md={12}
                                  xs={12}
                                  className="all-short-entry"
                                >
                                  {values.confirm === "Y" && (
                                    <TextField  autoComplete="off"
                                      name="confirmSteps"
                                      value={values.confirmSteps ?? ""}
                                      fullWidth
                                      placeholder="Describe Steps Taken"
                                      error={
                                        !!errors.confirmSteps && isCompleting
                                      }
                                      inputProps={{
                                        maxLength: MAX_LENGTH_SUMMARY_TEXT,
                                      }}
                                      onChange={handleChange}
                                      onBlur={(e) => {
                                        handleBlur(e);
                                        isValidDependedField(
                                          "confirmSteps",
                                          "confirm",
                                          StepStatusMap[4]
                                        );
                                      }}
                                      disabled={formFieldsDisabled}
                                      variant="outlined"
                                    />
                                  )}
                                  {getErrorMessage("confirm")}
                                  {getErrorMessage("confirmSteps")}
                                  {invalidFields.includes("confirmSteps") &&
                                    values.confirm === "Y" && (
                                      <span className="form-error">
                                        {
                                          EASInvestigationFieldsErrorMessage.day3
                                        }
                                      </span>
                                    )}
                                </Grid>
                              </Grid>
                            </FormControl>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled || isControlDisabled("pumCheck")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.pumCheck === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "pumCheck",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "pumManifest",
                                  e.target.checked
                                    ? initValues.pumManifest
                                    : "",
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />
                        Check the PUM (Pickup manifest) for discrepancies,
                        shipper load and count information, and proper reporting
                        process <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={4} xs={12} className="all-short-entry">
                          {values.pumCheck === "Y" && (
                            <>
                              <FormLabel
                                id="destinationTerminal"
                                className="mt-0 mb-1"
                              >
                                Pickup Manifest # <sup>*</sup>
                              </FormLabel>
                              <TextField  autoComplete="off"
                                name="pumManifest"
                                value={
                                  values.pumManifest
                                    ? `${values.pumManifest}`
                                    : ""
                                }
                                fullWidth
                                placeholder="Enter Pickup Manifest #"
                                type="number"
                                onPaste={HandlePaste}
                                onKeyDown={HandleKeyDown}
                                onInput={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  HandleNumberMaxLength(
                                    e,
                                    MAX_LENGTH_MANIFEST_NUMBER
                                  );
                                }}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValueExist("pumManifest");
                                }}
                                error={
                                  values.pumManifest === 0 ||
                                  (!!errors.pumManifest && isCompleting)
                                }
                                disabled={formFieldsDisabled}
                                variant="outlined"
                              />
                            </>
                          )}
                          {getErrorMessage("pumCheck")}
                          {getErrorMessage("pumManifest")}
                          {invalidFields.includes("pumManifest") && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day3}
                            </span>
                          )}
                          {values.pumManifest === 0 &&
                            values.pumCheck === "Y" && (
                              <span className="form-error">
                                Invalid pickup manifest# number
                              </span>
                            )}
                        </Grid>
                        <Grid item md={8} xs={12} className="all-short-entry">
                          {values.pumCheck === "Y" && (
                            <>
                              <FormLabel
                                id="destinationTerminal"
                                className="mt-0 mb-1"
                              >
                                List any and all discrepancies <sup>*</sup>
                              </FormLabel>
                              <TextField  autoComplete="off"
                                name="pumDiscrepancy"
                                value={values.pumDiscrepancy ?? ""}
                                fullWidth
                                placeholder="Describe Steps Taken"
                                error={!!errors.pumDiscrepancy && isCompleting}
                                inputProps={{
                                  maxLength: MAX_LENGTH_SUMMARY_TEXT,
                                }}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValueExist("pumDiscrepancy");
                                }}
                                disabled={formFieldsDisabled}
                                variant="outlined"
                              />
                            </>
                          )}
                          {getErrorMessage("pumDiscrepancy")}
                          {invalidFields.includes("pumDiscrepancy") && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day3}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id="shipperLoad">
                        Was this a shipper load and count? <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          <RadioGroup
                            row
                            aria-labelledby="shipperLoad"
                            name="shipperLoad"
                            className="textarea-radio-group"
                          >
                            <FormControlLabel
                              value="Y"
                              control={<Radio />}
                              label="Yes"
                              name="shipperLoad"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue(
                                  "shipperDiscrepancy",
                                  initValues["shipperDiscrepancy"],
                                  true
                                );
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.shipperLoad === "Y"}
                            />
                            <FormControlLabel
                              value="N"
                              control={<Radio />}
                              label="No"
                              name="shipperLoad"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue("shipperDiscrepancy", "", true);
                                setInvalidFields([
                                  ...invalidFields.filter(
                                    (field) => field !== "shipperDiscrepancy"
                                  ),
                                ]);
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.shipperLoad === "N"}
                            />
                          </RadioGroup>

                          {values.shipperLoad === "Y" && (
                            <>
                              <FormLabel
                                id="shipperDiscrepancy"
                                className="mb-1"
                              >
                                Were there any discrepancies? If so, were they
                                properly reported to the shipper?<sup>*</sup>
                              </FormLabel>
                              <TextField  autoComplete="off"
                                name="shipperDiscrepancy"
                                value={values.shipperDiscrepancy ?? ""}
                                fullWidth
                                placeholder="Describe Steps Taken"
                                error={
                                  !!errors.shipperDiscrepancy && isCompleting
                                }
                                inputProps={{
                                  maxLength: MAX_LENGTH_SUMMARY_TEXT,
                                }}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValueExist("shipperDiscrepancy");
                                }}
                                disabled={formFieldsDisabled}
                                variant="outlined"
                              />
                            </>
                          )}
                          {getErrorMessage("shipperLoad")}
                          {getErrorMessage("shipperDiscrepancy")}
                          {invalidFields.includes("shipperDiscrepancy") && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day3}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled ||
                            isControlDisabled("lineHaulManifest")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.lineHaulManifest === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "lineHaulManifest",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "numbersReview",
                                  e.target.checked
                                    ? initValues.numbersReview
                                    : "",
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />
                        Check all linehaul manifests – movement and
                        abnormalities. <sup>*</sup> &nbsp;
                        <TooltipWithClickAway
                          tooltipText="Get manifest numbers from the movement screen (F10)"
                          tooltipKey="tooltip4"
                          openTooltips={openTooltips}
                          handleTooltipOpen={handleTooltipOpen}
                          handleTooltipClose={handleTooltipClose}
                        />
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.lineHaulManifest === "Y" && (
                            <TextField  autoComplete="off"
                              name="numbersReview"
                              value={values.numbersReview ?? ""}
                              fullWidth
                              placeholder="List all Manifest number reviewed"
                              error={!!errors.numbersReview && isCompleting}
                              inputProps={{
                                maxLength: MAX_LENGTH_SUMMARY_TEXT,
                              }}
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                isValueExist("numbersReview");
                              }}
                              disabled={formFieldsDisabled}
                              variant="outlined"
                            />
                          )}
                          {getErrorMessage("lineHaulManifest")}
                          {getErrorMessage("numbersReview")}
                          {invalidFields.includes("numbersReview") && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day3}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled ||
                            isControlDisabled("interviewWorkers")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.interviewWorkers === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "interviewWorkers",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "workerSteps",
                                  e.target.checked
                                    ? initValues.workerSteps
                                    : "",
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />
                        Interview the dock worker(s) about movement
                        abnormalities. <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.interviewWorkers === "Y" && (
                            <TextField  autoComplete="off"
                              name="workerSteps"
                              value={values.workerSteps ?? ""}
                              fullWidth
                              placeholder="Describe Steps Taken"
                              error={!!errors.workerSteps && isCompleting}
                              inputProps={{
                                maxLength: MAX_LENGTH_SUMMARY_TEXT,
                              }}
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                isValueExist(
                                  EDSInvestigationFields.WorkerSteps
                                );
                              }}
                              disabled={formFieldsDisabled}
                              variant="outlined"
                            />
                          )}
                          {getErrorMessage("interviewWorkers")}
                          {getErrorMessage("workerSteps")}
                          {invalidFields.includes(
                            EDSInvestigationFields.WorkerSteps
                          ) && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day3}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <h3 className="header-text mb-0">
                      Call consignee to confirm what was ordered and received
                      (if anything)
                    </h3>
                  </Grid>

                  <Grid item md={3} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        Phone Number <sup>*</sup>
                      </FormLabel>
                      <TextField  autoComplete="off"
                        name="consigneePhone"
                        value={values.consigneePhone ?? ""}
                        fullWidth
                        error={!!errors.consigneePhone && isCompleting}
                        inputProps={{ maxLength: MAX_LENGTH_PHONE_NUMBER }}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist(EDSInvestigationFields.ConsigneePhone);
                        }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                        placeholder="Enter Phone Number"
                      />
                    </FormControl>
                    {getErrorMessage("consigneePhone")}
                    {invalidFields.includes(
                      EDSInvestigationFields.ConsigneePhone
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={3} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        Consignee Name <sup>*</sup>
                      </FormLabel>
                      <TextField  autoComplete="off"
                        name="consigneeName"
                        value={values.consigneeName ?? ""}
                        fullWidth
                        error={!!errors.consigneeName && isCompleting}
                        inputProps={{ maxLength: MAX_LENGTH_MEDIUM_ENTRY }}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist(EDSInvestigationFields.ConsigneeName);
                        }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                        placeholder="Enter Consignee Name"
                      />
                    </FormControl>
                    {getErrorMessage("consigneeName")}
                    {invalidFields.includes(
                      EDSInvestigationFields.ConsigneeName
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id="consigneeShort">
                        Is the consignee still short? <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          <RadioGroup
                            row
                            aria-labelledby="consigneeShort"
                            name="consigneeShort"
                            className="textarea-radio-group"
                          >
                            <FormControlLabel
                              value="Y"
                              control={<Radio />}
                              label="Yes"
                              name="consigneeShort"
                              // onChange={handleChange}
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue(
                                  "itemNumbers",
                                  initValues["itemNumbers"],
                                  true
                                );
                                setFieldValue(
                                  "orderReceived",
                                  initValues["orderReceived"],
                                  true
                                );
                                setFieldValue(
                                  "freightLabeled",
                                  initValues["freightLabeled"],
                                  true
                                );
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.consigneeShort === "Y"}
                            />
                            <FormControlLabel
                              value="N"
                              control={<Radio />}
                              label="No"
                              name="consigneeShort"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue("itemNumbers", "", true);
                                setFieldValue("orderReceived", "", true);
                                setFieldValue("freightLabeled", "", true);
                                setInvalidFields([
                                  ...invalidFields.filter(
                                    (field) =>
                                      ![
                                        "itemNumbers",
                                        "orderReceived",
                                        "freightLabeled",
                                      ].includes(field)
                                  ),
                                ]);
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.consigneeShort === "N"}
                            />
                          </RadioGroup>
                          {getErrorMessage("consigneeShort")}
                          {values.consigneeShort === "N" && (
                            <Snackbar
                              className="snackbar-error flex-start"
                              anchorOrigin={{ vertical, horizontal }}
                              open={true}
                              onClose={handleClose}
                              message="Note: If the consignee is no longer short, the investigation is considered complete once we obtain written confirmation and/or a signed DR."
                              key={vertical + horizontal}
                            />
                          )}

                          {values.consigneeShort === "Y" && (
                            <>
                              <FormLabel className="mb-10">
                                What are the item numbers received and still
                                missing? <sup>*</sup>
                              </FormLabel>
                              <TextField  autoComplete="off"
                                name="itemNumbers"
                                value={values.itemNumbers ?? ""}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValidDependedField(
                                    EDSInvestigationFields.ItemNumbers,
                                    EDSInvestigationFields.ConsigneeShort,
                                    StepStatusMap[4]
                                  );
                                  isValueExist(
                                    EDSInvestigationFields.ItemNumbers
                                  );
                                }}
                                disabled={formFieldsDisabled}
                                fullWidth
                                placeholder="Describe Steps Taken"
                                error={!!errors.itemNumbers && isCompleting}
                                inputProps={{
                                  maxLength: MAX_LENGTH_SUMMARY_TEXT,
                                }}
                                variant="outlined"
                              />
                              {getErrorMessage("consigneeShort")}
                              {getErrorMessage("itemNumbers")}
                              {invalidFields.includes(
                                EDSInvestigationFields.ItemNumbers
                              ) && (
                                <span className="form-error">
                                  {EASInvestigationFieldsErrorMessage.day3}
                                </span>
                              )}
                              <FormLabel className="mb-10">
                                What was ordered and received for each PO?{" "}
                                <sup>*</sup>
                              </FormLabel>
                              <TextField  autoComplete="off"
                                name="orderReceived"
                                value={values.orderReceived ?? ""}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValidDependedField(
                                    EDSInvestigationFields.OrderReceived,
                                    EDSInvestigationFields.ConsigneeShort,
                                    StepStatusMap[4]
                                  );
                                  isValueExist(
                                    EDSInvestigationFields.OrderReceived
                                  );
                                }}
                                disabled={formFieldsDisabled}
                                fullWidth
                                placeholder="Describe Steps Taken"
                                error={!!errors.orderReceived && isCompleting}
                                inputProps={{
                                  maxLength: MAX_LENGTH_SUMMARY_TEXT,
                                }}
                                variant="outlined"
                              />
                              {getErrorMessage("orderReceived")}
                              {invalidFields.includes(
                                EDSInvestigationFields.OrderReceived
                              ) && (
                                <span className="form-error">
                                  {EASInvestigationFieldsErrorMessage.day3}
                                </span>
                              )}
                              <FormLabel className="mb-10">
                                Was the freight received labeled for them?{" "}
                                <sup>*</sup>
                              </FormLabel>
                              <TextField  autoComplete="off"
                                name="freightLabeled"
                                value={values.freightLabeled ?? ""}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValidDependedField(
                                    EDSInvestigationFields.FreightLabeled,
                                    EDSInvestigationFields.ConsigneeShort,
                                    StepStatusMap[4]
                                  );
                                  isValueExist(
                                    EDSInvestigationFields.FreightLabeled
                                  );
                                }}
                                disabled={formFieldsDisabled}
                                fullWidth
                                placeholder="Describe Steps Taken"
                                error={!!errors.freightLabeled && isCompleting}
                                inputProps={{
                                  maxLength: MAX_LENGTH_SUMMARY_TEXT,
                                }}
                                variant="outlined"
                              />
                              {getErrorMessage("freightLabeled")}
                              {invalidFields.includes(
                                EDSInvestigationFields.FreightLabeled
                              ) && (
                                <span className="form-error">
                                  {EASInvestigationFieldsErrorMessage.day3}
                                </span>
                              )}
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <h3 className="header-text mb-0">
                      Call Shipper to confirm what was shipped, how it was
                      packaged and the value?
                    </h3>
                  </Grid>

                  <Grid item md={3} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        Phone Number <sup>*</sup>
                      </FormLabel>
                      <TextField  autoComplete="off"
                        name="shipperPhone"
                        value={values.shipperPhone ?? ""}
                        fullWidth
                        error={!!errors.shipperPhone && isCompleting}
                        inputProps={{ maxLength: MAX_LENGTH_PHONE_NUMBER }}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist(EDSInvestigationFields.ShipperPhone);
                        }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                        placeholder="Enter Phone Number"
                      />
                    </FormControl>
                    {getErrorMessage("shipperPhone")}
                    {invalidFields.includes(
                      EDSInvestigationFields.ShipperPhone
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={3} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        Shipper Name <sup>*</sup>
                      </FormLabel>
                      <TextField  autoComplete="off"
                        name="shipperName"
                        value={values.shipperName ?? ""}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist(EDSInvestigationFields.ShipperName);
                        }}
                        disabled={formFieldsDisabled}
                        placeholder="Enter Shipper Name"
                        error={!!errors.shipperName && isCompleting}
                        inputProps={{ maxLength: MAX_LENGTH_MEDIUM_ENTRY }}
                        variant="outlined"
                      />
                    </FormControl>
                    {getErrorMessage("shipperName")}
                    {invalidFields.includes(
                      EDSInvestigationFields.ShipperName
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        What are the details of what shipped? <sup>*</sup>
                      </FormLabel>
                      <TextField  autoComplete="off"
                        name="shippedDetails"
                        value={values.shippedDetails ?? ""}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist(EDSInvestigationFields.ShippedDetails);
                        }}
                        disabled={formFieldsDisabled}
                        fullWidth
                        placeholder="Describe Steps Taken"
                        error={!!errors.shippedDetails && isCompleting}
                        inputProps={{ maxLength: MAX_LENGTH_SUMMARY_TEXT }}
                        variant="outlined"
                      />
                    </FormControl>
                    {getErrorMessage("shippedDetails")}
                    {invalidFields.includes(
                      EDSInvestigationFields.ShippedDetails
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        Was this short shipped? <sup>*</sup>
                      </FormLabel>
                      <TextField  autoComplete="off"
                        name="shortShipped"
                        value={values.shortShipped ?? ""}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist(EDSInvestigationFields.ShortShipped);
                        }}
                        disabled={formFieldsDisabled}
                        fullWidth
                        placeholder="Describe Steps Taken"
                        error={!!errors.shortShipped && isCompleting}
                        inputProps={{ maxLength: MAX_LENGTH_SUMMARY_TEXT }}
                        variant="outlined"
                      />
                    </FormControl>
                    {getErrorMessage("shortShipped")}
                    {invalidFields.includes(
                      EDSInvestigationFields.ShortShipped
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        What is the value of the product? <sup>*</sup>
                      </FormLabel>
                      <TextField  autoComplete="off"
                        name="productValue"
                        value={values.productValue ?? ""}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist(EDSInvestigationFields.ProductValue);
                        }}
                        disabled={formFieldsDisabled}
                        fullWidth
                        placeholder="Describe Steps Taken"
                        error={!!errors.productValue && isCompleting}
                        inputProps={{ maxLength: MAX_LENGTH_SUMMARY_TEXT }}
                        variant="outlined"
                      />
                    </FormControl>
                    {getErrorMessage("productValue")}
                    {invalidFields.includes(
                      EDSInvestigationFields.ProductValue
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        Were there multiple shipments for the same consignee
                        that day? <sup>*</sup>
                      </FormLabel>
                      <TextField  autoComplete="off"
                        name="multiShipments"
                        value={values.multiShipments ?? ""}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist("multiShipments");
                        }}
                        disabled={formFieldsDisabled}
                        fullWidth
                        placeholder="Describe Steps Taken"
                        error={!!errors.multiShipments && isCompleting}
                        inputProps={{ maxLength: MAX_LENGTH_SUMMARY_TEXT }}
                        variant="outlined"
                      />
                    </FormControl>
                    {getErrorMessage("multiShipments")}
                    {invalidFields.includes("multiShipments") && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <h3 className="header-text mb-0">
                      Call driver about delivery
                    </h3>
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        Who did you talk to? <sup>*</sup>
                      </FormLabel>
                      <TextField  autoComplete="off"
                        name="callDriver"
                        value={values.callDriver ?? ""}
                        fullWidth
                        error={!!errors.callDriver && isCompleting}
                        inputProps={{ maxLength: MAX_LENGTH_SUMMARY_TEXT }}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist("callDriver");
                        }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                        placeholder="Enter Driver Name"
                      />
                    </FormControl>
                    {getErrorMessage("callDriver")}
                    {invalidFields.includes("callDriver") && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>
                  <Grid item md={12} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        What was delivered? Does it match the ADE? <sup>*</sup>
                      </FormLabel>
                      <TextField  autoComplete="off"
                        name="adeDelivery"
                        value={values.adeDelivery ?? ""}
                        fullWidth
                        error={!!errors.adeDelivery && isCompleting}
                        inputProps={{ maxLength: MAX_LENGTH_SUMMARY_TEXT }}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist("adeDelivery");
                        }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                        placeholder="Describe Steps Taken"
                      />
                    </FormControl>
                    {getErrorMessage("adeDelivery")}
                    {invalidFields.includes("adeDelivery") && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        Could the freight be in the nose? <sup>*</sup>
                      </FormLabel>
                      <TextField  autoComplete="off"
                        name="freightNose"
                        value={values.freightNose ?? ""}
                        fullWidth
                        error={!!errors.freightNose && isCompleting}
                        inputProps={{ maxLength: MAX_LENGTH_SUMMARY_TEXT }}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist(EDSInvestigationFields.FreightNose);
                        }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                        placeholder="Describe Steps Taken"
                      />
                    </FormControl>
                    {getErrorMessage("freightNose")}
                    {invalidFields.includes(
                      EDSInvestigationFields.FreightNose
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        Was there anything out of the ordinary about this
                        delivery? <sup>*</sup>
                      </FormLabel>
                      <TextField  autoComplete="off"
                        name="outOfOrdinary"
                        value={values.outOfOrdinary ?? ""}
                        fullWidth
                        error={!!errors.outOfOrdinary && isCompleting}
                        inputProps={{ maxLength: MAX_LENGTH_SUMMARY_TEXT }}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist("outOfOrdinary");
                        }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                        placeholder="Describe Steps Taken"
                      />
                    </FormControl>
                    {getErrorMessage("outOfOrdinary")}
                    {invalidFields.includes("outOfOrdinary") && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>
                  {/* F18 Notes */}
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel style={selectStyle} shrink={true}>
                        F18 Notes <sup></sup>
                      </InputLabel>
                      <TextField  autoComplete="off"
                        multiline
                        rows={2}
                        name="day3F18Note"
                        value={values.day3F18Note}
                        onChange={handleChange}
                        placeholder="Enter F18 Notes"
                        fullWidth
                        inputProps={{ maxLength: 300 }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            )}

            {activeStep === StepStatusMap[5] && (
              <div>
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled || isControlDisabled("check7818")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.check7818 === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "check7818",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "details7818",
                                  e.target.checked
                                    ? initValues.details7818
                                    : "",
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />
                        Check 78/18 and/or Power BI for any overages (OV, NB,
                        DO) to apply<sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.check7818 === "Y" && (
                            <TextField  autoComplete="off"
                              id={`details7818`}
                              name="details7818"
                              value={values.details7818 ?? ""}
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                isValueExist(
                                  EDSInvestigationFields.Details7818
                                );
                              }}
                              disabled={formFieldsDisabled}
                              fullWidth
                              className=""
                              placeholder="Provide details of your search"
                              error={!!errors.details7818 && isCompleting}
                              inputProps={{
                                maxLength: MAX_LENGTH_SUMMARY_TEXT,
                              }}
                              variant="outlined"
                            />
                          )}
                          {getErrorMessage(`check7818`)}
                          {getErrorMessage(`details7818`)}
                          {invalidFields.includes(
                            EDSInvestigationFields.Details7818
                          ) && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day4}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel className="mb-10">
                        Check cameras at DT and report findings <sup>*</sup>
                      </FormLabel>
                      <TextField  autoComplete="off"
                        name="cameraFindings"
                        value={values.cameraFindings ?? ""}
                        fullWidth
                        error={!!errors.cameraFindings && isCompleting}
                        inputProps={{ maxLength: MAX_LENGTH_SUMMARY_TEXT }}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist("cameraFindings");
                        }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                        placeholder="Describe Steps Taken"
                      />
                    </FormControl>
                    {getErrorMessage(`cameraFindings`)}
                    {invalidFields.includes("cameraFindings") && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day4}
                      </span>
                    )}
                  </Grid>
                  {/* F18 Notes */}
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel style={selectStyle} shrink={true}>
                        F18 Notes <sup></sup>
                      </InputLabel>
                      <TextField  autoComplete="off"
                        multiline
                        rows={2}
                        name="day4F18Note"
                        value={values.day4F18Note}
                        onChange={handleChange}
                        placeholder="Enter F18 Notes"
                        fullWidth
                        inputProps={{ maxLength: 300 }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            )}

            {activeStep === StepStatusMap[6] && (
              <div>
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                      <FormControlLabel
                        className="checkbox-shoartage"
                        disabled={
                          formFieldsDisabled ||
                          isControlDisabled("callSameDays")
                        }
                        control={
                          <Checkbox
                            disableRipple
                            checked={values.callSameDays === "Y"}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const checked =  e.target.checked;
                              setFieldValue(
                                "callSameDays",
                                checked ? "Y" : "N"
                              );
                              setFieldValue(
                                "callSameDaysDataset",
                                getCallSameDaysDataset(checked),
                                true
                              );
                            }}
                            sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                          />
                        }
                        label=""
                      />
                      Call same day deliveries and pickups <sup>*</sup>
                        <TooltipWithClickAway
                          tooltipText="Use F17 from FB inquiry screen for pickup info. To look up delivery manifest use Option 16 from the Operations menu"
                          tooltipKey="tooltip9"
                          openTooltips={openTooltips}
                          handleTooltipOpen={handleTooltipOpen}
                          handleTooltipClose={handleTooltipClose}
                        />
                      </FormLabel>
                      {getErrorMessage("callSameDays")}
                      {values.callSameDays === "Y" && (
                        <Grid
                          key={formKey}
                          container
                          spacing={2}
                          className="filter-margin"
                        >
                          <FieldArray name="callSameDaysDataset">
                            {({ push, remove }) => (
                              <>
                                {_.get(values, "callSameDaysDataset", []).map(
                                  (
                                    _d: ICallSameDaysDataset,
                                    index: number
                                  ) => (
                                    <React.Fragment key={index + ""}>
                                      <Grid
                                        item
                                        md={12}
                                        xs={12}
                                        className="pt-0"
                                      >
                                        <h3 className="header-text mb-0">
                                          PRO and Delivery Info: {index + 1}
                                        </h3>
                                      </Grid>

                                      <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        className="pt-0"
                                      >
                                        <FormControl className="radio-group question-group ">
                                          <FormLabel>
                                            All same Day Pro's <sup>*</sup>
                                          </FormLabel>
                                          <TextField  autoComplete="off"
                                            id={`tid-${index}`}
                                            name={`callSameDaysDataset[${index}].sameDayPro`}
                                            value={_.get(
                                              values,
                                              `callSameDaysDataset[${index}].sameDayPro`,
                                              ""
                                            )}
                                            onChange={handleChange}
                                            onBlur={(e) => {
                                              handleBlur(e);
                                              isValueExist(
                                                "sameDayPro",
                                                index,
                                                "callSameDaysDataset"
                                              );
                                            }}
                                            disabled={formFieldsDisabled}
                                            fullWidth
                                            className="mt-1"
                                            placeholder="PRO Number"
                                            variant="outlined"
                                            type="text"
                                            onKeyDown={HandleTextFieldKeyDown}
                                            onPaste={HandlePaste}
                                            inputProps={{ maxLength: 32 }}
                                          />
                                        </FormControl>
                                        {!invalidFields.includes(
                                          "sameDayPro" + index
                                        ) && errors.callSameDaysDataset &&
                                        errors.callSameDaysDataset.length > 0 &&
                                        _.get(
                                          errors,
                                          `callSameDaysDataset[${index}].sameDayPro`
                                        ) &&
                                        isCompleting ? (
                                          <span className="form-error">
                                            {_.get(
                                              errors,
                                              `callSameDaysDataset[${index}].sameDayPro`,
                                              ""
                                            ).toString()}
                                          </span>
                                        ) : (
                                          ""
                                        )}

                                        {isNaN(
                                          +(
                                            values?.callSameDaysDataset?.[index]
                                              ?.sameDayPro ?? ""
                                          )
                                        ) &&
                                          values.callSameDays === "Y" && (
                                            <span className="form-error">
                                              Invalid PRO# number
                                            </span>
                                          )}
                                        {invalidFields.includes(
                                          "sameDayPro" + index
                                        ) && !values?.callSameDaysDataset?.[index]
                                        ?.sameDayPro && (
                                          <span className="form-error">
                                            {
                                              EASInvestigationFieldsErrorMessage.day5
                                            }
                                          </span>
                                        )}
                                      </Grid>

                                      <Grid
                                        item
                                        md={9}
                                        xs={12}
                                        className="pt-0"
                                      >
                                        <FormControl className="radio-group question-group ">
                                          <FormLabel>
                                            Delivery Info?<sup>*</sup>
                                          </FormLabel>
                                          <TextField  autoComplete="off"
                                            id={`deliveryInfo-${index}`}
                                            name={`callSameDaysDataset[${index}].deliveryInfo`}
                                            value={_.get(
                                              values,
                                              `callSameDaysDataset[${index}].deliveryInfo`,
                                              ""
                                            )}
                                            onChange={handleChange}
                                            onBlur={(e) => {
                                              handleBlur(e);
                                              isValueExist(
                                                "deliveryInfo",
                                                index,
                                                "callSameDaysDataset"
                                              );
                                            }}
                                            disabled={formFieldsDisabled}
                                            fullWidth
                                            className="mt-1"
                                            placeholder="Delivery Info"
                                            inputProps={{
                                              maxLength:
                                                MAX_LENGTH_SUMMARY_TEXT,
                                            }}
                                            variant="outlined"
                                          />
                                        </FormControl>
                                        {!invalidFields.includes(
                                          "deliveryInfo" + index
                                        )  && errors.callSameDaysDataset &&
                                        errors.callSameDaysDataset.length > 0 &&
                                        _.get(
                                          errors,
                                          `callSameDaysDataset[${index}].deliveryInfo`
                                        ) &&
                                        isCompleting ? (
                                          <span className="form-error">
                                            {_.get(
                                              errors,
                                              `callSameDaysDataset[${index}].deliveryInfo`,
                                              ""
                                            ).toString()}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                        {invalidFields.includes(
                                          "deliveryInfo" + index
                                        ) && !values?.callSameDaysDataset?.[index]
                                        ?.deliveryInfo?.trim() && (
                                          <span className="form-error">
                                            {
                                              EASInvestigationFieldsErrorMessage.day5
                                            }
                                          </span>
                                        )}
                                      </Grid>
                                    </React.Fragment>
                                  )
                                )}
                                <Grid
                                  item
                                  md={12}
                                  xs={12}
                                  className="btn-group-submit"
                                >
                                  {!(
                                    formFieldsDisabled ||
                                    status ===
                                      EShortageInvestigationStatus.complete
                                  ) && (
                                    <>
                                      {values.callSameDaysDataset?.length! <
                                        24 && (
                                        <Button
                                          onClick={
                                            proInfoAddButtonDisabled
                                              ? void 0
                                              : () => {
                                                  push({
                                                    sameDayPro: undefined,
                                                    deliveryInfo: undefined,
                                                  });
                                                  proInfoAddRemove();
                                                }
                                          }
                                          variant="contained"
                                          className={`entry ml ${
                                            proInfoAddButtonDisabled
                                              ? "disable-btn disable"
                                              : ""
                                          }`}
                                        >
                                          Add
                                        </Button>
                                      )}
                                      {(values.callSameDaysDataset?.length ?? 0) > 1 && (
                                        <Button
                                          onClick={() => {
                                            remove(
                                              values.callSameDaysDataset
                                                ?.length! - 1
                                            );
                                            proInfoAddRemove("remove");
                                          }}
                                          variant="contained"
                                          className="clear-btn mr-0 ml"
                                        >
                                          Remove
                                        </Button>
                                      )}
                                    </>
                                  )}
                                </Grid>
                              </>
                            )}
                          </FieldArray>
                        </Grid>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled ||
                            isControlDisabled("contactTerminals")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.contactTerminals === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const checked = e.target.checked;
                                setFieldValue(
                                  "contactTerminals",
                                  checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "freightTerminalLeadInquiries",
                                  getFreightTerminalLeadInquiries(checked),
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />
                        Contact the terminals that handled the freight to find
                        leads and check cameras if applicable <sup>*</sup>
                        <TooltipWithClickAway
                          tooltipText="Use movement screen (F10) to locate terminals."
                          tooltipKey="tooltip6"
                          openTooltips={openTooltips}
                          handleTooltipOpen={handleTooltipOpen}
                          handleTooltipClose={handleTooltipClose}
                        />
                      </FormLabel>
                    </FormControl>
                    {getErrorMessage("contactTerminals")}
                  </Grid>

                  {values.contactTerminals === "Y" && (
                    <Grid
                      key={formKey}
                      container
                      spacing={2}
                      className="filter-margin custom-container-pos"
                    >
                      <FieldArray name="freightTerminalLeadInquiries">
                        {({ push, remove }) => (
                          <>
                            {_.get(
                              values,
                              "freightTerminalLeadInquiries",
                              []
                            ).map(
                              (
                                _ft: IDSFreightTerminalLeadInquiries,
                                index: number
                              ) => (
                                <React.Fragment key={index + ""}>
                                  <Grid item md={12} xs={12} className="pt-0">
                                    <h3 className="header-text mb-0">
                                      Requests for terminal assistance:{" "}
                                      {index + 1}
                                    </h3>
                                  </Grid>

                                  <Grid item md={3} xs={12} className="pt-0">
                                    <FormControl className="radio-group question-group ">
                                      <FormLabel>
                                        TID <sup>*</sup>
                                      </FormLabel>
                                      <TextField  autoComplete="off"
                                        id={`tid-${index}`}
                                        name={`freightTerminalLeadInquiries[${index}].terminal`}
                                        value={_.get(
                                          values,
                                          `freightTerminalLeadInquiries[${index}].terminal`,
                                          ""
                                        )}
                                        onChange={handleChange}
                                        onBlur={(e) => {
                                          handleBlur(e);
                                          isValueExist("terminal", index);
                                        }}
                                        disabled={formFieldsDisabled}
                                        fullWidth
                                        className="mt-1"
                                        placeholder="Enter TID"
                                        error={
                                          !!_.get(
                                            errors,
                                            `freightTerminalLeadInquiries[${index}].terminal`
                                          ) && isCompleting
                                        }
                                        inputProps={{ maxLength: 5 }}
                                        variant="outlined"
                                      />
                                    </FormControl>
                                    {errors.freightTerminalLeadInquiries &&
                                    errors.freightTerminalLeadInquiries.length >
                                      0 &&
                                    _.get(
                                      errors,
                                      `freightTerminalLeadInquiries[${index}].terminal`
                                    ) &&
                                    isCompleting &&
                                    !invalidFields.includes(
                                      "terminal" + index
                                    ) ? (
                                      <span className="form-error">
                                        {_.get(
                                          errors,
                                          `freightTerminalLeadInquiries[${index}].terminal`,
                                          ""
                                        ).toString()}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    {invalidFields.includes(
                                      "terminal" + index
                                    ) && !values?.freightTerminalLeadInquiries?.[index]
                                    ?.terminal?.trim()  && (
                                      <span className="form-error">
                                        {
                                          EASInvestigationFieldsErrorMessage.day5
                                        }
                                      </span>
                                    )}
                                  </Grid>

                                  <Grid item md={3} xs={12} className="pt-0">
                                    <FormControl className="radio-group question-group ">
                                      <FormLabel>
                                        Who did you talk to?<sup>*</sup>
                                      </FormLabel>
                                      <TextField  autoComplete="off"
                                        id={`talkedTo-${index}`}
                                        name={`freightTerminalLeadInquiries[${index}].talkedTo`}
                                        value={_.get(
                                          values,
                                          `freightTerminalLeadInquiries[${index}].talkedTo`,
                                          ""
                                        )}
                                        error={
                                          !!_.get(
                                            errors,
                                            `freightTerminalLeadInquiries[${index}].talkedTo`
                                          ) && isCompleting
                                        }
                                        onChange={handleChange}
                                        onBlur={(e) => {
                                          handleBlur(e);
                                          isValueExist("talkedTo", index);
                                        }}
                                        disabled={formFieldsDisabled}
                                        fullWidth
                                        className="mt-1"
                                        placeholder="Enter Who did you talk to"
                                        inputProps={{ maxLength: 32 }}
                                        variant="outlined"
                                      />
                                    </FormControl>
                                    {errors.freightTerminalLeadInquiries &&
                                    errors.freightTerminalLeadInquiries.length >
                                      0 &&
                                    _.get(
                                      errors,
                                      `freightTerminalLeadInquiries[${index}].talkedTo`
                                    ) &&
                                    isCompleting &&
                                    !invalidFields.includes(
                                      "talkedTo" + index
                                    ) ? (
                                      <span className="form-error">
                                        {_.get(
                                          errors,
                                          `freightTerminalLeadInquiries[${index}].talkedTo`,
                                          ""
                                        ).toString()}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    {invalidFields.includes(
                                      "talkedTo" + index
                                    )  && !values?.freightTerminalLeadInquiries?.[index]
                                    ?.talkedTo?.trim() && (
                                      <span className="form-error">
                                        {
                                          EASInvestigationFieldsErrorMessage.day5
                                        }
                                      </span>
                                    )}
                                  </Grid>

                                  <Grid item md={6} xs={12} className="pt-0">
                                    <FormControl className="radio-group question-group ">
                                      <FormLabel>
                                        Provide updates and/or information from
                                        the terminal <sup>*</sup>
                                      </FormLabel>
                                      <TextField  autoComplete="off"
                                        id={`updates-${index}`}
                                        name={`freightTerminalLeadInquiries[${index}].updates`}
                                        value={_.get(
                                          values,
                                          `freightTerminalLeadInquiries[${index}].updates`,
                                          ""
                                        )}
                                        onChange={handleChange}
                                        onBlur={(e) => {
                                          handleBlur(e);
                                          isValueExist("updates", index);
                                        }}
                                        disabled={formFieldsDisabled}
                                        fullWidth
                                        className="mt-1"
                                        placeholder="Enter Details"
                                        error={
                                          !!_.get(
                                            errors,
                                            `freightTerminalLeadInquiries[${index}].updates`
                                          ) && isCompleting
                                        }
                                        inputProps={{
                                          maxLength: MAX_LENGTH_SUMMARY_TEXT,
                                        }}
                                        variant="outlined"
                                      />
                                    </FormControl>
                                    {errors.freightTerminalLeadInquiries &&
                                    errors.freightTerminalLeadInquiries.length >
                                      0 &&
                                    _.get(
                                      errors,
                                      `freightTerminalLeadInquiries[${index}].updates`
                                    ) &&
                                    isCompleting &&
                                    !invalidFields.includes(
                                      "updates" + index
                                    ) ? (
                                      <span className="form-error">
                                        {_.get(
                                          errors,
                                          `freightTerminalLeadInquiries[${index}].updates`,
                                          ""
                                        ).toString()}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    {invalidFields.includes(
                                      "updates" + index
                                    )  && !values?.freightTerminalLeadInquiries?.[index]
                                    ?.updates?.trim() && (
                                      <span className="form-error">
                                        {
                                          EASInvestigationFieldsErrorMessage.day5
                                        }
                                      </span>
                                    )}
                                  </Grid>
                                </React.Fragment>
                              )
                            )}
                            <Grid item md={12} xs={12} className=" ">
                              <Snackbar
                                className="snackbar-error flex-start justify-content-left"
                                anchorOrigin={{ vertical, horizontal }}
                                open={true}
                                onClose={handleClose}
                                message="Note | Review strip manifests in imaging prior to contacting the terminal"
                                key={vertical + horizontal}
                              />
                            </Grid>
                            <Grid
                              item
                              md={12}
                              xs={12}
                              className="btn-group-submit"
                            >
                              {!(
                                formFieldsDisabled ||
                                status === EShortageInvestigationStatus.complete
                              ) && (
                                <>
                                  {values.freightTerminalLeadInquiries
                                    ?.length! < 6 && (
                                    <Button
                                      onClick={
                                        addButtonDisabled
                                          ? void 0
                                          : () => {
                                              push({
                                                terminal: undefined,
                                                talkedTo: undefined,
                                                updates: undefined,
                                              });
                                              addRemove();
                                            }
                                      }
                                      variant="contained"
                                      className={`entry ml ${
                                        addButtonDisabled
                                          ? "disable-btn disable"
                                          : ""
                                      }`}
                                    >
                                      Add
                                    </Button>
                                  )}
                                  {values.freightTerminalLeadInquiries
                                    ?.length !== 1 && (
                                    <Button
                                      onClick={() => {
                                        remove(
                                          values.freightTerminalLeadInquiries
                                            ?.length! - 1
                                        );
                                        addRemove("remove");
                                      }}
                                      variant="contained"
                                      className="clear-btn mr-0 ml"
                                    >
                                      Remove
                                    </Button>
                                  )}
                                </>
                              )}
                            </Grid>
                          </>
                        )}
                      </FieldArray>
                    </Grid>
                  )}
                  {/* F18 Notes */}
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel style={selectStyle} shrink={true}>
                        F18 Notes <sup></sup>
                      </InputLabel>
                      <TextField  autoComplete="off"
                        multiline
                        rows={2}
                        name="day5F18Note"
                        value={values.day5F18Note}
                        onChange={handleChange}
                        placeholder="Enter F18 Notes"
                        fullWidth
                        inputProps={{ maxLength: 300 }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            )}

            {activeStep === StepStatusMap[7] && (
              <div>
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled || isControlDisabled("followUp")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.followUp === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "followUp",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "followUpDetails",
                                  e.target.checked
                                    ? values.followUpDetails
                                    : "",
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />{" "}
                        Follow up on all leads/request from previous days{" "}
                        <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.followUp === "Y" && (
                            <TextField  autoComplete="off"
                              name="followUpDetails"
                              value={values.followUpDetails ?? ""}
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                isValueExist(
                                  EDSInvestigationFields.FollowUpDetails
                                );
                              }}
                              disabled={formFieldsDisabled}
                              fullWidth
                              placeholder="Provide details of your search"
                              error={!!errors.followUpDetails && isCompleting}
                              inputProps={{
                                maxLength: MAX_LENGTH_SUMMARY_TEXT,
                              }}
                              variant="outlined"
                            />
                          )}
                          {getErrorMessage("followUp")}
                          {getErrorMessage("followUpDetails")}
                          {invalidFields.includes(
                            EDSInvestigationFields.FollowUpDetails
                          ) && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day10}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>
                  {/* F18 Notes */}
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel style={selectStyle} shrink={true}>
                        F18 Notes <sup></sup>
                      </InputLabel>
                      <TextField  autoComplete="off"
                        multiline
                        rows={2}
                        name="day10F18Note"
                        value={values.day10F18Note}
                        onChange={handleChange}
                        placeholder="Enter F18 Notes"
                        fullWidth
                        inputProps={{ maxLength: 300 }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            )}

            {activeStep === StepStatusMap[8] && (
              <div>
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled ||
                            isControlDisabled("check7818_2")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.check7818_2 === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "check7818_2",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "details7818_2",
                                  e.target.checked ? values.details7818_2 : "",
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />{" "}
                        Check 78/18 and/or Power BI for any overages (OV, NB,
                        DO) to apply <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.check7818_2 === "Y" && (
                            <TextField  autoComplete="off"
                              name="details7818_2"
                              value={values.details7818_2 ?? ""}
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                isValueExist(
                                  EDSInvestigationFields.Details7818_2
                                );
                              }}
                              disabled={formFieldsDisabled}
                              fullWidth
                              placeholder="Provide details of your search"
                              error={!!errors.details7818_2 && isCompleting}
                              inputProps={{
                                maxLength: MAX_LENGTH_SUMMARY_TEXT,
                              }}
                              variant="outlined"
                            />
                          )}
                          {getErrorMessage("check7818_2")}
                          {getErrorMessage("details7818_2")}
                          {invalidFields.includes(
                            EDSInvestigationFields.Details7818_2
                          ) && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day15}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id="destinationTerminal">
                        Is this high value or high quantity? <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          <RadioGroup
                            row
                            aria-labelledby="destinationTerminal"
                            name="highValue"
                            className="textarea-radio-group"
                          >
                            <FormControlLabel
                              value="Y"
                              control={<Radio />}
                              label="Yes"
                              name="highValue"
                              checked={values.highValue === "Y"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                            />
                            <FormControlLabel
                              value="N"
                              control={<Radio />}
                              label="No"
                              name="highValue"
                              checked={values.highValue === "N"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    </FormControl>
                    {getErrorMessage("highValue")}
                  </Grid>
                </Grid>
                {/* {showSendAPB() && ( */}
                <div
                  className={`${
                    showSendAPB() ? "" : "hide-me"
                  } another-class-name`}
                >
                  <div className="MuiTypography-root header-notice justify-content">
                    Since you've chosen the "high value/quantity" option, an APB
                    will be issued. Please remove any photos you'd prefer not to
                    include in the APB email.
                  </div>
                  <SendAPBImageList
                    osdNumber={investigationDetails?.osdNumber}
                    setSelectedImages={setSelectedImages}
                    selectedImages={selectedImages}
                    handleImageSelect={handleImageSelect}
                    isSendAPBScreen={false}
                  />
                  <br />
                </div>
                {/* )} */}
                {/* F18 Notes */}
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel style={selectStyle} shrink={true}>
                        F18 Notes <sup></sup>
                      </InputLabel>
                      <TextField  autoComplete="off"
                        multiline
                        rows={2}
                        name="day15F18Note"
                        value={values.day15F18Note}
                        onChange={handleChange}
                        placeholder="Enter F18 Notes"
                        fullWidth
                        inputProps={{ maxLength: 300 }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            )}

            {activeStep === StepStatusMap[9] && (
              <div>
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel>
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled ||
                            isControlDisabled("finalReCheck")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.finalReCheck === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "finalReCheck",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "detailsOfSearch",
                                  e.target.checked
                                    ? initValues.detailsOfSearch
                                    : "",
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />{" "}
                        Final re-check of all leads/requests/overages to confirm
                        the freight cannot be located at this time <sup>*</sup>
                      </FormLabel>
                    </FormControl>
                    {getErrorMessage("finalReCheck")}
                  </Grid>
                  <Grid item md={12} xs={12} className="all-short-entry">
                    {values.finalReCheck === "Y" && (
                      <TextField  autoComplete="off"
                        id={`detailsOfSearch`}
                        name="detailsOfSearch"
                        value={values.detailsOfSearch ?? ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={formFieldsDisabled}
                        fullWidth
                        className=""
                        placeholder="Provide details of your search"
                        error={!!errors.detailsOfSearch && isCompleting}
                        inputProps={{ maxLength: MAX_LENGTH_SUMMARY_TEXT }}
                        variant="outlined"
                      />
                    )}
                  </Grid>
                  {/* F18 Notes */}
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel style={selectStyle} shrink={true}>
                        F18 Notes <sup></sup>
                      </InputLabel>
                      <TextField  autoComplete="off"
                        multiline
                        rows={2}
                        name="day20F18Note"
                        value={values.day20F18Note}
                        onChange={handleChange}
                        placeholder="Enter F18 Notes"
                        fullWidth
                        inputProps={{ maxLength: 300 }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            )}
          </>
        </FormikProvider>
        <Grid item md={12} xs={12} className="btn-group-submit">
          {formFieldsDisabled &&
            status !== EShortageInvestigationStatus.complete && (
              <Button
                variant="contained"
                onClick={enableEditing}
                className="assign ml mr-0"
              >
                Edit
              </Button>
            )}
          {!formFieldsDisabled && (
            <>
              <Button
                onClick={() => reset()}
                variant="contained"
                className="clear-btn mr-0 ml"
                type="button"
              >
                {isEditMode ? "Cancel" : "Clear"}
              </Button>
              <Button
                variant="contained"
                onClick={
                  invalidFields?.length > 0
                    ? void 0
                    : () => {
                        saveOrSubmit(EFormAction.save);
                      }
                }
                className={
                  invalidFields?.length > 0
                    ? "disable-btn disable entry ml"
                    : "entry ml"
                }
              >
                Save
              </Button>
              {shouldShowDayCompleteButton && (
                <Button
                  variant="contained"
                  onClick={
                    invalidFields?.length > 0
                      ? void 0
                      : () => {
                          saveOrSubmit(EFormAction.submit);
                        }
                  }
                  className={
                    invalidFields?.length > 0
                      ? "disable-btn disable entry ml"
                      : "entry ml"
                  }
                >
                  Complete Day {activeDay}
                </Button>
              )}
            </>
          )}
          {shouldShowFreightButton && (
            <Button
              onClick={() => handleDialogOpen()}
              variant="contained"
              className="entry ml"
            >
              Freight Located
            </Button>
          )}

          {shouldShowCompleteButton && (
            <Button
              onClick={() => handleFreightAndCompletion(EFormAction.complete)}
              variant="contained"
              className="entry ml"
            >
              Complete & Image
            </Button>
          )}
        </Grid>
      </AccordionDetails>
      <Loader pageLoader={pageLoading}></Loader>

      <div id="ds-investigation-day5" className="hide-me"></div>
      {ASInvestigationWizardStep.map((day: string, index: number) => (
        <>
          {day !== "Day 5" ? (
            <div key={day} className="hide-me" id={`investigation-${day}`}>
              <DSInvestigationTemplate
                currentDay={day}
                investigationValues={values}
                osdNumber={investigationDetails?.osdNumber}
              />
            </div>
          ) : (
            <>
              {(callSameDaysChunks || [[]]).map(
                (chunk: ICallSameDaysDataset[], index: number) => {
                  const chunkId = `investigation-${day}-callSameDays-${index}`;
                  return (
                    <div key={chunkId} className="hide-me" id={chunkId}>
                      <DSDay5Template
                        currentDay={day}
                        osdNumber={investigationDetails?.osdNumber}
                        investigationValues={values}
                        callSameDaysChunk={chunk}
                        pageIndex={getPageIndex(index, callSameDaysChunks)}
                      />
                    </div>
                  );
                }
              )}

              {
                <div
                  key={`investigation-${day}-freightTerminal-${index}`}
                  className="hide-me"
                  id={`investigation-${day}-freightTerminal-${index}`}
                >
                  <DSDay5Template
                    currentDay={day}
                    osdNumber={investigationDetails?.osdNumber}
                    investigationValues={values}
                    freightTerminalChunk={
                      values?.freightTerminalLeadInquiries ?? []
                    }
                  />
                </div>
              }
            </>
          )}
        </>
      ))}
      <CustomDialog
        message={"Are you sure freight is located?"}
        open={dialogOpen}
        onClose={handleDialogClose}
      />
    </div>
  );
};

export default React.memo(DsInvestigation); // Prevent unnecessary re-renders
