export const ConstantMessage: any = {
    deploymentInprogressError: "503 Service unavailable. Deployment in progress",
    statusCodeNotFound: "Error status code not found",
    serverError:'Something went wrong. Please try again later.',
    terminalAPIError :'There was an error with the get terminals API.',
    DashboardSummaryAPIError:'Error occurred while fetching dashboard summary from API',
    validateOktaToken: 'Unable to validate okta token. Please try again.',
    overageList: 'Error occurred while fetching overage list from API',
    getExceptionList: 'Error occurred while fetching exception list from API',
    exceptionAssign: 'Error occurred while assigning osd to user',
    exceptionDetails: 'Error occurred while fetching exception details from API',
    proImage: 'Error occurred while fetching PRO images from API',
    manifestDetails: 'Error occurred while fetching manifest details from API',
    manifestProDetails: "Error occurred while fetching manifest's PRO details from API",
    sendAPBMail: 'Error occurred while sending APB mail, please try again',
    masterProNumber: "Error occurred while fetching master PRO numbers from API",
    masterOsdNumber: "Error occurred while fetching master OSD numbers from API",
    commodityTypeMaster: "Error occurred while fetching master commodity from API",
    exceptionCodesMaster: "Error occurred while fetching master exception codes from API",
    checkPro: "Error occurred while fetching check digit pro details from API",
    createOverageEntry: "Error creating overage entry. Please try again.",
    createUpdateShortageInvestigation: "Error creating or updating shortage investigation entry. Please try again.",
    updateOverageEntry: "Error updating overage entry. Please try again.",
    updateASEntry: "Error updating all shortage entry. Please try again.",
    updateDSEntry: "Error updating delivered short entry. Please try again.",
    createShortageEntry: "Error creating shortage entry. Please try again.",
    OverageEntryDetailsAPIError: "Error occurred while fetching overage entry details from API",
    aSEntryDetailsAPIError: "Error occurred while fetching all shortage entry details from API",
    dSEntryDetailsAPIError: "Error occurred while fetching delivered short entry details from API",
    bnfEntryDetailsAPIError: "Error occurred while fetching bill no freight edit view details from API",
    bnfFollowUpAPIError: "Error occurred while bill no freight follow up",
    saveOrSubmitAPIError: "Error occurred while saving or submitting investigation notes. Please try again.",
    getInvestigationAPIError: "Error occurred while fetching investigation from API",
    InvestigationImageUploadAPIError: "Failed to upload investigation image. Please try again",
    locationMaster: "Error occurred while fetching zip code master records",
    DocTypeAPIError: "Error occurred while fetching doc types from API.",
    getShortagesAPIError: "Error occurred while fetching shortages. Please try again.",
    PrintAPIError: "Error occurred while printing. Please try again.",
    logglyLogAPIError: "Error occurred while log error in loggly. Please try again.",
    bnfFollowUpSuccess: '⁠OS&D follow up notes have been submitted successfully',
    freightLocatedSuccess: 'OS&D investigation freight have been located successfully',
    investigationNoteSaved : 'OS&D investigation notes have been saved successfully',
    investigationNoteCompleted: 'OS&D investigation notes have been completed successfully & upload investigation image in progress'
  };