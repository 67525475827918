import Button from "@mui/material/Button";
import "./OverageEntry.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import { useFormik } from "formik";
import { OverageEntryFormValue, overageEntryNBSchema } from "../../schema";
import { useDispatch, useSelector } from "react-redux";
import InvestigationForm from "./investigation";
import { commodityTypeMasterApi } from "../../API/getCommodityTypeMaster";
import {
  IFilterDropdown,
  TTerminal,
  saveFilterCommodityTypeData,
  savePackageOptionMaster,
} from "../../AppState/overagesFilterFormActions";
import AppState from "../../AppState";
import { getFilterDropdowns } from "../../API/getFilterDropdowns";
import { checkPro } from "../../API/checkPro";
import _, { debounce } from "lodash";
import { ILocation } from "../../models/master";
import CustomDialog from "../../components/confirmationPopup/confirmationPopup";
import { CreateOverageEntryAPI } from "../../API/createOverageEntry";
import Loader from "../../components/loader/loader";
import { PopoutNotification } from "../../AppState/popoutNotification";
import NOTIFICATION_TYPE from "../../AppState/NotificationType";
import Autocomplete from "@mui/material/Autocomplete";
import { getTerminals } from "../../API/getTerminals";
import { saveTerminalMasterState } from "../../AppState/terminalMaster";
import { UpdateOverageEntryAPI } from "../../API/updateOverageEntry";
import { GetOverageEntryDetailsAPI } from "../../API/getOverageEntryDetailsAPI";
import { APP, AppURLs, CheckDigitFields, DType, EAutoSearchFieldName, EFormAction } from "../../constants/app";
import { DateHelper } from "../../helpers/date.helper";
import { HandleKeyDown, HandlePaste, NoOptionsText, SortTerminals, TrimStrings, UserTerminal } from "../../helpers/utils.helper";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { TerminalSelection } from "../../AppState/terminalSelection";
import { locationMasterApi } from "../../API/locationMaster";
import UploadDocument from "../../components/uploadDocument/uploadDocument";
import { printApi } from "../../API/print";
import { IOvEntry } from "../../models/overage";
import { ICheckDigitPro } from "../../models/osd";
import { isFormValueChanged } from "../../AppState/isFormValueChanged";
import { terminalValuesSelector } from "../../AppState/selectors";
import TooltipWithClickAway from "../../components/terminalPicker/TooltipWithClickAway";
import { EOverageInvestigationStatus } from "../../constants/shortage";

interface TooltipState {
  [key: string]: boolean;
}

function OverageEntry() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const tabPosition: number = useSelector(
    (state: AppState) => state.tabPosition
  );
  const currentTerminalValue = useSelector(
    (state: AppState) => state.activeTerminal
  );
  const urls: string[] = [ AppURLs.myAssignments, AppURLs.overages];

  // get terminal master from redux state
  let terminalOptions: string[] = useSelector(terminalValuesSelector);

  const formValueChanged = useSelector(
    (state: AppState) => state.isFormValueChanged
  );

  const [overageEntrySubmitted, setOverageEntrySubmitted] =
    React.useState<boolean>(false);

  const [formFieldsDisabled, setFormFieldsDisabled] = React.useState<boolean>(
    !!params?.osdNumber
  );

  const [osdNumber, setOsdNumber] = React.useState<number>(
    Number(params?.osdNumber)
  );
  const [osdDetails, setOsdDetails] = React.useState<IOvEntry>();
  const [investigationStatus, setInvestigationStatus] = React.useState<number>(EOverageInvestigationStatus.exceptionEntry);
  const [updatedDateTime, setUpdatedDateTime] = React.useState<string>("");

  const [editFormValueChanged, setEditFormValueChanged] = React.useState<boolean>(false);
  const [inputValues, setInputValues] = React.useState({
    cInputValue: undefined,
    sZipInputValue: undefined,
    sCityInputValue: undefined,
    cZipInputValue: undefined,
    cCityInputValue: undefined
  });

  const defaultLocation: ILocation = {
    STATE: "",
    CITY: "",
    ZIP: "",
    CITYORDER: 0,
    ZIPORDER: 0,
  };

  const updateInputValue = (key: string, value: string | undefined) => {
    setInputValues(prevState => ({
      ...prevState,
      [key]: value
    }));
  };

  const [autocompleteValues, setAutocompleteValues] = React.useState<any>({
    cType: undefined,
    sZip: undefined,
    sCity: undefined,
    cZip: undefined,
    cCity: undefined,
  });

  const updateAutocompleteValue = (key: string, value: any) => {
    setAutocompleteValues((prevState: any) => ({
      ...prevState,
      [key]: value
    }));
  };

  const handleAutoCompleteOnChange = (value: any, type: DType) => {
    if (value) {
      switch (type) {
        case DType.commodity:
          updateAutocompleteValue("cType", value);
          setFieldValue("commodity", value?.code);
          break;
        case DType.shipperZip:
          updateAutocompleteValue("sZip", value);
          updateAutocompleteValue("sCity", value);
          setFieldValue("shipperZip", value?.ZIP);
          updateInputValue("sCityInputValue", value?.CITY);
          setFieldValue("shipperCity", value?.CITY);
          setFieldValue("shipperState", value?.STATE);
          break;
        case DType.shipperCity:
          updateAutocompleteValue("sZip", value);
          updateAutocompleteValue("sCity", value);
          setFieldValue("shipperZip", value?.ZIP);
          setSZipLocations([]);
          getLocations(value?.ZIP || "", DType.shipperZip);
          setFieldValue("shipperCity", value?.CITY);
          updateInputValue("sZipInputValue", value?.ZIP);
          setFieldValue("shipperState", value?.STATE);
          break;
        case DType.consigneeZip:
          updateAutocompleteValue("cZip", value);
          updateAutocompleteValue("cCity", value);
          setFieldValue("consigneeZip", value?.ZIP);
          setFieldValue("consigneeCity", value?.CITY);
          setFieldValue("consigneeState", value?.STATE);
          updateInputValue("cCityInputValue", value?.CITY);
          setCCityLocations([]);
          getLocations(value?.CITY || "", DType.consigneeCity);
          break;
        case DType.consigneeCity:
          updateAutocompleteValue("cZip", value);
          updateAutocompleteValue("cCity", value);
          setFieldValue("consigneeZip", value?.ZIP);
          setFieldValue("consigneeCity", value?.CITY);
          setFieldValue("consigneeState", value?.STATE);
          updateInputValue("cZipInputValue", value?.ZIP);

          setCZipLocations([]);
          getLocations(value?.ZIP || "", DType.consigneeZip);
          break;
        default:
          break;
      }
    }
  };

  const onAutoInputChange = (value: string, type?: DType) => {
    if (value === "undefined" || ( ["consigneeZip","consigneeCity","shipperZip", "shipperCity", "commodity"].indexOf(document?.activeElement?.id || "") === -1 && value === undefined)) return;
    switch (type) {
      case DType.commodity:
        updateInputValue("cInputValue", value);
        break;
      case DType.consigneeZip:
        setLoading(true);
        setCZipLocations([]);
        setFieldValue("consigneeZip", value);
        debouncedSave(value, type);
        updateInputValue("cZipInputValue", value);
        break;
      case DType.consigneeCity:
        setCCityLocations([]);
        setFieldValue("consigneeCity", value);
        updateInputValue("cCityInputValue", value);
        setLoading(true);
        debouncedSave(value, type);
        break;
      case DType.shipperCity:
        setSCityLocations([]);
        setFieldValue("shipperCity", value);
        updateInputValue("sCityInputValue", value);
        setLoading(true);
        debouncedSave(value, type);
        break;
      case DType.shipperZip:
        setSZipLocations([]);
        setFieldValue("shipperZip", value);
        updateInputValue("sZipInputValue", value);
        setLoading(true);
        debouncedSave(value, type);
        break;
      default:
        break;
    }
  };


  // user can print all overage entry form after successfully submitted
  const handlePrint = async () => {
    try {
      setPageLoading(true);
      const printResponse = await printApi(osdNumber.toString());
      setPageLoading(false);
      dispatch(
        PopoutNotification(
          NOTIFICATION_TYPE.SUCCESS,
          printResponse.status === "ok" ? `OS&D # ${osdNumber} Face Sheet was successfully Printed`: `OS&D # ${osdNumber} Print Face Sheet was Failed with status ${printResponse.status}`,
          Date.now().toString()
        )
      );
    } catch (error) {
      setPageLoading(false);
      dispatch(
        PopoutNotification(
          NOTIFICATION_TYPE.ERROR,
          typeof error === "string" ? error : `OS&D # ${osdNumber} Print face sheet failed`,
          Date.now().toString()
        )
      );
    }
  };

  const commodityCodeOptions = useSelector(
    (state: AppState) => state.filterCommodityTypeData
  );
  const packageCodeOptions = useSelector(
    (state: AppState) => state.packageOptionMasterData
  );
  const [entryUser, setEntryUser] = useState<string>("");
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState(false); // State to manage dialog open/close
  const [proCheckDError, setProCheckDError] = React.useState<boolean>(false);
  // get master from API for commodity code and package code
  const [expanded, setExpanded] = React.useState<string[]>(["panel1"]); // handle expand page using "expanded" variable

  const selectStyle = {
    fontSize: "13px",
  };

  // set Initial value of overage entry form
  // const [initialEditFormValue, setInitialEditFormValue] = React.useState<IOvEntry>();
  const [isEditFormValuePatched, setIsEditFormValuePatched] = React.useState<string>("");
  const initValue: IOvEntry = OverageEntryFormValue;
  initValue["reporting"] = UserTerminal();

  const [initialFormValue, setInitialFormValue] = React.useState<IOvEntry>(initValue);

  // Initialize overage entry form using formik and pass validation schema and initial values
  // handle all form event and submit form using formik
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
    handleSubmit,
    setFieldValue,
    resetForm,
    isSubmitting
  } = useFormik({
    validationSchema: overageEntryNBSchema,
    validateOnChange: false,
    initialValues: initValue,

    // Submit the form if all form fields are validated
    onSubmit: (values) => {
      if ((!editFormValueChanged && osdNumber)) return;
      if (proCheckDError) {
        document.getElementsByName("proNumber")[0].focus();
      }
      if (proCheckDError) return;
      setPageLoading(true);
      let reqInput = JSON.parse(JSON.stringify(values));
      const deleteFields = [
        "trailerOrBay",
      ];

      deleteFields.push(values.trailerOrBay === "T" ? "bay" : "trailer");

      if (["1", "2"].includes(reqInput["valueRange"])) {
        deleteFields.push("value1k");
      } else {
        reqInput["value1k"] = parseInt(reqInput["value1k"])
      }
      if (values.type === "NB") {
        deleteFields.push(
          "proNumber",
          "origin",
          "destination",
          "shipperAccount",
          "consigneeAccount"
        );
      } else {
        reqInput["proNumber"] = parseInt(reqInput["proNumber"])
      }

      reqInput["weight"] = parseInt(reqInput["weight"])
      reqInput["pieces"] = parseInt(reqInput["pieces"])
      reqInput["handlingUnits"] = parseInt(reqInput["handlingUnits"])
      reqInput["od400ProNumber"] = parseInt(reqInput["od400ProNumber"])

      if(reqInput["od400Manifest"] && !isNaN(reqInput["od400Manifest"])) {
        reqInput["od400Manifest"] = parseInt(reqInput["od400Manifest"]);
      }else {
        reqInput["od400Manifest"] = 0;
      }

      deleteFields.forEach((f: string) => {
        delete reqInput[f];
      });
      reqInput["commodity"] = reqInput["commodity"]?.trim();
      reqInput["values"] = reqInput["values"]?.toString();
      reqInput = TrimStrings(reqInput);

      if (osdNumber) {
        setPageLoading(true);
        reqInput["username"] = osdDetails?.username || entryUser;
        UpdateOverageEntryAPI(reqInput, osdNumber)
          .then((response) => {
            setPageLoading(false);
            viewOverageEntry();
            setOverageEntrySubmitted(true);
            setExpanded(["panel1", "panel2"]);
            setFormFieldsDisabled(true);
            setInitialFormValue(values);
            dispatch(
              PopoutNotification(
                NOTIFICATION_TYPE.SUCCESS,
                `OS&D # ${osdNumber}  has been updated successfully`,
                Date.now().toString()
              )
            );
          })
          .catch((err) => {
            setPageLoading(false);
            dispatch(
              PopoutNotification(
                NOTIFICATION_TYPE.ERROR,
                typeof err === "string" ? err : "Overage entry updating failed",
                Date.now().toString()
              )
            );
          });
      } else {
        CreateOverageEntryAPI(reqInput)
          .then((response) => {
            setPageLoading(false);
            if (response.osdNumber) {
              setInitialFormValue(values);
              setOverageEntrySubmitted(true);
              setExpanded(["panel1", "panel2"]);
              setFormFieldsDisabled(true);
              viewOverageEntry((response.osdNumber));
              setOsdNumber(response.osdNumber);
              setEntryUser(response.username);
              dispatch(
                PopoutNotification(
                  NOTIFICATION_TYPE.SUCCESS,
                  `OS&D # ${response.osdNumber}  has been created successfully`,
                  Date.now().toString()
                )
              );
            } else {
              dispatch(
                PopoutNotification(
                  NOTIFICATION_TYPE.ERROR,
                  "Overage entry creation failed",
                  Date.now().toString()
                )
              );
            }
          })
          .catch((err) => {
            setPageLoading(false);
            dispatch(
              PopoutNotification(
                NOTIFICATION_TYPE.ERROR,
                err || "Overage entry creation failed",
                Date.now().toString()
              )
            );
          });
      }
    },
  });

  // get  and package code option master
  const getMasters = (ParamString: string) => {
    if (ParamString === "commodityCodeOptions") {
      commodityTypeMasterApi()
        .then((results) => {
          const sortedData = results.data
            .sort((a: IFilterDropdown, b: IFilterDropdown) => parseInt(a?.code!) - parseInt(b?.code!))
            .map((a: IFilterDropdown) => {
              a.code = a?.code?.trim();
              return a;
            });
          if (values?.commodity) {
            updateAutocompleteValue("cType", sortedData.filter((d: IFilterDropdown) => d?.code?.trim() === values?.commodity)[0]);
          }
          dispatch(saveFilterCommodityTypeData(sortedData));
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (ParamString === "packageCode") {
      let params: { dataType: string } = {
        dataType: ParamString,
      };
      getFilterDropdowns(params)
        .then((results: IFilterDropdown[]) => {
          dispatch(savePackageOptionMaster(results));
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (ParamString === "terminalMaster") {
      getTerminals()
        .then((results: TTerminal[]) => {
          dispatch(saveTerminalMasterState(SortTerminals(results)));
          if (currentTerminalValue?.value === "") {
            dispatch(TerminalSelection(results?.find((terminal: TTerminal) => terminal?.value === UserTerminal() && terminal?.region !== null) ?? results[0]));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  // Handle accordion expand
  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (
        ["panel2", "panel3"].includes(panel) &&
        isExpanded &&
        !overageEntrySubmitted &&
        !osdNumber
      )
        return;
        if(osdNumber) {
          setFormFieldsDisabled(true);
          reset(EFormAction.edit);
        } else {
          reset(EFormAction.create);
        }
        setExpanded(prevExpanded => {
          if (isExpanded) {
            // Add the panel to the array if it doesn't already exist
            return prevExpanded.includes(panel) ? prevExpanded : [...prevExpanded, panel];
          } else {
            // Remove the panel from the array
            return prevExpanded.filter(item => item !== panel);
          }
        });
    };

  const enableEditing = () => {
    setFormFieldsDisabled(false);
  };

  // validate pro check digit via API if get pro details proD is valid otherwise not valid and show validation message
  const validateProCheckD = async () => {
    if (!values.proNumber) return;

    const pro = _.get(values, "proNumber", "")!.toString().padStart(11, "0");
    try {
      const checkProResponse = await checkPro(pro);
      if (_.isEmpty(checkProResponse)) {
        setProCheckDError(true);
      } else {
        setProCheckDError(false);
      }

      patchProDetails(checkProResponse);
    } catch (error) {
      patchProDetails({});
      setProCheckDError(true);
    }
  };

  // patch pro details value with OV type got the values from proNumber API as above validateProCheckD method
  const patchProDetails = (checkProResponse: ICheckDigitPro) => {
    const fields : Array<keyof ICheckDigitPro> = CheckDigitFields;
    fields.forEach((f: keyof ICheckDigitPro) => {
      setFieldValue(
        f,
        typeof checkProResponse[f] === "string"
          ? checkProResponse[f]?.trim()
          : checkProResponse[f] || ""
      );
    });
  };

  // set form value on exception type changed
  const reset = (action: EFormAction) => {
    setProCheckDError(false)
    const formValue = JSON.parse(JSON.stringify(values));
    switch (action) {
      case EFormAction.typeChanged:
        if (osdNumber || overageEntrySubmitted) {
          const fieldsToClear = [
            "shipper", "shipperAddress", "shipperCity", "shipperState", "shipperZip",
            "consigneeCity", "consigneeState", "consigneeZip", "consignee", "consigneeAddress"
          ];
          if (formValue.type === "NB") {
            fieldsToClear.push("origin", "destination", "shipperAccount", "consigneeAccount", "proNumber");
          }
          fieldsToClear.forEach(field => setFieldValue(field, ""));
          setFieldValue("reporting", formValue.reporting);
        } else {
          resetForm();
          updateAutocompleteValue("cType", undefined);
          updateInputValue("cInputValue", "");
          updateAutocompleteValue("cCity", undefined);
          updateInputValue("sZipInputValue", "");
          updateAutocompleteValue("cZip", undefined);
          updateInputValue("sCityInputValue", "");
          updateAutocompleteValue("sZip", undefined);
          updateInputValue("cZipInputValue", "");
          updateAutocompleteValue("sCity", undefined);
          updateInputValue("cCityInputValue", "");
          setFieldValue("type", formValue.type);
        }
        break;
      case EFormAction.create:
        resetForm();
          updateAutocompleteValue("cType", undefined);
          updateInputValue("cInputValue", undefined);
          updateAutocompleteValue("cCity", undefined);
          updateInputValue("sZipInputValue", undefined);
          updateAutocompleteValue("cZip", undefined);
          updateInputValue("sCityInputValue", undefined);
          updateAutocompleteValue("sZip", undefined);
          updateInputValue("cZipInputValue", undefined);
          updateAutocompleteValue("sCity", undefined);
          updateInputValue("cCityInputValue", undefined);
          setSZipLocations([]);
          setCZipLocations([]);
          setCCityLocations([]);
          setSCityLocations([]);
        document.getElementsByName("type")[0].focus();
        break;
      case EFormAction.edit:
        resetForm();
        document.getElementsByName("type")[0].focus();
        patchEditFormValues(initialFormValue);
        break;
    }

  };

  // handle confirmation pop while user want back according button
  const handleDialogOpen = () => {
    if (!formValueChanged) navigate(urls[tabPosition - 1] || urls[1]);
    setDialogOpen(true);
  };

  // Close the confirmation modal pop and navigate user to back overage listing page if user confirmed
  const handleDialogClose = (flag?: string) => {
    setDialogOpen(false);
    if (flag === "yes") {
      navigate(urls[tabPosition - 1]);
    }
  };


  const patchEditFormValues = (overageEntry: any) => {
    APP.OverageEntryFields.forEach((field: string) => {
      const value = overageEntry[field];
      const val = typeof value === "string" ? value.trim() : value ?? "";
      setFieldValue(field, val);
    });

    if (overageEntry?.valueRange?.trim() === "$0 - $250") {
      setFieldValue("valueRange", "1");
      setFieldValue("value1k", undefined);
    } else if (overageEntry?.valueRange?.trim() === "$250 - $1000") {
      setFieldValue("valueRange", "2");
      setFieldValue("value1k", undefined);
    } else if (
      overageEntry?.valueRange?.trim() === "GREATER THAN $1000"
    ) {
      setFieldValue("valueRange", "3");
      setFieldValue("value1k", overageEntry?.value1k);
    }
    if (overageEntry?.type === "OV") {
      setFieldValue("proNumber", `${overageEntry?.proNumber}`);
    }
    // commodityCodeOptions
    if (commodityCodeOptions && commodityCodeOptions.length > 0 && overageEntry?.commodity) {
      updateAutocompleteValue("cType", commodityCodeOptions.find(d => d?.code?.trim() === overageEntry?.commodity));
      updateInputValue("cInputValue", overageEntry?.commodity);
    }

    updateInputValue("sZipInputValue", overageEntry?.shipperZip);
    updateInputValue("sCityInputValue", overageEntry?.shipperCity);
    updateInputValue("cZipInputValue", overageEntry?.consigneeZip);
    updateInputValue("cCityInputValue", overageEntry?.consigneeCity);

    setIsEditFormValuePatched(Date.now().toString());
  }

  const viewOverageEntry = (osdId?: number | string) => {
    if (params?.osdNumber || osdNumber || osdId) {
      // get Overage entry and set form fields
      setPageLoading(true);
      GetOverageEntryDetailsAPI(params?.osdNumber || osdNumber || osdId!)
        .then((response) => {
          const overageEntry = response;
          const { 
            createdDate,
            lastUpdatedDate,
            createdTime,
            lastUpdatedTime,
           } = overageEntry; 

          const date = createdDate &&
            createdDate! > lastUpdatedDate!
            ? createdDate
            : lastUpdatedDate;
            const time =  createdDate &&
            createdTime &&
            (createdDate! > lastUpdatedDate! ||
              (createdDate ===
                lastUpdatedDate &&
                createdTime! >
                  lastUpdatedTime!))
            ? createdTime
            : lastUpdatedTime
           
            if (date && time) {
              setUpdatedDateTime(DateHelper.mergerAS400DateTime(date, time));
            }
          setOsdDetails(overageEntry);
          setInvestigationStatus((overageEntry.investigationStatus  ?? EOverageInvestigationStatus.exceptionEntry) as number)
          patchEditFormValues(overageEntry);
          setPageLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setPageLoading(false);
        });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce(
      (values, type) =>
        getLocations(values, type),
      1000
    ), []
  );

  const handleScroll = (event: any, type?: DType) => {
    const listboxNode = event.currentTarget;
    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (listboxNode.scrollHeight - position <= 1) {
      let page;
      switch (type) {
        case DType.shipperZip:
          page = sZipLocations.length / APP.overage.userAPILimit
          if (page < totalPages)
            getLocations(inputValues?.sZipInputValue ?? "", DType.shipperZip, "pagination", page + 1);
          break;
        case DType.shipperCity:
          page = sCityLocations.length / APP.overage.userAPILimit
          if (page < totalPages)
            getLocations(inputValues?.sCityInputValue ?? "", DType.shipperCity, "pagination", page + 1);
          break;
        case DType.consigneeZip:
          page = cZipLocations.length / APP.overage.userAPILimit
          if (page < totalPages)
            getLocations(inputValues?.cZipInputValue ?? "", DType.consigneeZip, "pagination", page + 1);
          break;
        case DType.consigneeCity:
          page = cCityLocations.length / APP.overage.userAPILimit
          if (page < totalPages)
            getLocations(inputValues?.cCityInputValue ?? "", DType.consigneeCity, "pagination", page + 1);
          break;
        default:
          break;
      }
    }

  };

  const [loading, setLoading] = React.useState<boolean>(true);
  const [totalPages, setTotalPages] = React.useState<number>(0);


  const [sZipLocations, setSZipLocations] = React.useState<ILocation[]>([]);
  const [sCityLocations, setSCityLocations] = React.useState<ILocation[]>([]);
  const [cCityLocations, setCCityLocations] = React.useState<ILocation[]>([]);
  const [cZipLocations, setCZipLocations] = React.useState<ILocation[]>([]);

  const getLocations = async (value: string, type: DType, setFlag: string = "", page?: number) => {
    if (!value || value.length < 3) { return; }

    let params = { search: value.toString(), page: page || APP.overage.userAPIpageLimit, limit: APP.overage.userAPILimit, };

    try {
      const results = await locationMasterApi(params);
      setLoading(false);
      const locations: ILocation[] = results.data;
      setTotalPages(results.totalPages);
      switch (type) {
        case DType.shipperZip:
          setSZipLocations(setFlag === "pagination" ? [...sZipLocations, ...locations] : locations || []);
          if (setFlag === "onPageLoad") {
            updateAutocompleteValue("sZip",locations.find((option) => option.CITY === osdDetails?.shipperCity && option.ZIP === osdDetails?.shipperZip));
          }
          break;
        case DType.shipperCity:
          setSCityLocations(setFlag === "pagination" ? [...sCityLocations, ...locations] : locations || []);
          if (setFlag === "onPageLoad") {
            updateAutocompleteValue("sCity", locations.find((option) => option.CITY === osdDetails?.shipperCity && option.ZIP === osdDetails?.shipperZip));
          }
          break;
        case DType.consigneeZip:
          setCZipLocations(setFlag === "pagination" ? [...cZipLocations, ...locations] : locations || []);
          if (setFlag === "onPageLoad") {
            updateAutocompleteValue("cZip", locations.find((option) => option.CITY === osdDetails?.consigneeCity && option.ZIP === osdDetails?.consigneeZip));
          }
          break;
        case DType.consigneeCity:
          setCCityLocations(setFlag === "pagination" ? [...cCityLocations, ...locations] : locations || []);
          if (setFlag === "onPageLoad") {
            updateAutocompleteValue("cCity", locations.find((option) => option.CITY === osdDetails?.consigneeCity && option.ZIP === osdDetails?.consigneeZip));
          }
          break;
        default:
          break;
      }
    } catch (error) { 
      setLoading(false); 
      console.error(error); 
    } 
  };

    // Declare a new state variable for tooltips with a default empty object
    const [openTooltips, setOpenTooltips] = useState<TooltipState>({});

    // Function to handle tooltip close
    const handleTooltipClose = (id: string) => {
      setOpenTooltips((prev) => ({ ...prev, [id]: false }));
    };
  
    // Function to handle tooltip open
    const handleTooltipOpen = (id: string) => {
      setOpenTooltips((prev) => ({ ...prev, [id]: true }));
    };

  useEffect(() => {
      if (commodityCodeOptions && commodityCodeOptions.length > 0 && osdDetails?.commodity) {
        updateAutocompleteValue("cType", commodityCodeOptions.find(d => d?.code?.trim() === osdDetails?.commodity));
        updateInputValue("cInputValue", osdDetails?.commodity)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commodityCodeOptions, osdDetails]);

    useEffect(() => {
      if (isEditFormValuePatched) {
        setInitialFormValue(values);
      }
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditFormValuePatched]);

    useEffect(() => {
      setEditFormValueChanged(!_.isEqual(values, initialFormValue));
      dispatch(isFormValueChanged(!_.isEqual(values, initialFormValue)));
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, initialFormValue]);


    useEffect(() => {
      dispatch(isFormValueChanged(false));
      if (params?.osdNumber) {
        viewOverageEntry();
      }
      if (commodityCodeOptions === null || commodityCodeOptions.length === 0) {
        getMasters("commodityCodeOptions");
      }
      if (packageCodeOptions === null || packageCodeOptions.length === 0) {
        getMasters("packageCode");
      }
      if (terminalOptions === null || terminalOptions.length === 0) {
        getMasters("terminalMaster");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if ((osdDetails && !_.isEmpty(osdDetails))) {
        getLocations(osdDetails.shipperZip, DType.shipperZip, "onPageLoad");
        getLocations(osdDetails.shipperCity || "", DType.shipperCity, "onPageLoad");
        getLocations(osdDetails.consigneeZip, DType.consigneeZip, "onPageLoad");
        getLocations(osdDetails.consigneeCity || "", DType.consigneeCity, "onPageLoad");
      }
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [osdDetails, isEditFormValuePatched]);

    useEffect(() => {
      if (isSubmitting) return;
      if (Object.keys(errors).length > 0) {
        document.getElementsByName(Object.keys(errors)[0])[0].focus();
      }
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitting]);

    return (
      <div className="bg-layout overage-entry">
        {/* header */}
        <div className="header-section">
          <div className="back-section">
            <div className="back" onClick={handleDialogOpen} role="button" tabIndex={0}>
              <ArrowLeftIcon />
            </div>
            <div className="page-hader-content">
              <div className="page-header">Overage Entry</div>
              {params?.osdNumber && (
                <div className="page-subtitle">
                  Last update: &nbsp;
                  { updatedDateTime ? updatedDateTime : "NA" }
                </div>
              )}
            </div>
            {!!osdNumber && <span className="badge">#{osdNumber}</span>}
          </div>
          <div className="edit-section"></div>
        </div>
        <CustomDialog open={dialogOpen} onClose={handleDialogClose} />

        {/* body */}
        <div className="content-area">
          <Accordion
            className="accordion"
            expanded={expanded.includes("panel1")}
            onChange={handleAccordionChange("panel1")}
          >
            <AccordionSummary
              className="header"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography
                className={
                  overageEntrySubmitted || osdNumber
                    ? "queue-check queue-check-gc"
                    : "queue-check"
                }
              >
                1
              </Typography>
              <Typography className="accordion-header">Overage Entry</Typography>
            </AccordionSummary>
            <form action="" onSubmit={handleSubmit} autoComplete="off" >
              <AccordionDetails className="accordion-container">
                <div id="overageEntry">
                  <Grid container spacing={2} className="filter-margin">
                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel style={selectStyle} shrink={true} id="type">
                          Type <sup>&nbsp;</sup>
                        </InputLabel>
                        <Select
                          disabled={formFieldsDisabled}
                          variant="outlined"
                          id="date"
                          tabIndex={0}
                          autoFocus
                          placeholder="Select a Date"
                          className="select"
                          fullWidth
                          name="type"
                          value={values?.type}
                          onChange={(e) => {
                            reset(EFormAction.typeChanged);
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          error={!!errors.type && touched.type}
                          // onBlur={handleBlur}
                          style={selectStyle}
                          IconComponent={() => (
                            <div className="dropdown-icon-group">
                              <ArrowDropUpIcon className="icon-style" />{" "}
                              <ArrowDropDownIcon className="icon-style" />{" "}
                            </div>
                          )}
                        >
                          <MenuItem style={selectStyle} value="NB">
                            NB
                          </MenuItem>
                          <MenuItem style={selectStyle} value="OV">
                            OV
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel style={selectStyle} shrink={true} id="type">
                          Reporting Terminal <sup>*</sup>
                        </InputLabel>
                        <Autocomplete
                          disablePortal
                          disabled={!!(osdNumber || overageEntrySubmitted)}
                          className="select custom-autocomplete"
                          id="reporting-terminal"
                          size="small"
                          disableClearable={true}
                          value={values?.reporting}
                          options={terminalOptions}
                          getOptionLabel={(option) => option || ""}
                          onChange={(e, v) =>  setFieldValue("reporting", v)}
                          noOptionsText={"Records not found"}
                          style={selectStyle}
                          // popupIcon={<KeyboardArrowDownIcon className="icon-style" />}
                          popupIcon={
                            <div className="dropdown-icon-group">
                              <ArrowDropUpIcon className="icon-style" />{" "}
                              <ArrowDropDownIcon className="icon-style" />{" "}
                            </div>
                          }
                          renderInput={(params) => (
                            <TextField  autoComplete="off"
                              {...params}
                              placeholder="Select Reporting Terminal"
                              variant="outlined"
                              className="dropdown-list"
                              style={selectStyle}
                              fullWidth
                              inputProps={{
                                ...params.inputProps,
                                style: { fontSize: "0.9rem" },
                              }}
                            />
                          )}
                        />
                      </FormControl>{" "}
                    </Grid>
                  </Grid>

                  {/* OV select changes  */}
                  {values?.type === "OV" && (
                    <div>
                      <Grid container spacing={2} className="filter-margin">
                        <Grid item md={3} xs={12}>
                          <FormControl fullWidth size="small">
                            <InputLabel
                              style={selectStyle}
                              shrink={true}
                              id="checkDigit"
                            >
                              PRO# <sup>*</sup>
                            </InputLabel>
                            <TextField  autoComplete="off"
                              id="checkDigit"
                              placeholder="Enter Pro Number"
                              variant="outlined"
                              onChange={handleChange}
                              error={(!!errors.proNumber && touched.proNumber) || proCheckDError}
                              disabled={formFieldsDisabled}
                              onBlur={(e) => {
                                validateProCheckD();
                                handleBlur(e);
                              }}
                              value={values.proNumber}
                              name="proNumber"
                              type="number"
                              onPaste={HandlePaste}
                              onKeyDown={HandleKeyDown}
                              onInput={(e: any) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 11);
                              }}
                            />
                          </FormControl>
                          {errors.proNumber && touched.proNumber ? (
                            <span className="form-error">{errors.proNumber}</span>
                          ) : null}

                          {!(errors.proNumber && touched.proNumber) && proCheckDError ? (
                            <span className="form-error">Enter valid PRO number</span>
                          ) : null}
                        </Grid>

                        <Grid item md={3} xs={12}>
                          <FormControl fullWidth size="small">
                            <InputLabel
                              style={selectStyle}
                              shrink={true}
                              id="origin"
                            >
                              Origin Terminal <sup>&nbsp;</sup>
                            </InputLabel>
                            <TextField  autoComplete="off"
                              id="origin"
                              value={values.origin}
                              name="origin"
                              disabled
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>

                        <Grid item md={3} xs={12}>
                          <FormControl fullWidth size="small">
                            <InputLabel style={selectStyle} shrink={true} >
                              Destination Terminal <sup>&nbsp;</sup>
                            </InputLabel>
                            <TextField  autoComplete="off"
                              
                              value={values.destination}
                              name="destination"
                              disabled
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} className="filter-margin">
                        <Grid item md={3} xs={12}>
                          <FormControl fullWidth size="small">
                            <InputLabel style={selectStyle} shrink={true} >
                              Shipper Account # <sup>&nbsp;</sup>
                            </InputLabel>
                            <TextField  autoComplete="off"
                              
                              value={values.shipperAccount}
                              name="shipperAccount"
                              disabled
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>

                        <Grid item md={3} xs={12}>
                          <FormControl fullWidth size="small">
                            <InputLabel style={selectStyle} shrink={true} >
                              Shipper Name <sup>&nbsp;</sup>
                            </InputLabel>
                            <TextField  autoComplete="off"
                              
                              value={values.shipper}
                              name="shipper"
                              disabled
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <FormControl fullWidth size="small">
                            <InputLabel style={selectStyle} shrink={true} >
                              Shipper Address <sup>&nbsp;</sup>
                            </InputLabel>
                            <TextField  autoComplete="off"
                              
                              value={values.shipperAddress}
                              name="shipperAddress"
                              disabled
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} className="filter-margin">
                        <Grid item md={3} xs={12}>
                          <FormControl fullWidth size="small">
                            <InputLabel
                              style={selectStyle}
                              shrink={true}
                              id="shipperCity"
                            >
                              Shipper City
                            </InputLabel>
                            <TextField  autoComplete="off"
                              
                              value={values.shipperCity}
                              name="shipperCity"
                              disabled
                            />
                          </FormControl>
                        </Grid>

                        <Grid item md={3} xs={12}>
                          <FormControl fullWidth size="small">
                            <InputLabel
                              style={selectStyle}
                              shrink={true}
                              id="shipperState"
                            >
                              Shipper State
                            </InputLabel>
                            <TextField  autoComplete="off"
                              
                              value={values.shipperState}
                              name="shipperState"
                              disabled
                            />
                          </FormControl>
                        </Grid>

                        <Grid item md={3} xs={12}>
                          <FormControl fullWidth size="small">
                            <InputLabel
                              style={selectStyle}
                              shrink={true}
                              id="shipperZip"
                            >
                              Shipper Zip
                            </InputLabel>
                            <TextField  autoComplete="off"
                              
                              value={values.shipperZip}
                              name="shipperZip"
                              disabled
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} className="filter-margin">
                        <Grid item md={3} xs={12}>
                          <FormControl fullWidth size="small">
                            <InputLabel style={selectStyle} shrink={true} >
                              Consignee Account # <sup>&nbsp;</sup>
                            </InputLabel>
                            <TextField  autoComplete="off"
                              
                              value={values.consigneeAccount}
                              name="consigneeAccount"
                              disabled
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>

                        <Grid item md={3} xs={12}>
                          <FormControl fullWidth size="small">
                            <InputLabel style={selectStyle} shrink={true} >
                              Consignee Name <sup>&nbsp;</sup>
                            </InputLabel>
                            <TextField  autoComplete="off"
                              
                              value={values.consignee}
                              name="consignee"
                              disabled
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <FormControl fullWidth size="small">
                            <InputLabel style={selectStyle} shrink={true} >
                              Consignee Address <sup>&nbsp;</sup>
                            </InputLabel>
                            <TextField  autoComplete="off"
                              
                              value={values.consigneeAddress}
                              name="consigneeAddress"
                              disabled
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} className="filter-margin">
                        <Grid item md={3} xs={12}>
                          <FormControl fullWidth size="small">
                            <InputLabel
                              style={selectStyle}
                              shrink={true}
                              id="consigneeCity"
                            >
                              Consignee City
                            </InputLabel>
                            <TextField  autoComplete="off"
                              
                              value={values.consigneeCity}
                              name="consigneeCity"
                              disabled
                            />
                          </FormControl>
                        </Grid>

                        <Grid item md={3} xs={12}>
                          <FormControl fullWidth size="small">
                            <InputLabel
                              style={selectStyle}
                              shrink={true}
                              id="consigneeState"
                            >
                              Consignee State
                            </InputLabel>
                            <TextField  autoComplete="off"
                              
                              value={values.consigneeState}
                              name="consigneeState"
                              disabled
                            />
                          </FormControl>
                        </Grid>

                        <Grid item md={3} xs={12}>
                          <FormControl fullWidth size="small">
                            <InputLabel
                              style={selectStyle}
                              shrink={true}
                              id="consigneeZip"
                            >
                              Consignee Zip
                            </InputLabel>
                            <TextField  autoComplete="off"
                              
                              value={values.consigneeZip}
                              name="consigneeZip"
                              disabled
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </div>
                  )}

                  {/* NB select changes  */}

                  {values?.type === "NB" && (
                    <div>
                      <Grid container spacing={2} className="filter-margin">
                        <Grid item md={3} xs={12}>
                          <FormControl fullWidth size="small">
                            <InputLabel
                              style={selectStyle}
                              shrink={true}
                              id="shipper"
                            >
                              Shipper Name <sup>*</sup>
                            </InputLabel>
                            <TextField  autoComplete="off"
                              id="outlined-basic"
                              placeholder="Enter Shipper Name"
                              variant="outlined"
                              onChange={handleChange}
                              error={!!errors.shipper && touched.shipper}
                              disabled={formFieldsDisabled}
                              onBlur={handleBlur}
                              inputProps={{ maxLength: 30 }}
                              name="shipper"
                              value={values.shipper}
                            />
                          </FormControl>
                          {errors.shipper && touched.shipper ? (
                            <span className="form-error">{errors.shipper}</span>
                          ) : null}
                        </Grid>

                        <Grid item md={9} xs={12}>
                          <FormControl fullWidth size="small">
                            <InputLabel
                              style={selectStyle}
                              shrink={true}
                              id="shipperAddress"
                            >
                              Shipper Address
                            </InputLabel>
                            <TextField  autoComplete="off"
                              id="outlined-basic"
                              placeholder="Enter Shipper Address"
                              variant="outlined"
                              onChange={handleChange}
                              error={
                                !!errors.shipperAddress && touched.shipperAddress
                              }
                              disabled={formFieldsDisabled}
                              onBlur={handleBlur}
                              name="shipperAddress"
                              value={values.shipperAddress}
                              inputProps={{ maxLength: 30 }}
                            />
                          </FormControl>
                          {errors.shipperAddress && touched.shipperAddress ? (
                            <span className="form-error">
                              {errors.shipperAddress}
                            </span>
                          ) : null}
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} className="filter-margin">
                        <Grid item md={3} xs={12}>
                          <FormControl fullWidth size="small">
                            <InputLabel
                              style={selectStyle}
                              shrink={true}
                              id="shipperZip"
                            >
                              Shipper Zip
                            </InputLabel>
                            <Autocomplete
                              disablePortal
                              disableClearable={true}
                              size="small"
                              id="shipperZp"
                              disabled={formFieldsDisabled}
                              value={inputValues?.sZipInputValue ?? defaultLocation}
                              key={`${autocompleteValues.sZip?.ZIP}-${autocompleteValues.sZip?.CITY}-${autocompleteValues.sZip?.STATE}-${isEditFormValuePatched}`}
                              inputValue={inputValues.sZipInputValue ?? ""}
                              onChange={(e, v) => handleAutoCompleteOnChange(v, DType.shipperZip)}
                              onInputChange={(e, v) => onAutoInputChange(v, DType.shipperZip)}
                              noOptionsText={
                                NoOptionsText(inputValues.sZipInputValue ?? "", loading, EAutoSearchFieldName.zip, 3)
                              }
                              forcePopupIcon={false}
                              className="autocomplete"
                              options={sZipLocations}
                              getOptionLabel={(option) => (typeof option === "string" ? option : option?.ZIP || "")}
                              isOptionEqualToValue={(option, value) => option?.ZIP === value?.ZIP}

                              renderInput={(params) => (
                                <TextField  autoComplete="off"
                                  placeholder="Enter & Select option"
                                  onBlur={handleBlur}
                                  error={!!errors.shipperZip && touched.shipperZip}
                                  {...params} variant="outlined" />
                              )}
                              renderOption={(props, option) => (
                                <li {...props} key={`${option.ZIP?.trim()} - ${option.CITY.trim()} - ${option.STATE.trim()}-option`} title={`${option.ZIP?.trim()} - ${option.CITY.trim()} - ${option.STATE.trim()}`}>
                                  {`${option.ZIP?.trim()} - ${option.CITY.trim()} - ${option.STATE.trim()}`}
                                </li>
                              )}
                              ListboxProps={{
                                onScroll: (e) => { handleScroll(e, DType.shipperZip) }
                              }}
                            />
                          </FormControl>
                          {errors.shipperZip && touched.shipperZip ? (
                            <span className="form-error">
                              {errors.shipperZip}
                            </span>
                          ) : null}
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <FormControl fullWidth size="small">
                            <InputLabel
                              style={selectStyle}
                              shrink={true}
                              id="shipperCity"
                            >
                              Shipper City
                            </InputLabel>
                            <Autocomplete
                              disableClearable={true}
                              disablePortal
                              size="small"
                              id="shipperCty"
                              disabled={formFieldsDisabled}
                              value={inputValues?.sCityInputValue ?? defaultLocation}
                              key={`${autocompleteValues.sCity?.ZIP}-${autocompleteValues.sCity?.STATE}-${isEditFormValuePatched}`}
                              inputValue={inputValues?.sCityInputValue ?? "" }
                              onChange={(e, v) => handleAutoCompleteOnChange(v, DType.shipperCity)}
                              onInputChange={(e, v) => onAutoInputChange(v, DType.shipperCity)}
                              noOptionsText={
                                NoOptionsText(inputValues.sCityInputValue ?? "", loading, EAutoSearchFieldName.city, 3)
                              }
                              forcePopupIcon={false}
                              className="autocomplete"
                              options={sCityLocations}
                              getOptionLabel={(option) => (typeof option === "string" ? option : option?.CITY || "")}
                              isOptionEqualToValue={(option, value) => option?.CITY === value?.CITY}
                              renderInput={(params) => (
                                <TextField  autoComplete="off"
                                  placeholder="Enter & Select option"
                                  onBlur={handleBlur}
                                  error={!!errors.shipperCity && touched.shipperCity}
                                  {...params} variant="outlined" />
                              )}
                              renderOption={(props, option) => (
                                <li {...props} title={`${option.CITY.trim()} -  ${option.ZIP?.trim()} - ${option.STATE.trim()}`}>
                                  {` ${option.CITY.trim()} - ${option.ZIP?.trim()} - ${option.STATE.trim()}`}
                                </li>
                              )}
                              ListboxProps={{
                                onScroll: (e) => { handleScroll(e, DType.shipperCity) }
                              }}
                            />
                          </FormControl>
                          {errors.shipperCity && touched.shipperCity ? (
                            <span className="form-error">
                              {errors.shipperCity}
                            </span>
                          ) : null}
                        </Grid>

                        <Grid item md={3} xs={12}>
                          <FormControl fullWidth size="small">
                            <InputLabel
                              style={selectStyle}
                              shrink={true}
                              id="shipperState"
                            >
                              Shipper State
                            </InputLabel>
                            <TextField  autoComplete="off"
                              id="outlined-basic"
                              placeholder="Shipper State"
                              variant="outlined"
                              onChange={handleChange}
                              error={
                                !!errors.shipperState && touched.shipperState
                              }
                              disabled
                              onBlur={handleBlur}
                              name="shipperState"
                              value={values.shipperState}
                              inputProps={{ maxLength: 2 }}
                            />
                          </FormControl>
                          {errors.shipperState && touched.shipperState ? (
                            <span className="form-error">
                              {errors.shipperState}
                            </span>
                          ) : null}
                        </Grid>

                      </Grid>

                      <Grid container spacing={2} className="filter-margin">
                        <Grid item md={3} xs={12}>
                          <FormControl fullWidth size="small">
                            <InputLabel
                              style={selectStyle}
                              shrink={true}
                              id="consignee"
                            >
                              Consignee Name <sup>*</sup>
                            </InputLabel>
                            <TextField  autoComplete="off"
                              id="outlined-basic"
                              inputProps={{ maxLength: 30 }}
                              placeholder="Enter Consignee Name"
                              variant="outlined"
                              onChange={handleChange}
                              error={!!errors.consignee && touched.consignee}
                              disabled={formFieldsDisabled}
                              onBlur={handleBlur}
                              name="consignee"
                              value={values.consignee}
                            />
                          </FormControl>
                          {errors.consignee && touched.consignee ? (
                            <span className="form-error">{errors.consignee}</span>
                          ) : null}
                        </Grid>

                        <Grid item md={9} xs={12}>
                          <FormControl fullWidth size="small">
                            <InputLabel
                              style={selectStyle}
                              shrink={true}
                              id="consigneeAddress"
                            >
                              Consignee Address
                            </InputLabel>
                            <TextField  autoComplete="off"
                              inputProps={{ maxLength: 30 }}
                              id="outlined-basic"
                              placeholder="Enter Consignee Address"
                              variant="outlined"
                              onChange={handleChange}
                              error={
                                !!errors.consigneeAddress &&
                                touched.consigneeAddress
                              }
                              disabled={formFieldsDisabled}
                              onBlur={handleBlur}
                              name="consigneeAddress"
                              value={values.consigneeAddress}
                            />
                          </FormControl>
                          {errors.consigneeAddress && touched.consigneeAddress ? (
                            <span className="form-error">
                              {errors.consigneeAddress}
                            </span>
                          ) : null}
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} className="filter-margin">
                        <Grid item md={3} xs={12}>
                          <FormControl fullWidth size="small">
                            <InputLabel
                              style={selectStyle}
                              shrink={true}
                              id="consigneeZip"
                            >
                              Consignee Zip
                            </InputLabel>
                            <Autocomplete
                              disableClearable={true}
                              disablePortal
                              size="small"
                              id="ConsigneeZp"
                              disabled={formFieldsDisabled}
                              value={inputValues?.cZipInputValue ?? defaultLocation}
                              key={`${autocompleteValues.cZip?.ZIP}-${autocompleteValues.cZip?.CITY}-${autocompleteValues.cZip?.STATE}-${isEditFormValuePatched}`}
                              inputValue={inputValues.cZipInputValue ?? ""}
                              onChange={(e, v) => handleAutoCompleteOnChange(v, DType.consigneeZip)}
                              onInputChange={(e, v) => onAutoInputChange(v, DType.consigneeZip)}
                              noOptionsText={
                                NoOptionsText(inputValues.cZipInputValue ?? "", loading, EAutoSearchFieldName.zip, 3)
                              }
                              forcePopupIcon={false}
                              className="autocomplete"
                              options={cZipLocations}
                              getOptionLabel={(option) => (typeof option === "string" ? option : option?.ZIP || "")}
                              isOptionEqualToValue={(option, value) => option?.ZIP === value?.ZIP}
                              renderInput={(params) => (
                                <TextField  autoComplete="off"
                                  placeholder="Enter & Select option"
                                  onBlur={handleBlur}
                                  error={!!errors.consigneeZip && touched.consigneeZip}
                                  {...params} variant="outlined" />
                              )}
                              renderOption={(props, option) => (
                                <li {...props} title={`${option.ZIP.trim()} -  ${option.CITY?.trim()} - ${option.STATE.trim()}`}>
                                  {` ${option.ZIP.trim()} - ${option.CITY?.trim()} - ${option.STATE.trim()}`}
                                </li>
                              )}
                              ListboxProps={{
                                onScroll: (e) => { handleScroll(e, DType.consigneeZip) }
                              }}
                            />
                          </FormControl>
                          {errors.consigneeZip && touched.consigneeZip ? (
                            <span className="form-error">
                              {errors.consigneeZip}
                            </span>
                          ) : null}
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <FormControl fullWidth size="small">
                            <InputLabel
                              style={selectStyle}
                              shrink={true}
                              id="consigneeCity"
                            >
                              Consignee City
                            </InputLabel>
                            <Autocomplete
                              disableClearable={true}
                              disablePortal
                              size="small"
                              id="consigneeCty"
                              disabled={formFieldsDisabled}
                              value={inputValues?.cCityInputValue ?? defaultLocation}
                              key={`${autocompleteValues.cCity?.ZIP}-${autocompleteValues.cCity?.CITY}-${autocompleteValues.cCity?.STATE}-${isEditFormValuePatched}`}
                              inputValue={inputValues?.cCityInputValue ?? ""}
                              onChange={(e, v) => handleAutoCompleteOnChange(v, DType.consigneeCity)}
                              onInputChange={(e, v) => onAutoInputChange(v, DType.consigneeCity)}
                              noOptionsText={
                                NoOptionsText(inputValues.cCityInputValue ?? "", loading, EAutoSearchFieldName.city, 3)
                              }
                              forcePopupIcon={false}
                              className="autocomplete"
                              options={cCityLocations}
                              getOptionLabel={(option) => (typeof option === "string" ? option : option?.CITY || "")}
                              isOptionEqualToValue={(option, value) => option?.CITY === value?.CITY}
                              renderInput={(params) => (
                                <TextField
                                  autoComplete="off"
                                  placeholder="Enter & Select option"
                                  onBlur={handleBlur}
                                  error={!!errors.consigneeCity && touched.consigneeCity}
                                  {...params} variant="outlined" />
                              )}
                              renderOption={(props, option) => (
                                <li {...props} title={`${option.CITY.trim()} - ${option.ZIP?.trim()} - ${option.STATE.trim()}`}>
                                  {`${option.CITY.trim()} - ${option.ZIP?.trim()} - ${option.STATE.trim()}`}
                                </li>
                              )}
                              ListboxProps={{
                                onScroll: (e) => { handleScroll(e, DType.consigneeCity) }
                              }}
                            />
                          </FormControl>
                          {errors.consigneeCity && touched.consigneeCity ? (
                            <span className="form-error">
                              {errors.consigneeCity}
                            </span>
                          ) : null}
                        </Grid>

                        <Grid item md={3} xs={12}>
                          <FormControl fullWidth size="small">
                            <InputLabel
                              style={selectStyle}
                              shrink={true}
                              id="consigneeState"
                            >
                              Consignee State
                            </InputLabel>
                            <TextField  autoComplete="off"
                              id="outlined-basic"
                              placeholder="Consignee State"
                              variant="outlined"
                              onChange={handleChange}
                              error={
                                !!errors.consigneeState && touched.consigneeState
                              }
                              disabled
                              onBlur={handleBlur}
                              name="consigneeState"
                              value={values.consigneeState}
                              inputProps={{ maxLength: 2 }}
                            />
                          </FormControl>
                          {errors.consigneeState && touched.consigneeState ? (
                            <span className="form-error">
                              {errors.consigneeState}
                            </span>
                          ) : null}
                        </Grid>
                      </Grid>
                    </div>
                  )}

                  <Grid container spacing={2} className="filter-margin">
                    <Grid item md={3} xs={12}>
                      <FormControl className="radio-group">
                        <FormLabel id="od400Bill">
                          Was an OD400 Bill Created? <sup>*</sup>
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="od400Bill"
                          name="od400Completed"
                        >
                          <FormControlLabel
                            value="Y"
                            control={<Radio />}
                            label="Yes"
                            name="od400Completed"
                            onChange={handleChange}
                            disabled={formFieldsDisabled}
                            onBlur={handleBlur}
                            checked={values.od400Completed === "Y"}
                          />
                          <FormControlLabel
                            value="N"
                            control={<Radio />}
                            label="No"
                            name="od400Completed"
                            onChange={(e) => {
                              setFieldValue("od400ProNumber", undefined);
                              handleChange(e);
                            }}
                            disabled={formFieldsDisabled}
                            onBlur={handleBlur}
                            checked={values.od400Completed === "N"}
                          />
                        </RadioGroup>
                        {errors.od400Completed && touched.od400Completed ? (
                          <span className="form-error">
                            {errors.od400Completed}
                          </span>
                        ) : null}
                        {values.od400Completed === "N" ? (
                          <span className="form-error">
                            Cannot proceed until OD400 bill is created
                          </span>
                        ) : null}
                      </FormControl>
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel
                          style={selectStyle}
                          shrink={true}
                          id="od400ProNumber"
                        >
                          OD400 PRO# <sup>*</sup>
                        </InputLabel>
                        <TextField  autoComplete="off"
                          disabled={
                            values.od400Completed !== "Y" || formFieldsDisabled
                          }
                          id="outlined-basic"
                          placeholder="Enter OD400 PRO#"
                          variant="outlined"
                          onChange={handleChange}
                          error={
                            !!errors.od400ProNumber && touched.od400ProNumber
                          }
                          onBlur={handleBlur}
                          name="od400ProNumber"
                          type="number"
                          onPaste={HandlePaste}
                          onKeyDown={HandleKeyDown}
                          onInput={(e: any) => {
                            e.target.value = Math.max(0, parseInt(e.target.value))
                              .toString()
                              .slice(0, 11);
                          }}
                          inputProps={{
                            maxLength: 11,
                          }}
                          value={
                            values.od400Completed === "N"
                              ? ""
                              : values.od400ProNumber
                          }
                        />
                      </FormControl>
                      {errors.od400ProNumber && touched.od400ProNumber ? (
                        <span className="form-error">
                          {errors.od400ProNumber}
                        </span>
                      ) : null}
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel
                          style={selectStyle}
                          shrink={true}
                          id="od400Manifest"
                        >
                          If located on Manifest, Provide Manifest #{" "}
                          <sup>&nbsp;</sup>
                        </InputLabel>
                        <TextField  autoComplete="off"
                          id="outlined-basic"
                          placeholder="Enter Manifest Number"
                          variant="outlined"
                          type="number"
                          onPaste={HandlePaste}
                          onKeyDown={HandleKeyDown}
                          onInput={(e: any) => {
                            e.target.value = Math.max(0, parseInt(e.target.value))
                              .toString()
                              .slice(0, 9);
                          }}
                          disabled={formFieldsDisabled}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={!!errors.od400Manifest && touched.od400Manifest}
                          name="od400Manifest"
                          value={values.od400Manifest}
                        />
                      </FormControl>
                      {errors.od400Manifest && touched.od400Manifest ? (
                        <span className="form-error">{errors.od400Manifest}</span>
                      ) : null}
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} className="">
                    <Grid item md={9} xs={12}>
                      <FormControl className="min-width checkbox-group">
                        <FormLabel id="shipmentLocation">
                          Was Shipment Located On<sup>*</sup>
                        </FormLabel>

                        <Grid container spacing={2}>
                          <Grid item md={4} xs={12} className="p10">
                            <FormGroup className="form-row">
                              <FormControlLabel
                                value="T"
                                control={<Radio />}
                                label="Trailer"
                                name="trailerOrBay"
                                onChange={handleChange}
                                disabled={formFieldsDisabled}
                                onBlur={handleBlur}
                                className="pull-right-label"
                                checked={values.trailerOrBay === "T"}
                              />
                              <FormGroup className="form-validation">
                                {values.trailerOrBay === "T" && (
                                  <>
                                    <TextField  autoComplete="off"
                                      className="justify-content"
                                      id="outlined-basic"
                                      placeholder="Enter Trailer Number"
                                      variant="outlined"
                                      onChange={handleChange}
                                      error={!!errors.trailer && touched.trailer}
                                      disabled={formFieldsDisabled}
                                      onBlur={handleBlur}
                                      name="trailer"
                                      inputProps={{ maxLength: 15 }}
                                      value={values.trailer}
                                    />
                                    {errors.trailer && touched.trailer ? (
                                      <span className="form-error">
                                        {errors.trailer}
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                            </FormGroup>
                          </Grid>

                          <Grid item md={4} xs={12} className="p10">
                            <FormGroup className="form-row">
                              <FormControlLabel
                                value="B"
                                control={<Radio />}
                                label="Bay"
                                name="trailerOrBay"
                                onChange={handleChange}
                                disabled={formFieldsDisabled}
                                onBlur={handleBlur}
                                checked={values.trailerOrBay === "B"}
                                className="pull-right-label"
                              />
                              <FormGroup className="form-validation">
                                {values.trailerOrBay === "B" && (
                                  <>
                                    <TextField  autoComplete="off"
                                      className="justify-content"
                                      id="outlined-basic"
                                      placeholder="Enter Bay Number"
                                      variant="outlined"
                                      onChange={handleChange}
                                      error={!!errors.bay && touched.bay}
                                      disabled={formFieldsDisabled}
                                      onBlur={handleBlur}
                                      name="bay"
                                      value={values.bay}
                                      inputProps={{ maxLength: 4 }}
                                    />
                                    {errors.bay && touched.bay ? (
                                      <span className="form-error">{errors.bay}</span>
                                    ) : null}
                                  </>
                                )}
                              </FormGroup>
                            </FormGroup>
                          </Grid>

                          <Grid item md={4} xs={12} className="p10"></Grid>
                          {errors.trailerOrBay && touched.trailerOrBay ? (
                            <span className="form-error checkbox-group-validation">
                              {errors.trailerOrBay}
                            </span>
                          ) : null}
                        </Grid>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} className="filter-margin">
                    <Grid item md={9} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel
                          style={selectStyle}
                          shrink={true}
                          id="shipmentHave"
                        >
                          Does Shipment Have<sup>&nbsp;</sup>
                        </InputLabel>

                        <Grid container spacing={2}>
                          <Grid item md={4} xs={12}>
                            <FormGroup className="form-row">
                              <TextField  autoComplete="off"
                                className="justify-content disabled"
                                
                                value="BL#"
                                variant="outlined"
                                disabled
                              />

                              <TextField  autoComplete="off"
                                className="justify-content"
                                
                                placeholder="Enter BL Number"
                                variant="outlined"
                                onChange={handleChange}
                                disabled={formFieldsDisabled}
                                error={!!errors.billNumber && touched.billNumber}
                                name="billNumber"
                                value={values.billNumber}
                                inputProps={{ maxLength: 20 }}
                              />
                            </FormGroup>
                            {errors.billNumber && touched.billNumber ? (
                              <span className="form-error">
                                {errors.billNumber}
                              </span>
                            ) : null}
                          </Grid>

                          <Grid item md={4} xs={12}>
                            <FormGroup className="form-row">
                              <TextField  autoComplete="off"
                                className="justify-content disabled"
                                
                                value="PO#"
                                variant="outlined"
                                disabled
                              />

                              <TextField  autoComplete="off"
                                className="justify-content"
                                
                                placeholder="Enter PO Number"
                                variant="outlined"
                                onChange={handleChange}
                                disabled={formFieldsDisabled}
                                error={!!errors.poNumber && touched.poNumber}
                                name="poNumber"
                                value={values.poNumber}
                                inputProps={{ maxLength: 20 }}
                              />
                            </FormGroup>
                            {errors.poNumber && touched.poNumber ? (
                              <span className="form-error">
                                {errors.poNumber}
                              </span>
                            ) : null}
                          </Grid>

                          <Grid item md={4} xs={12}>
                            <FormGroup className="form-row">
                              <TextField  autoComplete="off"
                                className="justify-content disabled"
                                
                                value="SN#"
                                variant="outlined"
                                disabled
                              />

                              <TextField  autoComplete="off"
                                className="justify-content"
                                
                                placeholder="Enter SN Number"
                                variant="outlined"
                                onChange={handleChange}
                                disabled={formFieldsDisabled}
                                error={!!errors.snNumber && touched.snNumber}
                                name="snNumber"
                                value={values.snNumber}
                                inputProps={{ maxLength: 20 }}
                              />
                            </FormGroup>
                            {errors.snNumber && touched.snNumber ? (
                              <span className="form-error">
                                {errors.snNumber}
                              </span>
                            ) : null}
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <FormControl className="radio-group">
                        <FormLabel id="hazmat">
                          Haz Mat <sup>&nbsp;</sup>
                        </FormLabel>
                        <RadioGroup row aria-labelledby="hazmat" name="hazmat">
                          <FormControlLabel
                            value="Y"
                            control={<Radio />}
                            label="Yes"
                            name="hazmat"
                            onChange={handleChange}
                            disabled={formFieldsDisabled}
                            onBlur={handleBlur}
                            checked={values.hazmat === "Y"}
                          />
                          <FormControlLabel
                            value="N"
                            control={<Radio />}
                            label="No"
                            name="hazmat"
                            onChange={handleChange}
                            disabled={formFieldsDisabled}
                            onBlur={handleBlur}
                            checked={values.hazmat === "N"}
                          />
                        </RadioGroup>
                        {errors.hazmat && touched.hazmat ? (
                          <span className="form-error">{errors.hazmat}</span>
                        ) : null}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} className="filter-margin">
                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel
                          style={selectStyle}
                          shrink={true}
                          id="handlingUnits"
                        >
                          How Many Handling Units Located <sup>*</sup>
                        </InputLabel>
                        <TextField  autoComplete="off"
                          type="number"
                          onPaste={HandlePaste}
                          id="outlined-basic"
                          placeholder="Enter Units Number"
                          variant="outlined"
                          onChange={handleChange}
                          error={!!errors.handlingUnits && touched.handlingUnits}
                          disabled={formFieldsDisabled}
                          onBlur={handleBlur}
                          name="handlingUnits"
                          value={values.handlingUnits}
                          onKeyDown={HandleKeyDown}
                          onInput={(e: any) => {
                            e.target.value = Math.max(0, parseInt(e.target.value))
                              .toString()
                              .slice(0, 5);
                          }}
                        />
                      </FormControl>
                      {errors.handlingUnits && touched.handlingUnits ? (
                        <span className="form-error">{errors.handlingUnits}</span>
                      ) : null}
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <FormControl
                        style={{ minWidth: 120 }}
                        fullWidth
                        size="small"
                      >
                        <InputLabel
                          style={selectStyle}
                          shrink={true}
                          id="commodity"
                        >
                          Commodity <sup>*</sup>
                        </InputLabel>
                        <Autocomplete
                          disabled={formFieldsDisabled}
                          className="custom-autocomplete"
                          noOptionsText="Records not found"
                          disablePortal
                          getOptionLabel={(option) => `${option.code} -  ${option.displayValue}`}
                          value={autocompleteValues.cType}
                          key={autocompleteValues.cType?.code}
                          inputValue={inputValues.cInputValue}
                          onClose={() => {
                            updateInputValue("cInputValue", "");
                          }}
                          onInputChange={(e, v) => onAutoInputChange(v, DType.commodity)}
                          forcePopupIcon={false}
                          options={commodityCodeOptions}
                          onChange={(e, v) => handleAutoCompleteOnChange(v, DType.commodity)}
                          disableClearable={true}
                          renderOption={(props, option) => (
                            <li {...props} title={`${option.code?.trim()} - ${option.displayValue.trim()}`}>
                              {option.code?.trim()} - {option.displayValue.trim()}
                            </li>
                          )}

                          filterOptions={(options, { inputValue }) =>
                            options.filter(option =>
                              option.displayValue.concat(" ", option?.code ?? "")?.toLowerCase().includes(inputValue?.toLowerCase())
                            )
                          }
                          renderInput={(params) => (
                            <TextField  autoComplete="off"
                              name="commodity"
                              placeholder="Enter & Select Commodity"
                              onBlur={handleBlur}
                              error={!!errors.commodity && touched.commodity && !values.commodity}
                              {...params} variant="outlined" />
                          )}
                          renderTags={(value) => (
                            <div className="auto-complete-selected-container" title={value.join(", ")}>
                              {value.join(", ")}
                            </div>
                          )}
                        />
                      </FormControl> 
                      {errors.commodity && !values.commodity && touched.commodity ? (
                        <span className="form-error">{errors.commodity}</span>
                      ) : null}
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel
                          style={selectStyle}
                          shrink={true}
                          id="package"
                        >
                          Package Type <sup>*</sup>
                        </InputLabel>
                        <Select
                          variant="outlined"
                          id="package"
                          className="select"
                          fullWidth
                          onChange={handleChange}
                          error={!!errors.package && touched.package && !values.package}
                          disabled={formFieldsDisabled}
                          onBlur={handleBlur}
                          name="package"
                          value={values.package}
                          style={selectStyle}
                          displayEmpty
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return <em>Select Package Type</em>;
                            }
                            return selected;
                          }}
                          IconComponent={() => (
                            <div className="dropdown-icon-group">
                              <ArrowDropUpIcon className="icon-style" />{" "}
                              <ArrowDropDownIcon className="icon-style" />{" "}
                            </div>
                          )}
                        >
                          {packageCodeOptions.map((obj: any) => (
                            <MenuItem
                              style={selectStyle}
                              key={obj?.actualValue}
                              value={obj?.actualValue}
                            >
                              {obj?.actualValue}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {errors.package && touched.package && !values.package ? (
                        <span className="form-error">{errors.package}</span>
                      ) : null}
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel style={selectStyle} shrink={true} id="pieces">
                          Pieces <sup>*</sup>
                        </InputLabel>
                        <TextField  autoComplete="off"
                          id="outlined-basic"
                          placeholder="Enter Pieces"
                          type="number"
                          onPaste={HandlePaste}
                          variant="outlined"
                          onChange={handleChange}
                          error={!!errors.pieces && touched.pieces}
                          disabled={formFieldsDisabled}
                          onBlur={handleBlur}
                          name="pieces"
                          value={values.pieces}
                          onKeyDown={HandleKeyDown}
                          onInput={(e: any) => {
                            e.target.value = Math.max(0, parseInt(e.target.value))
                              .toString()
                              .slice(0, 5);
                          }}
                        />
                      </FormControl>
                      {errors.pieces && touched.pieces ? (
                        <span className="form-error">{errors.pieces}</span>
                      ) : null}
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} className="filter-margin">
                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel style={selectStyle} shrink={true} id="weight">
                          Weight <sup>*</sup>
                        </InputLabel>
                        <TextField  autoComplete="off"
                          type="number"
                          onPaste={HandlePaste}
                          id="weight"
                          placeholder="Enter Weight"
                          variant="outlined"
                          onChange={handleChange}
                          error={!!errors.weight && touched.weight}
                          disabled={formFieldsDisabled}
                          onBlur={handleBlur}
                          name="weight"
                          value={values.weight}
                          onKeyDown={HandleKeyDown}
                          onInput={(e: any) => {
                            e.target.value = Math.max(0, parseInt(e.target.value))
                              .toString()
                              .slice(0, 7);
                          }}
                        />
                      </FormControl>
                      {errors.weight && touched.weight ? (
                        <span className="form-error">{errors.weight}</span>
                      ) : null}
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <FormControl className="radio-group">
                        <FormLabel id="shrinkWrap">
                          Was Shipment Shrink Wrapped? <sup>*</sup>
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="shrinkWrap"
                          name="shrinkWrap"
                        >
                          <FormControlLabel
                            value="Y"
                            control={<Radio />}
                            label="Yes"
                            name="shrinkWrap"
                            onChange={handleChange}
                            disabled={formFieldsDisabled}
                            onBlur={handleBlur}
                            checked={values.shrinkWrap === "Y"}
                          />
                          <FormControlLabel
                            value="N"
                            control={<Radio />}
                            label="No"
                            name="shrinkWrap"
                            onChange={handleChange}
                            disabled={formFieldsDisabled}
                            onBlur={handleBlur}
                            checked={values.shrinkWrap === "N"}
                          />
                        </RadioGroup>
                        {errors.shrinkWrap && touched.shrinkWrap ? (
                          <span className="form-error">{errors.shrinkWrap}</span>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel
                          style={selectStyle}
                          shrink={true}
                          id="consigneeAddress"
                        >
                          Does the shipment have (Item#, Carton#, Model#, Serial#) <sup>*</sup>
                        </InputLabel>
                        <TextField  autoComplete="off"
                          inputProps={{ maxLength: 56 }}
                          id="outlined-basic"
                          placeholder="Enter Item#, Carton#, Model#, Serial# with comma separated"
                          variant="outlined"
                          onChange={handleChange}
                          error={
                            !!errors.itemCartonModelSerialNumber &&
                            touched.itemCartonModelSerialNumber
                          }
                          disabled={formFieldsDisabled}
                          onBlur={handleBlur}
                          name="itemCartonModelSerialNumber"
                          value={values.itemCartonModelSerialNumber}
                        />
                      </FormControl>
                      {errors.itemCartonModelSerialNumber && touched.itemCartonModelSerialNumber ? (
                        <span className="form-error">
                          {errors.itemCartonModelSerialNumber}
                        </span>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    className="filter-margin align-items"
                  >
                    <Grid item md={9} xs={12}>
                      <FormControl className="radio-group value-range">
                        <FormLabel id="valueRange">
                          Value Range <sup>*</sup>
                        </FormLabel>

                        <Grid container spacing={2}>
                          <Grid
                            item
                            md={12}
                            xs={12}
                            className="float-div form-content-start"
                          >
                            <FormGroup className="shift-left">
                              <RadioGroup
                                row
                                aria-labelledby="valueRange"
                                name="valueRange"
                                value={values.valueRange}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                <FormControlLabel
                                  value="1"
                                  disabled={!!(osdNumber || overageEntrySubmitted)}
                                  control={<Radio />}
                                  label="$0 - $250.00"
                                  name="valueRange"
                                  onChange={handleChange}
                                  checked={values.valueRange === "1"}
                                />
                                <FormControlLabel
                                  value="2"
                                  disabled={!!(osdNumber || overageEntrySubmitted)}
                                  control={<Radio />}
                                  label="$250.00 - $1,000.00"
                                  name="valueRange"
                                  onChange={handleChange}
                                  checked={values.valueRange === "2"}
                                />
                                <FormControlLabel
                                  disabled={!!(osdNumber || overageEntrySubmitted)}
                                  value="3"
                                  control={<Radio />}
                                  label="Greater than $1,000.00"
                                  name="valueRange"
                                  onChange={handleChange}
                                  checked={values.valueRange === "3"}
                                />
                                <TooltipWithClickAway
                                tooltipText="Round the value up to the nearest dollar amount"
                                tooltipKey="tooltip1"
                                openTooltips={openTooltips}
                                handleTooltipOpen={handleTooltipOpen}
                                handleTooltipClose={handleTooltipClose}
                              />
                              </RadioGroup>
                            </FormGroup>

                            {values.valueRange === "3" && (
                              <FormGroup className="no-wrap">
                                <FormGroup className="form-row shift-left">
                                  <TextField  autoComplete="off"
                                    className="justify-content disabled"
                                    
                                    value="$"
                                    variant="outlined"
                                    disabled
                                  />
                                  <TextField  autoComplete="off"
                                    type="number"
                                    onPaste={HandlePaste}
                                    className="justify-content"
                                    
                                    placeholder="Enter Amount"
                                    variant="outlined"
                                    onChange={handleChange}
                                    error={!!errors.value1k && touched.value1k}
                                    disabled={!!(osdNumber || overageEntrySubmitted)}
                                    onBlur={handleBlur}
                                    name="value1k"
                                    value={values.value1k}
                                    onKeyDown={HandleKeyDown}
                                    onInput={(e: any) => {
                                      e.target.value = Math.max(
                                        0,
                                        parseInt(e.target.value)
                                      )
                                        .toString()
                                        .slice(0, 10);
                                    }}
                                  />
                                </FormGroup>
                                {errors.value1k && touched.value1k ? (
                                  <span className="form-error">
                                    {errors.value1k}
                                  </span>
                                ) : null}
                              </FormGroup>
                            )}
                          </Grid>
                          {errors.valueRange && touched.valueRange ? (
                            <span className="form-error radio-group-validation">
                              {errors.valueRange}
                            </span>
                          ) : null}
                        </Grid>
                      </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel style={selectStyle} shrink={true} id="weight">
                          Who Is The Manufacturer And/Or Brand
                        </InputLabel>
                        <TextField  autoComplete="off"
                          id="weight"
                          error={
                            !!errors.manufacturerBrand &&
                            touched.manufacturerBrand
                          }
                          placeholder="Manufacturer And/Or Brand"
                          variant="outlined"
                          onChange={handleChange}
                          disabled={formFieldsDisabled}
                          onBlur={handleBlur}
                          name="manufacturerBrand"
                          value={values.manufacturerBrand}
                          inputProps={{ maxLength: 30 }}
                        />
                      </FormControl>
                      {errors.manufacturerBrand && touched.manufacturerBrand ? (
                        <span className="form-error">
                          {errors.manufacturerBrand}
                        </span>
                      ) : null}
                    </Grid>
                  </Grid>

                  <h3 className="accordion-content-header">Additional Details</h3>

                  <Grid container spacing={2} className="filter-margin">
                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel
                          style={selectStyle}
                          shrink={true}
                          id="dimension"
                        >
                          Exact Dimensions/Measurements <sup>*</sup>
                        </InputLabel>
                        <TextField  autoComplete="off"
                          id="dimension"
                          placeholder="Enter Dimensions/Measurements"
                          variant="outlined"
                          onChange={handleChange}
                          error={!!errors.dimension && touched.dimension}
                          name="dimension"
                          disabled={formFieldsDisabled}
                          onBlur={handleBlur}
                          value={values.dimension}
                          inputProps={{ maxLength: 75 }}
                        />
                      </FormControl>
                      {errors.dimension && touched.dimension ? (
                        <span className="form-error">{errors.dimension}</span>
                      ) : null}
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel
                          style={selectStyle}
                          shrink={true}
                          id="barcode"
                        >
                          Barcode <sup>&nbsp;</sup>
                        </InputLabel>
                        <TextField  autoComplete="off"
                          id="barcode"
                          placeholder="Enter Barcode"
                          variant="outlined"
                          onChange={handleChange}
                          error={!!errors.barcode && touched.barcode}
                          disabled={formFieldsDisabled}
                          onBlur={handleBlur}
                          name="barcode"
                          value={values.barcode}
                          inputProps={{ maxLength: 75 }}
                        />
                      </FormControl>
                      {errors.barcode && touched.barcode ? (
                        <span className="form-error">{errors.barcode}</span>
                      ) : null}
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel
                          style={selectStyle}
                          shrink={true}
                          id="colorCode"
                        >
                          Color Coding <sup>*</sup>
                        </InputLabel>
                        <TextField  autoComplete="off"
                          id="colorCode"
                          placeholder="Enter Color Codes"
                          variant="outlined"
                          onChange={handleChange}
                          error={!!errors.colorCode && touched.colorCode}
                          disabled={formFieldsDisabled}
                          onBlur={handleBlur}
                          name="colorCode"
                          value={values.colorCode}
                          inputProps={{ maxLength: 75 }}
                        />
                      </FormControl>
                      {errors.colorCode && touched.colorCode ? (
                        <span className="form-error">{errors.colorCode}</span>
                      ) : null}
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel
                          style={selectStyle}
                          shrink={true}
                          id="heatNumbers"
                        >
                          Heat Numbers <sup>&nbsp;</sup>
                        </InputLabel>
                        <TextField  autoComplete="off"
                          id="colorCode"
                          placeholder="Enter Heat Numbers"
                          variant="outlined"
                          onChange={handleChange}
                          error={!!errors.heatNumbers && touched.heatNumbers}
                          name="heatNumbers"
                          value={values.heatNumbers}
                          disabled={formFieldsDisabled}
                          onBlur={handleBlur}
                          inputProps={{ maxLength: 75 }}
                        />
                      </FormControl>
                      {errors.heatNumbers && touched.heatNumbers ? (
                        <span className="form-error">{errors.heatNumbers}</span>
                      ) : null}
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} className="filter-margin">
                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel
                          style={selectStyle}
                          shrink={true}
                          id="agentInterline"
                        >
                          Agent/Interline Info And/Or Pro <sup>&nbsp;</sup>
                        </InputLabel>
                        <TextField  autoComplete="off"
                          id="agentInterline"
                          placeholder="Enter Agent/Interline Info And/Or Pro"
                          variant="outlined"
                          onChange={handleChange}
                          disabled={formFieldsDisabled}
                          error={
                            !!errors.manufacturerBrand &&
                            touched.manufacturerBrand
                          }
                          name="agentInterline"
                          value={values.agentInterline}
                          inputProps={{ maxLength: 75 }}
                        />
                      </FormControl>
                      {errors.agentInterline && touched.agentInterline ? (
                        <span className="form-error">
                          {errors.agentInterline}
                        </span>
                      ) : null}
                    </Grid>

                    <Grid item md={9} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel
                          style={selectStyle}
                          shrink={true}
                          id="description"
                        >
                          Description <sup>*</sup>
                        </InputLabel>
                        <TextField  autoComplete="off"
                          id="description"
                          placeholder="Enter Additional Description"
                          variant="outlined"
                          onChange={handleChange}
                          error={!!errors.additionalDescription && touched.additionalDescription}
                          disabled={formFieldsDisabled}
                          onBlur={handleBlur}
                          name="additionalDescription"
                          value={values.additionalDescription}
                        // inputProps={{ maxLength: 350 }}
                        />
                      </FormControl>
                      {errors.additionalDescription && touched.additionalDescription ? (
                        <span className="form-error">{errors.additionalDescription}</span>
                      ) : null}
                    </Grid>
                  </Grid>
                </div>
                <div className="btn-group-submit">
                  <Button
                    variant="contained"
                    onClick={() => {
                      overageEntrySubmitted || osdNumber
                        ? handlePrint()
                        : void 0;
                    }}
                    className={
                      overageEntrySubmitted || osdNumber
                        ? "entry"
                        : "disable-btn disable entry"
                    }
                  >
                    Print Face Sheet
                  </Button>
                  {!!osdNumber && !formFieldsDisabled && (
                    <>
                      <Button
                        type="button"
                        variant="contained"
                        className="clear-btn mr-0 ml"
                        onClick={() => reset(EFormAction.edit)}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        className={
                          editFormValueChanged
                            ? "assign mr-0 ml"
                            : "disable-btn disable assign mr-0 ml"
                        }
                      >
                        Update
                      </Button>
                    </>
                  )}
                  {!osdNumber && !overageEntrySubmitted && (
                    <>
                      <Button
                        type="button"
                        variant="contained"
                        className="clear-btn mr-0 ml"
                        onClick={() => reset(EFormAction.create)}
                      >
                        Clear
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        className={
                          overageEntrySubmitted
                            ? "disable-btn disable entry ml"
                            : "entry ml"
                        }
                      >
                        {values.valueRange === "3" ? "Submit & Send APB" : "Submit"}
                      </Button>
                    </>

                  )}
                  {!!osdNumber && formFieldsDisabled && investigationStatus !== EOverageInvestigationStatus.complete && (
                    <Button
                      // type="submit"
                      variant="contained"
                      className="assign mr-0 ml"
                      onClick={enableEditing}
                    >
                      Edit
                    </Button>
                  )}
                </div>
              </AccordionDetails>
            </form>
          </Accordion>
          <Accordion
            className="accordion"
            expanded={expanded.includes("panel2")}
            onChange={handleAccordionChange("panel2")}
          >
            <AccordionSummary
              className="header"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography
                className={
                  investigationStatus === EOverageInvestigationStatus.complete
                    ? "queue-check queue-check-gc"
                    : "queue-check"
                }
              >2</Typography>
              <Typography className="accordion-header mr-15">
                Overage Investigation &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>

              <Typography className="header-notice note-scroll">
                <div className="animate">Note: Do not advise the shipper or consignee that we have overage
                freight</div>
              </Typography>
            </AccordionSummary>
            <InvestigationForm
              key={values.type}
              osdNumber={osdNumber}
              expanded={expanded}
              exceptionType={values.type}
              proNumber={values?.proNumber}
              investigationDetails={osdDetails}
              isEditMode={!!params?.osdNumber}
              setInvestigationStatus={setInvestigationStatus}
            />
          </Accordion>
          <Accordion
          className="accordion"
          expanded={expanded.includes("panel3")}
          onChange={handleAccordionChange("panel3")} 
        >
          <AccordionSummary
            className="header"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography 
            className="queue-check"
            >3</Typography>
            <Typography className="accordion-header">
              Upload Documents
            </Typography> 
          </AccordionSummary>
          <UploadDocument   key={expanded.toString() || "upload-document"}
            osdNumber={osdNumber}
          />
        </Accordion>
        </div>
        <Loader pageLoader={pageLoading}></Loader>
      </div>
    );
  }

  export default OverageEntry;
