import { object, string, number } from "yup";

const alphanumericRegex = /^[0-9a-zA-Z]+$/;
const notAllowPipeRegex = /^[^|]*$/;
export const overageEntryNBSchema = object().shape({
  type: string().trim().required("Exception type is required"),
  reporting: string().trim().required("Reporting terminal is required"),
  shipper: string().trim().when(["type"], {
    is: (type: string) => type === "NB",
    then: (schema) => schema.required("Shipper name is required"),
  }),
  shipperAddress: string(),
  shipperCity: string(),
  shipperState: string(),
  shipperZip: string(),
  consignee: string().trim().when(["type"], {
    is: (type: string) => type === "NB",
    then: (schema) => schema.required("Consignee name is required"),
  }),
  consigneeCity: string(),
  consigneeState: string(),
  consigneeZip: string(),
  consigneeAddress: string(),
  od400Completed: string().trim().required("OD400 bill created is required"),
  od400ProNumber: number()
    .when(["od400Completed"], {
      is: (od400Completed: string) => od400Completed === "Y",
      then: (schema) =>
        schema
          .required("OD400 pro is required")
          .test(
            "len",
            "OD400 pro must be less than and equal to 11 Digit",
            (val) => val.toString().length <= 11
          ),
    }),
  trailerOrBay: string().trim().required("Please select trailer/bay"),
  trailer: string().trim().when("trailerOrBay", {
    is: (slo: string) => slo === "T",
    then: (schema) =>
      schema.required("Trailer number is required").matches(alphanumericRegex, {
        message: "Invalid trailer number, only alphanumerical allow",
      }),
    otherwise: (schema) => schema.notRequired(),
  }),
  bay: string().trim().when("trailerOrBay", {
    is: (slo: string) => slo === "B",
    then: (schema) => schema.required("Bay number is required").matches(alphanumericRegex, {
      message: "Invalid bay number, only alphanumerical allow",
    }),
    otherwise: (schema) => schema.notRequired(),
  }),
  od400Manifest: number()
    .notRequired()
    .test(
      "len",
      "Manifest number must be less than and equal to 9 Digit",
      (val) => val === undefined || val?.toString().length! <= 9
    ),
  shrinkWrap: string().trim().required("Please select"),
  weight: number().positive().required("Weight is required"),
  manufacturerBrand: string(),
  billNumber: string(),
  snNumber: string(),
  poNumber: string(),
  hazmat: string().trim().required("Haz Mat is required"),
  commodity: string().trim().required("Commodity is required"),
  package: string().trim().required("Package type is required"),
  itemCartonModelSerialNumber: string().trim().required("Does the shipment have (Item#, Carton#, Model#, Serial#) is required").max(56, 'Item#, carton#, model#, serial# must be less than and equal to 56 characters'),
  pieces: number().positive().required("Pieces is required"),
  handlingUnits: number()
    .positive()
    .integer()
    .required("Handling units is required"),
  valueRange: string().trim().required("Value range is required"),
  value1k: number()
    .nullable()
    .when("valueRange", {
      is: (valueRange: string) => valueRange === "3",
      then: (schema) => schema.required("Amount is required").moreThan(1000, 'Amount must Be greater Than 1000').lessThan(9999999999, 'Amount must be less than 9999999999'),
      otherwise: (schema) => schema.notRequired(),
    }),
  dimension: string().trim().required("Dimension is required").matches(notAllowPipeRegex, {
    message: "special character | is not allowed",
  }),
  barcode: string(),
  additionalDescription: string().trim().required("Description is required").matches(notAllowPipeRegex, {
    message: "special character | is not allowed",
  }).matches(notAllowPipeRegex, {
    message: "special character | is not allowed",
  }).test(
    "len",
    "Additional description must be less than and equal to 350 characters",
    (val) => val === undefined || val?.toString().length! <= 350
  ),
  heatNumbers: string().matches(notAllowPipeRegex, {
    message: "special character | is not allowed",
  }),
  colorCode: string().trim().required("Color code is required").matches(notAllowPipeRegex, {
    message: "special character | is not allowed",
  }),
  agentInterline: string().matches(notAllowPipeRegex, {
    message: "special character | is not allowed",
  }),
  proNumber: number().nullable()
    .when(["type"], {
      is: (type: string) => type === "OV",
      then: (schema) =>
        schema
          .positive()
          .required("Pro number is required")
          .test(
            "len",
            "Pro number must be less than and equal to 11 Digit",
            (val: number) => val?.toString().length <= 11
          ),
    }),

  origin: string(),
  destination: string(),
  shipperAccount: string(),
  consigneeAccount: string(),
});

export const overageInvestigationNBSchema = object().shape({
  osdType: string(),
  od400Check: string().trim().required("Please select"),
  od400CheckResponse: string().trim().required("Answer is required"),
  markingsCheck: string().when(["osdType"], {
    is: (osdType: string) => osdType === "NB",
    then: (schema) => schema.required("Please select"),
  }),
  markingsCheckResponse: string().trim().when(["osdType"], {
    is: (osdType: string) => osdType === "NB",
    then: (schema) => schema.required("Answer is required"),
  }),
  thCheck: string().trim().when(["osdType"], {
    is: (osdType: string) => osdType === "NB",
    then: (schema) => schema.required("Please select"),
  }),
  thCheckResponse: string().trim().when(["osdType"], {
    is: (osdType: string) => osdType === "NB",
    then: (schema) => schema.required("Answer is required"),
  }),
  mfCheck: string().trim().when(["osdType"], {
    is: (osdType: string) => osdType === "NB",
    then: (schema) => schema.required("Please select"),
  }),
  mfCheckResponse: string().trim().when(["osdType"], {
    is: (osdType: string) => osdType === "NB",
    then: (schema) => schema.required("Answer is required"),
  }),
  osAndDCheck: string().trim().when(["osdType"], {
    is: (osdType: string) => osdType === "NB",
    then: (schema) => schema.required("Please select"),
  }),
  osAndDCheckResponse: string().trim().when(["osdType"], {
    is: (osdType: string) => osdType === "NB",
    then: (schema) => schema.required("Answer is required"),
  }),
  googleCheck: string().trim().when(["osdType"], {
    is: (osdType: string) => osdType === "NB",
    then: (schema) => schema.required("Please select"),
  }),
  googleCheckResponse: string().trim().when(["osdType"], {
    is: (osdType: string) => osdType === "NB",
    then: (schema) => schema.required("Answer is required"),
  }),
  unmanifestedCheck: string().trim().when(["osdType"], {
    is: (osdType: string) => osdType === "NB",
    then: (schema) => schema.required("Please select"),
  }),
  unmanifestedCheckResponse: string().trim().when(["osdType"], {
    is: (osdType: string) => osdType === "NB",
    then: (schema) => schema.required("Answer is required"),
  }),
  undeliveredCheck: string().trim().when(["osdType"], {
    is: (osdType: string) => osdType === "NB",
    then: (schema) => schema.required("Please select"),
  }),
  undeliveredCheckResponse: string().trim().when(["osdType"], {
    is: (osdType: string) => osdType === "NB",
    then: (schema) => schema.required("Answer is required"),
  }),
  customerDeliveryCheck: string().trim().when(["osdType"], {
    is: (osdType: string) => osdType === "NB",
    then: (schema) => schema.required("Please select"),
  }),
  customerDeliveryCheckResponse: string().trim().when(["osdType"], {
    is: (osdType: string) => osdType === "NB",
    then: (schema) => schema.required("Answer is required"),
  }),
  undelInvCheck: string().trim().when(["osdType"], {
    is: (osdType: string) => osdType === "NB",
    then: (schema) => schema.required("Please select"),
  }),
  undelInvCheckResponse: string().trim().when(["osdType"], {
    is: (osdType: string) => osdType === "NB",
    then: (schema) => schema.required("Answer is required"),
  }),
  apbCheck: string().trim().when(["osdType"], {
    is: (osdType: string) => osdType === "NB",
    then: (schema) => schema.required("Please select"),
  }),
  apbCheckResponse: string().trim().when(["osdType"], {
    is: (osdType: string) => osdType === "NB",
    then: (schema) => schema.required("Answer is required"),
  }),
  emailCheck: string().trim().when(["osdType"], {
    is: (osdType: string) => osdType === "NB",
    then: (schema) => schema.required("Please select"),
  }),
  emailCheckResponse: string().trim().when(["osdType"], {
    is: (osdType: string) => osdType === "NB",
    then: (schema) => schema.required("Answer is required"),
  }),
  billOfLadingCheckResponse: string().trim().when(["osdType"], {
    is: (osdType: string) => osdType === "OV",
    then: (schema) => schema.required("Answer is required"),
  }),
  billOfLadingCheck: string().trim().when(["osdType"], {
    is: (osdType: string) => osdType === "OV",
    then: (schema) => schema.required("Please select"),
  }),
  drCheck: string().trim().when(["osdType"], {
    is: (osdType: string) => osdType === "OV",
    then: (schema) => schema.required("Please select"),
  }),
  drCheckResponse: string().trim().when(["osdType"], {
    is: (osdType: string) => osdType === "OV",
    then: (schema) => schema.required("Answer is required"),
  }),
  psCheck: string().trim().when(["osdType"], {
    is: (osdType: string) => osdType === "OV",
    then: (schema) => schema.required("Please select"),
  }),
  psCheckResponse: string().trim().when(["osdType"], {
    is: (osdType: string) => osdType === "OV",
    then: (schema) => schema.required("Answer is required"),
  }),
  consigneeCheck: string().trim().when(["osdType"], {
    is: (osdType: string) => osdType === "OV",
    then: (schema) => schema.required("Please select"),
  }),
  consigneeCheckResponse: string().trim().when(["osdType"], {
    is: (osdType: string) => osdType === "OV",
    then: (schema) => schema.required("Answer is required"),
  }),
  shipmentCheck: string().trim().when(["osdType"], {
    is: (osdType: string) => osdType === "OV",
    then: (schema) => schema.required("Please select"),
  }),
  shipmentCheckResponse: string().trim().when(["osdType"], {
    is: (osdType: string) => osdType === "OV",
    then: (schema) => schema.required("Answer is required"),
  }),
});

export const OverageEntryFormValue = {
  proNumber: "",
  reporting: "",
  origin: "",
  destination: "",
  shipperAccount: "",
  consigneeAccount: "",
  type: "NB",
  shipper: "",
  shipperAddress: "",
  shipperCity: "",
  shipperState: "",
  shipperZip: "",
  consigneeCity: "",
  consigneeState: "",
  consigneeZip: "",
  consignee: "",
  consigneeAddress: "",
  od400Completed: "",
  poNumber: "",
  od400Manifest: "",
  itemCartonModelSerialNumber: "",
  shrinkWrap: "",
  weight: "",
  manufacturerBrand: "",
  billNumber: "",
  snNumber: "",
  hazmat: "N",
  trailer: "",
  bay: "",
  commodity: "",
  package: "",
  pieces: "",
  od400ProNumber: "",
  handlingUnits: "",
  valueRange: "",
  dimension: "",
  barcode: "",
  additionalDescription: "",
  heatNumbers: "",
  colorCode: "",
  agentInterline: "",
  trailerOrBay: "",
  value1k: null,
};
