import "./ManifestProDetails.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { APP, AppURLs } from "../../../constants/app";
import DynamicGrid from "../../../components/dynamicGrid/DynamicGrid";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { manifestDetailsApi } from "../../../API/getManifestDetails";
import { manifestProDetailsApi } from "../../../API/getManifestProDetails";
import Loader from "../../../components/loader/loader";
import { useSelector } from "react-redux";
import AppState from "../../../AppState";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { EManifestType, IManifest, IManifestPro } from "../../../models/manifest";

function ManifestProDetails() {
  const param = useParams();
  const [searchParams] = useSearchParams();
  const pageType: EManifestType = (searchParams.get("type") ?? "1") as EManifestType;
  const tabPosition: number = useSelector(
    (state: AppState) => state.tabPosition
  );
  const columns: any[] = APP.overage.manifestProColumns;
  const urls: string [] = [ AppURLs.myAssignments, AppURLs.overages, AppURLs.shortages]

  const [manifestDetails, setManifestDetails] = useState<IManifest>({});
  const [manifestPros, setManifestPros] = useState<IManifestPro[]>([]);
  const [manifestNumber, setManifestNumber] = useState<string>(param.id!);
  const [isLoader, setIsLoader] = useState<boolean>(false);

  const getOverageDetails = () => {
    setIsLoader(true);
    //Get Manifest details from API by manifest number
    manifestDetailsApi(manifestNumber)
      .then((data) => {
        setManifestDetails(data);
      })
      .catch((error) => {
        console.log(error);
      });
    //Get Manifest pro details from API by manifest number
    manifestProDetailsApi(manifestNumber)
      .then((data) => {
        setIsLoader(false);
        setManifestPros(data?.data);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log(error);
      });
  };

  useEffect(() => {
    setManifestNumber(param.id!);
    getOverageDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param.id]);

  return (
    <div className="bg-layout">
      {/* header */}
      <div className="header-section description">
        <div className="back-section">
          <div className="back">
            <Link to={urls[tabPosition -1]}>
              <ArrowLeftIcon />
            </Link>
          </div>
          <div className="page-hader-content">
            <div className="page-header">
              { pageType === EManifestType["Manifest Short Reported On"] && (<>
                Manifest Short Reported Details
              </>)
              }
              { pageType === EManifestType["Last confirmed manifest #"] && (<>
                Last Confirmed Manifest Details
              </>)
              }
              {[EManifestType["Manifest Short Reported On"], EManifestType["Last confirmed manifest #"]].indexOf(pageType) === -1 && <>Manifest Pro Details</>}
            </div>
            
            <div className="page-subtitle">
              Last update: &nbsp;
              {manifestDetails?.lastUpdatedDate &&
              manifestDetails?.lastUpdatedTime
                ? `${manifestDetails?.lastUpdatedDate} ${manifestDetails?.lastUpdatedTime}`
                : "NA"}
            </div>
          </div>
        </div>
        <span className="badge">#{manifestDetails?.manifestNumber}</span>
        <div className="edit-section"></div>
      </div>

      {/* body */}
      <div className="content-area osd-description">
        <Accordion className="table-accordion" defaultExpanded>
          <AccordionSummary
            className="des-header"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Grid item md={6} xs={6}>
              <Typography className="accordion-header">
                Manifest Details
              </Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className="more-details">
            <Grid container spacing={2}>
              <Grid item lg={3} md={3} xs={5} className="background-box pb-0">
                <div className="grey-box">
                 <div className="content-box">
                    <div className="label">ORG</div>
                    <div className="value">{manifestDetails?.origin}</div>
                  </div>
                  <div className="content-box">
                    <div className="label">Going To</div>
                    <div className="value">{manifestDetails?.goingTo}</div>
                  </div>
                  <div className="content-box">
                    <div className="label">Now At</div>
                    <div className="value">{manifestDetails?.nowAt}</div>
                  </div>
                  <div className="content-box">
                    <div className="label">Dest</div>
                    <div className="value">{manifestDetails?.destination}</div>
                  </div>
                  <div className="content-box">
                    <div className="label">CLOSE</div>
                    <div className="value">{manifestDetails?.closeDate} {manifestDetails?.closeTime}</div>
                  </div>
                  <div className="content-box">
                    <div className="label">DISPATCH</div>
                    <div className="value">{manifestDetails?.dispatchDate} {manifestDetails?.dispatchTime}</div>
                  </div>
                </div>
              </Grid>

              <Grid item lg={3} md={3} xs={5} className="background-box pb-0">
                <div className="grey-box">
                <div className="content-box">
                  <div className="label">Arrival</div>
                  <div className="value">
                    {manifestDetails?.arrivalDate} { manifestDetails?.arrivalTime}
                  </div>
                  </div>
                  <div className="content-box">
                    <div className="label">Status</div>
                    <div className="value">
                      {manifestDetails?.status}
                    </div>
                  </div>
                  <div className="content-box">
                    <div className="label">Weight</div>
                    <div className="value">
                      {manifestDetails?.weight}
                    </div>
                  </div>
                  <div className="content-box">
                    <div className="label">Bills</div>
                    <div className="value">{manifestDetails?.bills}</div>
                  </div>
                  <div className="content-box">
                    <div className="label">Cubic Feet</div>
                    <div className="value">
                      {manifestDetails?.cubicFeet}
                    </div>
                  </div>
                  <div className="content-box">
                    <div className="label">Seal</div>
                    <div className="value">
                      {manifestDetails?.seal}
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid item lg={3} md={3} xs={5} className="background-box pb-0">
                <div className="grey-box">
                  <div className="content-box">
                    <div className="label">Type</div>
                    <div className="value">{manifestDetails?.manifestType}</div>
                  </div>
                  <div className="content-box">
                    <div className="label">Power</div>
                    <div className="value">{manifestDetails?.power}</div>
                  </div>
                  <div className="content-box">
                    <div className="label">Trailer</div>
                    <div className="value">
                      {manifestDetails?.trailerNumber}
                    </div>
                  </div>
                  <div className="content-box">
                    <div className="label">GS-Standard</div>
                    <div className="value">
                      {manifestDetails?.gsStandard}
                    </div>
                  </div>
                  <div className="content-box">
                    <div className="label">M/FB</div>
                    <div className="value">
                      {manifestDetails?.mFb}
                    </div>
                  </div>
                  <div className="content-box">
                    <div className="label">REV</div>
                    <div className="value">{manifestDetails?.totalRevenue}</div>
                  </div>
                </div>
              </Grid>

              <Grid item lg={3} md={3} xs={5} className="background-box pb-0">
                <div className="grey-box">
                  <div className="content-box">
                    <div className="label">DISP</div>
                    <div className="value">{manifestDetails?.dispatchNumber}</div>
                  </div>
                  <div className="content-box">
                    <div className="label">R/C</div>
                    <div className="value">{manifestDetails?.currentRoute}</div>
                  </div>
                  <div className="content-box">
                    <div className="label">H/U</div>
                    <div className="value">{manifestDetails?.handlingUnits}</div>
                  </div>
                  <div className="content-box">
                    <div className="label">T/C</div>
                    <div className="value">{manifestDetails?.temperatureRange}</div>
                  </div>
                  <div className="content-box">
                    <div className="label">M/WGT</div>
                    <div className="value">{manifestDetails?.enteredWeight}</div>
                  </div>
                  <div className="content-box">
                    <div className="label">M/BILL</div>
                    <div className="value">{manifestDetails?.enteredBill}</div>
                  </div> 
                </div>
              </Grid>
              <Grid item lg={3} md={3} xs={5} className="background-box pb-0">
                <div className="grey-box">
                  <div className="content-box">
                    <div className="label">DRIVER</div>
                  </div>
                  <div className="content-box">
                    {/* <div className="label">DRIVER</div> */}
                    <div className="value">{manifestDetails?.driverName }</div>
                  </div>
                  
                </div>
              </Grid>
              <Grid item lg={9} md={9} xs={10} className="background-box pb-0">
                <div className="grey-box">
                  <div className="content-box">
                    <div className="label">COMMENTS</div>
                  </div>
                  <div className="content-box">
                    {/* <div className="label">DRIVER</div> */}
                    <div className="value">{manifestDetails?.comments }</div>
                  </div>
                  
                </div>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion className="table-accordion" defaultExpanded>
          <AccordionSummary
            className="des-header"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Grid item md={6} xs={6}>
              <Typography className="accordion-header">Pro Details</Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className="pro-details">
            <DynamicGrid
              id="proNumber"
              checkboxSelection={false}
              rows={manifestPros}
              columns={columns}
              hideFooter={true}
              disableColumnMenu={true}
              paginationMode={"client"}
            ></DynamicGrid>
          </AccordionDetails>
        </Accordion>
      </div>
      <Loader pageLoader={isLoader}></Loader>
    </div>
  );
}

export default ManifestProDetails;
