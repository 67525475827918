import { IAllShortageInvestigation } from "../models/shortage";
import { CreateDynamicSchema, FieldConfig } from "./formik";

//  AS Investigation form day wise fields Start
const asDay1Fields: FieldConfig[] = [
  {
    name: "showAtDestTerm",
    type: "string",
    required: true,
    message: "Required field: please choose an option.",
  },

  {
    name: "dateToDestTerm",
    type: "string",
    nullable: true,
    when: {
      key: "showAtDestTerm",
      value: "N",
      required: true,
      message: "Required field: please enter a value.",
    },
  },
  {
    name: "notInDestTerm",
    type: "string",
    required: true,
    oneOf: {
      values: ["Y"],
      message: "Required field: please check the box.",
    },
    message: "Required field: please check the box.",
  },

  {
    name: "notInDestTermSteps",
    type: "string",
    nullable: false,
    when: {
      key: "notInDestTerm",
      value: "Y",
      required: false,
      message: "Required field: please enter a value.",
    },
  },
  {
    name: "day1F18Note",
    type: "string",
  },
];
const asDay2Fields: FieldConfig[] = [
  {
    name: "noMovement",
    type: "string",
    required: true,
    message: "Required field: please check the box.",
    oneOf: {
      values: ["Y"],
      message: "Required field: please check the box.",
    },
  },
  {
    name: "dateLastMovement",
    type: "string",
    nullable: true,
    when: {
      key: "noMovement",
      value: "Y",
      required: true,
      message: "Required field: please enter a value.",
    },
  },

  {
    name: "noMovementSteps",
    type: "string",
    nullable: true,
    when: {
      key: "noMovement",
      value: "Y",
      required: false,
      message: "Required field: please enter a value.",
    },
  },

  {
    name: "suffixBill",
    type: "string",
    required: true,
    message: "Required field: please check the box.",
    oneOf: {
      values: ["Y"],
      message: "Required field: please check the box.",
    },
  },

  {
    name: "suffixBillSteps",
    type: "string",
    nullable: true,
    when: {
      key: "suffixBill",
      value: "Y",
      required: true,
      message: "Required field: please enter a value.",
    },
  },
  {
    name: "bolCheck",
    type: "string",
    required: true,
    message: "Required field: please check the box.",
    oneOf: {
      values: ["Y"],
      message: "Required field: please check the box.",
    },
  },

  {
    name: "bolCheckSteps",
    type: "string",
    nullable: true,
    when: {
      key: "bolCheck",
      value: "Y",
      required: true,
      message: "Required field: please enter a value.",
    },
  },

  {
    name: "drCheck",
    type: "string",
    required: true,
    message: "Required field: please choose an option.",
  },
  {
    name: "drCheckSteps",
    type: "string",
    nullable: true,
    when: {
      key: "drCheck",
      value: "Y",
      required: true,
      message: "Required field: please enter a value.",
    },
  },
  {
    name: "day2F18Note",
    type: "string",
  },
];
const asDay3Fields: FieldConfig[] = [
  {
    name: "pumCheck",
    type: "string",
    required: true,
    message: "Required field: please check the box.",
    oneOf: {
      values: ["Y"],
      message: "Required field: please check the box.",
    },
  },
  {
    name: "pickupManifest",
    type: "number",
    nullable: true,
    when: {
      key: "pumCheck",
      value: "Y",
      required: true,
      message: "Required field: please enter a value.",
    },
  },
  {
    name: "pumSteps",
    type: "string",
    nullable: true,
    when: {
      key: "pumCheck",
      value: "Y",
      required: true,
      message: "Required field: please enter a value.",
    },
  },

  {
    name: "shipperLoad",
    type: "string",
    required: true,
    message: "Required field: please choose an option.",
  },
  {
    name: "discrepancies",
    type: "string",
    nullable: true,
    when: {
      key: "shipperLoad",
      value: "Y",
      required: true,
      message: "Required field: please enter a value.",
    },
  },

  {
    name: "checkLinehaulManifests",
    type: "string",
    required: true,
    message: "Required field: please check the box.",
    oneOf: {
      values: ["Y"],
      message: "Required field: please check the box.",
    },
  },
  {
    name: "linehaulManifests",
    type: "string",
    nullable: true,
    when: {
      key: "checkLinehaulManifests",
      value: "Y",
      required: true,
      message: "Required field: please enter a value.",
    },
  },

  {
    name: "interviewWorkers",
    type: "string",
    required: true,
    message: "Required field: please check the box.",
    oneOf: {
      values: ["Y"],
      message: "Required field: please check the box.",
    },
  },
  {
    name: "workerSteps",
    type: "string",
    nullable: true,
    when: {
      key: "interviewWorkers",
      value: "Y",
      required: true,
      message: "Required field: please enter a value.",
    },
  },

  {
    name: "consigneePhone",
    type: "string",
    required: true,
    message: "Required field: please enter a value.",
  },
  {
    name: "consigneeName",
    type: "string",
    required: true,
    message: "Required field: please enter a value.",
  },

  {
    name: "consigneeShort",
    type: "string",
    required: true,
    message: "Required field: please choose an option.",
  },
  {
    name: "itemNumbers",
    type: "string",
    nullable: true,
    when: {
      key: "consigneeShort",
      value: "Y",
      required: true,
      message: "Required field: please enter a value.",
    },
  },
  {
    name: "orderReceived",
    type: "string",
    nullable: true,
    when: {
      key: "consigneeShort",
      value: "Y",
      required: true,
      message: "Required field: please enter a value.",
    },
  },
  {
    name: "freightLabeled",
    type: "string",
    nullable: true,
    when: {
      key: "consigneeShort",
      value: "Y",
      required: true,
      message: "Required field: please enter a value.",
    },
  },
  {
    name: "shipperPhone",
    type: "string",
    required: true,
    message: "Required field: please enter a value.",
  },
  {
    name: "shipperName",
    type: "string",
    required: true,
    message: "Required field: please enter a value.",
  },

  {
    name: "shippedDetails",
    type: "string",
    required: true,
    message: "Required field: please enter a value.",
  },
  {
    name: "shortShipped",
    type: "string",
    required: true,
    message: "Required field: please enter a value.",
  },
  {
    name: "productValue",
    type: "string",
    required: true,
    message: "Required field: please enter a value.",
  },
  {
    name: "multShipments",
    type: "string",
    required: true,
    message: "Required field: please enter a value.",
  },
  {
    name: "day3F18Note",
    type: "string",
  },
];
const asDay4Fields: FieldConfig[] = [
  {
    name: "check7818",
    type: "string",
    required: true,
    message: "Required field: please check the box.",
    oneOf: {
      values: ["Y"],
      message: "Required field: please check the box.",
    },
  },
  {
    name: "details7818",
    type: "string",
    nullable: true,
    when: {
      key: "check7818",
      value: "Y",
      required: true,
      message: "Required field: please enter a value.",
    },
  },

  {
    name: "manifested",
    type: "string",
    required: true,
    message: "Required field: please choose an option.",
  },
  {
    name: "cameraFindings",
    type: "string",
    nullable: true,
    when: {
      key: "manifested",
      value: "Y",
      required: true,
      message: "Required field: please enter a value.",
    },
  },

  {
    name: "callSameDays",
    type: "string",
    required: true,
    message: "Required field: please check the box.",
    oneOf: {
      values: ["Y"],
      message: "Required field: please check the box.",
    },
  },

  {
    name: "callSameDaysDataset",
    type: "array",
    when: {
      key: "callSameDays",
      value: "Y",
      required: true,
      message: "Required field: please enter a value.",
    },
    schema: [
      {
        name: "deliveryInfo",
        type: "string",
        nullable: true,
        required: true,
        message: "Required field: please enter a value.",
      },
      {
        name: "sameDayPro",
        type: "string",
        nullable: true,
        required: true,
        message: "Required field: please enter a value.",
      },
    ],
  },

  {
    name: "day4F18Note",
    type: "string",
  },
];
const asDay5Fields: FieldConfig[] = [
  {
    name: "contactTerminals",
    type: "string",
    required: true,
    message: "Required field: please check the box.",
    oneOf: {
      values: ["Y"],
      message: "Required field: please check the box.",
    },
  },
  {
    name: "freightTerminalLeadInquiries",
    type: "array",
    when: {
      key: "contactTerminals",
      value: "Y",
      required: true,
      message: "Required field: please enter a value.",
    },
    schema: [
      {
        name: "terminal",
        type: "string",
        nullable: true,
        required: true,
        message: "Required field: please enter a value.",
      },
      {
        name: "manifest",
        type: "string",
        nullable: true,
        required: true,
        message: "Required field: please enter a value.",
      },
      {
        name: "discrepancies",
        type: "string",
        nullable: true,
        required: true,
        message: "Required field: please enter a value.",
      },
    ],
  },
  {
    name: "day5F18Note",
    type: "string",
  },
];
const asDay10Fields: FieldConfig[] = [
  {
    name: "followUp",
    type: "string",
    required: true,
    message: "Required field: please check the box.",
    oneOf: {
      values: ["Y"],
      message: "Required field: please check the box.",
    },
  },

  {
    name: "followUpDetails",
    type: "string",
    nullable: true,
    when: {
      key: "followUp",
      value: "Y",
      required: false,
      message: "Required field: please enter a value.",
    },
  },
  {
    name: "day10F18Note",
    type: "string",
  },
];
const asDay15Fields: FieldConfig[] = [
  {
    name: "check7818_2",
    type: "string",
    required: true,
    message: "Required field: please check the box.",
    oneOf: {
      values: ["Y"],
      message: "Required field: please check the box.",
    },
  },
  {
    name: "details7818_2",
    type: "string",
    nullable: true,
    when: {
      key: "check7818_2",
      value: "Y",
      required: true,
      message: "Required field: please enter a value.",
    },
  },
  {
    name: "highValue",
    type: "string",
    required: true,
    message: "Required field: please choose an option.",
  },
  {
    name: "day15F18Note",
    type: "string",
  },
];
const asDay20Fields: FieldConfig[] = [
  {
    name: "finalReCheck",
    type: "string",
    required: true,
    message: "Required field: please check the box.",
    oneOf: {
      values: ["Y"],
      message: "Required field: please check the box.",
    },
  },
  {
    name: "detailsOfSearch",
    type: "string",
  },
  {
    name: "day20F18Note",
    type: "string",
  },
];

// Exporting the dynamically created schema for all day
export const ASInvestigationSchema = [
  CreateDynamicSchema(asDay1Fields),
  CreateDynamicSchema(asDay2Fields),
  CreateDynamicSchema(asDay3Fields),
  CreateDynamicSchema(asDay4Fields),
  CreateDynamicSchema(asDay5Fields),
  CreateDynamicSchema(asDay10Fields),
  CreateDynamicSchema(asDay15Fields),
  CreateDynamicSchema(asDay20Fields),
];

export const ASInvestigationInitValue: IAllShortageInvestigation = {
  // Day 1
  showAtDestTerm: undefined,
  dateToDestTerm: undefined,
  notInDestTerm: undefined,
  notInDestTermSteps: undefined,
  // Day 2
  noMovement: undefined,
  dateLastMovement: undefined,
  noMovementSteps: undefined,
  suffixBill: undefined,
  suffixBillSteps: undefined,
  bolCheck: undefined,
  bolCheckSteps: undefined,
  drCheck: undefined,
  drCheckSteps: undefined,
  // Day 3
  pumCheck: undefined,
  pickupManifest: undefined,
  pumSteps: undefined,
  shipperLoad: undefined,
  discrepancies: undefined,
  checkLinehaulManifests: undefined,
  linehaulManifests: undefined,
  interviewWorkers: undefined,
  workerSteps: undefined,
  consigneePhone: undefined,
  consigneeName: undefined,
  consigneeShort: undefined,
  itemNumbers: undefined,
  orderReceived: undefined,
  freightLabeled: undefined,
  shipperPhone: undefined,
  shipperName: undefined,
  shippedDetails: undefined,
  shortShipped: undefined,
  productValue: undefined,
  multShipments: undefined,
  // Day 4
  check7818: undefined,
  details7818: undefined,
  manifested: undefined,
  cameraFindings: undefined,
  callSameDays: undefined,
  callSameDaysDataset: [
    {
      sameDayPro: undefined,
      deliveryInfo: undefined,
    },
  ],
  // Day 5
  contactTerminals: undefined,
  freightTerminalLeadInquiries: [
    { terminal: undefined, manifest: undefined, discrepancies: undefined },
  ],
  // Day 10
  followUp: undefined,
  followUpDetails: undefined,
  // Day 15
  check7818_2: undefined,
  details7818_2: undefined,
  highValue: undefined,
  // Day 20
  finalReCheck: undefined,
  detailsOfSearch: undefined,

  day1F18Note: undefined,
  day2F18Note: undefined,
  day3F18Note: undefined,
  day4F18Note: undefined,
  day5F18Note: undefined,
  day10F18Note: undefined,
  day15F18Note: undefined,
  day20F18Note: undefined,
};
// AS Investigation form End
