import React, { useEffect, useState, useRef } from "react";
import "./HeaderSearch.css";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import SearchFilterForm from "../filterForm/searchFilterForm";
import { useSelector } from "react-redux";
import AppState from "../../AppState";
import { TTerminal } from "../../AppState/overagesFilterFormActions";
import { filterFromSelector } from "../../AppState/selectors";

type Props = {
  onFilterClick?: React.MouseEventHandler<HTMLButtonElement>;
  onSearchChange?: (value: string) => void;
  value?: string;
  maxlength?: number;
};

const HeaderSearch: React.FC<Props> = ({
  onFilterClick,
  onSearchChange,
  value = "",
  maxlength = 100,
}: Props) => {
  const [isFilterFormVisible, setFilterFormVisible] = useState(false);

  const filterPanelRef = useRef<HTMLDivElement>(null);
  const tab = useSelector((state: AppState) => state.tabPosition);
  const currentTerminalValue: TTerminal = useSelector(
    (state: AppState) => state.activeTerminal
  );
  const filterFrom = useSelector(filterFromSelector);

  const handleFilterClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation(); // Prevents event from triggering document listener
    setFilterFormVisible((prev) => !prev);
    onFilterClick?.(e); // Call the parent click handler if provided
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        filterPanelRef.current &&
        !filterPanelRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).closest("#searchFilter") // Exclude button clicks
      ) {
        setFilterFormVisible(false);
      }
    };
  
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    setFilterFormVisible(false);
  }, [tab, currentTerminalValue, filterFrom?.search]);

  return (
    <div className="filter-Header">
      <FormGroup className="filter-search" test-id="headerSearch">
        <FormControl className="filter-search-control">
          <TextField  autoComplete="off"
            placeholder="Search"
            id="outlined-start-adornment"
            variant="outlined"
            size="small"
            fullWidth
            value={value === "" ? "" : value}
            onChange={(e) => onSearchChange?.(e.target.value)}
            InputProps={{
              inputProps: {
                maxLength: maxlength,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    id="searchFilter"
                    edge="end"
                    onClick={handleFilterClick}
                  >
                    <TuneIcon />
                  </IconButton>
                </InputAdornment>
              ),
              style: { fontSize: 14 },
            }}
          />
        </FormControl>
      </FormGroup>

      {isFilterFormVisible && (
        <div
          ref={filterPanelRef}
          onMouseDown={(e) => e.stopPropagation()} // Prevent event propagation
        >
          <SearchFilterForm onFilterSubmit={() => setFilterFormVisible(false)} />
        </div>
      )}
    </div>
  );
};

export default HeaderSearch;
