import React, { useEffect, useState } from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "./popupImage.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { IProImage } from "../../models/master";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import CachedIcon from '@mui/icons-material/Cached';
import Draggable from "react-draggable";
import Paper from "@mui/material/Paper";

interface DialogImageProps {
  isDialogOpened: boolean;
  handleCloseDialog: () => void;
  imageData: IProImage[];
  excNumber: string;
}

function PaperComponent(props: any) {
  const [bounds, setBounds] = useState({ top: 0, left: 0, right: 0, bottom: 0 });

  useEffect(() => {
    const updateBounds = () => {
      const parent = document.documentElement; // or any other parent element
      const top = -(parent.clientHeight * 0.05); // 5% from the top
      const bottom = parent.clientHeight * 0.8; // 80% from the top
      const left = -(parent.clientWidth * 0.7); // 5% from the left
      const right = parent.clientWidth * 0.7; // 95% from the left

      setBounds({ top, left, right, bottom });
    };

    updateBounds();
    window.addEventListener('resize', updateBounds);

    return () => {
      window.removeEventListener('resize', updateBounds);
    };
  }, []);

  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      bounds={bounds}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const DialogImage: React.FC<DialogImageProps> = ({
  isDialogOpened,
  handleCloseDialog,
  imageData,
  excNumber,
}) => {
  const fullWidth: boolean = true;
  const [images, setImages] = React.useState<string[]>([
    imageData[0]?.imageFilename!,
  ]);
  const [zoomLevels, setZoomLevels] = React.useState<number[]>(
    new Array(imageData.length).fill(1)
  );
  const [rotationAngles, setRotationAngles] = React.useState<number[]>(
    new Array(imageData.length).fill(0)
  );
  const [highlightedIndex, setHighlightedIndex] = React.useState<number | null>(null);
  const maxZoomLevel = 9; // 3^2 = 9, as 2 clicks of 3x zoom

  const handleClose = () => {
    handleCloseDialog();
  };

  const [currentIndex, setCurrentIndex] = React.useState(0);

  const handlePrevClick = () => {
    const previousIndex = currentIndex;
    const newIndex = (currentIndex - 1 + imageData.length) % imageData.length;

    // Reset zoom and rotation for the previous image
    const newZoomLevels = [...zoomLevels];
    const newRotationAngles = [...rotationAngles];
    newZoomLevels[previousIndex] = 1;
    newRotationAngles[previousIndex] = 0;

    setZoomLevels(newZoomLevels);
    setRotationAngles(newRotationAngles);

    setCurrentIndex(newIndex);
    setHighlightedIndex(newIndex); // Update highlight to the new current image
  };

  const handleNextClick = () => {
    const previousIndex = currentIndex;
    const newIndex = (currentIndex + 1) % imageData.length;

    // Reset zoom and rotation for the previous image
    const newZoomLevels = [...zoomLevels];
    const newRotationAngles = [...rotationAngles];
    newZoomLevels[previousIndex] = 1;
    newRotationAngles[previousIndex] = 0;

    setZoomLevels(newZoomLevels);
    setRotationAngles(newRotationAngles);

    setCurrentIndex(newIndex);
    setHighlightedIndex(newIndex); // Update highlight to the new current image
  };

  const onChange = (index: number) => {
    setCurrentIndex(index);
    setZoomLevels(new Array(imageData.length).fill(1)); // Reset zoom levels
    setRotationAngles(new Array(imageData.length).fill(0)); // Reset rotation angles
    if (images.length < imageData.length) {
      setImages([...images, imageData[index].imageFilename!]);
    }
    if (index === highlightedIndex) {
      setHighlightedIndex(index); // Re-add highlight if returning to the highlighted index
    }
  };

  const handleZoomIn = () => {
    const newZoomLevels = [...zoomLevels];
    if (newZoomLevels[currentIndex] < maxZoomLevel) {
      newZoomLevels[currentIndex] *= 1.5;
      setZoomLevels(newZoomLevels);
      const draggableImage = document.querySelector('.draggable-image');
      if (draggableImage) {
        draggableImage.classList.add('cursor-zoom-in');
      }
    }
    setHighlightedIndex(null); // Remove highlight
  };

  const handleZoomOut = () => {
    const newZoomLevels = [...zoomLevels];
    if (newZoomLevels[currentIndex] > 1) {
      newZoomLevels[currentIndex] /= 1.5;
      setZoomLevels(newZoomLevels);
    }
    if (newZoomLevels[currentIndex] <= 1) {
      setHighlightedIndex(currentIndex); // Add highlight
      const draggableImage = document.querySelector('.draggable-image');
      if (draggableImage) {
        draggableImage.classList.remove('cursor-zoom-in');
      }
    }
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.currentTarget;
    target.style.cursor = 'grabbing';
    target.style.userSelect = 'none';

    const startX = e.pageX - target.offsetLeft;
    const startY = e.pageY - target.offsetTop;

    const onMouseMove = (moveEvent: MouseEvent) => {
      target.style.left = `${moveEvent.pageX - startX}px`;
      target.style.top = `${moveEvent.pageY - startY}px`;
    };

    const onMouseUp = () => {
      target.style.cursor = 'grab';
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  const handleReset = () => {
    const newZoomLevels = [...zoomLevels];
    newZoomLevels[currentIndex] = 1;
    setZoomLevels(newZoomLevels);

    const newRotationAngles = [...rotationAngles];
    newRotationAngles[currentIndex] = 0;
    setRotationAngles(newRotationAngles);

    const imageContainer = document.querySelector('.image-container');
    if (imageContainer) {
      imageContainer.scrollLeft = (imageContainer.scrollWidth - imageContainer.clientWidth) / 2;
      imageContainer.scrollTop = (imageContainer.scrollHeight - imageContainer.clientHeight) / 2;
    }

    setHighlightedIndex(currentIndex); // Add highlight
  };

  const handleRotate = () => {
    handleReset();
    const newRotationAngles = [...rotationAngles];
    newRotationAngles[currentIndex] = (newRotationAngles[currentIndex] + 90) % 360;
    setRotationAngles(newRotationAngles);
    setHighlightedIndex(currentIndex); // Add highlight
  };

  return (
    <div>
      <Dialog
        open={isDialogOpened}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="draggable-dialog-title"
        keepMounted
        maxWidth="lg"
        fullWidth={fullWidth}
        PaperComponent={PaperComponent}
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="draggable-dialog-title"
          className="modal-header"
        >
          Exception # <span className="exceptionNo">{excNumber}</span>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 15,
            top: 15,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers className="Gallery">
          <div className="pre-btn" onClick={handlePrevClick}>
            <ChevronLeftOutlinedIcon />
          </div>
          <div className="next-btn" onClick={handleNextClick}>
            <ChevronRightOutlinedIcon />
          </div>

          <div className="button-group button-controls">
            <IconButton
              aria-label="zoom-in"
              onClick={handleZoomIn}
              disabled={zoomLevels[currentIndex] >= maxZoomLevel}
              className="page-control-btn"
              title="Zoom In"
            >
              <ZoomInIcon />
            </IconButton>
            <IconButton
              aria-label="zoom-out"
              onClick={handleZoomOut}
              disabled={zoomLevels[currentIndex] <= 1}
              className="page-control-btn"
              title="Zoom Out"
            >
              <ZoomOutIcon />
            </IconButton>
            <IconButton aria-label="rotate-left" onClick={handleRotate} className="page-control-btn" title="Rotate Right">
              <RotateRightIcon />
            </IconButton>
            <IconButton aria-label="rotate-right" onClick={handleReset} className="page-control-btn" title="Reset">
              <CachedIcon />
            </IconButton>
          </div> 
          {imageData.map((obj: any, index: number) => (
          <p className="legend" key={obj.docType}>{obj.docType}</p>
        ))}
          <Carousel
            infiniteLoop
            stopOnHover={true}
            showArrows={false}
            showThumbs={false}
            selectedItem={currentIndex}
            onChange={(index) => onChange(index)}
          >
            {imageData.map((_: any, index: number) => (
              <div key={index + ""} className={`image-container ${highlightedIndex === index ? 'highlight' : ''}`}>
                <div
                  className="draggable-image"
                  onMouseDown={zoomLevels[index] > 1 ? handleMouseDown : undefined}
                  style={{
                    transform: `
                      scale(${zoomLevels[index]}) 
                      rotate(${rotationAngles[index]}deg) 
                      ${rotationAngles[index] === 90 ? 'translateY(-100%)' : ''} 
                      ${rotationAngles[index] === 270 ? 'translateX(-100%)' : ''}
                    `.trim(),
                    transformOrigin: `${rotationAngles[index] === 90 || rotationAngles[index] === 270 ? 'top left' : 'center'}`
                  }}
                >
                  <img src={images[index]} alt="AS400 Docs" />
                </div>
                

              </div>
            ))}
          </Carousel>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DialogImage;
