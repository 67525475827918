import moment from "moment";

const DateHelper: any = {
  convertToAS400Date: function (date: string) {
    let momentDate;
    if (moment.isMoment(date)) {
      momentDate = date;
    } else {
      momentDate = moment(date, "MM-DD-YYYY");
    }
    let century = parseInt((momentDate.year() / 100).toString()) - 19;
    let formattedDate = momentDate.format("YYMMDD");
    return Number("" + century + formattedDate);
  },

  convertDateTimeFromDB: function (
    date: string,
    time: string,
    formattedDate: string = "MM/DD/YYYY hh:mm A"
  ) {
    if(date){
      return  moment(`${date} ${time}`, "DD-MM-YYYY hh:mm:ss").format(formattedDate);
    } else {
      return "";
    }
   
  },

  convertFromAS400Time: function (time?: string) {
    if (time && time !== "") {
      if (time.length === 1) time = "" + 0 + time;
      if (time.length === 2) time = "" + 0 + time;
      if (time.length === 3) time = "" + 0 + time;
      if (time.length <= 4) {
        return time.substring(0, 2) + ":" + time.substring(2);
      } else {
        if (time.length === 5) time = "" + 0 + time;
        return (
          time.substring(0, 2) +
          ":" +
          time.substring(2, 4) +
          ":" +
          time.substring(4, 6)
        );
      }
    } else {
      return "00:00";
    }
  },
  convertFromAS400Date: function (date?: string, doFormat: boolean = true) {
    if (date && date !== "") {
      if (/^[\d.]+$/.test(date)) {
        let century = parseInt(date.charAt(0)) + 19;
        let isoDate = "" + century + date.substring(1, 7);
        if (doFormat) {
          return moment(isoDate, "YYYYMMDD").format("DD/MM/YYYY");
        } else {
          return moment(isoDate, "YYYYMMDD");
        }
      } else if (new Date(date).toString() !== "Invalid Date") {
        return moment(new Date(date)).format("YYYY-MM-DD");
      } else {
        return date;
      }
    } else {
      return "0";
    }
  },
  
  // To convert start date from filter date dropdown with specific selection.
  startDateSelector: (value: string ="") => {
    switch (value) {
      case "TOD":
        return moment().format("YYYY-MM-DD");
      case "YST":
        return moment().subtract(1, "days").format("YYYY-MM-DD");
      case "DBY":
        return moment().subtract(2, "days").format("YYYY-MM-DD");
      case "MTD":
        return moment().clone().startOf("month").format("YYYY-MM-DD");
      case "PMO":
        return moment()
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
      case "9MO":
        return undefined;
      default:
        return undefined;
    }
  },

  // To convert end date from overages filter date dropdown with specific selection.
  endDateSelector: (value: string= "") => {
    switch (value) {
      case "TOD":
        return moment().format("YYYY-MM-DD");
      case "YST":
        return moment().subtract(1, "days").format("YYYY-MM-DD");
      case "DBY":
        return moment().subtract(2, "days").format("YYYY-MM-DD");
      case "MTD":
        return moment().format("YYYY-MM-DD");
      case "PMO":
        return moment()
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD");
      case "9MO":
        return moment().subtract(9, "months").format("YYYY-MM-DD");
      default:
        return undefined;
    }
  },

  mergerAS400DateTime: (date: number, time: number)  => {
    const convertedDate = DateHelper.convertFromAS400Date(date.toString());
    const convertedTime = DateHelper.convertFromAS400Time(time.toString())
    return DateHelper.convertDateTimeFromDB(convertedDate, convertedTime);
  }
};

export { DateHelper };
