import { IException } from "./osd";
import { IASEntry } from "./shortage";

export interface IShortage extends IException {
  entryBy: string;
  lastManifest?: number;
}

export interface IShortagesList {
  data: IShortage[];
  total: number;
  page: number;
  limit: number;
  totalPages: number;
}

export interface IDSFreightTerminalLeadInquiries {
  terminal?: string;
  talkedTo?: string;
  updates?: string;
}

export interface ICallSameDaysDataset {
  sameDayPro?: string | number;
  deliveryInfo?: string;
}
export interface IDSInvestigation {
  // General Section (7 keys)
  osdNumber?: number; // db key name: AHSEQ, data type: 9/0, Human friendly name: osd number
  proNumber?: number; // db key name: AIPRO, data type: 11/0, Human friendly name: Pro#
  osdType?: string;
  username?: string;
  status?: string;
  statusMessage?: string;
  freightLocated?: string;

  // Day 1 Section (6 keys)
  statusCorrect?: string;
  statusResponse?: string;
  deliveryCode?: string;
  deliveryCodeResponse?: string;
  epImaging?: string;
  epImagingResponse?: string;
  day1F18Note?: string;

  // Day 2 Section (7 keys)
  noMovement?: string;
  dateLastMovement?: string;
  noMovementSteps?: string;
  suffixBill?: string;
  suffixBillSteps?: string;
  spotTrailer?: string;
  freightLeft?: string;
  day2F18Note?: string;
  // Day 3 Section (32 keys)
  bolCheck?: string;
  bolSteps?: string;
  bolDiscrepancies?: string;
  drCheck?: string;
  confirm?: string;
  confirmSteps?: string;
  pumCheck?: string;
  pumManifest?: number;
  pumDiscrepancy?: string;
  shipperLoad?: string;
  shipperDiscrepancy?: string;
  lineHaulManifest?: string;
  numbersReview?: string;
  interviewWorkers?: string;
  workerSteps?: string;
  consigneePhone?: string;
  consigneeName?: string;
  consigneeShort?: string;
  itemNumbers?: string;
  orderReceived?: string;
  freightLabeled?: string;
  shipperPhone?: string;
  shipperName?: string;
  shippedDetails?: string;
  shortShipped?: string;
  productValue?: string;
  multiShipments?: string;
  callDriver?: string;
  adeDelivery?: string;
  freightNose?: string;
  outOfOrdinary?: string;
  day3F18Note?: string;

  // Day 4 Section (3 keys)
  check7818?: string;
  details7818?: string;
  cameraFindings?: string;
  day4F18Note?: string;

  // Day 5 Section (21 keys)
  callSameDays?: string; // db key name: AIDY5A, data type: 1 A/N, Human friendly name: Call Same Days (Y/N)
  callSameDaysDataset?: ICallSameDaysDataset[];
  contactTerminals?: string;
  freightTerminalLeadInquiries?: IDSFreightTerminalLeadInquiries[];
  day5F18Note?: string;

  // Day 10 Section (2 keys)
  followUp?: string;
  followUpDetails?: string;
  day10F18Note?: string;

  // Day 15 Section (3 keys)
  check7818_2?: string;
  details7818_2?: string;
  highValue?: string;
  day15F18Note?: string;

  // Day 20 Section (2 keys)
  finalReCheck?: string;
  detailsOfSearch?: string;
  day20F18Note?: string;

}

export interface IDeliveredShort extends IASEntry {
  shrinkWrappedPalletCount?: string;
  wasOriginalShrinkwrapIntact?: string;
  description: string;
}

export interface IASEntryCreateParams {
  proNumber?: number | string | null;
  osdNumber?: number | string;
  reporting?: string;
  type?: string;
  itemCartonModelSerialNumber?: string;
  weight?: number;
  lastManifest?: number;
  manifest?: number;
  trailer?: string;
  bay?: string;
  commodity?: string;
  package?: string;
  pieces?: number;
  valueRange?: string;
  dimension?: string;
  packagingDescription?: string;
  additionalDescription?: string;
  productDescription?: string;
  agentInterline?: string;
  trailerOrBay?: string;
  value1k?: number | null;
  origin?: string;
  destination?: string;
  shipper?: string;
  shipperAccount?: string;
  shipperAddress?: string;
  shipperCity?: string;
  shipperState?: string;
  shipperZip?: string;
  consignee?: string;
  consigneeAccount?: string;
  consigneeAddress?: string;
  consigneeCity?: string;
  consigneeState?: string;
  consigneeZip?: string;
}

export enum EDSInvestigationFields {
  ShowAtDestTerm = "showAtDestTerm",
  DateToDestTerm = "dateToDestTerm",
  NotInDestTerm = "notInDestTerm",
  NotInDestTermSteps = "notInDestTermSteps",
  NoMovement = "noMovement",
  DateLastMovement = "dateLastMovement",
  NoMovementSteps = "noMovementSteps",
  SuffixBill = "suffixBill",
  SuffixBillSteps = "suffixBillSteps",
  BolCheck = "bolCheck",
  BolCheckSteps = "bolCheckSteps",
  DrCheck = "drCheck",
  DrCheckSteps = "drCheckSteps",
  PumCheck = "pumCheck",
  PickupManifest = "pickupManifest",
  PumSteps = "pumSteps",
  ShipperLoad = "shipperLoad",
  Discrepancies = "discrepancies",
  CheckLinehaulManifests = "checkLinehaulManifests",
  LinehaulManifests = "linehaulManifests",
  InterviewWorkers = "interviewWorkers",
  WorkerSteps = "workerSteps",
  ConsigneePhone = "consigneePhone",
  ConsigneeName = "consigneeName",
  ConsigneeShort = "consigneeShort",
  ItemNumbers = "itemNumbers",
  OrderReceived = "orderReceived",
  FreightLabeled = "freightLabeled",
  ShipperPhone = "shipperPhone",
  ShipperName = "shipperName",
  ShippedDetails = "shippedDetails",
  ShortShipped = "shortShipped",
  ProductValue = "productValue",
  MultShipments = "multShipments",
  Check7818 = "check7818",
  Details7818 = "details7818",
  Manifested = "manifested",
  CameraFindings = "cameraFindings",
  CallSameDays = "callSameDays",
  SameDayPros = "sameDayPros",
  ContactTerminals = "contactTerminals",
  InvestigationDetails = "investigationDetails",
  Terminal = "terminal",
  Manifest = "manifest",
  FollowUp = "followUp",
  FollowUpDetails = "followUpDetails",
  Check7818_2 = "check7818_2",
  Details7818_2 = "details7818_2",
  HighValue = "highValue",
  FinalReCheck = "finalReCheck",

  ConfirmStatus = "confirmStatus",
  ConfirmStatusSteps = "confirmStatusSteps",
  ConfirmDelivery = "confirmDelivery",
  ConfirmDeliverySteps = "confirmDeliverySteps",

  DriverSpotSteps = "driverSpotSteps",
  DriverSpotStepsSteps = "driverSpotStepsSteps",
  BolDuplicate = "bolDuplicate",
  ConfirmCompletionSteps = "confirmCompletionSteps",
  AnyDiscrepancies = "anyDiscrepancies",
  DidYouTalk = "didYouTalk",
  FreightPulled = "freightPulled",
  ShipmentLoadOut = "shipmentLoadOut",
  DeliveredADE = "deliveredADE",
  FreightNose = "freightNose",
  OrdinaryDelivery = "ordinaryDelivery",
  CameraDT = "cameraDT",
  CallSameDay = "callSameDay",
  ProDelivery = "proDelivery",
}

export interface IDSEntry {
  itemCartonModelSerialNumber?: string;
  weight?: number;
  commodity?: string;
  package?: string;
  pieces?: number;
  description?: string;
  osdNumber?: number;
}
