import _ from "lodash";
import React, { useMemo } from "react";
import {
  IAllShortageInvestigation,
  IDay5Investigation,
} from "../models/shortage";
import { ASInvestigationWizardStep } from "../constants/shortage";

interface ASInvestigationTemplateProps {
  values?: IAllShortageInvestigation;
  osdNumber: number;
  currentDay?: string;
}

const ASInvestigationTemplate: React.FC<ASInvestigationTemplateProps> = (
  props: ASInvestigationTemplateProps
): JSX.Element => {
  const values = useMemo(() => props.values, [props.values]);

  return (
    <>
      {values && (
        <>
          {props.currentDay === ASInvestigationWizardStep[0] && (
            <div id="as-in-day1">
              <div style={{ width: "100%", fontFamily: "calibri" }}>
                <div
                  style={{
                    width: "100vw",
                    background: "#f5f7fb",
                    padding: "10px 10px 15px",
                    lineHeight: "40px",
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginBottom: "15px",
                    textTransform: "uppercase",
                  }}
                >
                  All Shortage Investigation Day 1{" "}
                  <span
                    style={{
                      background: "#f5f5f5",
                      border: "1px solid #d4d4d4",
                      padding: "5px 15px",
                      borderRadius: "20px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    #{props?.osdNumber}
                  </span>
                </div>
              </div>
              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Does the freight show at the destination terminal?
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.showAtDestTerm === "Y" ? "Yes" : "No"}
                  </div>
                  {values.showAtDestTerm !== "Y" && (
                    <div
                      style={{
                        width: "75%",
                        margin: "0 0 0 0",
                        display: "inline-block",
                        background: "#f5f7fb",
                        padding: "10px",
                        border: "1px solid #b5b6b9",
                        wordWrap: "break-word",
                      }}
                    >
                      {values.dateToDestTerm}
                    </div>
                  )}
                </div>
              </div>

              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Confirm freight is not in the destination terminals OS&D bay
                  or on their Overage report
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.notInDestTerm === "Y" ? "Yes" : "No"}
                  </div>
                  <div
                    style={{
                      width: "75%",
                      margin: "0 0 0 0",
                      display: "inline-block",
                      background: "#f5f7fb",
                      padding: "10px",
                      border: "1px solid #b5b6b9",
                      wordWrap: "break-word",
                    }}
                  >
                    {values.notInDestTerm === "Y" && values.notInDestTermSteps}
                  </div>
                </div>
              </div>
              {/* F18 Notes */}
              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>F18 Notes</div>

                <div>
                  <div
                    style={{
                      width: "75%",
                      margin: "0 0 0 0",
                      display: "inline-block",
                      background: "#f5f7fb",
                      padding: "10px",
                      border: "1px solid #b5b6b9",
                      wordWrap: "break-word",
                    }}
                  >
                    {values.day1F18Note}
                  </div>
                </div>
              </div>
            </div>
          )}
          {props.currentDay === ASInvestigationWizardStep[1] && (
            <div id="as-in-day2">
              <div style={{ width: "100%", fontFamily: "calibri" }}>
                <div
                  style={{
                    width: "100vw",
                    background: "#f5f7fb",
                    padding: "10px 10px 15px",
                    lineHeight: "40px",
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginBottom: "15px",
                    textTransform: "uppercase",
                  }}
                >
                  All Shortage Investigation Day 2{" "}
                  <span
                    style={{
                      background: "#f5f5f5",
                      border: "1px solid #d4d4d4",
                      padding: "5px 15px",
                      borderRadius: "20px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    #{props?.osdNumber}
                  </span>
                </div>
              </div>
              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Confirm the master shows no movement?
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.noMovement === "Y" ? "Yes" : "No"}
                  </div>
                  <br />
                  {values.noMovement === "Y" && (
                    <>
                      <div style={{ margin: "0 0 10px" }}>
                        Select date of last movement
                      </div>
                      <div
                        style={{
                          width: "75%",
                          margin: "0 0 0 0",
                          display: "inline-block",
                          background: "#f5f7fb",
                          padding: "10px",
                          border: "1px solid #b5b6b9",
                          wordWrap: "break-word",
                        }}
                      >
                        {values.dateLastMovement}
                      </div>{" "}
                      <br />
                    </>
                  )}
                  {values.noMovement === "Y" && values.noMovementSteps && (
                    <>
                      <div style={{ margin: "0 0 10px" }}>Described steps</div>
                      <div
                        style={{
                          width: "75%",
                          margin: "0 0 0 0",
                          display: "inline-block",
                          background: "#f5f7fb",
                          padding: "10px",
                          border: "1px solid #b5b6b9",
                          wordWrap: "break-word",
                        }}
                      >
                        {values.noMovementSteps}
                      </div>{" "}
                    </>
                  )}
                </div>
              </div>

              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Check if a suffix bill or OD400 was cut to move some or all of
                  the freight.
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.suffixBill === "Y" ? "Yes" : "No"}
                  </div>
                  {values.suffixBill === "Y" && (
                    <div
                      style={{
                        width: "75%",
                        margin: "0 0 0 0",
                        display: "inline-block",
                        background: "#f5f7fb",
                        padding: "10px",
                        border: "1px solid #b5b6b9",
                        wordWrap: "break-word",
                      }}
                    >
                      {values.suffixBillSteps}
                    </div>
                  )}
                </div>
              </div>

              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Check the BOL to confirm completion and no discrepancies?
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.bolCheck === "Y" ? "Yes" : "No"}
                  </div>

                  {values.bolCheck === "Y" && (
                    <div
                      style={{
                        width: "75%",
                        margin: "0 0 0 0",
                        display: "inline-block",
                        background: "#f5f7fb",
                        padding: "10px",
                        border: "1px solid #b5b6b9",
                        wordWrap: "break-word",
                      }}
                    >
                      {values.bolCheckSteps}
                    </div>
                  )}
                </div>
              </div>

              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>Is there a DR?</div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.drCheck === "Y" ? "Yes" : "No"}
                  </div>
                  {values.drCheck === "Y" && (
                    <>
                      <div>
                        Did you confirm completion and no discrepancies?:
                      </div>{" "}
                      <br />
                      <div
                        style={{
                          width: "75%",
                          margin: "0 0 0 0",
                          display: "inline-block",
                          background: "#f5f7fb",
                          padding: "10px",
                          border: "1px solid #b5b6b9",
                          wordWrap: "break-word",
                        }}
                      >
                        {values.drCheckSteps}
                      </div>
                    </>
                  )}
                </div>
              </div>
              {/* F18 Notes */}
              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>F18 Notes</div>

                <div>
                  <div
                    style={{
                      width: "75%",
                      margin: "0 0 0 0",
                      display: "inline-block",
                      background: "#f5f7fb",
                      padding: "10px",
                      border: "1px solid #b5b6b9",
                      wordWrap: "break-word",
                    }}
                  >
                    {values.day2F18Note}
                  </div>
                </div>
              </div>
            </div>
          )}
          {props.currentDay === ASInvestigationWizardStep[2] && (
            <div id="as-in-day3">
              <div style={{ width: "100%", fontFamily: "calibri" }}>
                <div
                  style={{
                    width: "100vw",
                    background: "#f5f7fb",
                    padding: "10px 10px 15px",
                    lineHeight: "40px",
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginBottom: "15px",
                    textTransform: "uppercase",
                  }}
                >
                  All Shortage Investigation Day 3{" "}
                  <span
                    style={{
                      background: "#f5f5f5",
                      border: "1px solid #d4d4d4",
                      padding: "5px 15px",
                      borderRadius: "20px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    #{props?.osdNumber}
                  </span>
                </div>
              </div>
              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Check the PUM (Pickup manifest) for discrepancies, shipper
                  load and count information, and proper reporting process
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.pumCheck === "Y" ? "Yes" : "No"}
                  </div>
                  {values.pumCheck === "Y" && (
                    <>
                      <div>Pickup Manifest #</div> <br />
                      <div
                        style={{
                          width: "75%",
                          margin: "0 0 0 0",
                          display: "inline-block",
                          background: "#f5f7fb",
                          padding: "10px",
                          border: "1px solid #b5b6b9",
                          wordWrap: "break-word",
                        }}
                      >
                        {values.pickupManifest}
                      </div>
                      <br />
                      <br />
                    </>
                  )}

                  {values.pumCheck === "Y" && (
                    <>
                      <div>List any and all discrepancies: </div> <br />
                      <div
                        style={{
                          width: "75%",
                          margin: "0 0 0 0",
                          display: "inline-block",
                          background: "#f5f7fb",
                          padding: "10px",
                          border: "1px solid #b5b6b9",
                          wordWrap: "break-word",
                        }}
                      >
                        {values.pumSteps}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Was this a shipper load and count?
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.shipperLoad === "Y" ? "Yes" : "No"}
                  </div>
                  {values.shipperLoad === "Y" && (
                    <>
                      <div style={{ margin: "0 0 10px" }}>
                        Were there any discrepancies? If so, were they properly
                        reported to the shipper?
                      </div>
                      <div
                        style={{
                          width: "75%",
                          margin: "0 0 0 0",
                          display: "inline-block",
                          background: "#f5f7fb",
                          padding: "10px",
                          border: "1px solid #b5b6b9",
                          wordWrap: "break-word",
                        }}
                      >
                        {values.discrepancies}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Check all linehaul manifests – movement and abnormalities.
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.checkLinehaulManifests === "Y" ? "Yes" : "No"}
                  </div>
                  {values.checkLinehaulManifests === "Y" && (
                    <div
                      style={{
                        width: "75%",
                        margin: "0 0 0 0",
                        display: "inline-block",
                        background: "#f5f7fb",
                        padding: "10px",
                        border: "1px solid #b5b6b9",
                        wordWrap: "break-word",
                      }}
                    >
                      {values.linehaulManifests}
                    </div>
                  )}
                </div>
              </div>

              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Interview the dock worker(s) about movement abnormalities.
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.interviewWorkers === "Y" ? "Yes" : "No"}
                  </div>
                  {values.interviewWorkers === "Y" && (
                    <div
                      style={{
                        width: "75%",
                        margin: "0 0 0 0",
                        display: "inline-block",
                        background: "#f5f7fb",
                        padding: "10px",
                        border: "1px solid #b5b6b9",
                        wordWrap: "break-word",
                      }}
                    >
                      {values.workerSteps}
                    </div>
                  )}
                </div>
              </div>

              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Call consignee to confirm what was ordered and received (if
                  anything)
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  ></div>
                  <div>Phone Number: </div> <br />
                  <div
                    style={{
                      width: "75%",
                      margin: "0 0 0 0",
                      display: "inline-block",
                      background: "#f5f7fb",
                      padding: "10px",
                      border: "1px solid #b5b6b9",
                      wordWrap: "break-word",
                    }}
                  >
                    {values.consigneePhone}
                  </div>
                  <br />
                  <br />
                  <div>Consignee Name: </div> <br />
                  <div
                    style={{
                      width: "75%",
                      margin: "0 0 0 0",
                      display: "inline-block",
                      background: "#f5f7fb",
                      padding: "10px",
                      border: "1px solid #b5b6b9",
                      wordWrap: "break-word",
                    }}
                  >
                    {values.consigneeName}
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Is the consignee still short?
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.consigneeShort === "Y" ? "Yes" : "No"}
                  </div>
                  {values.consigneeShort === "Y" && (
                    <>
                      <div>
                        {" "}
                        What are the item numbers received and still missing?{" "}
                      </div>{" "}
                      <br />
                      <div
                        style={{
                          width: "75%",
                          margin: "0 0 0 0",
                          display: "inline-block",
                          background: "#f5f7fb",
                          padding: "10px",
                          border: "1px solid #b5b6b9",
                          wordWrap: "break-word",
                        }}
                      >
                        {values.itemNumbers}
                      </div>
                      <br />
                      <br />
                    </>
                  )}

                  {values.consigneeShort === "Y" && (
                    <>
                      <div> What was ordered and received for each PO? </div>{" "}
                      <br />
                      <div
                        style={{
                          width: "75%",
                          margin: "0 0 0 0",
                          display: "inline-block",
                          background: "#f5f7fb",
                          padding: "10px",
                          border: "1px solid #b5b6b9",
                          wordWrap: "break-word",
                        }}
                      >
                        {values.orderReceived}
                      </div>
                      <br />
                      <br />
                    </>
                  )}

                  {values.consigneeShort === "Y" && (
                    <>
                      <div> Was the freight received labeled for them? </div>{" "}
                      <br />
                      <div
                        style={{
                          width: "75%",
                          margin: "0 0 0 0",
                          display: "inline-block",
                          background: "#f5f7fb",
                          padding: "10px",
                          border: "1px solid #b5b6b9",
                          wordWrap: "break-word",
                        }}
                      >
                        {values.freightLabeled}
                      </div>
                      <br />
                      <br />
                    </>
                  )}
                </div>
              </div>

              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Call Shipper to confirm what was shipped, how it was packaged
                  and the value?
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  ></div>
                  <div> Phone Number: </div> <br />
                  <div
                    style={{
                      width: "75%",
                      margin: "0 0 0 0",
                      display: "inline-block",
                      background: "#f5f7fb",
                      padding: "10px",
                      border: "1px solid #b5b6b9",
                      wordWrap: "break-word",
                    }}
                  >
                    {values.shipperPhone}
                  </div>
                  <br />
                  <br />
                  <div> Shipper Name: </div> <br />
                  <div
                    style={{
                      width: "75%",
                      margin: "0 0 0 0",
                      display: "inline-block",
                      background: "#f5f7fb",
                      padding: "10px",
                      border: "1px solid #b5b6b9",
                      wordWrap: "break-word",
                    }}
                  >
                    {values.shipperName}
                  </div>
                  <br />
                  <br />
                  <div> What are the details of what shipped? </div> <br />
                  <div
                    style={{
                      width: "75%",
                      margin: "0 0 0 0",
                      display: "inline-block",
                      background: "#f5f7fb",
                      padding: "10px",
                      border: "1px solid #b5b6b9",
                      wordWrap: "break-word",
                    }}
                  >
                    {values.shippedDetails}
                  </div>
                  <br />
                  <br />
                  <div>Was this short shipped?</div>
                  <br />
                  <div
                    style={{
                      width: "75%",
                      margin: "0 0 0 0",
                      display: "inline-block",
                      background: "#f5f7fb",
                      padding: "10px",
                      border: "1px solid #b5b6b9",
                      wordWrap: "break-word",
                    }}
                  >
                    {values.shortShipped}
                  </div>
                  <br />
                  <br />
                  <div> What is the value of the product? </div> <br />
                  <div
                    style={{
                      width: "75%",
                      margin: "0 0 0 0",
                      display: "inline-block",
                      background: "#f5f7fb",
                      padding: "10px",
                      border: "1px solid #b5b6b9",
                      wordWrap: "break-word",
                    }}
                  >
                    {values.productValue}
                  </div>
                  <br />
                  <br />
                  <div>
                    {" "}
                    Were there multiple shipments for the same consignee that
                    day?{" "}
                  </div>{" "}
                  <br />
                  <div
                    style={{
                      width: "75%",
                      margin: "0 0 0 0",
                      display: "inline-block",
                      background: "#f5f7fb",
                      padding: "10px",
                      border: "1px solid #b5b6b9",
                      wordWrap: "break-word",
                    }}
                  >
                    {values.multShipments}
                  </div>
                  <br />
                  <br />
                </div>
              </div>
              {/* F18 Notes */}
              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>F18 Notes</div>

                <div>
                  <div
                    style={{
                      width: "75%",
                      margin: "0 0 0 0",
                      display: "inline-block",
                      background: "#f5f7fb",
                      padding: "10px",
                      border: "1px solid #b5b6b9",
                      wordWrap: "break-word",
                    }}
                  >
                    {values.day3F18Note}
                  </div>
                </div>
              </div>
            </div>
          )}
          {props.currentDay === ASInvestigationWizardStep[3] && (
            <div id="as-in-day4">
              <div style={{ width: "100%", fontFamily: "calibri" }}>
                <div
                  style={{
                    width: "100vw",
                    background: "#f5f7fb",
                    padding: "10px 10px 15px",
                    lineHeight: "40px",
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginBottom: "15px",
                    textTransform: "uppercase",
                  }}
                >
                  All Shortage Investigation Day 4{" "}
                  <span
                    style={{
                      background: "#f5f5f5",
                      border: "1px solid #d4d4d4",
                      padding: "5px 15px",
                      borderRadius: "20px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    #{props?.osdNumber}
                  </span>
                </div>
              </div>
              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Check 78/18 and/or Power BI for any overages (OV, NB, DO) to
                  apply
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.check7818 === "Y" ? "Yes" : "No"}
                  </div>
                  <div
                    style={{
                      width: "75%",
                      margin: "0 0 0 0",
                      display: "inline-block",
                      background: "#f5f7fb",
                      padding: "10px",
                      border: "1px solid #b5b6b9",
                      wordWrap: "break-word",
                    }}
                  >
                    {values.check7818 === "Y" && values.details7818}
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Did this show manifested to the destination terminal (even if
                  NF)?
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.manifested === "Y" ? "Yes" : "No"}
                  </div>
                  {values.manifested === "Y" && (
                    <>
                      <div>Check cameras at DT and report findings</div>
                      <br />
                      <div
                        style={{
                          width: "75%",
                          margin: "0 0 0 0",
                          display: "inline-block",
                          background: "#f5f7fb",
                          padding: "10px",
                          border: "1px solid #b5b6b9",
                          wordWrap: "break-word",
                        }}
                      >
                        {values.cameraFindings}
                      </div>
                    </>
                  )}
                </div>
              </div>
              {/* F18 Notes */}
              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>F18 Notes</div>

                <div>
                  <div
                    style={{
                      width: "75%",
                      margin: "0 0 0 0",
                      display: "inline-block",
                      background: "#f5f7fb",
                      padding: "10px",
                      border: "1px solid #b5b6b9",
                      wordWrap: "break-word",
                    }}
                  >
                    {values.day4F18Note}
                  </div>
                </div>
              </div>
            </div>
          )}
          {props.currentDay === ASInvestigationWizardStep[4] && (
            <div id="as-in-day5">
              <div style={{ width: "100%", fontFamily: "calibri" }}>
                <div
                  style={{
                    width: "100vw",
                    background: "#f5f7fb",
                    padding: "10px 10px 15px",
                    lineHeight: "40px",
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginBottom: "15px",
                    textTransform: "uppercase",
                  }}
                >
                  All Shortage Investigation Day 5{" "}
                  <span
                    style={{
                      background: "#f5f5f5",
                      border: "1px solid #d4d4d4",
                      padding: "5px 15px",
                      borderRadius: "20px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    #{props?.osdNumber}
                  </span>
                </div>
              </div>
              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Contact the terminals that handled the freight to find leads
                  and check cameras if applicable
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.contactTerminals === "Y" ? "Yes" : "No"}
                  </div>

                  {values.contactTerminals === "Y" && (
                    <>
                      {_.get(values, "freightTerminalLeadInquiries", []).map(
                        (_i: IDay5Investigation, index: number) => (
                          <React.Fragment key={index + ""}>
                            <div style={{ marginBottom: "10px" }}>
                              <h3 className="header-text mb-0">
                                Investigation details: {index + 1}
                              </h3>
                            </div>
                            <div style={{ marginBottom: "10px" }}>
                              <strong>TID:</strong>{" "}
                            </div>
                            <div
                              style={{
                                width: "75%",
                                margin: "0 0 0 0",
                                display: "inline-block",
                                background: "#f5f7fb",
                                padding: "10px",
                                border: "1px solid #b5b6b9",
                                wordWrap: "break-word",
                              }}
                            >
                              {_.get(
                                values,
                                `freightTerminalLeadInquiries[${index}].terminal`
                              )}
                            </div>

                            <div style={{ marginBottom: "10px" }}>
                              <strong>Manifest #:</strong>{" "}
                            </div>
                            <div
                              style={{
                                width: "75%",
                                margin: "0 0 0 0",
                                display: "inline-block",
                                background: "#f5f7fb",
                                padding: "10px",
                                border: "1px solid #b5b6b9",
                                wordWrap: "break-word",
                              }}
                            >
                              {_.get(
                                values,
                                `freightTerminalLeadInquiries[${index}].manifest`
                              )}
                            </div>

                            <div style={{ marginBottom: "10px" }}>
                              <strong>
                                List any discrepancies or abnormalities:
                              </strong>{" "}
                            </div>
                            <div
                              style={{
                                width: "75%",
                                margin: "0 0 0 0",
                                display: "inline-block",
                                background: "#f5f7fb",
                                padding: "10px",
                                border: "1px solid #b5b6b9",
                                wordWrap: "break-word",
                              }}
                            >
                              {_.get(
                                values,
                                `freightTerminalLeadInquiries[${index}].discrepancies`
                              )}
                            </div>
                          </React.Fragment>
                        )
                      )}
                    </>
                  )}
                </div>
              </div>
              {/* F18 Notes */}
              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>F18 Notes</div>

                <div>
                  <div
                    style={{
                      width: "75%",
                      margin: "0 0 0 0",
                      display: "inline-block",
                      background: "#f5f7fb",
                      padding: "10px",
                      border: "1px solid #b5b6b9",
                      wordWrap: "break-word",
                    }}
                  >
                    {values.day5F18Note}
                  </div>
                </div>
              </div>
            </div>
          )}
          {props.currentDay === ASInvestigationWizardStep[5] && (
            <div id="as-in-day10">
              <div style={{ width: "100%", fontFamily: "calibri" }}>
                <div
                  style={{
                    width: "100vw",
                    background: "#f5f7fb",
                    padding: "10px 10px 15px",
                    lineHeight: "40px",
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginBottom: "15px",
                    textTransform: "uppercase",
                  }}
                >
                  All Shortage Investigation Day 10{" "}
                  <span
                    style={{
                      background: "#f5f5f5",
                      border: "1px solid #d4d4d4",
                      padding: "5px 15px",
                      borderRadius: "20px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    #{props?.osdNumber}
                  </span>
                </div>
              </div>
              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Follow up on all leads/request from previous days
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.followUp === "Y" ? "Yes" : "No"}
                  </div>
                  {values.followUp === "Y" && (
                    <div
                      style={{
                        width: "75%",
                        margin: "0 0 0 0",
                        display: "inline-block",
                        background: "#f5f7fb",
                        padding: "10px",
                        border: "1px solid #b5b6b9",
                        wordWrap: "break-word",
                      }}
                    >
                      {values.followUpDetails}
                    </div>
                  )}
                </div>
              </div>
              {/* F18 Notes */}
              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>F18 Notes</div>

                <div>
                  <div
                    style={{
                      width: "75%",
                      margin: "0 0 0 0",
                      display: "inline-block",
                      background: "#f5f7fb",
                      padding: "10px",
                      border: "1px solid #b5b6b9",
                      wordWrap: "break-word",
                    }}
                  >
                    {values.day10F18Note}
                  </div>
                </div>
              </div>
            </div>
          )}
          {props.currentDay === ASInvestigationWizardStep[6] && (
            <div id="as-in-day15">
              <div style={{ width: "100%", fontFamily: "calibri" }}>
                <div
                  style={{
                    width: "100vw",
                    background: "#f5f7fb",
                    padding: "10px 10px 15px",
                    lineHeight: "40px",
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginBottom: "15px",
                    textTransform: "uppercase",
                  }}
                >
                  All Shortage Investigation Day 15{" "}
                  <span
                    style={{
                      background: "#f5f5f5",
                      border: "1px solid #d4d4d4",
                      padding: "5px 15px",
                      borderRadius: "20px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    #{props?.osdNumber}
                  </span>
                </div>
              </div>
              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Check 78/18 and/or Power BI for any overages (OV, NB, DO) to
                  apply
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.check7818_2 === "Y" ? "Yes" : "No"}
                  </div>
                  {values.check7818_2 === "Y" && (
                    <div
                      style={{
                        width: "75%",
                        margin: "0 0 0 0",
                        display: "inline-block",
                        background: "#f5f7fb",
                        padding: "10px",
                        border: "1px solid #b5b6b9",
                        wordWrap: "break-word",
                      }}
                    >
                      {values.details7818_2}
                    </div>
                  )}
                </div>

                <div style={{ margin: "20px 0 10px" }}>
                  Is this high value or high quantity?
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.highValue === "Y" ? "Yes" : "No"}
                  </div>
                </div>
              </div>
              {/* F18 Notes */}
              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>F18 Notes</div>

                <div>
                  <div
                    style={{
                      width: "75%",
                      margin: "0 0 0 0",
                      display: "inline-block",
                      background: "#f5f7fb",
                      padding: "10px",
                      border: "1px solid #b5b6b9",
                      wordWrap: "break-word",
                    }}
                  >
                    {values.day15F18Note}
                  </div>
                </div>
              </div>{" "}
            </div>
          )}
          {props.currentDay === ASInvestigationWizardStep[7] && (
            <div id="as-in-day20">
              <div style={{ width: "100%", fontFamily: "calibri" }}>
                <div
                  style={{
                    width: "100vw",
                    background: "#f5f7fb",
                    padding: "10px 10px 15px",
                    lineHeight: "40px",
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginBottom: "15px",
                    textTransform: "uppercase",
                  }}
                >
                  All Shortage Investigation Day 20{" "}
                  <span
                    style={{
                      background: "#f5f5f5",
                      border: "1px solid #d4d4d4",
                      padding: "5px 15px",
                      borderRadius: "20px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    #{props?.osdNumber}
                  </span>
                </div>
              </div>
              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>
                  Final re-check of all leads/requests/overages to confirm the
                  freight cannot be located at this time
                </div>

                <div>
                  <div
                    style={{
                      width: "15vw",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {values.finalReCheck === "Y" ? "Yes" : "No"}
                  </div>
                  {values.finalReCheck === "Y" && (
                    <div
                      style={{
                        width: "75%",
                        margin: "0 0 0 0",
                        display: "inline-block",
                        background: "#f5f7fb",
                        padding: "10px",
                        border: "1px solid #b5b6b9",
                        wordWrap: "break-word",
                      }}
                    >
                      {values.detailsOfSearch}
                    </div>
                  )}
                </div>
              </div>
              {/* F18 Notes */}
              <div
                style={{
                  width: "calc(100vw - 40px)",
                  margin: "0 20px 20px",
                  display: "inline-block",
                }}
              >
                <div style={{ margin: "0 0 10px" }}>F18 Notes</div>

                <div>
                  <div
                    style={{
                      width: "75%",
                      margin: "0 0 0 0",
                      display: "inline-block",
                      background: "#f5f7fb",
                      padding: "10px",
                      border: "1px solid #b5b6b9",
                      wordWrap: "break-word",
                    }}
                  >
                    {values.day20F18Note}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ASInvestigationTemplate;
