import React, { useEffect, useState } from "react";
import {
  Checkbox,
  ImageList,
  ImageListItem,
} from "@mui/material";
import { IProImage } from "../../models/master";
import { useDispatch } from "react-redux";
import { PopoutNotification } from "../../AppState/popoutNotification";
import NOTIFICATION_TYPE from "../../AppState/NotificationType";
import { APP } from "../../constants/app";
import { getProImages } from "../../API/getProImages";
import Loader from "../loader/loader";
type Props = {
  osdNumber?: number;
  setSelectedImages: (imageName: string[]) => void;
  selectedImages: string[];
  handleImageSelect: (imageName: string) => void;
  isSendAPBScreen?: boolean;
  exceptionType?: string;
};
const SendAPBImageList: React.FC<Props> = ({
  osdNumber,
  setSelectedImages,
  selectedImages,
  handleImageSelect,
  exceptionType = "",
  isSendAPBScreen = true
}) => {
  const dispatch = useDispatch();
  const [images, setImages] = useState<IProImage[]>([]);
  const [imageLoading, setImageLoading] = useState<boolean>(true);

  useEffect(() => {
    if (osdNumber) {
      const fetchImages = async () => {
        try {
          const { data } = await getProImages({
            osdNumber,
            documentFilter: ["DS", "AS"].indexOf(exceptionType.trim()) !== -1 
            ?  APP.overage.shortageSendAPBImageType
            : APP.overage.sendAPBImageType,
          });
          const sortedImages = data.sort((a: IProImage, b: IProImage) =>
            a.imageFilename!.localeCompare(b.imageFilename!)
          );
          setImages(sortedImages);
          setSelectedImages(
            data.map((image: IProImage) => image.imageFilename || "")
          );
          if (data.length === 0 && isSendAPBScreen) {
            dispatch(
              PopoutNotification(
                NOTIFICATION_TYPE.INFO,
                "No image available",
                Date.now().toString()
              )
            );
          }
        } catch (error) {
          if(isSendAPBScreen){
            dispatch(
              PopoutNotification(
                NOTIFICATION_TYPE.ERROR,
                typeof error === "string" ? error : "Image API error",
                Date.now().toString()
              )
            );
          }
        } finally {
          setImageLoading(false);
        }
      };

      fetchImages();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [osdNumber]);

  return (
    <div className="content-area osd-description">
      <ImageList cols={8} rowHeight={200} className="margin-grid">
        {images.map((image: IProImage) => (
          <ImageListItem key={image.imageFilename} className="grid-image">
            <img src={image.imageFilename} alt={image.typeDescription} />
            <Checkbox
              checked={selectedImages.includes(image.imageFilename || "")}
              onChange={() =>
                handleImageSelect &&
                handleImageSelect(image.imageFilename || "")
              }
              className="img-checkbox"
              style={{ transform: "scale(1.5)" }}
              color="success"
              sx={{
                color: "#fff",
                "&.Mui-checked": {
                  color: "#3db3c5",
                },
              }}
              disableRipple
            />
            <div className="image-legend">{image.docType}</div>
          </ImageListItem>
        ))}
      </ImageList>

      {images.length === 0 && !imageLoading && (
        <div className="no-image">
          No images found{isSendAPBScreen ?  ", click on Send button to send email" : ""}
        </div>
      )}
      <Loader pageLoader={imageLoading} />
    </div>
  );
};

export default SendAPBImageList;
